export default {
  // When the bound element is inserted into the DOM...
  inserted: function(el, binding, vnode) {
    if (!binding.value || !binding.value.ref || !binding.value.isnew) {
      return;
    }
    new Animation(el, binding).start();
  },
}

const durations = {
  'headerTransitionDuration' : 500,
}

class Animation {
  constructor(el, binding) {
    this.el = el;
    this.node = binding.value.ref;
    this.h = this.node.clientHeight;
    this.header = document.getElementById("cb-header");
  }

  start() {
    this.beforeStart();
    window.cbTimeout(() => {
      this._start();
    });
    window.cbTimeout(() => {
      this.afterStart();
    },durations.headerTransitionDuration);
  }
  //------------------------------------------------------------------------------
  //  Calculate app height - header height + body content height
  //  Fade the body content and start the animation
  //------------------------------------------------------------------------------

  beforeStart() {
    this.node.style['opacity'] = 0;
    this.node.style['height'] = this.header.$prevHeight ? this.header.$prevHeight : "64px";
    window.addClass(this.el, "cb-transition__none");
  }

  //------------------------------------------------------------------------------
  // start animating header height from prev height / min height
  //------------------------------------------------------------------------------

  _start() {
    this.node.style['height'] = this.h + "px";
    this.node.style['opacity'] = 1;
  }

  //------------------------------------------------------------------------------
  // reset header height to auto
  // update prevHeight variable to current header height
  //------------------------------------------------------------------------------

  afterStart() {
    this.header.$prevHeight = this.node.style.height;
    this.node.style.height = 'auto';
    window.removeClass(this.el, "cb-transition__none");
  }

}
