<template>
  <div class="cb-placeholder" :class="deriveClass">
  </div>
</template>
<script type="text/javascript">
export default {
  props: ['customClass'],
  computed: {
    deriveClass() {
      var output = {
        'cb-placeholder__sm': this.containsClass('sm'),
        'cb-placeholder__st': this.containsClass('st'),
        'cb-placeholder__md': this.containsClass('md'),
        'cb-placeholder__xl': this.containsClass('xl'),
        'cb-placeholder__rect--sm': this.containsClass('rect-sm'),
        'cb-placeholder__rect--md': this.containsClass('rect-md'),
        'cb-placeholder__rect--lg': this.containsClass('rect-lg'),
        'cb-placeholder__rect--xl': this.containsClass('rect-xl'),
        'cb-placeholder__rect--xxl': this.containsClass('rect-xxl'),
        'cb-placeholder__center': this.containsClass('center'),
        'cb-placeholder__mobile': this.containsClass('mobile'),
      }
      this.otherClasses().forEach(c => output[c] = true);
      return output;
    }
  },
  methods: {
    otherClasses() {
      return this.classList.filter(c => ['sm', 'st', 'md', 'xl', 'rect-sm','rect-md','rect-lg', 'rect-xl', 'rect-xxl', 'center', 'mobile'].indexOf(c) == -1);
    },
    containsClass(className) {
      return this.classList.indexOf(className) > -1;
    },
  },
  data() {
    return {
      classList: []
    }
  },
  mounted() {
    this.classList = this.customClass.split(' ');
  },
}
</script>