<style src="@/assets/styles/components/collections/form/phone.styl" lang="stylus" scoped></style>
<template>
  <div
    class="cb-field"
    :class="[{'cb-field--active': internalValue || focussed || countryCode}, customClass,{'cb-field--focus': focussed}, {'cb-field--error':!!errorMessage && !focussed}, {'cb-field--sibling':siblingError},{'cb-field--select-alt': this.config.meta.help_text}]"
  >
    <div class="cb-field__phone">
      <div class="cb-field__num">
        <input
          ref="tel"
          type="tel"
          class="cb-field__control"
          :readonly="readonly"
          :name="name"
          :aria-disabled="readonly"
          :disabled="readonly"
          :class="{'cb-field__readonly':readonly}"
          :aria-required="required"
          :id="name"
          @focus="focussed = true"
          :value="phoneNum"
          @keydown="onKeyDown"
          @input="onTelChange"
          @blur="blur($event)"
          :maxlength="maxLength"
          :autocomplete="autocompleteMapping"
        >
        <label
          class="cb-field__label"
          :class="{'cb-field__label--s': isShort}"
          :for="name"
        >{{displayLabel}}</label>
        <div class="cb-field__help" v-if="this.config.meta.help_text">
          <template>
            <div
              class="cb-field__trigger"
              :class="{'active-opacity': helpTextShown}"
              @click="showHelpText($event)"
              ref="helpText"
            ></div>
            <transition name="help-text">
              <div class="cb-field__menu" v-show="helpTextShown">{{this.config.meta.help_text}}</div>
            </transition>
          </template>
        </div>
        <div class="cb-field__error" v-if="errorMessageWrapper && !focussed">{{errorMessageWrapper}}</div>
      </div>
      <div
        class="cb-field__ext"
        :class="[{'cb-field__ext--active': swapControl}, customExtClass, {'cb-field__ext--after': internalValue || focussed || countryCode}]"
      >
        <input
          type="tel"
          autocomplete="false"
          ref="countryCodeDisplay"
          class="cb-field__placeholder"
          :value="countryCode"
          tabindex="-1"
          :placeholder="focussed && getCountryCode"
        >
        <select
          ref="select"
          class="cb-field__control"
          :readonly="readonly"
          :name="name"
          id="ext"
          :aria-required="required"
          @focus="focusControl()"
          @blur="blurControl()"
          @click="focusControl()"
          v-model="countryCode"
          @change="onCountryChange"
        >
          <option
            v-for="(option, index) in allowedValues()"
            :value="option.dial_code"
            v-bind:key="index"
          >{{ countryCodedisplayName(option) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
    import FieldMixin from "@/mixins/field-mixin";
    import AutoCompleteMapping from "@/utils/auto-complete-mapping.js";
    import CountryIsdList from "@/utils/country-isd-code";

    export default {
        name: "phone",
        props: ["name", "customClass", "useparent", "options", "siblingError"],
        mixins: [FieldMixin],
        created() {
            this.pName = this.findParentForm();
        },
        watch: {
            internalValue(newValue, oldValue) {
                this.updateInput(this.countryCode, newValue);
            }
        },
        data() {
            return {
                placeholder: "",
                swapControl: false,
                focussed: false,
                countryCode: "",
                phoneNum: undefined,
                defaultFormat: "+. ... ... ....",
                maxLength: 20
            };
        },
        methods: {
            onKeyDown(event) {
                (event.key && /[^A-Za-z0-9]/.test(event.key)) && event.preventDefault();

            },
            onCountryChange(event) {
                this.updateInput(event.target.value, this.phoneNum);

            },
            onTelChange(event) {
                this.updateInput(this.countryCode, event.target.value, event);

            },
            updateInput(countryCode, tel, event) {
                let storeValue = "",
                    formatted;
                this.phoneNum = "";
                countryCode = countryCode || this.getCountryCode;
                formatted = this.formatValues({countryCode, tel});
                this.phoneNum = formatted.tel;
                this.countryCode = formatted.countryCode;
                storeValue = this.getAlphaNumericValue(formatted.tel) ?  this.countryCode + this.getAlphaNumericValue(formatted.tel): '';
                if (event) {
                    let cursorStart = event.target.selectionStart,
                        cursorEnd = event.target.selectionEnd;
                    if (tel.length !== formatted.tel.length) {
                        let diff = Math.abs(formatted.tel.length - tel.length);
                        cursorStart = cursorEnd = cursorEnd + diff;
                    }
                    this.$nextTick(() => {
                        event.target.setSelectionRange(cursorStart, cursorEnd);
                    });
                }
                this.$store.dispatch("update_form_input", {
                    name: this.name,
                    value: storeValue,
                    formName: this.pName
                });
            },
            allowedValues() {
                return CountryIsdList;
            },
            countryCodedisplayName(option) {
                return option.name + "(" + option.dial_code + ")";
            },
            focusControl() {
                this.swapControl = true;
                this.focussed = true;
            },
            blurControl() {
                this.swapControl = false;
                this.focussed = false;
            },
            focusExt(e) {
                this.$refs.select.focus();
            },
            blur(event) {
                this.focussed = false;
                this.validate(true);
            },
            formatValues({countryCode, tel}) {
                if (!tel) {
                    return {countryCode, tel};
                }
                let countryMeta;
                if (tel.startsWith("+")) {
                    countryMeta = this.getCountryMeta(tel);
                    countryMeta && (tel = tel.substring(countryMeta.dial_code.length));
                }
                if (!countryMeta) {
                    if (countryCode) {
                        countryMeta = this.getCountryMeta(countryCode);
                    } else {
                        return {countryCode: null, tel};
                    }
                }
        
                tel = (
                    this.getFormattedPhoneNumber(
                        countryMeta.format,
                        this.getAlphaNumericValue(tel)
                    ) || tel
                ).substring(0, 14);
                countryCode = countryMeta.dial_code;
                return {countryCode, tel};
            },
            getAlphaNumericValue(tel) {
                return tel.replace(/[^A-Za-z0-9]/g, "");
            },
            getCountryMeta(str) {
                const DEFAULT_COUNTRY_CODE = "+1";
                str = str || DEFAULT_COUNTRY_CODE;
                let matchedMeta = CountryIsdList.find(countryMeta => {
                    return str.startsWith(countryMeta.dial_code);
                });
                CountryIsdList.forEach(countryMeta => {
                    if (str.includes(countryMeta.dial_code)) {
                        matchedMeta =
                            countryMeta.dial_code.length > matchedMeta.dial_code.length
                                ? countryMeta
                                : matchedMeta;
                    }
                });
                return matchedMeta;
            },
            getFormattedPhoneNumber(format, strippedInternalValue) {
                format = format || this.defaultFormat;
                let phoneFormat = format.split(" ").shift();
                let temp = "";
                format = format.substring(phoneFormat.length + 1, format.length);

                // Trim starting zeros
                // https://mychargebee.atlassian.net/browse/CHKOUTENGG-4905
                const allowedDigitsCount = format.match(/\./g).length
                const excessDigitsCount =  strippedInternalValue.length - allowedDigitsCount

                if (excessDigitsCount > 0) {
                    const regex = new RegExp(`^0{0,${excessDigitsCount}}`)
                    strippedInternalValue = strippedInternalValue.replace(regex, '')
                }

                for (
                    var i = 0, j = 0;
                    i < format.length && j < strippedInternalValue.length;
                    i++
                ) {
                    temp += format[i] == "." ? strippedInternalValue[j++] : format[i];
                }
                return temp;
            }
        },
        computed: {
            customExtClass() {
                if (!this.countryCode && !this.getCountryCode) {
                    return;
                }
                switch (
                (this.countryCode && this.countryCode.length) ||
                (this.getCountryCode && this.getCountryCode.length)
                    ) {
                    case 2:
                        return "cb-field__ext--sm";
                    case 4:
                        return "cb-field__ext--lg";
                    case 5:
                        return "cb-field__ext--xl";
                }
            },
            getCountryCode() {
                return this.defaultCountryCode ? this.defaultCountryCode : "+1";
            },
            defaultCountryCode() {
                let countryName = this.pName == "card" ? "billing_country" : "country";
                if (
                    this.$store.getters.fields(this.pName) &&
                    this.$store.getters.field_config(this.pName, countryName)
                ) {
                    let value = this.$store.getters.field_config(this.pName, countryName)
                        .value;
                    //check if present in allowed values
                    let arr =
                        this.allowedValues() &&
                        this.allowedValues().filter(av => av.code == value);
                    return arr && arr[0] && arr[0].dial_code;
                }
                return false;
            },
            autocompleteMapping() {

                var mapping = AutoCompleteMapping[`${this.pName}[${this.name}]`];
                return mapping ? mapping : "off";
            }
        }
    };
</script>
