import TextField from "@/components/collections/form/TextField"
import ExpiryField from "@/components/collections/form/ExpiryField"
import CardField from "@/components/modules/hosted-pages/CardField"
import AddressComponent from "@/components/modules/hosted-pages/AddressComponent"
import ButtonField from "@/components/collections/form/ButtonComponent"
import RegionField from "@/components/collections/form/RegionField"
import SelectField from "@/components/collections/form/SelectField"
import TextArea from "@/components/collections/form/TextArea"
import CheckBox from "@/components/collections/form/Checkbox"
import Phone from "@/components/collections/form/Phone"
import Timestamp from "@/components/collections/form/Timestamp"
import DateField from "@/components/collections/form/DateField"
import TimeField from "@/components/collections/form/TimeField"
import Vue from "vue"
export default {
	computed: {
		config() {
       return this.$store.state.form[this.fname] && this.$store.state.form[this.fname].meta;
    },
    config_in_twos() {
      let fields = this.config.filter(i => (i.type != 'hidden') );
      return fields.reduce((o, field, index) => {
        let last = o[o.length - 1];
        if ((last && last.length == 2) || !last) {
          o.push([field]);
        } else if (last[0].short_field && last[0].label.length <= 18 && field.short_field) {
          last.push(field);
        } else {
          o.push([field]);
        }
        return o;
      }, [])
    }
	},
	methods: {
		componentName(type) {
      switch (type) {
        case "text":
          return "cb-text";
        case "textarea":
          return "cb-textarea";
        case "expiry":
          return "cb-expiry";
        case "region":
          return "cb-region";
        case "select":
          return "cb-select";
        case "hidden":
          return;
        case "checkbox":
          return "cb-checkbox";
        case "address":
          return "cb-address";
        case "card":
          return "cb-card";
        case "tel":
          return "cb-phone";
        case "timestamp":
          return "cb-timestamp";
        case "date":
          return "cb-date";
        case "time":
          return "cb-date";
      }
      return "cb-text"
    },
    informError(index, siblingIndex) {
      if(!this.siblingError[index]) {
        Vue.set(this.siblingError, index + "", {});
      }
      Vue.set(this.siblingError[index + ""], siblingIndex + "",true);
    },
    clearError(index, siblingIndex) {
      if(!this.siblingError[index]) {
        Vue.set(this.siblingError, index + "", {});
      }
      Vue.set(this.siblingError[index + ""], siblingIndex + "", false);
    },
    showSiblingError(index, siblingIndex) {
      return this.siblingError[index + ""] && this.siblingError[index + ""][siblingIndex + ""];
    }
	},
	data() {
		return{
			siblingError: {}
		}
	},
	components: {
		'cb-text': TextField,
    'cb-region': RegionField,
    'cb-select': SelectField,
    'cb-address': AddressComponent,
    'cb-button': ButtonField,
    'cb-expiry': ExpiryField,
    'cb-card': CardField,
    'cb-textarea': TextArea,
    'cb-checkbox': CheckBox,
    'cb-phone': Phone,
    'cb-timestamp': Timestamp,
    'cb-date': DateField,
    'cb-time': TimeField
	}
}