import Raven from 'raven-js';

export default class ImportHelper {
    static wrapImport(a) {
        if(window.logger && window.logger.sentry) {
            return () => a.call().catch(err => Raven.captureException(new Error(err)))
        } else {
            return () => a.call()
        }
    }

    // Component Loader function
    // NOTE: Currently configured only for @/components/common/payment-methods
    static loadComponent (component) {
        return new Promise((resolve, reject) => {
            try {
                if(component) {
                    // Dynamic chunk naming does not work on static string
                    // Import String must be dynamic, webpack creates chunks of all suspected files under the specified static directory, ex. payment-methods
                    resolve(import(/* webpackChunkName: "[request]" */ "@/components/modules/hosted-pages/payment-methods/"+component+".vue").then(m => m.default))
                } else resolve(null)
            } catch(error) {
                reject(error)
            }
        })
    }

    // Create and append script tag to DOM
    static loadScriptTag (url, options) {

        return new Promise((resolve, reject) => {
            let script = document.createElement('script')
            script.async = true
            script.src = url
            script.type = 'text/javascript'

            // Resolve on script load
            script.onload = () => {
                resolve()
            }

            // Reject on script error
            script.onerror = () => {
                reject(new Error(url));
            }

            // Set non-standard attributes
            if(options && options.attributes) {
                Object.keys(options.attributes).map(attr => {
                    script.setAttribute(attr,options.attributes[attr].toString())
                })
            }

            if(options && options.appendIntoBody) {
                // Append to DOM body
                document.body.appendChild(script)
            } else {
                // Append to DOM head
                document.head.appendChild(script)
            }
        })
    }

    static isNamespacePresent(namespace, scope = window) {
        if(namespace) {
            // Checker function to check if namespace exists
            if(namespace.constructor === Function) return namespace()
            return !!(namespace && scope && scope[namespace])
        }
        return true
    }

    static checkGlobalNamespace(namespace, rootObj) {
        return new Promise((resolve, reject) => {
            // Check if the variable is already present
            if(ImportHelper.isNamespacePresent(namespace, rootObj)) {
                resolve()
            } else {
                let timeElapsed = 0
                const _interval = 100
                const _threshold = 10000
                let checker = setInterval(() => {
                    // Wait until namespace loads
                    if(ImportHelper.isNamespacePresent(namespace, rootObj)) {
                        clearInterval(checker)
                        resolve()
                    } else {
                        timeElapsed += _interval
                        if(timeElapsed > _threshold) {
                            clearInterval(checker)
                            reject(new Error(namespace))
                        }
                    }
                }, _interval)
            }
        })
    }

    /**
     * Function that Loads script tag, waits for namespace to load then resolves
     * {Url} - Script src url to inject
     * {Options} - Additional parameters/attributes for script tag
     * {namespace} - To ensure namespace is available after loading script (Accepts string or boolean Function)
     * {rootObj} - The scope under which the namespace is checked for availability if string namespace is specified
     *             Ex. window.braintree - for window.braintree.applePay
     */
    static loadScript(url, urlOptions, namespace, rootObj) {
        return new Promise((resolve, reject) => {
            if(ImportHelper.isNamespacePresent(namespace, rootObj)) {
                return resolve();
            } else {
                ImportHelper.loadScriptTag(url, urlOptions)
                    .then(() => ImportHelper.checkGlobalNamespace(namespace, rootObj))
                    .then(() => resolve())
                    .catch(e => {
                        console.error("Error loading script", url, e)
                        reject(e)
                    })
            }
        })
    }

  static loadCountries(locale, formName) {
    const languages = ["de", "en", "es", "fr", "it", "pt"];
    const chunk =
      languages.indexOf(locale) >= 0
        ? import(
            /* webpackChunkName: "[request]" */ `@/i18n/${locale}/countries.js`
          )
        : import(`@/i18n/en/countries.js`);
    return chunk.then((countries) =>
      countries.default
        .filter((thisCountry) => {
          if (!thisCountry) {
            return false;
          }
          if (thisCountry.hasOwnProperty("ignoreIn")) {
            if (formName) {
              if (
                thisCountry["ignoreIn"].some((thisForm) => thisForm === formName)
              ) {
                return false;
              }
            }
          }
          if (thisCountry.value === "XI") {
            if (
              !window.initInfo.hasOwnProperty("is_post_brexit") ||
              typeof window.initInfo["is_post_brexit"] !== "boolean" ||
              window.initInfo["is_post_brexit"] === false
            ) {
              return false;
            }
          }
          return true;
        })
        .sort((a, b) =>
          a.display_name > b.display_name
            ? 1
            : b.display_name > a.display_name
            ? -1
            : 0
        )
    );
  }
}
