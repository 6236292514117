import apiClient from "@/utils/cb-client"
import Vue from 'vue'
import jwtDecode from 'jwt-decode'
import CBNotification from "@/models/cb-notification"
import { uuid, JsEvents, sendToCbjs } from "@/utils/cbjs-helper"

const state = {
  authenticated: false,
  page_type: undefined,
  jwt: undefined,
  email: undefined,
  sso_token: undefined,
  sso_token_source: undefined,
  customer_handle: undefined,
  linked_handles: undefined,
  hpToken: undefined,
  sessionToken: uuid(),
  authType: undefined,
  authTokenType: undefined,
  current_notification: undefined,
  set_loader: false,
  is_preview: false,
  scopes: [],
  braintree_client_token: undefined,
  pm_token: "",
  pm_email: "",
  pm_username: "",
  pm_authorized: false,
  pm_consent: true,
  portal_redirect_url: undefined,
  has_password: false,
  login_time: undefined,
  linked_customers: undefined
}

const SET_JWT = "SET_JWT";
const SET_EMAIL = "SET_EMAIL";
const SET_AUTHENTICATED = "SET_AUTHENTICATED";
const SET_CUSTOMER_HANDLE = "SET_CUSTOMER_HANDLE";
const SET_LINKED_HANDLES = "SET_LINKED_HANDLES";
const APPEND_LINKED_HANDLES = "APPEND_LINKED_HANDLES"
const APPEND_LINKED_CUSTOMERS = "APPEND_LINKED_CUSTOMERS"
const SET_HP_TOKEN = "SET_HP_TOKEN";
const SET_AUTH_TYPE = "SET_AUTH_TYPE";
const SET_SSO_TOKEN = "SET_SSO_TOKEN";
const SET_PAGE_TYPE = "SET_PAGE_TYPE";
const SET_AUTH_TOKEN_TYPE = "SET_AUTH_TOKEN_TYPE";
const SET_CURRENT_NOTIFICATION = "SET_CURRENT_NOTIFICATION";
const SET_LOADER = "SET_LOADER";
const SET_IS_PREVIEW = "SET_IS_PREVIEW";
const SET_BRAINTREE_CLIENT_TOKEN = "SET_BRAINTREE_CLIENT_TOKEN"
const SET_PM_TOKEN = "SET_PM_TOKEN";
const SET_PM_EMAIL = "SET_PM_EMAIL";
const SET_PM_AUTHORIZED = "SET_PM_AUTHORIZED";
const SET_PM_CONSENT = "SET_PM_CONSENT";
const SET_HAS_PASSWORD = "SET_HAS_PASSWORD";
const SET_LOGIN_TIME = "SET_LOGIN_TIME";
const SET_LINKED_CUSTOMERS = "SET_LINKED_CUSTOMERS";
const SET_BUSINESS_ENTITY_ID = "SET_BUSINESS_ENTITY_ID";
const SET_PM_USERNAME = "SET_PM_USERNAME";

const actions = {
  authenticate_user({commit, state}) {
    var source = state.hpToken ? "checkout" : "portal";
    return apiClient.auth_service.authenticate({}, {id: state.customer_handle, source: source}).then((data) => {
      if(data.access_token){
        commit(SET_JWT, data.access_token);
        commit(SET_AUTHENTICATED, true);
        commit(SET_CUSTOMER_HANDLE, data.customer_handle);
      }
    });
  },
  verify_otp({commit, state}, otp) {
    var source = state.hpToken ? "checkout" : "portal";
    return apiClient.auth_service.verify_otp({}, {email: state.email, otp: otp, source: source }).then((data) => {
      if(data.access_token){
        commit(SET_JWT, data.access_token);
        commit(SET_AUTHENTICATED, true);
        commit(SET_LINKED_HANDLES, data.linked_handles);
        commit(SET_CUSTOMER_HANDLE, data.customer_handle);
        commit(SET_LINKED_CUSTOMERS, data.data);
        commit(SET_LOGIN_TIME, new Date().getTime());
        window.cbStorage.setItem("ct", new Date().getTime());
      }
    });
  },

  verify_password({commit, state}, password){
    var source = state.hpToken ? "checkout" : "portal";
    return apiClient.auth_service.verify_password({}, {email: state.email, password: password, source: source}).then((data) => {
      if(data.access_token){
        commit(SET_JWT, data.access_token);
        commit(SET_AUTHENTICATED, true);
        commit(SET_LINKED_HANDLES, data.linked_handles);
        commit(SET_CUSTOMER_HANDLE, data.customer_handle);
        commit(SET_LINKED_CUSTOMERS, data.data);
        commit(SET_LOGIN_TIME, new Date().getTime());
        window.cbStorage.setItem("ct", new Date().getTime());
      }
    });
  },

  logout_user({commit, state, dispatch}, error) {
    dispatch('init_auth');
  },

  remove_token_and_logout() {
    var token = window.cbStorage.getItem("cbt");
    window.cbStorage.removeItem("cbt");
    if(token) {
      return apiClient.auth_service.logout({}, {token: token});
    }
    else {
      return new Promise((resolve, reject) => resolve(true));
    }
  },

  activate_sso_token({commit, state}) {
    return apiClient.auth_service.activate_sso_token({}, {portal_session_token: state.sso_token, source: state.sso_token_source}).then((data) => {
      if(data.access_token){
        commit(SET_JWT, data.access_token);
        commit(SET_AUTHENTICATED, true);
        commit(SET_CUSTOMER_HANDLE, data.customer_handle);
        commit(SET_LOGIN_TIME, new Date().getTime());
        window.cbStorage.setItem("ct", new Date().getTime());
      }
    });
  },

  reset_auth({commit, state}) {
    commit(SET_JWT, undefined);
    commit(SET_AUTHENTICATED, false);
    commit(SET_CUSTOMER_HANDLE, undefined);
    commit(SET_LINKED_HANDLES,undefined);
    commit(SET_LINKED_CUSTOMERS, undefined);
    commit(SET_LOGIN_TIME, undefined);
    window.cbStorage.removeItem("ct");
    window.cbStorage.removeItem("lh");
  },

  check_user({commit, state}, {email, send_otp}) {
    return apiClient.auth_service.check_user({}, {email, send_otp}).then((data) => {
      commit(SET_EMAIL, email);
      commit(SET_HAS_PASSWORD, data.password_login);
      return data;
    });
  },
  check_user_if_existing({commit, state}, email) {
    return apiClient.auth_service.check_if_existing({hp_token: state.hpToken}, {email: email}).then((data) => {
      return data;
    });
  },
  check_user_checkout({commit, state}, {email, send_otp}) {
    return apiClient.auth_service.check_user_checkout({}, {email, send_otp}).then((data) => {
      commit(SET_EMAIL, email);
      return data;
    });
  },
  init_auth({commit, state, dispatch}) {
    let isPreview = window.initInfo && window.initInfo.is_preview;
    if(isPreview) {
      if(window.top == window || 
        ['/d/plans/','/hosted_pages/action_pages_preview', "/checkout_and_portal_settings","/dashboards","/brand_settings/preview/portal"].filter(i => document.referrer.indexOf(i) != -1 || (window?.__CB_HP_CONTEXT__?.referrer?.indexOf(i) != -1)).length == 0){
        apiClient.auth_service.clear_preview_cookie({}).then(() => {
          window.cbReload();
        });
        return;
      }
      commit(SET_IS_PREVIEW, isPreview);
    }
    var token = window.cbStorage.getItem("cbt");
    var ch = window.cbStorage.getItem("ch");
    var lh = window.cbStorage.getItem("lh") && JSON.parse(window.cbStorage.getItem("lh"));
    var lastCreatedAt = window.cbStorage.getItem("ct");
    // check should be based on default timeout
    if(token && (ch||lh) && lastCreatedAt &&
      (new Date().getTime() - Number(lastCreatedAt)) <= (60 * 60 * 1000)) {
      commit(SET_JWT, token);
      commit(SET_AUTHENTICATED, true);
      commit(SET_LINKED_HANDLES,lh);
      commit(SET_CUSTOMER_HANDLE, ch);
      commit(SET_LOGIN_TIME, lastCreatedAt);
    }
    else {
      commit(SET_JWT, undefined);
      commit(SET_AUTHENTICATED, false);
      commit(SET_CUSTOMER_HANDLE, undefined);
      commit(SET_LINKED_HANDLES,undefined);
      commit(SET_LOGIN_TIME, undefined);
      window.cbStorage.removeItem("ct");
    }
  },
  generate_braintree_token({commit, state}, pm){
    return new Promise((resolve, reject) => {
      if(state.braintree_client_token){
        resolve(state.braintree_client_token);
      }
      apiClient.braintree
        .generate_client_token({},{gw_id:pm.id, gw_currency:pm.gateway_currency, payment_type:pm.type})
        .then(data => {
            commit(SET_BRAINTREE_CLIENT_TOKEN, data.client_token);
            resolve(data.client_token);
        }, (error) => {
          logger.e(error);
          reject(error);
        });
    });
  },
  display_common_pm_error({dispatch, getters}){
    dispatch(
      "set_current_notification",
      new CBNotification("error", {
        isDismissible: true,
        message: getters.common_translate('payment_method.common_error')
      })
    );
  },
  reset_pm({commit, state}){
    commit(SET_PM_TOKEN, undefined);
    commit(SET_PM_EMAIL, undefined);
    commit(SET_PM_AUTHORIZED, false);
    commit(SET_PM_CONSENT, true);
    commit(SET_PM_USERNAME,undefined);
  },
  set_pm_token({commit,state}, token){
    commit(SET_PM_TOKEN, token);
  },
  set_pm_email({commit,state}, email){
    commit(SET_PM_EMAIL, email);
  },
  set_pm_username({commit,state}, username){
    commit(SET_PM_USERNAME,username);
  },
  set_pm_authorized({commit,state}, bool){
    commit(SET_PM_AUTHORIZED, bool);
  },
  set_pm_consent({commit,state}, bool){
    commit(SET_PM_CONSENT, bool);
  },
  set_hp_token({commit, state}, hpToken) {
    commit(SET_HP_TOKEN, hpToken);
  },
  set_auth_type({commit, state}, authType) {
    commit(SET_AUTH_TYPE, authType);
  },
  set_sso_token({commit, state}, options) {
    commit(SET_SSO_TOKEN, options);
  },
  set_host_origin({commit, state}, origin) {
    commit(SET_HOST_ORIGIN, origin);
  },
  set_page_type({commit, state}, page_type) {
    commit(SET_PAGE_TYPE, page_type);
  },

  set_hp_token_auth({commit, state}, customer_handle) {
    commit(SET_AUTH_TOKEN_TYPE, "hp_token");
    commit(SET_AUTHENTICATED, true);
    commit(SET_CUSTOMER_HANDLE, customer_handle);
  },

  set_current_notification({commit, state, dispatch}, error) {
    commit(SET_CURRENT_NOTIFICATION, error);
  },

  set_loader({commit, state, dispatch}, value) {
    commit(SET_LOADER, value);
  },

  set_has_password({commit}, value) {
    commit(SET_HAS_PASSWORD, value);
  },

  set_business_entity_id({commit}, business_entity_id) {
    commit(SET_BUSINESS_ENTITY_ID, business_entity_id)
    window.cbjsLoader.then(() => {
      const cbInstance = Chargebee.getInstance()
      if(cbInstance) cbInstance.setBusinessEntity(business_entity_id);
    })
  }
}

const mutations = {
  [SET_JWT] (state, jwt) {
    state.jwt = jwt;
    // should we do it here? TODO change this
    if(jwt) {
      sendToCbjs(JsEvents.SetAuthToken, { jwt })
      window.cbStorage.setItem("cbt", jwt);
      let decodedClaims = jwtDecode(jwt);
      state.scopes = decodedClaims.scopes || [];
      if(decodedClaims.hp_token) {
        state.hpToken = decodedClaims.hp_token;
      }
      state.portal_redirect_url = decodedClaims.portal_redirect;
    }
    else {
      sendToCbjs(JsEvents.SetAuthToken, { jwt: "" })
      window.cbStorage.removeItem("cbt");
    }
  },
  [SET_AUTHENTICATED] (state, authenticated) {
    state.authenticated = authenticated;
  },
  [SET_EMAIL] (state, email) {
    state.email = email;
  },
  [SET_HAS_PASSWORD] (state, has_password) {
    Vue.set(state, 'has_password', has_password);
  },
  [SET_CUSTOMER_HANDLE](state, customer_handle) {
    state.customer_handle = customer_handle;
    // should we do it here? TODO change this
    if(customer_handle) {
      sendToCbjs(JsEvents.SetCustomerHandle, { customer_handle })
      window.cbStorage.setItem("ch", customer_handle);
    }
    else {
      sendToCbjs(JsEvents.SetCustomerHandle, { customer_handle: "" })
      window.cbStorage.removeItem("ch");
    }
  },
  [SET_LINKED_HANDLES](state, linked_handles){
    state.linked_handles = linked_handles;
    if(linked_handles) {
    window.cbStorage.setItem("lh", JSON.stringify(linked_handles));
    }
    else {
      window.cbStorage.removeItem("lh");
    }
  },
  [APPEND_LINKED_HANDLES](state, linked_handles){
    if (!state.linked_handles) {
      state.linked_handles = [];
    }
    
    if (linked_handles && linked_handles.length > 0) {
      for (const newHandle of linked_handles) {
        const existingHandleIndex = state.linked_handles.findIndex(customer => customer.handle === newHandle.handle);
        if (existingHandleIndex === -1) {
          state.linked_handles.push(newHandle);
        }
      }
    }
  
    if (state.linked_handles.length > 0) {
      window.cbStorage.setItem("lh", JSON.stringify(state.linked_handles));
    } else {
      window.cbStorage.removeItem("lh");
    }
  },

  [APPEND_LINKED_CUSTOMERS](state, data){
    if (!state.linked_customers) {
      state.linked_customers = [];
    }
    if (data && data.length > 0) {
      for (const cus of data) {
        state.linked_customers.push(cus.customer);
      }
    }
  },

  [SET_LINKED_CUSTOMERS](state, linked_customers) {
    state.linked_customers = linked_customers && linked_customers.map(({customer}) => customer);
  },
  [SET_HP_TOKEN](state, hpToken) {
    state.hpToken = hpToken;
  },
  [SET_AUTH_TYPE] (state, authType) {
    state.authType = authType;
    if(authType) {
      window.cbStorage.setItem("authType", authType);
    }
    else {
      window.cbStorage.removeItem("authType");
    }
  },
  [SET_SSO_TOKEN] (state, options) {
    state.sso_token = options.token;
    state.sso_token_source = options.source;
  },

  [SET_PAGE_TYPE] (state, pageType) {
    state.page_type = pageType;
  },
  [SET_AUTH_TOKEN_TYPE] (state, authTokenType) {
    state.authTokenType = authTokenType;
  },
  [SET_CURRENT_NOTIFICATION] (state, current_notification) {
    Vue.set(state, 'current_notification', current_notification);
  },
  [SET_LOADER] (state, set_loader) {
    Vue.set(state, 'set_loader', set_loader);
  },
  [SET_IS_PREVIEW] (state, is_preview) {
    Vue.set(state, 'is_preview', is_preview);
  },
  [SET_BRAINTREE_CLIENT_TOKEN] (state, token) {
    Vue.set(state, 'braintree_client_token', token);
  },
  [SET_PM_TOKEN] (state, token) {
    Vue.set(state, "pm_token", token);
  },
  [SET_PM_EMAIL] (state, email) {
    Vue.set(state, "pm_email", email);
  },
  [SET_PM_USERNAME] (state, username) {
    Vue.set(state, "pm_username", username);
  },
  [SET_PM_AUTHORIZED] (state, bool) {
    Vue.set(state, "pm_authorized", bool);
  },
  [SET_PM_CONSENT] (state, bool) {
    Vue.set(state, "pm_consent", bool);
  },
  [SET_LOGIN_TIME] (state, time) {
    Vue.set(state, 'login_time', time);
  },
  [SET_BUSINESS_ENTITY_ID] (state, business_entity_id) {
    Vue.set(state, 'business_entity_id', business_entity_id);
  }
}

const getters = {
  authenticated: state => state.authenticated,
  authType: state => state.authType,
  hpToken: state => state.hpToken,
  sessionToken: state => state.sessionToken,
  customer_handle: state => state.customer_handle,
  business_entity_id: state => state.business_entity_id,
  linked_handles: state => state.linked_handles,
  is_auth_by_hp: state => state.authTokenType == "hp_token",
  current_notification: state => state.current_notification,
  set_loader: state => state.set_loader,
  current_error: state => (state.current_notification && state.current_notification.type == "error") ? state.current_notification : undefined,
  current_error_source: state => state.current_notification && state.current_notification && state.current_notification.type == "error" && state.current_notification.errorSource,
  is_preview: state => state.is_preview,
  pm_email: (state) => state.pm_email,
  pm_username: (state) => state.pm_username,
  pm_token: (state) => state.pm_token,
  pm_authorized: (state) => state.pm_authorized,
  pm_consent: (state) => state.pm_consent,
  portal_redirect: (state) => state.portal_redirect_url,
  has_password: state => state.has_password,
  login_time: state => state.login_time,
  linkedCustomer: state => (id) => state.linked_customers && state.linked_customers.filter(customer => customer.id == id)[0]
}

export const FULL_ACCESS = "FULL_ACCESS";
export const MANAGE_PAYMENTS = "MANAGE_PAYMENTS";
export const EXTEND_SUBSCRIPTION = "EXTEND_SUBSCRIPTION";

export default {
  state,
  actions,
  mutations,
  getters
}
