<template>
	<div class="cb-header__wrap cb-header__wrap--nav" @click="back" role="navigation">
		<div class="cb-header__title" v-cb-link = "'color'">
	    	<transition name="fade" mode="out-in">
	    		<div :key="header" class="cb-transition__fade--short"><i class="icon-angle-left cb-header__icon"></i><span class="cb-header__text">{{header}}</span></div>
	    	</transition>
	    </div>
	</div>
</template>
<script type="text/javascript">
	export default  {
		props: ['editMode'],
		computed: {
			header() {
				if(this.$route.meta.headerTitle) {
					return this.$store.getters.translate(this.$route.meta.headerTitle);
				}

    			return this.$store.getters.translate(this.editMode ? `${this.$route.name}.header.edit`: `${this.$route.name}.header`);
  			}
		},
		methods: {
			back() {
				if(this.$store.getters.hosted_page_type == "portal" && this.$store.state.config.backToEntryPoint) {
					this.$router.customPush({name : this.$route.query.source});
					this.$store.dispatch("set_back_to_entry_point", false);
				} else {
					this.$router.go(-1);
				}
			}
		}
	}
</script>
