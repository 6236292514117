import RestClient from "./rest-client"
import CbError from "./cb-error.js"
import BulkApi from "@/utils/bulk-api";
import { deepGet } from "@/utils/helper-functions"
import RecaptchaUtil from '@/utils/recaptcha';

let constructQueryParams = function(allowed, params) {
  return allowed.reduce((o, p) => {
    if(params[p]) {
      o += `${p}=${params[p]}&`;
    }
    return o;
  }, "");
}

export function removeValuesWithObjectType(obj) {
  return Object.keys(obj).reduce((a, k) => {
    if(!(obj[k] && obj[k].constructor == Object)) {
      a[k] = obj[k];
    }
    return a;
  }, {})
}

const apis = [
  {
    name: "generic",
    actions: [
      {
        name: "bulk",
        method: 'post',
        set_locale: true,
        endpoint: '/api/internal/bulk',
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json"
        },
        auth: true,
        recaptcha: true
      }
    ]
  },
  {
    name: "gifts",
    actions: [
      {
        name: "create",
        method: "post",
        endpoint: "/api/v2/gifts",
        auth: true,
        recaptcha: true
      },
      {
        name: "create_for_items",
        method: "post",
        endpointFn: (params) => `/api/v2/gifts/create_for_items?hp_token=${params.hp_token}`,
        auth: true,
        set_locale: true
      },
      {
        name: "claim",
        method: "post",
        endpointFn: (params) => `/api/v2/gifts/${params.id}/claim`,
        auth: true,
        recaptcha: true
      },
      {
        name: "get",
        method: "get",
        endpointFn: (params) => `/api/v2/gifts/${params.id}`,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json"
        }
      },
      {
        name: "list",
        method: "get",
        endpoint: "/api/v2/gifts",
        auth: true
      },
      {
        name: "cancel_gift",
        method: "post",
        endpointFn: (params) => `api/v2/gifts/${params.id}/cancel`,
        auth: true
      }
    ]
  },
  {
    name: "estimates",
    actions: [
      {
        name: "create_subscription",
        method: "post",
        endpointFn: (params) => `/api/v2/estimates/create_subscription?hp_token=${params.hp_token}`,
        set_locale: true
      },
      {
        name: "update_subscription",
        method: "post",
        endpointFn: (params) => {
          let query = constructQueryParams(['hp_token'], params);
          return `/api/v2/estimates/update_subscription?${query}`;
        },
        auth: true,
        set_locale: true
      },
      {
        name: "recurring",
        method: "get",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/recurring_subscription_estimate?skip_one_time_coupon=true`,
        set_locale: true
      },
      {
        name: "resume",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/resume_subscription_estimate?resume_option=immediately`,
        auth:true
      },
      {
        name: "create_invoice",
        method: "post",
        endpointFn: (params) => `/api/v2/estimates/create_invoice?hp_token=${params.hp_token}`,
        auth: true,
        set_locale: true
      },
      {
        name: "create_invoice_for_items",
        method: "post",
        endpointFn: (params) => `/api/v2/estimates/create_invoice_for_items?hp_token=${params.hp_token}`,
        auth: true,
        set_locale: true
      },
    ]
  },
  {
    name: "paypal",
    actions: [
      {
        name: "setup_authorization",
        method: "post",
        endpoint: "/api/internal/paypal/setup_authorization",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "fetch_details",
        method: "post",
        endpoint: "/api/internal/paypal/token_info",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "gocardless",
    actions: [
      {
        name: "create_redirect",
        method: "post",
        endpoint: "/api/internal/gocardless/create_redirect",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "retrieve_gocardless_info",
        method: "post",
        endpoint: "/api/internal/retrieve_gocardless_info",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "braintree",
    actions: [
      {
        name: "generate_client_token",
        method: "post",
        endpoint: "/api/internal/braintree/generate_client_token",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "plaid",
    actions: [
      {
        name: "exchange_token",
        method: "post",
        endpoint: "/api/internal/plaid/exchange_token",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "link_token",
        method: "post",
        endpoint: "/api/internal/plaid/link_token",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "apple_pay",
    actions: [
      {
        name: "fetch_ip_country",
        method: "post",
        endpoint: "/api/internal/fetch_ip_country",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "stripe",
    actions: [
      {
        name: "retrieve_token",
        method: "post",
        endpoint: "/api/internal/stripe/retrieve_token",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "verify_bank_account",
        method: "post",
        endpoint: "/api/internal/bank_account/verify",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "voucher",
    actions: [
      {
        name: "fetch_data",
        method: "get",
        endpointFn: (params) => `/api/v2/invoices/${params.invoice_id}/payment_vouchers?status=${params.status}`,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "hosted_pages",
    actions: [
      {
        name: "get",
        method: "get",
        endpoint: '/api/internal/hosted_page/retrieve',
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "resync_pm_list",
        method: "get",
        ignore_locale: true,
        endpointFn: (params) => {
          return `/api/internal/hosted_page/retrieve?token=${params.hp_token}&locale=${params.locale}`
        },
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "get_paynow_url",
        method: "post",
        auth: true,
        endpointFn: (params) => `/api/v2/hosted_pages/collect_now?customer[id]=${params.customerId}`
      },
    ]
  },
  {
    name: "item_price",
    actions: [
      {
        name: "list",
        method: "get",
        endpoint: "api/v2/item_prices",
        set_locale: true
      },
      {
        name: "get",
        method: "get",
        endpointFn: (params) => `api/v2/item_prices/${params.id}`,
        set_locale: true
      },
      {
        name: "fetch_recommended_addons",
        method: "get",
        endpointFn: (params) => `api/v2/item_prices/${params.itemPriceId}/recommended_addons`,
        set_locale: true,
      }
    ]
  },
  {
    name: "differential_prices",
    actions: [
      {
        name: "list",
        method: "get",
        endpointFn: (params) => `api/v2/differential_prices?limit=5&parent_item_id[is]=${params.planId}&offset=${params.offset}`,
        set_locale: true
      }
    ]
  },
  {
    name: "item",
    actions: [
      {
        name: "get",
        method: "get",
        endpoint: "api/v2/items",
        set_locale: true
      },
      {
        name: "get",
        method: "get",
        endpointFn: (params) => `api/v2/items/${params.id}`,
        set_locale: true
      }
    ]
  },
  {
    name: "plans",
    actions: [
      {
        name: "get",
        method: "get",
        endpointFn: (params) => `/api/v2/plans/${params.id}`,
        set_locale: true
      },
      {
        name: "list",
        method: "get",
        endpoint: "/api/v2/plans",
        set_locale: true
      },
      {
        name: "fetch_other_products",
        method: "get",
        endpoint: '/api/internal/products/list',
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        },
        set_locale: true
      }
    ]
  },
  {
    name: "addons",
    actions: [
      {
        name: "get",
        method: "get",
        endpoint: '/api/v2/addons',
        set_locale: true
      }
    ]
  },
  {
    name: "payment_sources",
    actions: [
      {
        name: "get",
        method: "get",
        endpointFn: (params) => `/api/v2/payment_sources/${params.id}`,
        set_locale: true,
        auth: true
      }
    ]
  },
  {
    name: "payment_intents",
    actions: [
      {
        name: "create",
        method: "post",
        endpointFn: (params) => `/api/v2/payment_intents`,
        auth:true
      },
      {
        name: "update",
        method: "post",
        endpointFn: (params) => `/api/v2/payment_intents/${params.id}`,
        auth:true
      }
    ]
  },
  {
    name: "offline_pm",
    actions: [
      {
        name: "fetchopmDefaultList",
        method: "get",
        endpointFn: (params) => `/api/internal/offline_pm/default_list`,
        auth:true,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "subscriptions",
    actions: [
      {
        name: "create",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions?hp_token=${params.hp_token}`,
        recaptcha: true
      },
      {
        name: "get",
        method: "get",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}`,
        set_locale: true
      },
      {
        name: "update",
        method: "post",
        endpointFn: (params) => {
          let query = constructQueryParams(['hp_token', 'resource'], params);
          return `/api/v2/subscriptions/${params.id}?${query}`
        },
        auth: true
      },
      {
        name: "update_for_items",
        method: "post",
        endpointFn: (params) => {
          let query = constructQueryParams(['hp_token', 'resource'], params);
          return `/api/v2/subscriptions/${params.id}/update_for_items?${query}`
        },
        auth: true
      },
      {
        name: "override_billing_profile",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/override_billing_profile`,
        auth: true
      },
      {
        name: "update_bulk",
        method: "post",
        endpointFn: (params) => `/api/internal/subscriptions/${params.id}?hp_token=${params.hp_token}`,
        auth: true,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "cancel",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/cancel`,
        auth: true
      },
      {
        name: "cancel_for_items",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/cancel_for_items`,
        auth: true
      },
      {
        name: "reactivate",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/reactivate`,
        auth: true
      },
      {
        name: "scheduled_changes",
        method: "get",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/renewal_estimate?include_delayed_charges=false&use_existing_balances=false`,
        auth: true,
        set_locale: true
      },
      {
        name: "remove_scheduled_changes",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/remove_scheduled_changes`,
        auth: true
      },
      {
        name: "remove_scheduled_cancellation",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/remove_scheduled_cancellation`,
        auth: true
      },
      {
        name: "remove_scheduled_pause",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/remove_scheduled_pause`,
        auth: true
      },
      {
        name: "resume_now",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/resume?resume_option=immediately`,
        auth: true
      },
      {
        name: "pause",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/pause`,
        auth: true
      },
      {
        name: "fetch_child_subscriptions",
        method: "get",
        endpointFn: (params) => `/api/internal/v3_portal/child_subscription?parent_id=${params.id}`,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "list_contract_terms",
        method: "get",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/contract_terms`,
        auth: true
      },
      {
        name: "get_metered_items_history",
        method: "get",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/history`,
        auth: true
      },
      {
        name: "attach_payment_mandate",
        method: "post",
        endpointFn: (params) => `/api/internal/subscriptions/${params.id}/attach_mandate`,
        auth: true,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "customers",
    actions: [
      {
        name: "create",
        method: "post",
        endpoint: '/api/v2/customers',
        ignore_locale: true,
      },
      {
        name: "get",
        method: "get",
        endpointFn: (params) => `/api/v2/customers/${params.id}`,
        auth: true,
        set_locale:true
      },
      {
        name: "update",
        method: "post",
        endpointFn: (params) => {
          let query = constructQueryParams(['hp_token'], params);
          return `/api/v2/customers/${params.id}?${query}`
        },
        auth: true,
        ignore_locale: true
      },
      {
        name: "create_sub_for_items",
        method: "post",
        endpointFn: (params) => `/api/v2/customers/{customer_id}/subscription_for_items?hp_token=${params.hp_token}?include_discounts=true`
      },
      {
        name: "create_sub_for_items_auth",
        method: "post",
        endpointFn: (params) => `/api/v2/customers/${params.id}/subscription_for_items?hp_token=${params.hp_token}`,
        auth: true,
      },
      {
        name: "get_family",
        method: "get",
        endpointFn: (params) => `/api/v2/customers?relationship[payment_owner_id][is]=${params.id}&id[is_not]=${params.id}`,
        auth: true
      },
      {
        name: "shipping_address_list",
        method: "get",
        endpoint: "/api/v2/subscriptions",
        auth: true
      },
      {
        name: "payment_method_list",
        method: "get",
        endpoint: "/api/v2/payment_sources",
        auth: true
      },
      {
        name: "create_card_payment_source",
        method: "post",
        endpointFn: (params) => `/api/v2/payment_sources/create_card?hp_token=${params.hp_token}`,
        auth: true,
        recaptcha: true
      },
      {
        name: "create_bank_account_payment_source",
        method: "post",
        endpointFn: (params) => `/api/v2/payment_sources/create_bank_account?hp_token=${params.hp_token}`,
        auth: true,
        recaptcha: true
      },
      {
        name: "create_payment_source",
        method: "post",
        endpointFn: (params) => `/api/v2/payment_sources/create_using_temp_token?hp_token=${params.hp_token}`,
        auth: true,
        recaptcha: true
      },
      {
        name: "create_payment_source_using_pi",
        method: "post",
        endpointFn: (params) => `/api/v2/payment_sources/create_using_payment_intent?hp_token=${params.hp_token}`,
        auth: true,
        recaptcha: true
      },
      {
        name: "create_voucher_payment_source",
        method: "post",
        endpointFn: (params) => `/api/v2/payment_sources/create_voucher_payment_source?hp_token=${params.hp_token}`,
        auth: true,
        recaptcha: true
      },
      {
        name: "remove_payment_source",
        method: "post",
        endpointFn: (params) => `/api/v2/payment_sources/${params.id}/delete`,
        auth: true,
        recaptcha: true
      },
      {
        name: "assign_payment_role",
        method: "post",
        endpointFn: (params) => `/api/v2/customers/${params.id}/assign_payment_role`,
        auth: true
      },
      {
        name: "create_subscription_estimate",
        method: "get",
        endpointFn: (params) => `/api/v2/customers/${params.id}/create_subscription_estimate?hp_token=${params.hp_token}`,
        auth: true,
        set_locale: true
      },
      {
        name: "create_subscription",
        method: "post",
        endpointFn: (params) => `/api/v2/customers/${params.id}/subscriptions?hp_token=${params.hp_token}`,
        auth: true
      },
      {
        name: "update_billing_info",
        method: "post",
        endpointFn: (params) => `/api/v2/customers/${params.id}/update_billing_info?hp_token=${params.hp_token}`,
        auth: true
      },
      {
        name: "create_subscription_bulk",
        method: "post",
        endpointFn: (params) => `/api/internal/customers/${params.id}/subscriptions?hp_token=${params.hp_token}`,
        auth: true,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "create_invoice",
        method: "post",
        endpointFn: (params) => `/api/v2/invoices?hp_token=${params.hp_token}`,
        auth: true,
        recaptcha: true
      },
      {
        name: "create_invoice_for_items",
        method: "post",
        endpointFn: (params) => `/api/v2/invoices/create_for_charge_items_and_charges?hp_token=${params.hp_token}`,
        auth: true,
        recaptcha: true
      },
      {
        name: "invoices",
        method: "get",
        endpointFn: (params) => `/api/v2/invoices`,
        set_locale: true,
        auth: true
      },
      {
        name: "invoice_by_parent",
        method: "get",
        endpointFn: (params) => `/api/internal/v3_portal/parent_invoices`,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "download_invoice",
        method: "post",
        endpointFn: (params) => `/api/v2/invoices/${params.invoiceId}/pdf`,
        auth: true
      },
      {
        name: "download_usages",
        method: "post",
        endpointFn: (params) => `/api/v2/usages/pdf`,
        auth: true
      },
      {
        name: "collect_payment",
        method: "post",
        endpointFn: (params) => `/api/v2/customers/${params.id}/collect_payment`,
        auth: true
      },
      {
        name: "collect_direct_debit_payment",
        method: "post",
        endpointFn: (params) => `/api/internal/customers/${params.id}/collect_payment?hp_token=${params.hp_token}`,
        auth: true,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      ,
      {
        name: "fetch_relations",
        method: "get",
        endpointFn: (params) => `/api/internal/customer/relations`,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "field_configs",
    actions: [
      {
        name: "checkout",
        method: "get",
        ignore_locale: true,
        endpointFn: (params) => {
          return `/api/internal/configurations/1/retrieve?type=${params.type}&locale=${params.locale}`
        },
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "gift_checkout",
        method: "get",
        ignore_locale: true,
        endpointFn: (params) => {
          return `/api/internal/configurations/1/retrieve?type=checkout_gift&locale=${params.locale}`
        },
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "portal",
        method: "get",
        ignore_locale: true,
        endpointFn: (params) => {
          return `/api/internal/portal_configurations/1/retrieve?locale=${params.locale}`
        },
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "retrieve_init_info",
        method: "get",
        ignore_locale: true,
        endpointFn: (params) => {
          return `/api/internal/1/retrieve_init_info?locale=${params.locale}`
        },
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "style_configs",
    actions: [
      {
        name: "get",
        method: "get",
        endpoint: "/api/internal/retrieve_init_info",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "comments",
    actions: [
      {
        name: "create",
        method: "post",
        auth: true,
        endpoint: "/api/v2/comments"
      }
    ]
  },
  {
    name: "auth_service",
    actions: [
      {
        name: "authenticate",
        method: "post",
        endpoint: "/api/internal/ssp_users/authenticate",
        set_locale:true,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "list_users",
        method: "get",
        endpoint: "/api/internal/ssp_users/list",
        set_locale:true,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "verify_otp",
        method: "post",
        endpoint: "/api/internal/ssp_users/verify_otp",
        set_locale:true,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "verify_password",
        method: "post",
        endpoint: "/api/internal/ssp_users/verify_password",
        set_locale:true,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "check_user",
        method: "post",
        endpoint: "/api/internal/ssp_users/check",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "check_if_existing",
        method: "get",
        endpointFn: (params) => `/api/internal/hosted_pages/${params.hp_token}/check_existing`,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "check_user_checkout",
        method: "post",
        endpoint: "/api/internal/ssp_users/check_for_checkout",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "activate_sso_token",
        method: "post",
        endpoint: "/api/internal/ssp_users/activate_token",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "update_password",
        method: "post",
        endpoint: "/api/internal/ssp_users/update_password",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "logout",
        method: "post",
        endpoint: "/api/internal/ssp_users/logout",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
      {
        name: "clear_preview_cookie",
        method: "post",
        endpoint: "/hp_v3_settings/clear_cookie",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
    ]
  },
  {
    name: "einvoice",
    actions: [
      {
        name: "einvoice_configuration",
        method: "post",
        endpoint: "/api/internal/einvoice_configuration",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    ]
  },
  {
    name: "loggers",
    actions: [
      {
        name: "info_error",
        method: "post",
        endpoint: "/api/internal/kvl",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          'Content-Type': 'application/json',
        }
      },
      {
        name: "tokenization",
        method: "post",
        endpoint: "/hosted_pages/hp_v2_logger",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        }
      },
      {
        name: "hp_form",
        method: "post",
        endpointFn: (params) => `/api/internal/hosted_pages/${params.hp_token}/log?log_type=${params.log_type}`,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          'Content-Type': 'application/json',
        }
      }
    ]
  },
  {
    name: "vat_service",
    actions: [
      {
        name: "validate",
        method: "get",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        set_locale: true,
        endpoint: "/hosted_pages/validate_vat"
      }
    ]
  },
  {
    name: "po_number",
    actions: [
      {
        name: "validate",
        method: "post",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        endpoint: "/api/internal/validate_po_field"
      }
    ]
  },
  {
    name: "retention_service",
    actions: [
      {
        name: "pre_cancel",
        method: "post",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        endpointFn: (params) => `/api/v2/hosted_pages/pre_cancel`,
        auth: true,
        recaptcha: true
      },
      {
        name: "push_event",
        method: "post",
        endpoint: "/api/v2/hosted_pages/events",
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        },
        auth: true,
        recaptcha: true
      }
    ]
  },
  {
    name: "gateway",
    actions: [
      {
        name: "retrieve",
        method: "get",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        endpoint: "/api/internal/gateway/retrieve"
      },
      {
        name: "retrieve_gw_payment_method_config",
        method: "post",
        endpointFn: (params) => `/api/internal/retrieve_gw_payment_method_config?hp_token=${params.hp_token}`,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      },
    ]
  },
  {
    name: "cart",
    actions: [
      {
        name: "create_subscription",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/cart_sub_create?hp_token=${params.hp_token}`,
        set_locale: true,
        // chain: true,
      },
      {
        name: "create_gift_subscription",
        method: "post",
        endpointFn: (params) => `/api/v2/gifts/cart_gift_create?hp_token=${params.hp_token}`,
        set_locale: true
      },
      {
        name: "create_subscription_for_customer",
        method: "post",
        endpointFn: (params) => `/api/v2/customers/${params.id}/cart_sub_create_for_customer?hp_token=${params.hp_token}`,
        set_locale: true,
        auth: true,
        chain: true,
      },
      {
        name: "create_gift_subscription_for_customer",
        method: "post",
        endpointFn: (params) => `/api/v2/customers/${params.id}/cart_gift_create_for_customer?hp_token=${params.hp_token}`,
        set_locale: true,
        auth: true
      },
      {
        name: "update_subscription",
        method: "post",
        endpointFn: (params) => `/api/v2/subscriptions/${params.id}/cart_sub_update?hp_token=${params.hp_token}`,
        set_locale: true,
        auth: true,
        // chain: true,
      },
    ]
  },
  {
    name: "usages",
    actions: [
      {
        name: "get_unbilled_usages",
        method: "get",
        endpointFn: (params) => `/api/v2/usages?subscription_id[is]=${params.subscriptionId}&item_price_id[is]=${params.itemPriceId}&invoice_id[is_present]=false&limit=${params.limit}`
      }
    ]
  }
]

let getEndpoint = function(action, params) {
  const _params = Object.assign({}, params);
  if(action.endpoint) {
    return action.endpoint;
  }
  if(action.endpointFn) {
    let sanitizedParams = Object.keys(_params).reduce((o, param) => {
      if(_params[param]) {
        o[param] = encodeURIComponent(_params[param]).replace(/%2F/g,'/');
      }
      return o;
    }, {});
    return action.endpointFn(sanitizedParams);
  }
}

// Stores the current request promise, and chains any addition request of same action type
// TODO : watch memory footprint, Clear request once complete (either success/ error)
class ApiHandler {
  constructor() {
    this.apiStore = {}
  }

  chainRequest(apiName, actionName, httpMethod, getRequestPromise) {
    // Check if request already exists
    const existingReq = deepGet(this.apiStore, `${apiName}.${actionName}`)
    if(existingReq) {
      // Add the request to promise chain
      this.apiStore[apiName][actionName] = existingReq
        .catch(() => Promise.resolve()) // Catch existing rejections and errors although previously handled
        .then(() => getRequestPromise()); // Chain the new request
    } else {
      if(!this.apiStore[apiName]) this.apiStore[apiName] = {};
      this.apiStore[apiName][actionName] = getRequestPromise()
    }
    return this.apiStore[apiName][actionName]
  }
}

const apiHandler = new ApiHandler();

class CbClient {
  constructor() {
    this.restClient = new RestClient();
    let that = this;
    apis.forEach(api => {
      this[api.name] = { };
      api.actions.forEach(action => {

        /**
          Generate Bulk action for API
         */
        this[api.name][`${action.name}_bulk_action`] = function (args) {
          const finalArgs = that.getFinalArguments(action, arguments);
          const [_url, _data, _headers] = finalArgs;
          return {
            path: _url.replace('/api/v2', '').split('?')[0],
            query_params: removeValuesWithObjectType({..._data, ...args}),
            content_id: BulkApi.generateRequestId()
            // Attach if any additional headers required
            // headers: _headers,
          }
        };

        /**
          Generate function based on API and its Action
         */
        this[api.name][action.name] = function (args) {
          const finalArgs = that.getFinalArguments(action, arguments);

          /**
            Generate Post Action for API
          */
          const [_url, _data, _headers] = finalArgs;
          if(_data && _data.postAction) {
            _headers["CbSource"] = "viaHp";
            if(!_headers["Content-Type"] && action.method == 'post') {
              _headers["Content-Type"] = "application/x-www-form-urlencoded";
            }
            return Promise.resolve({
              path: (new URL(_url.split('?')[0], window.location.origin)).toString(),
              method: action.method,
              payload: removeValuesWithObjectType({..._data, ...args}),
              headers: _headers,
            })
          }
          return that.doRecaptchaValidation(action, api.name)
            .then(token => {
              if (token != undefined) {
                finalArgs[1].recaptcha_token = token;
              }
              const getRequestPromise = () => new Promise((resolve, reject) => {
                that.restClient[action.method].apply(that.restClient, finalArgs)
                  .then(response => resolve(response.data))
                  .catch((error) => {
                    if(!error.response) return reject(error);
                    let cbError = new CbError(error.response.status, error.response.data, undefined, that.store);
                    if (error.response.status == 401) {
                      window.cbStorage.removeItem('cbt');
                      if (that.store) {
                        that.store.dispatch('logout_user', cbError);
                      }
                      try {
                        if (error.response.config.url.endsWith("/activate_token")) {
                          reject(cbError);
                        }
                      }
                      catch (e) {
                      }
                    }
                    else {
                      reject(cbError);
                    }
                  });
              });
              if (action.chain) return apiHandler.chainRequest(api.name, action.name, action.method, getRequestPromise)
              return getRequestPromise();
            });
        }
      })
    })
  }

  doRecaptchaValidation(action, name) {
    let recaptchaKey = this.store.getters.recaptcha_key;
    if (action.method == "get"
      || !action.recaptcha
      || recaptchaKey == undefined
      || !RecaptchaUtil.isRecaptchaEnabled()
    ) return Promise.resolve();
    let instance = Chargebee.getInstance();
    return instance.validateRecaptcha(name);
  }

  getFinalArguments(action, args) {
    let urlEndPoint = getEndpoint(action, Array.prototype.shift.apply(args));
    let finalArgs = [].slice.call(args);
    finalArgs.unshift(urlEndPoint);
    if(finalArgs.length == 1) {
      finalArgs.push({});
    }
    if(!action.ignore_locale && this.store){
      let bodyParams = finalArgs[finalArgs.length - 1];
      bodyParams['locale'] = this.store.state.config.init_info_locale;
    }
    let headers = {};
    if(action.headers && Object.keys(action.headers).length >= 0) {
      Object.assign(headers, action.headers);
    }

    if(this.store) {
      let jwt;
      if(this.store.state.auth.page_type == "portal") {
        jwt = this.store.state.auth.jwt;
        headers['Authorization'] = `Bearer ${jwt}`;
        headers['CbSource'] = 'viaCp';
      }
      else {
        if(action.auth && this.store.state.auth.authTokenType != "hp_token" && this.store.state.auth.jwt){
          jwt = this.store.state.auth.jwt;
          headers['Authorization'] =  `Bearer ${jwt}`;
        }
        else {
          if(this.store.state.auth.hpToken) {
            let token = this.store.state.auth.hpToken
            headers['Authorization'] = `Bearer hp_${token}`
          }
        }
      }

      // Set business entity ID header if present
      if(this.store.state.auth.business_entity_id) {
        headers['chargebee-business-entity-id'] = this.store.state.auth.business_entity_id
      }
    }

    finalArgs.push(headers);
    return finalArgs;
  }

  syncWithStore(store) {
    this.store = store;
  }
}

export default new CbClient();
