export default {
    methods: {
      pushBeIdQueryParam() {
        if (window.cbStorage.getItem("be_id")) {
          this.$router.push({
            query: {
              ...this.$route.query,
              be_id: window.cbStorage.getItem("be_id")
            }
          });
        }
      }
    },
    mounted() {
      this.pushBeIdQueryParam();
    }
  };