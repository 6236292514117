<template>
	<div>
		<div class="cb-section cb-bar">
	  		<cb-slide-n-fade :isList = "true" :is-list="true" :timeOut="true" :reCalculateMargin = "true">
					<div class="cb-bar__wrap" :class="{'cb-bar__wrap--noborder': index == limit - 1  && giftLimit && giftedSubsDisplayList.length >= giftLimit}" v-cb-link = "'shadow'" v-for="(item, index) in giftedSubsDisplayList" @click="navigate_to('gift_details', {params: {giftId: item.giftId}})" v-if="(!giftLimit || index < giftLimit)"  role="navigation" data-cb-id="subscription_details" v-bind:key='index'>
		    		<div class="cb-bar__detail" :class="{'cb-bar__detail--alert': item.status == 'unclaimed'}">
							<i class="icon-gift" :style="themeColor"></i>
							<span class="cb-bar__middle">
								{{getGiftedTxt(item.status,item.receiverName)}}
							</span>
						</div>
						<div class="cb-bar__title">
		    			<span class="cb-bar__middle">{{item.plan_name}}</span>
						<span class="cb-bar__middle" v-if="item.plan_quantity_in_decimal"> &times; {{item.plan_quantity_in_decimal}}</span>
		    			<span class="cb-bar__middle" v-else-if="item.plan_quantity > 1"> &times; {{item.plan_quantity}}</span>
		    		</div>
		    		<template v-if="item.addons && item.addons.length > 0">
		    			<div class="cb-bar__title">
		    				<div class="cb-bar__title" v-for="(addon, index) in item.addons" v-if="index < 1" v-bind:key="index">
		    					{{addon.details.invoice_name || addon.details.name}}
								<span v-if="addon.quantity_in_decimal"> &times; {{addon.quantity_in_decimal}}</span>
								<span v-else-if="addon.quantity > 1"> &times; {{addon.quantity}}</span>
		    				</div>
		          </div>
		        </template>
		        <div v-if="item.addons && item.addons.length > 1">
							+{{item.addons.length - 1}} {{getMoreItemTxt()}}
		        </div>
		        <div class="cb-bar__content">
		        	<span class="cb-bar__price">
		        		{{ item.price | cbCurrency(item.currency_code) }}
		        	</span>
		        </div>
		      </div>
		    </cb-slide-n-fade>
		</div>
	</div>
</template>
<script type="text/javascript">
	import {
	  mapGetters
	} from "vuex"
	export default {
		computed: {
	    ...mapGetters([
	    	'giftedSubsDisplayList',
	    	'subDataLoaded'
	    ]),
	    limit() {
	    	return this.giftedSubsDisplayList.length;
	    }
	  },
	  props : ['giftLimit'],
	  components: {
	  },
	  methods: {
	  	navigate_to(section, options) {
	      options = options || {};
	      options.name = section;
	      this.$router.customPush(options);
	    },
	    getGiftedTxt(status, name) {
	    	switch(status) {
	    		case "claimed": return this.t('gift_tag.claimed', {
	    			name: name });
	    		case "unclaimed": return this.t('gift_tag.unclaimed', {
	    			name: name });
	    		case "scheduled": return this.t('gift_tag.scheduled', {
	    			name: name });
	    		case "expired": return this.t('gift_tag.expired', {
	    			name: name });
	    		case "cancelled": return this.t('gift_tag.canceled', {
	    			name: name });
	    	}
	    },
	    getMoreItemTxt() {
				return this.t('more_items_txt.custom_label') || this.st('more_items_txt');
	    }
	  }
	}
</script>