import Vue from "vue"
import apiClient from "@/utils/cb-client"
import PortalHelper from "@/utils/portal-helper"
import SubItemsModel from "@/utils/items-model";
import SubData from "@/models/sub-data"
import GiftData from "@/models/gift-data"
import EstimateData from "@/models/estimate-data"
import { termTranslate } from "@/utils/helper-functions"
import CBNotification from "@/models/cb-notification"
import lodashChunk from 'lodash-es/chunk';

const state = {
  list: [],
  addonList: [],
  planList: [],
  giftList: [],
  childSubList: [],
  itemPriceList: [],
  differentialPricesList: []
}

const ADD_SUB_DATA = "ADD_SUB_DATA";
const ADD_ORDER_ITEM_TO_SUBDATA = "ADD_ORDER_ITEM_TO_SUBDATA";
const SET_SUBDATA_PLAN = "SET_SUBDATA_PLAN";
const SET_SUBDATA_ADDONS = "SET_SUBDATA_ADDONS";
const SET_PLANLIST = "SET_PLANLIST";
const SET_ADDONLIST = "SET_ADDONLIST";
const SET_SUBDATA_LOADED = "SET_SUBDATA_LOADED";
const SET_OTHER_PRODUCTS = "SET_OTHER_PRODUCTS";
const RESET_SUBSCRIPTION = "RESET_SUBSCRIPTION";
const RESET_LIST = "RESET_LIST";
const RESET_SUB_LIST = "RESET_SUB_LIST";
const RESET_CHILD_SUB_LIST = "RESET_CHILD_SUB_LIST";
const ADD_PREVIEW_ORDERITEM_TO_SUBDATA = "ADD_PREVIEW_ORDERITEM_TO_SUBDATA";
const ADD_PREVIEW_ESTIMATE_TO_SUBDATA = "ADD_PREVIEW_ESTIMATE_TO_SUBDATA";
const APPEND_PLANLIST = "APPEND_PLANLIST";
const SET_ITEM_PRICE_LIST = "SET_ITEM_PRICE_LIST";
const APPEND_DIFFERENTIAL_PRICES_LIST = "APPEND_DIFFERENTIAL_PRICES_LIST";
const APPEND_ADDONLIST = "APPEND_ADDONLIST";
const SET_PREVIEW_SUBDATA_ADDONS = "SET_PREVIEW_SUBDATA_ADDONS";
const SET_PREVIEW_SUBDATA_PLAN = "SET_PREVIEW_SUBDATA_PLAN";
const LINK_PREVIEW_ESITMATE = "LINK_PREVIEW_ESITMATE";
const ADD_GIFT_DATA_TO_SUBDATA = "ADD_GIFT_DATA_TO_SUBDATA";
const ADD_GIFT_DATA = "ADD_GIFT_DATA";
const SET_GIFTDATA_LOADED = "SET_GIFTDATA_LOADED";
const ADD_ORDER_ITEM_TO_GIFTDATA = "ADD_ORDER_ITEM_TO_GIFTDATA";
const RESET_GIFT = "RESET_GIFT";
const ADD_CHILD_SUB_DATA = "ADD_CHILD_SUB_DATA";
const SET_RESUME_ESTIMATE = "SET_RESUME_ESTIMATE";
const SET_CONTRACT_TERMS = "SET_CONTRACT_TERMS";
const SET_OTHER_ITEMS = "SET_OTHER_ITEMS";
const SET_SUB_HAS_UNBILLED_USAGES = "SET_SUB_HAS_UNBILLED_USAGES";
const CLEAR_DIFFERENTIAL_PRICES_LIST = "CLEAR_DIFFERENTIAL_PRICES_LIST";

const loadAddons = (ids, isItemsModel) => {
  if(isItemsModel) {
    return SubItemsModel.fetchItemPrices(ids).then(data => {
      const addons = data.list
        .filter(({item_price}) => SubItemsModel.isAddon(item_price))
        .map(({item_price}) => SubItemsModel.convertItemPriceToAddon(item_price))
      return addons;
    })
  } else {
    let queryString = ids.map(id  => `'${id}'`).join(",");
    return apiClient.addons.get({}, { "id[in]": `[${queryString}]`, limit: ids.length })
      .then((data) => data.list.map(({ addon }) => addon))
  }
};


const actions = {
  init_sub_data({ commit, state, rootState }, reset) {
    if (reset) {
      commit(RESET_LIST);
    }
    rootState.customer.subscriptions.forEach((subscription) => {
      commit(ADD_SUB_DATA, new SubData(subscription));
    });

    rootState.customer.gifted_subscriptions.forEach((item) => {
      commit(ADD_GIFT_DATA, new GiftData(item));
    });

    rootState.customer.child_subscriptions.forEach((item) => {
      commit(ADD_CHILD_SUB_DATA, new SubData(item));
    });

  },

  init_subscriptions({commit, state, rootState }, reset) {
    if (reset) {
      commit(RESET_SUB_LIST);
      commit(RESET_CHILD_SUB_LIST);
    }
    rootState.customer.subscriptions.forEach((subscription) => {
      commit(ADD_SUB_DATA, new SubData(subscription));
    });
    rootState.customer.child_subscriptions.forEach((item) => {
      commit(ADD_CHILD_SUB_DATA, new SubData(item));
    });
  },

  load_sub_data({ commit, state }, subscription) {
    commit(ADD_SUB_DATA, new SubData(subscription));
  },

  add_plan_to_list({ commit, state }, plan) {
    commit(APPEND_PLANLIST, [plan]);
  },

  add_addons_to_list({ commit, state }, addons) {
    commit(APPEND_ADDONLIST, addons);
  },

  fetch_other_products({ commit, state }, subData) {
    let planId = subData.orderItem.plan.id;
    return apiClient.plans.fetch_other_products({}, { plan_id: planId, subscription_id: subData.id }).then(data => {
      if (data) {
        commit(SET_OTHER_PRODUCTS, { subData: subData, data: data });
        if(subData.isItemsModel) {
          commit(SET_OTHER_ITEMS, {subData, data})
        }
      } else {
        commit(SET_OTHER_ITEMS, {subData, data: {}})
        commit(SET_OTHER_PRODUCTS, { subData: subData, data: [] });
        // data = [{plan_id: subData.planId, plan_quantity: subData.orderItem.plan.quantity, addons: subData.orderItem.addons}]
      }
    });
  },

  fetch_resume_estimate({ commit, state} ,subData) {
    return apiClient.estimates.resume({id: subData.id}).then((data) => {
      commit(SET_RESUME_ESTIMATE, {subData: subData, estimate: data.estimate});
    });
  },

  set_addon_list({ commit, state }, {addonList, isItemsModel}) {
    addonList = addonList.filter(i => state.addonList.map(k => k.id).indexOf(i) == -1);
    if (addonList && addonList.length > 0) {
      const chunks = lodashChunk(addonList, 100);
      // /* For max limit: 10*100 = 1000 addons -> */ .slice(0, 10);
      const promises = chunks.map(ids => {
        return loadAddons(ids, isItemsModel)
          .then(addons => {
            if(addons && addons.length) {
              return commit(APPEND_ADDONLIST, addons);
            }
          })
      });
      return Promise.all(promises);
    }
  },

  set_differential_prices_list({ state, commit, dispatch }, planId) {
    commit(CLEAR_DIFFERENTIAL_PRICES_LIST);
    dispatch('construct_differential_prices_list', {planId: planId, offset: "0"})
  },

  construct_differential_prices_list({ commit, dispatch }, {planId, offset}) {
    if (typeof offset != 'undefined') {
      SubItemsModel.fetchDifferentialPrices(planId, offset).then((data) => {
        commit(APPEND_DIFFERENTIAL_PRICES_LIST, data.list)
        dispatch('construct_differential_prices_list', {planId: planId, offset: data.next_offset})
      });
    }
  },

  override_billing_profile({ commit, state }, { subData, paymentSourceId }) {
    return apiClient.subscriptions.override_billing_profile({ id: subData.id }, { payment_source_id: paymentSourceId })
      .then(({ subscription }) => {
        // Check if it is reactive
        commit(RESET_SUBSCRIPTION, { subData: subData, subscription: subscription });
      }).catch(error => logger.e(error));
  },

  override_billing_profile_with_offline_pm({ commit, state }, { subData, offlinePaymentMethod }) {
    let params = { auto_collection: "off", offline_payment_method: offlinePaymentMethod };
    const req = subData.isItemsModel
    ? apiClient.subscriptions.update_for_items({ id: subData.id, resource: 'offline_pm_sub' }, params)
    : apiClient.subscriptions.update({ id: subData.id, resource: 'offline_pm_sub' }, params)
      return req.then(({ subscription }) => {
        // Check if it is reactive
        commit(RESET_SUBSCRIPTION, { subData: subData, subscription: subscription });
      }).catch(error => logger.e(error));
  },

  cancel_subscription({ commit }, { subData, end_of_term, date,  reason, cancel_reason_code }) {
    let params = (end_of_term == 'specific_date') ? {cancel_at : date} : { end_of_term: end_of_term || false };
    if(subData.subscription.contract_term) {
      // end_of_term is not applicable for contract subscription
      delete params.end_of_term
    }
    if(!!cancel_reason_code) {
      params['cancel_reason_code'] = cancel_reason_code;
    }
    const req = subData.isItemsModel
      ? apiClient.subscriptions.cancel_for_items({ id: subData.id }, params)
      : apiClient.subscriptions.cancel({ id: subData.id }, params)

    return req
      .then(({ subscription }) => {
        commit(RESET_SUBSCRIPTION, { subData: subData, subscription: subscription });
      }).then(() => {
        if (reason) {
          return apiClient.comments.create({}, {
            entity_type: "subscription",
            entity_id: subData.id,
            notes: reason
          })
        }
      });
  },

  reactivate_subscription({ commit }, {subData}) {
    return apiClient.subscriptions.reactivate({ id: subData.id })
      .then(({ subscription }) => {
        commit(RESET_SUBSCRIPTION, { subData: subData, subscription: subscription });
      });
  },

  remove_scheduled_cancellation({ commit}, {subData}) {
    return apiClient.subscriptions.remove_scheduled_cancellation({id: subData.id})
    .then(({ subscription}) => {
      commit(RESET_SUBSCRIPTION, {subData: subData, subscription: subscription})
    });
  },

  remove_scheduled_changes({ commit }, subData) {
    return apiClient.subscriptions.remove_scheduled_changes({ id: subData.id })
      .then(({ subscription }) => {
        commit(RESET_SUBSCRIPTION, { subData: subData, subscription: subscription });
      });
  },
  construct_preview_estimate({ commit, state, rootState, dispatch, getters }, {subData, promise}) {
    return promise.then(orderItem => {
      const planIds = [];
      const addonIds = [];

      if(state.planList.findIndex(pl => pl.id == orderItem.plan.id) == -1) {
        planIds.push(orderItem.plan.id);
      }

      if (orderItem.addons) {
        orderItem.addons.forEach((addon) => {
          if(state.addonList.findIndex(a => a.id == addon.id) == -1) {
            addonIds.push(addon.id);
          }
        });
      }
      const payload = subData.isItemsModel ? { itemPriceIds: [...planIds, ...addonIds]} : { addonIds, planIds };
      return dispatch('fetch_item_details', payload)
  }).then(() => {
    commit(SET_PREVIEW_SUBDATA_PLAN, subData);
    commit(SET_PREVIEW_SUBDATA_ADDONS, subData);
    commit(LINK_PREVIEW_ESITMATE, subData);
  });

  },
  fetch_gift_data({commit, state, rootState}) {
    return this.dispatch('fetch_gift_data_for_subs', rootState.customer.subscriptions);
  },

  fetch_gift_data_for_subs({commit, state, rootState}, subscriptions) {
    var promises = [];
    subscriptions.filter(subscription => subscription.gift_id).forEach((subscription) =>{
      promises.push(apiClient.gifts.get({id:subscription.gift_id}));
    });

    return Promise.all(promises).then((gifts) => {
      gifts.forEach((item) => {
        let subscriptionId = item.gift.gift_receiver.subscription_id;
        let subData = state.list.filter(i => i.id == subscriptionId)[0];
        subData.setGiftData(item.gift);
        commit(ADD_GIFT_DATA_TO_SUBDATA, {subData: subData, giftData: item.gift});
      });
    });
  },

  remove_scheduled_pause({commit}, {subData}) {
    return apiClient.subscriptions.remove_scheduled_pause({id: subData.id })
    .then(({subscription}) => {
      commit(RESET_SUBSCRIPTION, {subData: subData, subscription: subscription });
    });
  },

  resume_subscription_now({commit}, {subData, data}) {
    return apiClient.subscriptions.resume_now({id: subData.id }, data)
    .then(({subscription}) => {
      commit(RESET_SUBSCRIPTION, {subData: subData, subscription: subscription });
      commit(SET_RESUME_ESTIMATE, {subData: subData, estimate: undefined});
      this.dispatch("set_payment_intent", undefined);
    })
    .catch(error => {
      this.dispatch('set_current_notification', new CBNotification('error', error));
    });
  },

  clear_resume_estimate({commit}, subData) {
    commit(SET_RESUME_ESTIMATE, {subData: subData, estimate: undefined});
  },

  pause_subscription({commit}, {subData, pauseOption, resumeDate }) {
    var resume;
    if(resumeDate){
      resume = Math.round(resumeDate/1000);
    }
    return apiClient.subscriptions.pause({id: subData.id}, {pause_option: pauseOption, resume_date: resume})
    .then(({ subscription }) => {
      commit(RESET_SUBSCRIPTION, { subData: subData, subscription: subscription });
    });
  },



  fetch_recurring_estimate({ commit, state, rootState }) {
    var promises = [];
    rootState.customer.subscriptions.forEach((subscription) => {
      promises.push(apiClient.estimates.recurring({ id: subscription.id }));
    });

    return Promise.all(promises).then(data => {
      data.forEach(({ estimate }) => {
        let subscriptionId = estimate.subscription_estimate.id;
        let subData = state.list.filter(i => i.id == subscriptionId)[0];
        // assert subdata is not null;
        subData.setRecurringEstimate(estimate.invoice_estimate);
        commit(ADD_ORDER_ITEM_TO_SUBDATA, { subData: subData, orderItem: subData.constructOrderItem() });
      });
    });
  },

  fetch_recurring_estimate_for_child_subs({commit, state, rootState}) {
    return this.dispatch("fetch_recurring_estimate_for_given_child_subs", rootState.customer.child_subscriptions);
  },

  fetch_recurring_estimate_for_given_child_subs({commit, state, rootState}, childSubs) {
    if(!childSubs.length){
      return;
    }
    var promises = [];
    childSubs.forEach((childSub) => {
      if(!state.childSubList.some((l) => l.id == childSub.id)) {
        commit(ADD_CHILD_SUB_DATA, new SubData(childSub));
      }
      promises.push(apiClient.estimates.recurring({id : childSub.id}))
    });
    return Promise.all(promises).then(data => {
      data.forEach(({estimate}) => {
        let childSubId = estimate.subscription_estimate.id;
        let childSubData = state.childSubList.filter(i => i.id == childSubId)[0];
        childSubData.setRecurringEstimate(estimate.invoice_estimate);
        commit(ADD_ORDER_ITEM_TO_SUBDATA, { subData: childSubData, orderItem: childSubData.constructOrderItem() });
       });
    }).catch((e)=> {console.log("error",e);});
  },



  fetch_estimates_for_gifts({ commit, state, rootState}) {
    return this.dispatch("fetch_recurring_estimates_for_gifts", rootState.customer.gifted_subscriptions);
  },

  fetch_recurring_estimates_for_gifts({ commit, state, rootState}, giftItems) {
    var promises = [];
    giftItems.forEach((giftItem) => {
      promises.push(apiClient.estimates.recurring({id : giftItem.subscription.id}));
    });

    return Promise.all(promises).then( data => {
      data.forEach(({ estimate }) => {
        let subscriptionId = estimate.subscription_estimate.id;
        let giftData = state.giftList.filter(i => i.subscriptionId == subscriptionId)[0];
        giftData.setEstimate(estimate.invoice_estimate);
        commit(ADD_ORDER_ITEM_TO_GIFTDATA, {giftData: giftData, orderItem: giftData.constructOrderItem()});
        //commit(SET_GIFTDATA_LOADED, { giftData: giftData, loaded: true });
      })
    })
  },

  fetch_recurring_estimate_for_subs({commit, state, rootState}, subscriptions) {
    if(!subscriptions.length){
      return;
    }
    var promises = []
    subscriptions.forEach((sub) => {
      commit(ADD_SUB_DATA, new SubData(sub));
      promises.push(apiClient.estimates.recurring({id: sub.id}));
    });
    return Promise.all(promises).then(data => {
      data.forEach(({estimate}) => {

        let subscriptionId = estimate.subscription_estimate.id;
        let subData = state.list.filter(i => i.id == subscriptionId)[0];
        subData.setRecurringEstimate(estimate.invoice_estimate);
        commit(ADD_ORDER_ITEM_TO_SUBDATA, {subData: subData, orderItem: subData.constructOrderItem()});
      });
    }).catch((e)=>{console.log("error",e);});
  },

  fetch_recurring_estimate_sub({ commit, state, rootState, dispatch }, {subData, skipLinkSubData}) {
    return apiClient.estimates.recurring({ id: subData.subscription.id }).then(({ estimate }) => {
      subData.setRecurringEstimate(estimate.invoice_estimate);
      commit(SET_SUBDATA_LOADED, { subData: subData, loaded: false });
      commit(ADD_ORDER_ITEM_TO_SUBDATA, { subData: subData, orderItem: subData.constructOrderItem() });
      if(!skipLinkSubData) {
        dispatch('link_sub_data_detail', subData);
      }
    });
  },

  fetch_plan_details({ commit, state, rootState, dispatch }) {
    return dispatch('fetch_plan_details_of_subscriptions', rootState.customer.subscriptions
      .concat(rootState.customer.gifted_subscriptions.map(({subscription}) => subscription))
      .concat(rootState.customer.child_subscriptions));
  },

  fetch_einvoice_details({ commit, state, rootState, dispatch}) {
    let einvoice_data = rootState.customer.entity_identifiers;
    if (einvoice_data && einvoice_data.length) {
      dispatch('set_einvoice_schemes', einvoice_data);
    }
  },

  fetch_plan_details_of_subscriptions({commit, state, rootState, dispatch}, subscriptions) {
    let planIds = [];
    let addonIds = [];
    let itemPriceIds = [];
    subscriptions.forEach((subscription) => {
      const isItemsModel = subscription.is_items_model;
      if(state.planList.findIndex(pl => pl.id == subscription.plan_id) == -1 && planIds.indexOf(subscription.plan_id) == -1) {
        planIds.push(subscription.plan_id);
        if(isItemsModel) itemPriceIds.push(subscription.plan_id);
      }
      if (subscription.addons) {
        subscription.addons.forEach((addon) => {
          if(state.addonList.findIndex(a => a.id == addon.id) == -1 && addonIds.indexOf(addon.id) == -1) {
            addonIds.push(addon.id);
            if(isItemsModel) itemPriceIds.push(addon.id);
          }
        });
      }
    });

    return dispatch('fetch_item_details',{planIds, addonIds, itemPriceIds});
    // call apis

  },

  fetch_item_prices({ commit, state, dispatch }, itemPriceIds) {
    return SubItemsModel.fetchItemPrices(itemPriceIds).then(data => {
      commit(SET_ITEM_PRICE_LIST, data.list.map(({item_price}) => item_price));
      const plans = data.list.filter(({item_price}) => SubItemsModel.isPlan(item_price))
        .map(({item_price}) => SubItemsModel.convertItemPriceToPlan(item_price))
      if(!!(plans && plans.length)) {
        commit(APPEND_PLANLIST, plans);
      }

      const addons = data.list.filter(({item_price}) => SubItemsModel.isAddon(item_price))
        .map(({item_price}) => SubItemsModel.convertItemPriceToAddon(item_price))
      if(!!(addons && addons.length)) {
        commit(APPEND_ADDONLIST, addons);
      }
    })
  },

  fetch_item_details({ commit, state, dispatch },{planIds,addonIds, itemPriceIds}) {
    var promises = [];

    if (itemPriceIds && itemPriceIds.length) {
      promises.push(dispatch("fetch_item_prices", itemPriceIds))
    } else {
      var planQueryString = planIds && planIds.map(p => `'${p}'`).join(",");
      var addonQueryString = addonIds && addonIds.map(a => `'${a}'`).join(",");

      if(planQueryString) {
        promises.push(apiClient.plans.list({}, { "id[in]": `[${planQueryString}]` }).then(data => {
          commit(APPEND_PLANLIST, data.list.map(({ plan }) => plan));
        }));
      }
      if (addonQueryString) {
        promises.push(apiClient.addons.get({}, { "id[in]": `[${addonQueryString}]`, limit: addonIds.length}).then(data => {
          commit(APPEND_ADDONLIST, data.list.map(({ addon }) => addon));
        }));
      }
    }

    return Promise.all(promises);
  },

  link_sub_data_details({ commit, state, dispatch }) {
    state.list.forEach(subData => dispatch('link_sub_data_detail', subData));
  },

  link_child_sub_data_details({commit, state, dispatch}) {
    state.childSubList.forEach(subData => dispatch('link_sub_data_detail', subData));
  },

  // this will be used for gift data as well
  link_sub_data_detail({ commit, state, dispatch }, subData){
    if(subData.orderItem){
      commit(SET_SUBDATA_PLAN, subData);
      commit(SET_SUBDATA_ADDONS, subData);
      commit(SET_SUBDATA_LOADED, { subData: subData, loaded: true });
    }

  },

  add_preview_item_to_subdata({commit, getters} , {subData, estimate}) {
    commit(ADD_PREVIEW_ORDERITEM_TO_SUBDATA, { subData, estimate });
    commit(ADD_PREVIEW_ESTIMATE_TO_SUBDATA, { subData: subData, estimate: new EstimateData(estimate, getters.previewCartItems(subData)) });
  },

  link_gift_data_details({commit, state, dispatch}) {
    state.giftList.forEach(giftData => dispatch('link_sub_data_detail', giftData));
  },

  cancel_gift({ commit }, giftData) {
    return apiClient.gifts.cancel_gift({ id: giftData.giftId })
      .then((data) => {
        commit(RESET_GIFT, { giftData: giftData, subscription: data.subscription, giftInfo: data.gift });
      });
  },

  get_contract_terms({ commit, state}, subData) {
    return apiClient.subscriptions.list_contract_terms({id: subData.id}).then((data) => {
      const contractTerms = data && data.list && data.list.map(pl => pl.contract_term) || []
      commit(SET_CONTRACT_TERMS, {subData, contractTerms});
    });
  },

  check_unbilled_usages({commit}, subscription) {
    const subscriptionId = subscription.id
    return apiClient.subscriptions.get_metered_items_history({
      id: subscriptionId,
    }).then(meteredItemsHistory => {
      const meteredItemPriceIds = meteredItemsHistory.list.map(obj => obj.subscription_history.item_price_id)
      // TODO: replace by Set if browser compatibility present
      const uniqItemPriceIds = []
      meteredItemPriceIds.forEach(id => {
        if(!uniqItemPriceIds.includes(id)) {
          uniqItemPriceIds.push(id)
        }
      })

      const activeMeteredItemPriceIds = subscription.subscription_items.filter(item => item.hasOwnProperty('metered_quantity'))
        .map(item => item.item_price_id)
      const missingItemPriceIds = uniqItemPriceIds.filter(id => !activeMeteredItemPriceIds.includes(id))
      if(missingItemPriceIds.length <= 0) {
        return true
      }
      // TODO: if usage is found for a single item, return.
      return Promise.all(
        missingItemPriceIds.map(itemPriceId => {
          return apiClient.usages.get_unbilled_usages({
            subscriptionId,
            itemPriceId,
            limit: 1
          }).then(({list}) => {
            const hasUsage = !!(list && list.length)
            if (hasUsage) {
              commit(SET_SUB_HAS_UNBILLED_USAGES, {
                subscriptionId,
                value: true
              })
            }
          })
        })
      ).then(true)
    })
  }
}

const mutations = {
  [ADD_SUB_DATA](state, subData) {
    state.list.push(subData);
  },

  [ADD_CHILD_SUB_DATA](state, subData) {
    state.childSubList.push(subData);
  },

  [RESET_SUBSCRIPTION](state, { subData, subscription }) {
    Vue.set(subData, 'subscription', subscription);
  },

  [ADD_ORDER_ITEM_TO_SUBDATA](state, { subData, orderItem }) {
    Vue.set(subData, 'orderItem', orderItem);
  },

  [SET_RESUME_ESTIMATE](state, {subData, estimate}) {
    Vue.set(subData, 'resumeEstimate' , estimate );
  },

  [ADD_GIFT_DATA_TO_SUBDATA](state, { subData, giftData }) {
    Vue.set(subData, 'giftData', giftData)
  },

  [ADD_PREVIEW_ORDERITEM_TO_SUBDATA](state, { subData, estimate }) {
    Vue.set(subData, 'previewOrderItem', subData.constructOrderItemsFromEstimate(estimate));
  },

  [ADD_PREVIEW_ESTIMATE_TO_SUBDATA](state, { subData, estimate }) {
    Vue.set(subData, 'previewEstimate', estimate);
  },

  [SET_PREVIEW_SUBDATA_PLAN](state, subData) {
    Vue.set(subData.previewOrderItem.plan, 'details', state.planList.filter(detail => detail.id == subData.previewOrderItem.plan.id)[0]);
  },

  [SET_PREVIEW_SUBDATA_ADDONS](state, subData) {
    subData.previewOrderItem.addons.forEach(addon => {
      Vue.set(addon, 'details', state.addonList.filter(detail => detail.id == addon.id)[0]);
    });
  },

  [SET_SUBDATA_PLAN](state, subData) {
    Vue.set(subData.orderItem.plan, 'details', state.planList.filter(detail => detail.id == subData.orderItem.plan.id)[0]);
  },

  [SET_SUBDATA_ADDONS](state, subData) {
    subData.orderItem.addons.forEach(addon => {
      Vue.set(addon, 'details', state.addonList.filter(detail => detail.id == addon.id)[0]);
    });
  },

  [LINK_PREVIEW_ESITMATE](state, subData) {
    Vue.set(subData.previewOrderItem.plan, 'lineItem', subData.previewEstimate.getPlanLineItems()[0]);
    subData.previewOrderItem.addons.forEach(function(addon) {
      Vue.set(addon, 'lineItem', subData.previewEstimate.getAddonLineItems().filter(li => li.entity_id == addon.id)[0]);
    });
  },

  [SET_PLANLIST](state, planList) {
    // No need to be reactive
    state.planList = planList;
  },

  [APPEND_PLANLIST](state, planList) {
    // if plan is already present. resync else just add
    planList.forEach(plan => {
      let index = state.planList.findIndex(pl => pl.id == plan.id);
      if (index > -1) {
        state.planList[index] = plan;
      } else {
        state.planList.push(plan);
      }
    });
  },

  [APPEND_ADDONLIST](state, addonList) {
    // if addon is already present. resync else just add
    addonList.forEach(addon => {
      let index = state.addonList.findIndex(pl => pl.id == addon.id);
      if (index > -1) {
        state.addonList[index] = addon;
      } else {
        state.addonList.push(addon);
      }
    });
  },

  [SET_ADDONLIST](state, addonList) {
    // No need to be reactive
    state.addonList = addonList;
  },

  [SET_SUBDATA_LOADED](state, { subData, loaded }) {
    Vue.set(subData, 'loaded', loaded);
  },

  [SET_OTHER_PRODUCTS](state, { subData, data }) {
    Vue.set(subData, 'other_products', data.items);
    Vue.set(subData, 'retain_addons_for_change', data.use_existing_addons);
    Vue.set(subData, 'allowed_addons', data.allowed_addons);
  },

  [SET_OTHER_ITEMS](state, {subData, data }) {
    let subItems = [];
    let itemPrices = [];
    if(data.subscription_items) {
      subItems = data.subscription_items.map(subItem => JSON.parse(subItem).item)
    }
    if(data.item_prices) {
      itemPrices = data.item_prices.map(itemPrice => JSON.parse(itemPrice).item_price)
    }

    const { plan: { details } = {} } = subData?.orderItem ?? {};
    const attachedItems = details?.attached_items || []
    if(attachedItems && attachedItems.length) {
      const itemIds = attachedItems.map(attachedItem => attachedItem.attached_item_id)
      const attachedItemPrices = itemPrices.filter(itemPrice => itemIds.indexOf(itemPrice.item_id) > -1)

      Vue.set(subData.orderItem.plan.details, 'attached_item_prices', attachedItemPrices)
    }

    Vue.set(subData, 'other_product_items', { items: subItems, item_prices: itemPrices })
  },

  [RESET_LIST](state) {
    state.list = [];
    state.giftList = [];
    state.childSubList = [];
  },

  [RESET_SUB_LIST](state) {
    state.list = [];
  },

  [RESET_CHILD_SUB_LIST](state) {
    state.childSubList = [];
  },

  [ADD_GIFT_DATA] (state, giftData) {
    state.giftList.push(giftData);
  },

  [SET_GIFTDATA_LOADED](state, { giftData, loaded }) {
    Vue.set(giftData, 'loaded', loaded);
  },

  [ADD_ORDER_ITEM_TO_GIFTDATA](state, { giftData, orderItem }) {
    Vue.set(giftData, 'orderItem', orderItem);
  },

  [RESET_GIFT](state, {giftData, subscription, giftInfo}) {
    Vue.set(giftData, 'subscription', subscription);
    Vue.set(giftData, 'giftInfo', giftInfo);
  },

  [SET_CONTRACT_TERMS](state, {subData, contractTerms}) {
    Vue.set(subData, 'contract_terms', contractTerms);
  },

  [SET_ITEM_PRICE_LIST](state, itemPriceList) {
    Vue.set(state, 'itemPriceList', itemPriceList);
  },

  [SET_SUB_HAS_UNBILLED_USAGES](state, {subscriptionId, value}) {
    const index = state.list.findIndex(sub => sub.id === subscriptionId);
    if (index !== -1) {
      const subData = state.list[index].subscription
      Vue.set(subData, 'has_unbilled_usages', value)
    }
  },
  [APPEND_DIFFERENTIAL_PRICES_LIST](state, differentialPricesList) {
    differentialPricesList.forEach(differentialPrice => {
      state.differentialPricesList.push(differentialPrice);
    });
  },
  [CLEAR_DIFFERENTIAL_PRICES_LIST](state) {
    state.differentialPricesList = []
  },
}

const getters = {
  subDataDisplayList: state => {
    return state.list.every(subData => subData.loaded) ? state.list.map(subData => subData.displayer()) : [];
  },
  giftedSubsDisplayList: state => {
    return state.giftList.every(giftData => giftData.loaded) ? state.giftList.map(giftData => giftData.displayer()) : [];
  },
  giftedSubsDataLoaded: state => state.giftList.every(giftData => giftData.loaded),
  subDataLoaded: state => state.list.every(subData => subData.loaded),
  fetchSubData: state => (subscriptionId) => state.list.filter(o => o.id == subscriptionId)[0] || state.childSubList.filter(o => o.id == subscriptionId)[0],
  fetchGiftData: state => (giftId) => state.giftList.filter(o => o.giftId == giftId)[0],
  fetchPlanData: state => (planId) => state.planList.filter(o => o.id == planId)[0],
  billingText: (state, getters) => (item, currentProduct) => {
    if (['active', 'future', 'trial'].indexOf(item.state) > -1) {
      if(item.state === 'future' && item.subscription.remaining_billing_cycles === 1){
        return '';
      } 
      const period = currentProduct ? currentProduct.period : item.billing_period
      const periodUnit = currentProduct ? currentProduct.period_unit : item.billing_period_unit
      return getters.stranslate('billing_interval') +
      ` ${period > 1 ? period : ''} ${termTranslate(periodUnit, period > 1, getters.stranslate)}`;
    }
  },
  previewCartItems: (state, getters) => (subData) => {
    var output = subData.previewOrderItem && [].concat(subData.previewOrderItem.plan, subData.previewOrderItem.addons, subData.previewOrderItem.plan_setup).filter(i => !!i);
    return output && getters.can_allow('line_items.hide_zero') ? output.filter(i => i.lineItem && i.lineItem.amount > 0) : output
  },
  fetchChildSubData: state => (subscriptionId) => state.childSubList.filter(o => o.id == subscriptionId)[0],
  childSubDataDisplayList: state => {
    return state.childSubList.every(subData => subData.loaded) ? state.childSubList.map(subData => subData.displayer()) : [];
  },
  childSubDataLoaded: state => state.childSubList.every(subData => subData.loaded),
  subItemPriceList: state => state.itemPriceList,
  differentialPricesList: state => state.differentialPricesList
}
export default {
  state,
  actions,
  mutations,
  getters
}
