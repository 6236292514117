import apiClient from "@/utils/cb-client"
export default class SubItemsModel {

  static ItemPriceToPlanParamMap = {
    // : "addon_applicability",
    pricing_model: "charge_model",
    currency_code: "currency_code",
    // : "enabled_in_hosted_pages",
    // : "enabled_in_portal",
    free_quantity: "free_quantity",
    // : "giftable",
    id: "id",
    item_id: "item_id",
    external_name: "invoice_name",
    // : "is_shippable",
    name: "name",
    item_type: "object",
    period: "period",
    period_unit: "period_unit",
    price: "price",
    pricing_model: "pricing_model",
    resource_version: "resource_version",
    // : "show_description_in_invoices",
    // : "show_description_in_quotes",
    status: "status",
    taxable: "taxable",
    updated_at: "updated_at",
  }

  static ItemPriceToAddonParamMap = {
    // : "charge_type",
    currency_code: "currency_code",
    // : "description",
    // : "enabled_in_portal",
    id: "id",
    item_id: "item_id",
    external_name: "external_name",
    invoice_name: "invoice_name",
    // : "is_shippable",
    name: "name",
    item_type: "object",
    period: "period",
    period_unit: "period_unit",
    price: "price",
    pricing_model: "pricing_model",
    resource_version: "resource_version",
    // : "show_description_in_invoices",
    // : "show_description_in_quotes",
    status: "status",
    taxable: "taxable",
    // : "type",
    updated_at: "updated_at",
    free_quantity: "free_quantity",
    // tiers: [],

    amount_in_decimal: "amount_in_decimal", // For attached addons
    amount: "amount", // For attached addons,
    tiers: "tiers"
  }

  static isAddon(item) {
    return !!(item && (item.item_type == "addon" || item.type == "addon" || this.isCharge(item)))
  }

  static isPlan(item) {
    return !!(item && (item.item_type == "plan" || item.type == "plan"))
  }

  static isCharge(item) {
    return !!(item && (item.item_type == "charge" || item.type == "charge"))
  }

  static convertItemPriceToAddon(itemPrice, item) {
    if (SubItemsModel.isAddon(itemPrice)) {
      const addon = {}
      Object.keys(SubItemsModel.ItemPriceToAddonParamMap).map(key => {
        addon[SubItemsModel.ItemPriceToAddonParamMap[key]] = itemPrice[key]
      })

      if (itemPrice.item_type == 'charge') {
        addon.charge_type = 'non_recurring';
      } else if (itemPrice.item_type == 'addon') {
        addon.charge_type = 'recurring';
      }
      addon.metered = !!(item && item.metered)

      return addon
    }
  }

  static convertItemPriceToPlan(itemPrice) {
    if (SubItemsModel.isPlan(itemPrice)) {
      const plan = {}
      Object.keys(SubItemsModel.ItemPriceToPlanParamMap).map(key => {
        plan[SubItemsModel.ItemPriceToPlanParamMap[key]] = itemPrice[key]
      })

      if(itemPrice.attached_items) {
        plan["attached_items"] = itemPrice.attached_items;
      }

      return plan
    }
  }

  static fetchItemPrices(itemPriceIds) {
    var itemPriceQueryString = itemPriceIds.map(i => `'${i}'`).join(",");
    return apiClient.item_price.list({}, { "id[in]": `[${itemPriceQueryString}]`, limit: itemPriceIds.length })
  }

  static fetchDifferentialPrices(planId, offset) {
    return apiClient.differential_prices.list({planId: planId, offset: offset})
  }

  // For addon item recommendation & Default item price selection
  static getSuitableAddonItemPrice(plan, item, itemPrices) {
    // Get itemPrice of matching plan period unit
    const planPeriodUnit = plan.period_unit;
    const planPeriod = plan.period;
    let matchingItemPrice = itemPrices.find(itemPrice => itemPrice.period_unit == planPeriodUnit && itemPrice.period == planPeriod)

    // Return if exact matching period unit and period is found
    if(matchingItemPrice) return matchingItemPrice;

    let isPlanPeriodUnitMatched = false
    // To recommend the period unit closest to the plan period unit
    const periodUnits = [ 'day', 'week', 'month', 'year' ].filter(periodUnit => {
      if(planPeriodUnit == periodUnit) isPlanPeriodUnitMatched = true;
      if(isPlanPeriodUnitMatched) return false
      return true;
    }).reverse();

    // Select the closest available period unit
    let matchingPeriodUnit = periodUnits.find(periodUnit => {
      return itemPrices.some(itemPrice => itemPrice.period_unit == periodUnit)
    })

    if(matchingPeriodUnit) {
      // Return corrsponding itemPrice
      return itemPrices.find(itemPrice => itemPrice.period_unit == matchingPeriodUnit)
    }

    // Select first charge item price as default
    // TODO check this
    if(item.type == 'charge' && itemPrices.length > 0) return itemPrices[0]
  }

}
