class SubCancelReasonCodeConfig {

	init(config) {
		this.config = {}
		this.config.mandatory = (config && config.mandatory) || false;
		this.config.reasons = [];
		this.setReasonCodes((config && config.reasons) || {});
		this.config.app_reasons = [];
		this.setAppReasonCodes((config && config.app_reasons) || {});
	}

	setReasonCodes(reasons) {
		Object.keys(reasons).forEach(k => {
			this.config.reasons.push({displayText: reasons[k], value:k})
		})
	}

	setAppReasonCodes(reasons) {
		Object.keys(reasons).forEach(k => {
			this.config.app_reasons[k] = reasons[k];
		})
	}

	isMandatory() {
		return this.config.mandatory;
	}

	getReasonCodes() {
		return this.config.reasons;
	}
	
	canShowReasonCodes() {
		return this.getReasonCodes().length > 0;
	}

	getLocalizedTextByCode(code) {
		return this.config.app_reasons[code] || code;
	}
    
}

export default new SubCancelReasonCodeConfig();