import Transformer from "@/utils/transformer"
export default class CbError {
  constructor(status, response, exceptionType, store) {
    this.status = status;
    let tmp = this.checkForWebParamException(response);
    this._source = tmp ? tmp : response ;

    if(exceptionType) {
      this.exceptionType = exceptionType;
    } else if(this._source.param == "payment") {
      this.exceptionType = "cb";
    }
    else {
      this.exceptionType = this._source.param ? "cb_param" : "cb";
    }
    this.fillValues(store);
  }

  checkForWebParamException(response) {
    if(response.errors && response.errors[1] && response.errors[1].param) {
      return {
        "param": response.errors[1].param,
        "error_msg": response.errors[1].message
      }
    }
  }

  fillValues(store) {
    if(this.isParamException()) {
      this.fillParamException(store);
      this.modifyParamMessages(store);
    }
    else {
      this.fillException();
    }
  }

  fillParamException(store) {
    // TODO check if this can be userd
    this.paramName = Transformer.fetchParamNameWithoutResource(this._source.error_param || this._source.param);
    this.paramMessage = this.paramName ? `${this.paramName} ${this._source.error_msg}` : this._source.error_msg;
    this.message = store.getters.stranslate('error');
    this.type = Transformer.fetchResourceName(this._source.error_param || this._source.param);
  }

  modifyParamMessages(store) {
    if((this.type == "coupon_ids" || this.paramName.startsWith('coupon')) && this.status != "500") {
      this.paramMessage = store.getters.stranslate('coupon_error');
      this.message = "Invalid Coupon Code";
    }
  }

  fillException() {
    if(this._source.errors) {
      this.message = this._source.errors[0].message;
    }
    else {
      this.message = this._source.message || this._source.error_msg;
    }
    if(this._source.type == "payment" || this._source.param == "payment") {
      this.type = "payment_method";
    }
  }

  isParamException() {
    return this.exceptionType == "cb_param";
  }

  reviewScreenMessage() {
    return this.isParamException() ? this.paramMessage : this.message
  }
}