<template>
</template>
<script type="text/javascript">
  import { mapGetters } from "vuex"
  import CBNotification from "@/models/cb-notification"
  import { fetchForwardParams, resolveRouteName } from "@/utils/helper-functions"
  export default {
    methods: {

    },
    computed: {
      ...mapGetters([
        "authenticated",
        "customer_handle",
        "origin"
      ])
    },
    data() {
      return {
        forward: "",
      }
    },
    mounted() {
      this.forward = this.$route.query.forward;
      if(!this.$route.query.source) {
        this.$store.dispatch('set_auth_type', 'sso');
      }
      this.$store.dispatch('set_sso_token', {token: this.$route.query.token, source: this.$route.query.source});
      this.$store.dispatch('activate_sso_token').then(() => {
        if(window.cbStorage.localStorageEnabled) {
          window.cbPostMessage({key: "cb.authenticated", value: this.customer_handle})
        }
        this.$router.replace({
          name: (this.forward && resolveRouteName(this.forward)) || "home",
          params: fetchForwardParams(this.$route.query)
        });
      }).catch(error => {
        if(error.status == 401) {
          window.cbPostMessage("cb.unauthenticated");
        }

        this.$store.dispatch('set_current_notification', new CBNotification('error', error));
        this.$router.push({
          name: "error"
        });
        logger.e(error)
      });
    }
  }
</script>