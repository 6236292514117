export default {
	computed: {
		isThisEntryPoint() {
			return !this.$route.query.source || this.$route.query.source == this.$route.name;
		},
		titleText() {
			var textKey = this.$route.meta.header;
			return this.t(textKey);
		}
	}
}