import Vue from 'vue'
import MultiDecimalHelper from "@/utils/multi-decimal"

const state = {
  orderItems: []
}

const ADD_ORDER_ITEM = "ADD_ORDER_ITEM";

const UPDATE_QUANTITY = "UPDATE_QUANTITY";
const REMOVE_ITEM = "REMOVE_ITEM";
const ADD_ITEMS = "ADD_ITEMS";
const ADD_RECOMMENDED_ITEM = "ADD_RECOMMENDED_ITEM";
const ADD_COUPON = "ADD_COUPON";
const REMOVE_COUPON = "REMOVE_COUPON";
const REPLACE_ORDER_ITEM = "REPLACE_ORDER_ITEM";
const REPLACE_COUPON_LIST = "REPLACE_COUPON_LIST";
const REPLACE_ADDON_LIST = "REPLACE_ADDON_LIST";
const SET_QUANTITY_META_OVERRIDES = "SET_QUANTITY_META_OVERRIDES";
const UPDATE_QUANTITY_IN_DECIMAL = "UPDATE_QUANTITY_IN_DECIMAL";

// Estimate Debouncer
var calculateEstimate = window.debounce((dispatch, resolve, reject) => {
  dispatch('calculate_estimate', { resolve: resolve, reject: reject })
}, 600);

const actions = {
  add_order_item({ commit, state, dispatch }, { orderItem, skipFetchDetails }) {
    commit(ADD_ORDER_ITEM, orderItem);
    return new Promise((resolve, reject) => {
      dispatch('calculate_estimate', { resolve: resolve, reject: reject, fetchDetails: (skipFetchDetails ? false : true) });
    }).then(()=> {
      dispatch('trigger_cart_change');
    });
  },
  replace_order_item({ commit, state, dispatch }, orderItem) {
    commit(REPLACE_ORDER_ITEM, orderItem);
  },

  update_quantity({ commit, dispatch }, { item, value }) {
    commit(UPDATE_QUANTITY, { item: item, value: value });
    return new Promise((resolve, reject) => {
      calculateEstimate(dispatch, resolve, reject)
    }).then(()=> {
      dispatch('trigger_cart_change');
    });
  },

  update_quantity_in_decimal({ commit, dispatch }, { item, value }) {
    commit(UPDATE_QUANTITY_IN_DECIMAL, { item: item, value: value });
    // TODO DRY
    return new Promise((resolve, reject) => {
      calculateEstimate(dispatch, resolve, reject)
    }).then(()=> {
      dispatch('trigger_cart_change');
    });
  },

  add_recommended_addon({ commit, dispatch }, item) {
    commit(ADD_RECOMMENDED_ITEM, item);
    return new Promise((resolve, reject) => {
      commit(REPLACE_ADDON_LIST, true);
      dispatch('calculate_estimate', { resolve: resolve, reject: reject });
    }).then(()=> {
      dispatch('trigger_cart_change');
    });
  },

  remove_item({ commit, dispatch }, item) {
    commit(REMOVE_ITEM, item);
    return new Promise((resolve, reject) => {
      if (item.type == "addon") {
        commit(REPLACE_ADDON_LIST, true);
      }
      dispatch('calculate_estimate', { resolve: resolve, reject: reject });
    }).then(()=> {
      dispatch('trigger_cart_change');
    });
  },
  remove_item_no_side_effect({ commit, dispatch }, item) {
    commit(REMOVE_ITEM, item);
  },
  add_items({ commit, dispatch }, items) {
    commit(ADD_ITEMS, items);
    return new Promise((resolve, reject) => {
      commit(REPLACE_ADDON_LIST, true);
      dispatch('calculate_estimate', { resolve: resolve, reject: reject });
    }).then(()=> {
      dispatch('trigger_cart_change');
    });
  },
  apply_coupon({ commit, dispatch }, coupon) {
    commit(ADD_COUPON, coupon);
    return new Promise((resolve, reject) => {
      commit(REPLACE_COUPON_LIST, true);
      dispatch('calculate_estimate', { resolve: resolve, reject: reject });
    }).then(()=> {
      dispatch('trigger_cart_change');
    });
  },
  remove_coupon({ commit, dispatch }, coupon) {
    commit(REMOVE_COUPON, coupon);
    return new Promise((resolve, reject) => {
      commit(REPLACE_COUPON_LIST, true);
      dispatch('calculate_estimate', { resolve: resolve, reject: reject });
    }).then(()=> {
      dispatch('trigger_cart_change');
    });
  },
  remove_coupon_no_side_effect({ commit, dispatch }, coupon) {
    commit(REMOVE_COUPON, coupon);
  },
  set_replace_addon_list({ commit, dispatch }, value) {
    commit(REPLACE_ADDON_LIST, true);
  },
  set_replace_coupon_list({ commit, dispatch }, value) {
    commit(REPLACE_COUPON_LIST, true);
  },
  set_quantity_meta_overrides({ commit }, overrides) {
    commit(SET_QUANTITY_META_OVERRIDES, overrides);
  }
}

const mutations = {
  [ADD_ORDER_ITEM](state, orderItem) {
    if (state.orderItems.length > 1) {
      logger.w("orderItems count cannot be > 1");
      return;
    }
    state.orderItems.push(orderItem);
  },
  [REPLACE_ORDER_ITEM](state, orderItem) {
    Vue.set(state, "orderItems", [orderItem]);
  },

  [UPDATE_QUANTITY](state, { item, value }) {
    Vue.set(item, 'quantity', value);
  },

  [UPDATE_QUANTITY_IN_DECIMAL](state, { item, value }) {
    Vue.set(item, 'quantity_in_decimal', value);
  },

  [REMOVE_ITEM](state, item) {
    if (item.type == "plan") {
      logger.w("plan deletion triggered");
      return;
    }
    // state.orderItems[0].flattened.splice(state.orderItems[0].flattened.indexOf(item), 1);
    state.orderItems[0].addons.splice(state.orderItems[0].addons.indexOf(item), 1);
  },

  [ADD_ITEMS](state, addonList) {
    addonList.forEach(addon => {
      let index = state.orderItems[0].addons.findIndex(pl => pl.id == addon.id);
      if (index > -1) {
        state.orderItems[0].addons[index] = addon;
      } else {
        state.orderItems[0].addons.push({
          id: addon.id,
          quantity: 1,
          quantity_in_decimal: MultiDecimalHelper.getDefaultQuantity(),
          type: 'addon',
          details: addon.details,
          metered: !!addon.metered
        });
      }
    });
  },

  [ADD_COUPON](state, couponCode) {
    if (!state.orderItems[0].coupon_list.some(e => e.code == couponCode)){
      state.orderItems[0].coupon_list.push({code: couponCode});
    }
  },

  [ADD_RECOMMENDED_ITEM](state, addon) {
    let index = state.orderItems[0].addons.findIndex(pl => pl.id == addon.id);
    if (index > -1) {
      state.orderItems[0].addons[index] = addon;
    } else {
      let {id, quantity, quantity_in_decimal, details, metered} = addon
      state.orderItems[0].addons.push({
        id, quantity, quantity_in_decimal, details, metered, type: 'addon' });
    }
  },

  [REMOVE_COUPON](state, coupon) {
    state.orderItems[0].coupon_list.splice(state.orderItems[0].coupon_list.findIndex(e => e.id == coupon.code || e.code == coupon.id), 1);
  },

  [REPLACE_COUPON_LIST](state, replace_coupon_list) {
    Vue.set(state.orderItems[0], 'replace_coupon_list', replace_coupon_list);
  },

  [REPLACE_ADDON_LIST](state, replace_addon_list) {
    Vue.set(state.orderItems[0], 'replace_addon_list', replace_addon_list);
  },

  [SET_QUANTITY_META_OVERRIDES](state, { item, meta }) {
    Vue.set(item, 'quantity_meta_overrides', meta);
  }

}

const getters = {
  cart_items: (state, getters) => {
    var output = state.orderItems[0] && [].concat(state.orderItems[0].plan, state.orderItems[0].addons,state.orderItems[0].event_based_addons, state.orderItems[0].charges, state.orderItems[0].plan_setup).filter(i => !!i);
    return output && getters.can_allow('line_items.hide_zero') ? output.filter(i => i.amount > 0 || i.metered) : output;
  },
  coupon_list: state => (state.orderItems[0] && state.orderItems[0].coupon_list) || [],
  replace_coupon_list: state => state.orderItems[0] && state.orderItems[0].replace_coupon_list
}

export default {
  state,
  actions,
  mutations,
  getters
}
