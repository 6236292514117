import Vue from "vue"
import { getRefererDomain, isOpenedInsideAnIframe } from "@/utils/helper-functions"
import UrlHelper from "@/utils/url-helper"
import apiClient from "@/utils/cb-client"
import einvoiceHelper from "@/utils/einvoice-helper"
import { CDN } from "@/components/modules/hosted-pages/LazyLoadPaymentComponents"
import ImportHelper from '@/utils/chunkImportWrapper'
import PaymentHelper from "@/utils/payment-helper";

function preloadDeviceDataJS(pm_list = [], getters) {
  pm_list.forEach(pm => {
    if(pm.gateway_name == "CHARGEBEE_PAYMENTS" 
        && pm.fraud_detection_config
        && pm.fraud_detection_config.merchant_id) {
      ImportHelper.loadScript(CDN.finix, null, 'Finix')
      .then(() => {
        let env = getters.sandbox_site ? 'sandbox' : 'live';
        window.cbFinixAuth = window.Finix.Auth(env, pm.fraud_detection_config.merchant_id);
      })
    }
  });
}

function lazyLoad3DSComponent(pm_list = []) {
  if(pm_list && pm_list.find(pm => PaymentHelper.is3dsEnabled(pm))) {
    window.cbjsLoader.then(() => {
      Chargebee.getInstance().load3DSHandler();
    })
  }
}

const SET_STYLE_CONFIG = "SET_STYLE_CONFIG";
const SET_TEXTS = "SET_TEXTS";
const SET_PM_LISTS = "SET_PM_LISTS";
const SET_PM_COUNT = "SET_PM_COUNT";
const SET_INITED = "SET_INITED";
const SET_INFO_ALERTS = "SET_INFO_ALERTS";
const SET_UI_CONFIG = "SET_UI_CONFIG";
const SET_NL_TEXTS = "SET_NL_TEXTS";
const SET_LOCALE_LIST = "SET_LOCALE_LIST";
const SET_LOCALE = "SET_LOCALE";
const SET_CONFIG_LOCALE = "SET_CONFIG_LOCALE";
const SET_SANDBOX_SITE = "SET_SANDBOX_SITE";
const SET_ALLOWED_DOMAINS = "SET_ALLOWED_DOMAINS";
const SET_RECAPTCHA_SITE_KEY = "SET_RECAPTCHA_SITE_KEY";
const SET_ACTION_PAGES_REDIRECT_URL = "SET_ACTION_PAGES_REDIRECT_URL";
const SET_CAMPAIGN_DETAILS = "SET_CAMPAIGN_DETAILS";
const SET_CHARGEBEE_LOGIN_MODE = "SET_CHARGEBEE_LOGIN_MODE";
const SET_LOGIN_EXPIRY_TIME = "SET_LOGIN_EXPIRY_TIME";
const SET_SITE = "SET_SITE";
const SET_ORGANISATION = "SET_ORGANISATION";
const SET_DAY_BASED_BILLING_ENABLED = "SET_DAY_BASED_BILLING_ENABLED";
const SET_MULTI_DECIMAL_CONFIG = "SET_MULTI_DECIMAL_CONFIG";
const SET_METERED_BILLING_ENABLED = "SET_METERED_BILLING_ENABLED";
const SET_EINVOICE_BASE_CONFIGURATION = "SET_EINVOICE_BASE_CONFIGURATION";
const SET_EINVOICE_CHECKOUT_CONFIGURATION = "SET_EINVOICE_CHECKOUT_CONFIGURATION";
const SET_USE_RECOMMENDED_ADDONS_INTERNAL_API = 'SET_USE_RECOMMENDED_ADDONS_INTERNAL_API';
const SET_COLLECT_NOW_TXN_MAX_LIMIT = "SET_COLLECT_NOW_TXN_MAX_LIMIT";

export const baseState = {
  style: {

  },
  pm_list: [],
  texts: {},
  inited: false,
  info_alerts: {},
  ui_config: {},
  init_info_locale: {},
  configuration_locale: {},
  supported_locale_list: {},
  sandbox_site: true,
  allowed_domains: [],
  chargebee_login_mode: undefined,
  login_expiry_time: undefined,
  recaptcha_key: undefined,
  action_pages_redirect_url: undefined,
  campaignDetails: undefined,
  site: undefined,
  organisation: undefined,
  day_based_billing_enabled: undefined,
  einvoice_base_configuration: [],
  collect_now_txn_max_limit: undefined,
  einvoice_checkout_configuration: []
}

const PaymentMethodsNeedNewWindow = ["ideal", "sofort", "dotpay", "giropay", "netbanking_emandates", "bancontact"];
const CardGatewayNeedNewWindow = ["MOLLIE", "RAZORPAY"];

function cbInit(data) {
  if(!data && window.cbStorage.isLocalStorageEnabled()) {
    let cbInitCount = window.cbStorage.getItem('cb_init_count') || 0;
    cbInitCount = parseInt(cbInitCount);
    if(cbInitCount < 2) {
      window.cbStorage.setItem('cb_init_count', ++cbInitCount);
      window.cbReload();
    } else {
      window.cbStorage.removeItem('cb_init_count');
    }
  }
}

function isSupportedPM(pm, getters) {
    const hpType = getters.hp_type || getters.hosted_page_type;
    if(!PaymentHelper.isSupportedPayment(hpType, pm)) {
      return false;
    }
    // iframe tag is not supported by some payments so, those payments are removed here.
    if(isOpenedInsideAnIframe() && (pm.type == "amazon_payments" || pm.type == "apple_pay")) {
      return false;
    }
    if (pm.type == "apple_pay"){
      try {
        return window.ApplePaySession && ApplePaySession.canMakePayments();
      } catch (e) {
        return false;
      }
    }

    if ((PaymentMethodsNeedNewWindow.includes(pm.type) && window.initInfo.hide_ideal_on_mobile)
            || (pm.type == "google_pay") || (pm.type == "card" && CardGatewayNeedNewWindow.includes(pm.gateway_name))) {
      return !UrlHelper.newWindowNotSupported();
    }

    return true;
}

export const DEFAULT_ALLOWED_DOMAINS = ['https://hosted.atomicpricing.com', 'https://hosted.devpricify.com'];

export const baseActions = {
  init_app({commit, getters, state, dispatch}) {
    var data = window.initInfo;
    cbInit(data);
    commit(SET_STYLE_CONFIG, data);
    commit(SET_SANDBOX_SITE, data.sandbox);
    dispatch('set_pm_count', data.pm_count);
    if(getters.hosted_page_type == "portal" && !data.sso_enabled) {
      dispatch('reset_auth');
    } else if (getters.hosted_page_type == "checkout" && !data.checkout_sso_enabled) {
      dispatch('reset_auth');
    }
    if(getters.hosted_page_type == "portal") {
      dispatch('set_auth_type', data.sso_enabled ? "sso" : "email");
    } else {
      dispatch('set_auth_type', data.checkout_sso_enabled ? "sso" : "email");
    }
    
    /* PORTAL : PC 2.0 fetch einvoice config data */
    if (data.items_model_enabled && window.initInfo.is_e_invoicing_enabled) dispatch('set_einvoice_configuration');

    commit(SET_LOCALE_LIST,data.supported_locale_list);
    commit(SET_LOCALE,data.init_info_locale);
    commit(SET_NL_TEXTS, data.non_loggedin_texts);

    /**
      Sets default allowed domains. Included Atomic pricing
      TODO: Undo after backend change rollout.
     */
    let allowedDomains = (data.allowed_domains || '');
    const separator = allowedDomains.length > 0 ? ',' : '';
    allowedDomains += `${separator}${DEFAULT_ALLOWED_DOMAINS.join(',')}`;

    commit(SET_ALLOWED_DOMAINS, allowedDomains);
    commit(SET_RECAPTCHA_SITE_KEY, data.captcha_site_key || data.recaptcha_site_key);
    commit(SET_CHARGEBEE_LOGIN_MODE, data.chargebee_login_mode);
    commit(SET_LOGIN_EXPIRY_TIME, data.login_expiry_time);
    commit(SET_SITE, data.site);
    commit(SET_ORGANISATION, data.organisation);
    commit(SET_DAY_BASED_BILLING_ENABLED, data.day_based_billing_enabled);
    commit(SET_MULTI_DECIMAL_CONFIG, data.multi_decimal)
    commit(SET_METERED_BILLING_ENABLED, data.metered_billing_enabled)
    window.currency_list = data.currency_list;
    commit(SET_COLLECT_NOW_TXN_MAX_LIMIT, data.collect_now_txn_max_limit)
    commit(SET_INITED);
    commit(SET_USE_RECOMMENDED_ADDONS_INTERNAL_API, data.use_recommended_addons_internal_api);
  },

  set_pm_list({commit, state, getters}, pm_list) {
    pm_list = pm_list.filter((pm) => isSupportedPM(pm, getters));
    preloadDeviceDataJS(pm_list, getters);
    lazyLoad3DSComponent(pm_list);
    commit(SET_PM_LISTS, pm_list);
  },

  set_pm_count({commit, state}, pm_count) {
    commit(SET_PM_COUNT, pm_count);
  },

  set_base_config({commit}, data) {
    commit(SET_TEXTS, data.txts);
    commit(SET_INFO_ALERTS, data.info_alerts);
    commit(SET_UI_CONFIG, data.ui_config);
    commit(SET_CONFIG_LOCALE, data.configuration_locale);
  },

  set_action_pages_redirect_url({commit}, url) {
    commit(SET_ACTION_PAGES_REDIRECT_URL, url);
  },

  action_pages_redirect({getters}) {
    if (getters.action_pages_redirect_url) {
      let url = new URL(getters.action_pages_redirect_url);
      getters.transactions.forEach((transaction, index) => {
        let key = 'transactions[' + index + ']';
        url.searchParams.append(key, transaction.id);
      });
      window.location.href = url;
    } 
  },

  set_campaign_details({commit, state}, campaignDetails) {
    commit(SET_CAMPAIGN_DETAILS, campaignDetails);
  },

  set_einvoice_configuration({commit, state}) {
    return apiClient.einvoice.einvoice_configuration({}, {})
      .then(data => {
        if (data) { 
          if (data.einvoice_base_configuration) {
            let baseConfig = JSON.parse(data.einvoice_base_configuration);
            einvoiceHelper.setEinvoiceBaseConfig(baseConfig);
            commit(SET_EINVOICE_BASE_CONFIGURATION, baseConfig);
          }
      
          if (data.einvoice_checkout_configuration) {
            let checkoutConfig = JSON.parse(data.einvoice_checkout_configuration);
            einvoiceHelper.setEinvoiceCheckoutConfig(checkoutConfig);
            commit(SET_EINVOICE_CHECKOUT_CONFIGURATION, checkoutConfig);
          }
        }
      })
      .catch(error => {
        logger.e(error)
      });
  }
}

export const baseMutations = {
  [SET_STYLE_CONFIG] (state, config) {
    Vue.set(state, "style", config);
  },

  [SET_TEXTS] (state, texts) {
    Vue.set(state, 'texts', texts);
  },
  [SET_PM_LISTS] (state, pm_list) {
    Vue.set(state, 'pm_list', pm_list);
  },
  [SET_PM_COUNT] (state, pm_count) {
    Vue.set(state, 'pm_count', pm_count);
  },
  [SET_INITED] (state) {
    Vue.set(state, 'inited', true)
  },
  [SET_INFO_ALERTS] (state, info_alerts) {
    Vue.set(state, 'info_alerts', info_alerts);
  },
  [SET_UI_CONFIG] (state, ui_config) {
    Vue.set(state, 'ui_config', ui_config);
  },
  [SET_NL_TEXTS](state, txts) {
    Vue.set(state, 'nl_texts', txts);
  },
  [SET_LOCALE_LIST](state, supported_locale_list) {
    Vue.set(state,'supported_locale_list',supported_locale_list);
  },
  [SET_LOCALE] (state, init_info_locale){
    Vue.set(state,'init_info_locale',init_info_locale);
  },
  [SET_CONFIG_LOCALE] (state, configuration_locale){
    Vue.set(state,'configuration_locale',configuration_locale);
  },
  [SET_SANDBOX_SITE] (state, sandbox){
    Vue.set(state, "sandbox_site", sandbox);
  },
  [SET_ALLOWED_DOMAINS] (state, allowedDomains) {
    if(allowedDomains) {
      Vue.set(state, "allowed_domains", allowedDomains.split(","));
    }
  },
  [SET_RECAPTCHA_SITE_KEY] (state, key){
    Vue.set(state,"recaptcha_key",key);
  },
  [SET_CHARGEBEE_LOGIN_MODE] (state, value) {
    Vue.set(state, "chargebee_login_mode", value)
  },
  [SET_LOGIN_EXPIRY_TIME] (state, value) {
    Vue.set(state, "login_expiry_time", value)
  },
  [SET_ACTION_PAGES_REDIRECT_URL](state, url) {
    Vue.set(state, 'action_pages_redirect_url', url);
  },
  [SET_CAMPAIGN_DETAILS](state, campaignDetails) {
    state.campaignDetails = campaignDetails;
  },
  [SET_SITE](state, site) {
    state.site = site;
  },
  [SET_ORGANISATION](state, organisation) {
    Vue.set(state, 'organisation', organisation);
  },
  [SET_DAY_BASED_BILLING_ENABLED](state, day_based_billing_enabled) {
    Vue.set(state, 'day_based_billing_enabled', day_based_billing_enabled);
  },
  [SET_MULTI_DECIMAL_CONFIG](state, value) {
    Vue.set(state, 'multi_decimal_config', value)
  },
  [SET_METERED_BILLING_ENABLED](state, value) {
    Vue.set(state, 'metered_billing_enabled', value)
  },
  [SET_EINVOICE_BASE_CONFIGURATION] (state, value) {
    Vue.set(state, 'einvoice_base_configuration', value);
  },
  [SET_EINVOICE_CHECKOUT_CONFIGURATION] (state, value) {
    Vue.set(state, 'einvoice_checkout_configuration', value);
  },
  [SET_USE_RECOMMENDED_ADDONS_INTERNAL_API](state, value) {
    Vue.set(state, 'use_recommended_addons_internal_api', value);
  },
  [SET_COLLECT_NOW_TXN_MAX_LIMIT] (state, value) {
    Vue.set(state, 'collect_now_txn_max_limit', value);
  },
}

export const baseGetters = {
  image_url: state => state.style.image && state.style.image.url,
  origin: state => state.style.origin,
  color: state => {
    return {
      'color': (state.style && state.style.color) || "#00ABC9"
    }
  },
  background_color: state => {
    return {
      'background-color': (state.style && state.style.color) || "#00ABC9"
    }
  },
  border_color: state => {
    return {
      'border-color': state.style && state.style.color || "#00ABC9"
    }
  },
  shadow_color: state => {
      let color = (state.style && state.style.color) || "#00ABC9"
      return {
          'color': hexToRgba(color,0.5)
      }
  },
  pm_list: state => state.pm_list,
  pm_list_filtered: (state, getters) => getters.pm_list.filter(pm => pm.invoice_limit ? pm.invoice_limit <= getters.estimate_total : true),
  mpm_supported: (state, getters) => (state.pm_count == -1 || (state.pm_count > 1)),
  canAdd: (state, getters) => state.pm_count == -1 || (getters.payment_method_list.length < state.pm_count),
  canAddNewPaymentMethod: (state, getters) => {
    if(state.pm_count == -1) return true;
    return (getters.master_pm_list.length < state.pm_count) ? true : false;
  },
  pm_by_type: (state, getters) => (pmType) => getters.pm_list.filter(pm => pm.type == pmType)[0],
  pm_by_mandate_enabled: (state, getters) => getters.pm_list.filter(pm => pm.mandate_config && pm.mandate_config.enabled && pm.gateway_name == "STRIPE")[0],
  show_mandate_screen: (state, getters) => getters.pm_list.filter(pm => pm.mandate_config && pm.mandate_config.enabled && pm.gateway_name == "RAZORPAY")[0],
  inited: state => state.inited,
  info_alerts: state => state.info_alerts,
  ui_config: state => state.ui_config,
  init_info_locale: state => state.init_info_locale,
  recaptcha_key: state => state.recaptcha_key,
  sandbox_site: state => state.sandbox_site,
  referer_allowed_domain: state => {
    let allowedDomains = state.allowed_domains;
    return getRefererDomain(allowedDomains);
  },
  is_otp_pwd: (state, getters) => getters.authType == "email" && state.chargebee_login_mode == 200,
  login_expiry_time: state => state.login_expiry_time * 60 * 1000,
  common_translate: state => (key) => {
    return state.texts['hp_v3.' + key];
  },
  get_text: state => (key) => {
    return state.texts[key];
  },
  excludeAddressForTaxCalculation: (state) => () => {
    return false;
  },
  action_pages_redirect_url: state => (!window.isOpenedByCb && state.action_pages_redirect_url),
  campaignDetails: state => state.campaignDetails,
  skip_cart_type_check: state => state.style && state.style.skip_cart_type_check,
  show_iframe_close: state => state.style && state.style.show_iframe_close,
  site_name: state => state.site,
  organisation_name: state=> state.organisation,
  day_based_billing_enabled: state => state.day_based_billing_enabled,
  multi_decimal_config: state => state.multi_decimal_config,
  metered_billing_enabled: state => state.metered_billing_enabled,
  einvoice_base: state => state.einvoice_base_configuration,
  einvoice_configuration: state => state.einvoice_checkout_configuration,
  show_einvoice: (state, getters) => getters.einvoice_base && getters.einvoice_base.isEnabled && getters.einvoice_base.collectPublicIdentifiersCheckout,
  use_recommended_addons_internal_api: state => state.use_recommended_addons_internal_api,
  collect_now_txn_max_limit: state => state.collect_now_txn_max_limit,
}

function hexToRgba(hex, opacity) {
  return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).concat(opacity||1).join(',') + ')';
}
