<template>
  <!-- TODO handle i18n -->
  <div id="app" class="cb-hp" :class="{'cb-hp--hidden': !isAndroidInstagram()}" tabindex="0" lang="en" role="dialog" aria-labelledby="dialog1Title" aria-describedby="dialog1Desc">
    <h2 class="no-display" id="dialog1Title">Billing Modal</h2>
    <p class="no-display" id="dialog1Desc">Billing modal powered by Chargebee</p>
    <router-view v-if="inited"></router-view>
    <grecaptcha-text v-if="recaptchaTextEnabled"/>
    <div class="cb-hp__close" :class="{'cb-hp__close--inside': isMobileIframe, 'cb-hp__close--show': isMobileIframe && show_iframe_close}" v-if="!hideClose" @click="close" v-cb-link = "'shadow'" role="button" aria-label="close checkout" data-cb-id="cb_close" v-on:keydown="closeKey">
      <i class="icon-remove cb-hp__close-icon"></i>
    </div>
  </div>
</template>
<style src="@/assets/styles/app.styl" lang="stylus"></style>
<style src="@/assets/styles/base/_fonts.styl" lang="stylus" scoped></style>
<script>
import { mapGetters } from "vuex"
import { isOpenedInsideAnIframe } from "@/utils/helper-functions"
import UrlHelper from "@/utils/url-helper"
import GrecaptchaText from '@/components/collections/elements/GrecaptchaText'
import RecaptchaUtil from '@/utils/recaptcha'
import triggerBeacon from '@/beacon'

export default {
    name: 'app',
    data() {
      return {
        transitionName: ""
      }
    },
    created() {
        if(!this.inited) {
          this.$store.dispatch('init_app');
        }
        addEventListener("beforeunload", triggerBeacon(this.$store));
    },
    mounted() {
        this.transitionName = "fadeMain";

        document.body.addEventListener("keydown", (event) => {
          if (event.keyCode == 27) {
            this.close();
          }
        });
    },
    methods: {
      // TODO DRY the code
      isAndroidInstagram() {
        return UrlHelper.isAndroidInstagram();
      },
      close() {
        window.cbPostMessage("cb.close");
        if(window.parent == window){
          // window.close();
        }
      },
      closeKey(event) {
        if(event.keyCode == 9) {
          document.getElementById("app").focus();
        }
      }
    },
    computed: {
      ...mapGetters(
        [
            'authenticated',
            'inited',
            'show_iframe_close'
        ]
      ),
      hideClose() {
        return !this.show_iframe_close && !window.isOpenedByCb && isOpenedInsideAnIframe();
      },
      isMobileIframe() {
        return window.isOpenedByCb && window.isMobileResolution && isOpenedInsideAnIframe();
      },
      recaptchaTextEnabled() {
        return RecaptchaUtil.isRecaptchaTextEnabled();
      }
    },
    components: {
      'grecaptcha-text': GrecaptchaText
    }
}
</script>
