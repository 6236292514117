
import { deepGet } from "@/utils/helper-functions"
import apiClient from "@/utils/cb-client"
import Transformer from "@/utils/transformer"
import { UpperCaseGateway, Gateway } from '@/models/gateway-type';

export const paymentMethods = {
  CARD: 'card',
  IDEAL: 'ideal',
  SOFORT: 'sofort',
  BANCONTACT: 'bancontact',
  GOOGLE_PAY: 'google_pay',
  APPLE_PAY: 'apple_pay',
  GIROPAY: 'giropay',
  DOTPAY: 'dotpay',
  DIRECT_DEBIT: 'direct_debit',
  NETBANKING_EMANDATES: "netbanking_emandates",
  UPI: 'upi',
  PAYPAL_EXPRESS_CHECKOUT : 'paypal_express_checkout',
  BOLETO: 'boleto',
  VENMO: 'venmo',
  FASTER_PAYMENTS: 'faster_payments',
  PAY_TO: 'pay_to',
  SEPA_INSTANT_TRANSFER: 'sepa_instant_transfer',
  KLARNA_PAY_NOW: 'klarna_pay_now',
  ONLINE_BANKING_POLAND: 'online_banking_poland'
}

export const offlinePaymentMethods = {
  CASH: 'cash',
  CHECK: 'check',
  BANK_TRANSFER: 'bank_transfer',
  ACH_CREDIT: 'ach_credit',
  SEPA_CREDIT: 'sepa_credit',
}

export const paymentStep = {
  FIRST_STEP: 'first_step',
  SECOND_STEP: 'second_step'
}

const DirectDebitIntentSupport = {
  'CHECKOUT_COM': ['USD', 'EUR'],
  'CHARGEBEE_PAYMENTS': ['USD', 'GBP', 'EUR'],
  'BLUESNAP': ['EUR', 'USD'],
  'BRAINTREE': ['USD'],
  'ADYEN': ['EUR','GBP','USD'],
  'STRIPE': ['GBP', 'AUD'],
  'GOCARDLESS': ['EUR', 'GBP', 'SEK', 'USD'],
}

export const AchVerificationType = {
  INSTANT : 'instant',
  AUTOMATIC: 'automatic',
  MANUAL: 'manual',
}

const Async_Gateway = [Gateway.ADYEN];
const Async_Payment_Methods = [offlinePaymentMethods.ACH_CREDIT, offlinePaymentMethods.SEPA_CREDIT, paymentMethods.DIRECT_DEBIT];
const Deprecated_Payment_Methods = [paymentMethods.SOFORT];

class PaymentHelper {
  setConfig(state, getters) {
    this.state = state
    this.getters = getters
  }

  isMandateEnabledPaymentMethod(pm) {
    return pm && pm.mandate_config && pm.mandate_config.enabled
    && [UpperCaseGateway.RAZORPAY, UpperCaseGateway.STRIPE].includes(pm.gateway_name);;
  }

  // You can't use stored payment source without mandate
  isStrictMandatePaymentMethod(pm) {
    return this.isMandateEnabledPaymentMethod(pm)
    && [UpperCaseGateway.RAZORPAY].includes(pm.gateway_name);
  }

  isMultiStepPaymentMethod(paymentMethodType) {
    return [
      'paypal_express_checkout',
      'direct_debit',
      'amazon_payments',
      'apple_pay',
      'venmo',
      'faster_payments',
      'pay_to',
      'sepa_instant_transfer'
    ].includes(paymentMethodType)
  }

  isInAppBankAccountPaymentMethod(pm, pmType) {
    return ['direct_debit', 'faster_payments', 'pay_to', 'sepa_instant_transfer'].includes(pmType) &&
      deepGet(pm, 'configuration.scheme') &&
      deepGet(pm, 'configuration.local_details_enabled') != false &&
      !pm.plaid_config;
  }

  isInAppInstantBankAccountPaymentMethod(pmType) {
    let pm = this.getters.pm_by_type(pmType);
    return ['faster_payments', 'pay_to', 'sepa_instant_transfer'].includes(pmType) &&
    deepGet(pm, 'configuration.scheme') &&
    deepGet(pm, 'configuration.local_details_enabled') != false;
  }

  isCJSButtonPaymentMethod(paymentMethodType) {
    if(paymentMethodType == 'direct_debit') {
      return this.isPreAuthDirectDebit();
    }

    let pm = this.getters.pm_by_type(paymentMethodType);

    if(['faster_payments', 'pay_to', 'sepa_instant_transfer'].includes(paymentMethodType)) {
      return !pm.configuration.local_details_enabled
    }

    return [
      paymentMethods.GOOGLE_PAY,
      paymentMethods.APPLE_PAY,
      paymentMethods.VENMO,
      paymentMethods.PAYPAL_EXPRESS_CHECKOUT
    ].includes(paymentMethodType);
  }


  isBoletoPaymentMethod(paymentMethodType) {
    return [
      'boleto'
    ].includes(paymentMethodType);
  }

  isOfflinePaymentMethod(paymentMethodType) {
    return [
      'cash',
      'check',
      'bank_transfer',
      'ach_credit',
      'sepa_credit',
      'boleto'
    ].includes(paymentMethodType);
  }

  // Payment Intent support for ref Id flow in Direct Debit
  isDirectDebitRefIdPaymentIntentSupported(gatewayName) {
    gatewayName = gatewayName.toUpperCase();
    return [
      'CHARGEBEE_PAYMENTS'
    ].includes(gatewayName);
  }

  checkDirectDebitIntentLookup(pm) {
    if(!pm) {
      return false;
    }
    let gwName = pm.gateway_name.toUpperCase();
    //TODO: Once release get stable, Remove the use of "can_use_intent_flow".
    if(gwName == "GOCARDLESS" && !pm.configuration.can_use_intent_flow) {
      return false;
    }
    let lookup = DirectDebitIntentSupport[gwName];
    return lookup && lookup.includes(pm.gateway_currency.toUpperCase());
  }

  isDirectDebitPaymentIntentSupported(paymentInfo) {
    if(paymentInfo && paymentInfo.reference_id) {
      return this.isDirectDebitRefIdPaymentIntentSupported(paymentInfo.gateway)
    }
    let pm = this.getters.pm_by_type(paymentMethods.DIRECT_DEBIT);
    if(pm) {
      let currency = pm.gateway_currency.toUpperCase();
      if(currency == 'USD' && this.isPreAuthDirectDebit(pm)) {
        return true;
      }
      return this.checkDirectDebitIntentLookup(pm);
    }
    return false;
  }

  isInstantBankPaymentIntentSupported(paymentInfo) {
    return !(paymentInfo && paymentInfo.reference_id)
  }

  isPreAuthDirectDebit(pm) {
    pm = pm || this.getters.pm_by_type(paymentMethods.DIRECT_DEBIT);
    if(pm && pm.plaid_config) {
      return [
        'CHECKOUT_COM'
      ].includes(pm.gateway_name.toUpperCase());
    } else if(pm && pm.financial_connections_config) {
      return [
        'STRIPE'
      ].includes(pm.gateway_name.toUpperCase());
    } else if(['GOCARDLESS'].includes(pm.gateway_name.toUpperCase())
        && this.checkDirectDebitIntentLookup(pm)) {
      return !pm.configuration.local_details_enabled;
    }
    return false;
  }

  getOfflinePaymentMethodIcon(paymentMethodType) {
    switch (paymentMethodType) {
      case 'cash':
        return 'icon-offline-cash'
      case 'check':
        return 'icon-offline-check'
      case 'bank_transfer':
        return 'icon-offline-bank-transfer'
      case 'ach_credit':
        return 'icon-offline-ach-credit'
      case 'sepa_credit':
        return 'icon-offline-sepa-credit'
      case 'boleto':
        return 'icon-offline-boleto'
    }
  }

  canShowPaymentMethodPicker() {
    let out = false;
    let len = this.getters.pm_list_filtered.length;
    this.getters.pm_list_filtered.forEach(pm => {
      if(pm.mode == 'OFFLINE' && (len > 3 || !pm.payment_instruction)) {
        out = true;
      }
      if(pm.type == 'google_pay') {
        out = true;
      }
    });
    return out;
  }

  canShowInstantBankSelector(type) {
    return ['faster_payments', 'sepa_instant_transfer'].includes(type);
  }

  ibanFieldAllowed(scheme) {
    return ['SEPA', 'BACS'].includes(scheme);
  }

  getPaymentMethodDisplayName(method) {
    if(!method) {
      return;
    }
    switch(method.type) {
      case "card":
        return this.st("credit_card");
      case "paypal_express_checkout":
        return "PayPal";
      case "venmo":
        return "Venmo";
      case "amazon_payments":
      case "apple_pay":
      case 'ideal':
      case 'sofort':
      case 'bancontact':
      case 'google_pay':
      case 'upi':
      case 'giropay':
      case 'dotpay':
      case 'faster_payments':
      case 'sepa_instant_transfer':
      case 'pay_to':
      case 'online_banking_poland':
        return this.cmt(`pm.${method.type}`);
      case "direct_debit":
        if(method.configuration && method.configuration.scheme) {
          switch (method.configuration.scheme) {
          case "ACH":
            return this.st("pm.ach")
          case "PAD":
            return this.cmt("direct_debit.pad")
          default:
            return this.st("pm.direct_debit");
          }
        } else {
          return this.st("pm.direct_debit");
        }
      case 'netbanking_emandates':
        return this.cmt("pm.netbanking");
      default:
        return method.display_name;
    }
  }

  st(key) {
    return this.getters.stranslate(key);
  }

  cmt(key) {
    return this.getters.common_translate(key);
  }

  hasTempToken(data) {
    return !!data.tmp_token
  }

  isCreateCardPaymentSource(pmType, data) {
    return pmType == 'card' && !this.hasTempToken(data)
  }

  doAction({ data, pm, pmType, replacePrimary, intent }, isBulkAction=false) {
    const suffix = isBulkAction ? '_bulk_action' : ''
    const params = {
      id: this.state.auth.customer_handle,
      hp_token: this.state.auth.hpToken,
    };

    const _create_source_using_pi = () => {
      return apiClient.customers[`create_payment_source_using_pi${suffix}`](
        params,
        Transformer.createPaymentSourceUsingPaymentIntent(intent, this.state, this.getters,replacePrimary)
      )
    }

    if(!!intent) {
      /**
       * In case of bulk action, the return object wont be a promise,
       * instead it will return an entry to be added to the list of calls to be made under bulk action
       */
      if (isBulkAction) {
        return _create_source_using_pi()
      } else {
        return new Promise((resolve, reject) => {
          /**
           * 0ms delay is added to make the API call on next event cycle
           * to prevent Network Error exclusively in Safari
           * inspite of request getting succeeded.
           * Ref: https://mychargebee.atlassian.net/browse/CHKOUTENGG-4497
           */
          setTimeout(() => {
            _create_source_using_pi().then(resolve).catch(reject)
          })
        })
      }
    }
    else if (this.isCreateCardPaymentSource(pmType, data)) {
      return apiClient.customers[`create_card_payment_source${suffix}`](
        params,
        Transformer.createCardPaymentSource(this.state, this.getters, data, replacePrimary)
      );

    } else if (this.isInAppBankAccountPaymentMethod(pm, pmType) && !this.hasTempToken(data)) {
      return apiClient.customers[`create_bank_account_payment_source${suffix}`](
        params,
        Transformer.createBankAccountPaymentSource(this.state, this.getters, data, replacePrimary)
      );
    } else if(this.isBoletoPaymentMethod(pmType)) {
      return apiClient.customers[`create_voucher_payment_source${suffix}`](
        params,
        Transformer.createVoucherPaymentSource(this.state, this.getters, data, replacePrimary)
      )
    } else {
      return apiClient.customers[`create_payment_source${suffix}`](
        params,
        Transformer.createPaymentSourceUsingTmpToken(this.state, this.getters, data, replacePrimary)
      )

    }
  }

  doApiRequest(params) {
    return this.doAction(params, false)
  }

  doBulkAction(params) {
    return this.doAction(params, true)
  }

  isPaymentIntentTransaction() {
    if(this.getters.selected_payment_method && this.getters.payment_intent) {
      const selectedGatewayId = this.getters.selected_payment_method.gateway_account_id;
      const paymentIntentGwId = this.getters.payment_intent.gateway_account_id;

      return selectedGatewayId === paymentIntentGwId;
    }
    return false;
  }

  is3dsEnabled(pm) {
    return pm.type == "card" && pm.enabled3ds;
  }

  getAddress(addr) {
    return {
      firstName: addr.first_name,
      lastName: addr.last_name,
      addressLine1: addr.line1,
      addressLine2: addr.line2,
      addressLine3: addr.line3,
      city: addr.city,
      state: addr.state,
      stateCode: addr.state_code,
      countryCode: addr.country,
      zip: addr.zip,
    }
  }

  getCustomerBillingAddress(store) {
    const addr = (store.state.customer && store.state.customer.billing_address) || {}
    return this.getAddress(addr)
  }

  getShippingAddress(store) {
    const addr = store.getters.selected_shipping_address;
    if(addr) return this.getAddress(addr)
  }

  getRazorpayAdditionalData(pmType, {shippingAddress, replace_primary_pm, retain_card}) {
    const additionalData = {};
    if (shippingAddress != null && Object.keys(shippingAddress).length !== 0) {
      additionalData['shippingAddress'] = shippingAddress;
    }
    const pm = this.getters.pm_by_type(pmType);

    let isOnetime = false;
    if(pm && this.getters.hosted_page_type == 'checkout') {
      isOnetime = !pm.mandate_config || !pm.mandate_config.enabled
    } else if (pm && this.getters.hosted_page_type == 'paynow') {
      isOnetime = !this.getters.isSingleSubInvoiceSelected || !(replace_primary_pm || retain_card)
    }
    if(isOnetime) {
      additionalData['paymentType'] =  "ONETIME"
    }
    if(!isOnetime && this.getters.cart_plan && this.getters.cart_plan.details) {
      additionalData['planId'] = this.getters.cart_plan.details.id;
    }
    return additionalData;
  }

  isSupportedPayment(hpType, ps) {
    if(!hpType || !ps) return true;

    if(Deprecated_Payment_Methods.includes(ps.type)) {
      return false;
    }
    // For offline payment we don't get gateway
    const gateway = (ps.gateway_name || ps.gateway || '').toLowerCase();

    if(hpType == 'portal' && [paymentMethods.SEPA_INSTANT_TRANSFER, paymentMethods.KLARNA_PAY_NOW, paymentMethods.ONLINE_BANKING_POLAND].includes(ps.type)) {
      return false;
    }

    if(hpType == 'collect_now' && [paymentMethods.KLARNA_PAY_NOW].includes(ps.type)) {
      return false;
    }

    return !(ps && hpType === "checkout_gift" && (Async_Gateway.includes(gateway) || Async_Payment_Methods.includes(ps.type)));
  }

  getCustomerInfo(store) {
    const customer = store.state.customer.data || {};
    return {
      firstName: customer.first_name,
      lastName: customer.last_name,
      phone: customer.phone,
      email: customer.email,
    }
  }

  loadGatewayConfig(getters, pm_source_list = []) {
    let config = {
      hasGw: true
    }
    const hpType = getters.hp_type || getters.hosted_page_type;
    if(hpType != 'portal') {
      const pm_list = getters.pm_list || [];
      const gwConfig = getters.non_smart_routing_gw_config || {};
      const cardPm = pm_source_list.find(pm => pm.type == paymentMethods.CARD);
      /* if customer has any saved card payment method and card gateway is not present in smart routing so
        we will fetch the gateway config to check if 3ds is applicable or not */
      if(cardPm && !gwConfig[cardPm.gateway_account_id] && pm_list && !pm_list.find(pm => pm.type == paymentMethods.CARD && pm.id == cardPm.gateway_account_id)) {
         config = {
          hasGw: false,
          cardPm
        }
      }
    }
    return config;
  }

}

export default new PaymentHelper()
