import HostedPageHelper from "@/utils/hosted-page-helper"
import PaymentHelper from "@/utils/payment-helper.js"
import contractConfig from "@/utils/subscription-contract-config";
import SubItemsModel from "@/utils/items-model";
import MultiDecimalHelper from "@/utils/multi-decimal"
export default class SubData {
  constructor(subscription) {
    this.subscription = subscription;
    this.id = subscription.id;
    this.isItemsModel = !!subscription.is_items_model;
    this.isMultiDecimal = MultiDecimalHelper.isMultiDecimal(subscription);
  }


  setRecurringEstimate(estimate) {
    this.recurringEstimate = estimate;
  }

  setGiftData(gift) {
    this.giftData = gift;
  }

  constructOrderItem() {
    // get plans and addons from recurring estimate and construct order item
    if(this.recurringEstimate) {
      return HostedPageHelper.constructOrderItemsFromEstimate(this.recurringEstimate, this.subscription);
    }
    else {
      return HostedPageHelper.constructOrderItemsFromSubscription(this.subscription);
    }
  }

  constructOrderItemsFromEstimate(estimate) {
    return HostedPageHelper.constructOrderItemsFromEstimate(estimate, this.subscription);
  }

  status() {
    return this.subscription.status;
  }

  billing_period() {
    return this.subscription.billing_period;
  }

  billing_period_unit() {
    return this.subscription.billing_period_unit;
  }

  planId() {
    return this.orderItem.plan.id;
  }

  payment_source_id() {
    return this.subscription.payment_source_id;
  }

  customerId() {
    return this.subscription.customer_id
  }

  displayer() {
    const { details } = this.orderItem ? this.orderItem.plan : {};
    return {
      plan: this.orderItem.plan,
      plan_id: this.orderItem.plan.id,
      plan_trial_unit: details?.trial_period_unit || ' ',
      plan_name: details?.invoice_name || details?.name || ' ',
      plan_quantity: this.orderItem.plan.quantity,
      plan_quantity_in_decimal: this.orderItem.plan.quantity_in_decimal,
      plan_quantity_metered: this.orderItem.plan.metered_quantity,
      description: details?.description || ' ',
      addons: this.orderItem.addons,
      hide_plan: !this.orderItem.plan.lineItem,
      state: this.subscription.status,
      billing_period: this.subscription.billing_period,
      billing_period_unit: this.subscription.billing_period_unit,
      currency_code: this.subscription.currency_code,
      recurring_price: this.recurringEstimate ? this.recurringEstimate.total : 0,
      shipping_address: this.subscription.shipping_address,
      payment_source_id: this.subscription.payment_source_id,
      id: this.id,
      activate_at: this.subscription.activate_at,
      auto_collection: this.subscription.auto_collection,
      subscription: this.subscription,
      recurringEstimate: this.recurringEstimate,
      isScheduledForResume: this.subscription.status == "paused" && this.subscription.resume_date,
      isScheduledForPause: (["active", "non_renewing"].indexOf(this.subscription.status) != -1) && this.subscription.pause_date,
      gift_id: this.subscription.gift_id,
      gift_data: this.giftData,
      isGift: this.subscription.gift_id,
      isGiftClaimed : this.subscription.gift_id && this.giftData && this.giftData.status == "claimed",
      isContractSubscription: !!this.subscription.contract_term,
      contract: this.subscription.contract_term,
      isItemsModel: this.isItemsModel,
      isMultiDecimal: this.isMultiDecimal,
      offline_payment_method: this.subscription.offline_payment_method,
      cancel_reason_code: this.subscription.cancel_reason_code,
      has_unbilled_usages: this.subscription.has_unbilled_usages,
      isPaidViaOfflinePm(store) {
        let auto_collection = this.subscription.auto_collection ? this.subscription.auto_collection : store.getters.customer_data.auto_collection;
        return auto_collection == 'off' && PaymentHelper.isOfflinePaymentMethod(this.subscription.offline_payment_method);
      },
      isMeteredPlan: this.orderItem.plan.metered,
      items: this.subscription.subscription_items,
      isAllItemsMetered: this.subscription.subscription_items && !! this.subscription.subscription_items.length &&
         ! this.subscription.subscription_items.filter(item => ! item.hasOwnProperty('metered_quantity')).length,
      isPaidByParent(store) {
        return store.getters.customerHasRelatedPaymentOwner(this.subscription.customer_id) && (this.subscription.override_relationship != true) ;
      },
      isInvoicedToParent(store) {
        return store.getters.customerHasRelatedInvoiceOwner(this.subscription.customer_id) && (this.subscription.override_relationship != true);
      },
      getPaymentOwnerDisplayName(store) {
        return store.getters.getCustomerDisplayName(store.getters.getPayOwnerIdOfCustomer(this.subscription.customer_id));
      },

      isScheduledForCancellation(){
        if(this.isContractSubscription) {
          return !!~['renew_once', 'cancel'].indexOf(this.contract.action_at_term_end)
        }
        return (["cancelled"].indexOf(this.state) == -1) && this.subscription.cancelled_at != undefined;
      },
      remainingTrialPeriod() {
        let seconds = this.subscription.trial_end - Math.round(Date.now() / 1000);
        return Math.round(seconds / (60 * 60 * 24));
      },
      scheduledResumptionDate() {
        return this.subscription.resume_date;
      },

      canCancel(store) {
        if(this.isGift) {
          return this.state != "cancelled" && store.getters.cancan.allow('cancel-subscription.gift-subscription.reciever.cancel');
        } else if(this.isContractSubscription) {
          if(!!~['renew', 'evergreen'].indexOf(this.contract.action_at_term_end))
          // If override is enabled, use portal contract setting otherwise use portal subscription settings
            if(contractConfig.isEnabled() && !contractConfig.isCancellationAllowed())  return false;
        }
        return store.getters.cancan.allow('cancel-subscription.action') && !(this.state == "paused" || this.state == "cancelled" || this.isScheduledForCancellation());
      },

      canShowEdit(store) {
        if(this.isContractSubscription && contractConfig.isEnabled() && !contractConfig.isEditAllowed()) {
          return false
          // Do not allow if contract is in Last billing cycle
          // Do not allow if its non-renewing and change occurs at end_of_term
          // This case will be handled in backend
        }

        // check if change subscription is selected
        if(!store.getters.cancan.allow('change-subscription.action')){
          return false;
        }

        if(this.gift_id) {
          return false;
        }

        switch(this.state){
          case "active" :
            return store.getters.cancan.allow('change-subscription.allowed_status.active');
          case "non_renewing" :
            return store.getters.cancan.allow('change-subscription.allowed_status.non_renewing');
          case "in_trial" :
            return store.getters.cancan.allow('change-subscription.allowed_status.trial');
          default :
            return false
        }

        if(this.isScheduledForPause) {
          return false;
        }

        // check if scheduled for cancellation
        // if(this.isScheduledForCancellation()) {
        //   if(!store.getters.cancan.allow('change-subscription.action.change_now')) {
        //     return false;
        //   }

        //   if(this.subscription.activate_at) {
        //     let auto_collection = this.subscription.auto_collection ?
        //        this.subscription.auto_collection == "on" : store.state.customer.data.auto_collection == "on";
        //     if(!auto_collection) {
        //       return false;
        //     }
        //     if(auto_collection && store.getters.payment_method_list.length == 0) {
        //       return false;
        //     }
        //   }
        // }

        return true;
      },
      remainingDaysForCancellation() {
        if(this.subscription.cancelled_at) {
          let cancelDate = this.subscription.cancelled_at * 1000;
          return Math.ceil(Math.abs(cancelDate - Date.now()) / (1000 * 3600 * 24));
        }
      },
      mandateID(store) {
        const subscription = this.subscription;
        if(subscription && subscription.auto_collection != "off" && subscription.payment_mandates && subscription.payment_mandates[0] && subscription.payment_mandates[0].mandate_id) {
          const paymentSourceId = this.subscription.payment_source_id || store.getters.customer_data.primary_payment_source_id;
         const mandate = this.subscription.payment_mandates.filter(mandate => mandate.payment_source_id === paymentSourceId);
         return mandate && mandate[0] && mandate[0].mandate_id;
       }
        return '';
      },
    }
  }
}
