import { termTranslate,replaceWithPlaceholders, replaceFromData } from "@/utils/helper-functions"

var CBStyleConfig = {};

CBStyleConfig.install = function(Vue, options){
  var common = {
    computed: {
      backgroundColor() {
        return this.$store.getters.background_color;
      },
      themeColor() {
        return this.$store.getters.color;
      },
      shadowColor() {
        return this.$store.getters.shadow_color;
      },
      logo() {
        return this.$store.getters.image_url;
      },
      borderColor(){
        return this.$store.getters.border_color;
      }
    },
    methods: {
      t(key, placeholders) {
        var output = this.$store.getters.translate(key);
        return replaceWithPlaceholders(output, placeholders);
      },
      ct(key, placeholders) { // locale translation for configurable texts
        var output = this.$store.getters.ctranslate(key);
        return replaceWithPlaceholders(output, placeholders);
      },
      st(key, placeholders) { // locale translation for static texts
        var output = this.$store.getters.stranslate(key);
        return replaceWithPlaceholders(output, placeholders);
      },
      cmt(key, placeholders) { // locale translation for common texts
        var output = this.$store.getters.common_translate(key);
        return replaceWithPlaceholders(output, placeholders);
      },
      nlt(key, placeholders) {
        var output = this.$store.getters.nl_translate(key);
        return replaceWithPlaceholders(output, placeholders);
      },
      nlDataTranslate(key, placeholders) {
        var output = this.$store.getters.nl_translate(key);
        return replaceFromData(output, placeholders);
      },
      dataTranslate(key, data) {
        var text = this.$store.getters.translate(key);
        return replaceFromData(text, data);

      },
      termTranslate(term,multiple) {
        return termTranslate(term,multiple, this.st);
      },
      date(timestamp, options) {
        if(!timestamp) {
          return '';
        }
        var options = options ||  { year: 'numeric', month: 'short', day: 'numeric' };
        var date = new Date(timestamp * 1000);
        return date.toLocaleDateString(this.$store.state.config.configuration_locale, options);
      },
      dateTime(timestamp) {
        return this.date(timestamp, {year: "numeric", month: "short", day: "numeric",hour: "2-digit", minute: "2-digit"});
      },
      focusOnFirstChild() {
        let firstChild = this.$el.querySelector("input:not([readonly=true])");
        window.setTimeout(() => {
          firstChild && firstChild.focus();
        },1000);
      }
    }
  }

  Vue.mixin(common);
};

export default CBStyleConfig;