<template>
  <div class="cb-tooltip" :class="{'cb-tooltip--inline': isInline, 'cb-tooltip--form': !isInline, 'cb-tooltip--top' : (toolTipPos=='top'), 'cb-tooltip--bottom' : (toolTipPos=='bottom'),'cb-tooltip--left' : (toolTipPos=='left'),'cb-tooltip--right' : (toolTipPos=='right'),  }"  v-if="text">
    <template v-if="!this.$props.showOnhover">
      <div :class="{'active-opacity': helpTextShown }" @click="showHelpText($event)" ref="helpText">
        <slot>
          <div class="cb-tooltip__trigger"></div>
        </slot>
      </div>
      <transition name="fadeInUpZoom">
        <div class="cb-tooltip__menu" :class="{'cb-tooltip__menu-sm': isSmall}" v-show="helpTextShown" ref="helpTextPopup" :style="{left: tooltipLeftPos+'px', top: tooltipTopPos+'px'}">
          {{text}}
          <span class="cb-tooltip__arrow" ref="helpTextArrow" :style="{marginLeft: arrowLeft}"></span>
        </div>
      </transition>
    </template>
    <template v-else>
      <div class="tool_tip" @mouseenter="showHelpText($event)"  ref="helpText">
        <slot>
          <div class="cb-tooltip__trigger"></div>
        </slot>
        <div class="cb-tooltip__menu tooltip_text" :class="{'cb-tooltip__menu-sm': isSmall}" ref="helpTextPopup" :style="{left: tooltipLeftPos+'px', top: tooltipTopPos+'px'}">
          {{text}}
          <span class="cb-tooltip__arrow" ref="helpTextArrow" :style="{marginLeft: arrowLeft}"></span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: ['text', 'isInline', 'isSmall', 'toolTipPos', 'showOnhover', 'relativeParentClass'], //isInline -> false for textboxes & true for others;
  data() {
    return {
      helpTextShown: false,
      tooltipLeftPos:0,
      tooltipTopPos:'auto',
      triggerWidth: '4',
      arrowLeft: '0'
    }
  },
  mounted() {
    this.$nextTick(()=> {
      this.triggerWidth = this.$refs.helpText && this.$refs.helpText.offsetWidth/2;
    })
  },
  methods: {
    showHelpText(event) {
      if (this.helpTextShown) {
        // remove event listener
        if(!this.$props.showOnhover) document.removeEventListener('click', this.closeHelpText);
      } else {
        window.cbTimeout(function() {

          if(!this.$props.showOnhover) document.addEventListener('click', this.closeHelpText);
          let tooltipArrowWidth = this.$refs.helpTextArrow && this.$refs.helpTextArrow.offsetWidth,
              tooltipArrowHeight = this.$refs.helpTextArrow && this.$refs.helpTextArrow.offsetHeight,
              helpTextPopupWidth = this.$refs.helpTextPopup && this.$refs.helpTextPopup.offsetWidth,
              helpTextPopupHeight = this.$refs.helpTextPopup && this.$refs.helpTextPopup.offsetHeight,
              helpTextPopupLeft = this.$refs.helpTextPopup && this.$refs.helpTextPopup.offsetLeft,
              helpTextPopupTop = this.$refs.helpTextPopup && this.$refs.helpTextPopup.offsetTop,
              helpTextWidth = this.$refs.helpText && this.$refs.helpText.offsetWidth,
              helpTextHeight = this.$refs.helpText && this.$refs.helpText.offsetHeight,
              helpTextLeft = this.$refs.helpText && this.$refs.helpText.offsetLeft,
              helpTextTop = this.$refs.helpText && this.$refs.helpText.offsetTop;

          let overflowedWidth = 0;

          if(this.toolTipPos=='top' || this.toolTipPos=='bottom'){
           if(helpTextPopupWidth > 0){ // Condition for IE 10 fallback
             this.tooltipLeftPos = helpTextLeft - helpTextPopupWidth/2 +  tooltipArrowWidth/4;
            overflowedWidth = (this.tooltipLeftPos+ helpTextPopupWidth/2+ helpTextPopupWidth/2) -document.getElementsByClassName(this.relativeParentClass)[0].offsetWidth;
            if(overflowedWidth>=0){
              this.tooltipLeftPos = this.tooltipLeftPos - overflowedWidth;
              this.arrowLeft = overflowedWidth+'px';
           }
            if(this.toolTipPos=='top'){
              this.tooltipTopPos = helpTextTop - helpTextPopupHeight;
            }
           }
          }

          else if(this.toolTipPos=='left'){
            this.tooltipLeftPos =  helpTextLeft - helpTextPopupWidth;
            this.tooltipTopPos = helpTextTop - helpTextPopupHeight/2 -this.$refs.helpText.offsetHeight/2 + this.$refs.helpText.offsetHeight/2 + tooltipArrowHeight/2
            this.triggerWidth =0;
          }

          else{
            this.tooltipLeftPos =  helpTextLeft + helpTextWidth;
            this.tooltipTopPos = helpTextTop - helpTextPopupHeight/2 -this.$refs.helpText.offsetHeight/2+  this.$refs.helpText.offsetHeight/2+ tooltipArrowHeight/2;
            this.triggerWidth =0;
          }

        }.bind(this));

      }

      this.helpTextShown = !this.helpTextShown;
    },
    closeHelpText(event) {
      var helpTextElement = this.$refs.helpText;
      if (event.target != helpTextElement) {
        if(!this.$props.showOnhover) document.removeEventListener('click', this.closeHelpText);
        this.helpTextShown = false;
      }
    }
  }
}
</script>
<style scoped>
.tooltip_text {
      visibility: hidden;
      opacity: 0;
      transform: translateY(50%) scale(0);
      transition: opacity 0.3s, transform 0.3s;
    }

.tool_tip:hover .tooltip_text {
      visibility: visible;
      opacity: 1;
      transform: translateY(0) scale(1);
    }
</style>
