import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/common/auth"
import customer from "@/store/common/customer"
import form from "@/store/common/form"

import config from "./portal_config"
import invoice from "./invoice"
import subData from "./sub_data"
import changeOrder from './change-order'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    customer,
    form,
    auth,
    config,
    invoice,
    subData,
    changeOrder
  },
  strict: true
});