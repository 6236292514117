import Vue from 'vue'
import Validators from "@/utils/validators.js";

const state = {
  temp : {}
}

const CREATE_FORM = "CREATE_FORM";
const DELETE_FIELD_FROM_FORM = "DELETE_FIELD_FROM_FORM";
const INSERT_FIELD_INTO_FORM = "INSERT_FIELD_INTO_FORM";
const CREATE_FIELD = "CREATE_FIELD";
const UPDATE_FORM_META = "UPDATE_FORM_META"
const DELETE_FIELD = "DELETE_FIELD";
const CREATE_VIRTUAL_FIELD = "CREATE_VIRTUAL_FIELD";
const MARK_LOADED = "MARK_LOADED";
const UPDATE_FIELD = "UPDATE_FIELD";
const SET_ERROR = "SET_ERROR";
const CLEAR_FORM = "CLEAR_FORM";
const SET_FORM_INVALID = "SET_FORM_INVALID";
const SET_TEMP = "SET_TEMP";
const DELETE_TEMP = "DELETE_TEMP";
const FOCUS_FIELD = 'FOCUS_FIELD'
const BLUR_FIELD = 'BLUR_FIELD'

var fillForm = function(dispatch, name, config, defValues, pName) {
  config.forEach(f => {
    if(f.sub_fields){
      var tmp = {
        meta: f,
        invalid: false,
        error: "",
      }
      fillForm(dispatch, name, f.sub_fields, defValues, f.name);
      dispatch('create_virtual_field', {name: name, key: f.name, value: tmp});
    }
    else {
      var tmp = {
        meta: f,
        value: defValues[f.name] || f.def_value || "",
        invalid: false,
        error: "",
        pName: pName,
        focussed: false
      }
      dispatch('create_field', {name: name, key: f.name, value: tmp});
    }
  });
}

const actions = {
  create_form({commit, state, dispatch}, pl) {
    commit(CREATE_FORM, pl);
    fillForm(dispatch, pl.name, pl.config, pl.def_values || {});
    commit(MARK_LOADED, pl.name)
  },

  create_field({commit, state}, pl) {
    commit(CREATE_FIELD, pl)
  },

  delete_form_field({commit, state}, {formName, fieldName}) {
    commit(DELETE_FIELD_FROM_FORM, {formName, fieldName});
  },

  insert_form_fields({commit, state, dispatch}, {formName, config}) {
    config.forEach(f => {
      let tmp = {
        meta: f,
        value: f.def_value || "",
        invalid: false,
        error: "",
        pName: formName,
        focussed: false
      }
      dispatch('create_field', {name: formName, key: f.name, value: tmp});
      commit(INSERT_FIELD_INTO_FORM, {formName, fieldConfig: f});
    })
  },

  // DON"T USE THIS FOR VIRTAL & SUB FIELDS
  update_form_meta({commit, getters, dispatch}, pl) {
    commit(UPDATE_FORM_META, pl);
    let newConfig = pl.config;
    let exDataKeys =  getters.fields(pl.formName);
    //delete unused field in new config
    exDataKeys.forEach(exKey => {
      let found = newConfig.filter(newConf => newConf.name == exKey);
      if(found.length == 0) {
       commit(DELETE_FIELD, {formName: pl.formName, key: exKey});
      }
    });
    //insert new field in new config
    newConfig.forEach(newConf => {
      let found = exDataKeys.filter(exKey => newConf.name == exKey);
      if(found.length == 0) {
        var tmp = {
          meta: newConf,
          value: newConf.def_value || "",
          invalid: false,
          error: "",
          pName: pl.formName
        }
        dispatch('create_field', {name:pl.formName, key: newConf.name, value: tmp});
      }
    });
  },

  create_virtual_field({commit, state}, pl) {
    commit(CREATE_VIRTUAL_FIELD, pl);
  },

  update_form_input({commit, state}, pl) {
    commit(UPDATE_FIELD, pl)
  },

  update_form_inputs({commit, state}, pl) {
    let newValues = pl.data;
    let formName = pl.formName;
    Object.keys(newValues).forEach(key => {
      commit(UPDATE_FIELD, {
        name: key,
        value: newValues[key],
        formName: formName
      })
    });
  },

  validate_form({commit, state, dispatch}, name) {
    return new Promise((resolve, reject) => {
      Object.keys(state[name].data).forEach(field => {
        dispatch('validate_field', {fname: name, name: field});
      });
      var invalidFields = Object.keys(state[name].data).filter(f => state[name].data[f].invalid);
      if (invalidFields.length > 0) {
        commit(FOCUS_FIELD, {
          fname: name,
          name: invalidFields[0]
        })
      }
      commit(SET_FORM_INVALID, {fname: name, invalid: invalidFields != 0});
      resolve(invalidFields.length == 0);
    });
  },

  focus_field({commit, state, dispatch}, {formName, fieldName}) {
    commit(FOCUS_FIELD, {
      fname: formName,
      name: fieldName
    })
  },

  blur_field({commit, state, dispatch}, {formName, fieldName}) {
    commit(BLUR_FIELD, {
      fname: formName,
      name: fieldName
    })
  },

  is_form_complete({commit, state, dispatch}, name) {
    return Object.keys(state[name].data).filter(f => !state[name].data[f].value).length == 0;
  },

  validate_field({commit, getters, state}, {fname, name, options}) {
    var config = getters.field_config(fname, name);
    var validations = config.meta.validations;
    var optional = !config.meta.required;
    var value = config.value;
    var skipEmpty = options && options.skipEmpty;
    var errMessageToBeSet = "";
    // if(validations && (optional ? !!value : skipEmpty ? value : true)){
    if(validations && (skipEmpty ? value : true)){
      Object.keys(validations).some(key => {
        errMessageToBeSet = Validators[key] &&
          Validators['validationWrapper'](key, validations, value, state[fname].data, name);
          return !!errMessageToBeSet;
      });
    }
    commit(SET_ERROR, {fname: fname, name: name, error: errMessageToBeSet})
  },

  set_error({commit, state}, {fname, name, error}) {
    commit(SET_ERROR, {fname: fname, name: name, error: error})
  },

  clear_form({commit, getters, state}, fname) {
    Object.keys(state[fname].data).forEach(field => {
      commit(UPDATE_FIELD, {formName: fname, value: "", name: field});
    });
  },

  set_form_temp({commit}, dataset) {
    Object.keys(dataset).forEach(key => {
      commit(SET_TEMP, {key: key, value: dataset[key]});
    });
  },

  delete_form_temp({commit}, key) {
    commit(DELETE_TEMP, key);
  }
}

const mutations = {
  [CREATE_FORM] (state, {name, config}) {
    var tmp = {
      meta: config,
      data: {},
      invalid: false,
      vData: {}
    }

    Vue.set(state, name, tmp);
  },

  [CREATE_FIELD] (state, {name, key, value}) {
    Vue.set(state[name].data, key, value);
  },

  [DELETE_FIELD_FROM_FORM] (state, {formName, fieldName}) {
    const index = state[formName].meta.findIndex(e => e.name == fieldName);
    if(index > -1) {
      Vue.delete(state[formName].meta, index);
      Vue.delete(state[formName].data, fieldName);
    }
  },

  [INSERT_FIELD_INTO_FORM] (state, {formName, fieldConfig}) {
    const index = state[formName].meta.findIndex(e => e.name == fieldConfig.name);
    if(index < 0) {
      Vue.set(state[formName].meta, state[formName].meta.length, fieldConfig);
    }
  },

  [UPDATE_FORM_META] (state, {formName, config}) {
    Vue.set(state[formName], 'meta', config);
  },

  [DELETE_FIELD] (state, {formName, key}) {
    Vue.delete(state[formName].data, key);
  },

  [CREATE_VIRTUAL_FIELD] (state, {name, key, value}) {
    Vue.set(state[name].vData, key, value);
  },

  [MARK_LOADED] (state, name) {
    Vue.set(state[name], 'loaded', true);
  },

  [UPDATE_FIELD] (state, {formName, value, name}) {
    Vue.set(state[formName].data[name], 'value', value);
  },

  [SET_ERROR] (state, {fname, name, error}) {
    Vue.set(state[fname].data[name], 'error', error);
    Vue.set(state[fname].data[name], 'invalid', !!error);
  },

  [FOCUS_FIELD] (state, {fname, name}) {
    Vue.set(state[fname].data[name], 'focussed', true);
  },

  [BLUR_FIELD] (state, {fname, name}) {
    Vue.set(state[fname].data[name], 'focussed', false);
  },

  [SET_FORM_INVALID] (state, {fname, invalid}) {
    Vue.set(state[fname], 'invalid', invalid);
  },

  [SET_TEMP] (state, {key, value}) {
    Vue.set(state['temp'], key, value);
  },

  [DELETE_TEMP] (state, key) {
    Vue.delete(state['temp'], key);
  }
}

const getters = {
  field_config: state => (pName, name) => {
    return state[pName] && state[pName].data[name]
  },
  vField_config: state => (pName, name) => {
    return state[pName] && state[pName].vData[name]
  },
  values: state => (name) => {
    const formData = (state[name] && state[name].data) || {}
    return Object.keys(formData).reduce((o, f) => {
      o[f] = state[name].data[f].value;
      return o;
    }, {})
  },
  field_value: state => (pName, name) => {
    return state[pName].data[name] && state[pName].data[name].value;
  },
  field: state => (pName, name) => {
    return state[pName].data[name] && state[pName].data[name];
  },
  fields: state => (pName) => {
    return Object.keys(state[pName].data);
  },
  is_form_complete: (state, getters) => (name) => {
    return Object.keys(state[name].data).filter(f => !state[name].data[f].value && !getters.field_config(name, f).meta.read_only).length == 0
  },
  is_form_complete_only_required: (state, getters) => (name) => {
    return Object.keys(state[name].data).filter(f => getters.field_config(name, f).meta.required).filter(f => {
      if(f == "state" && ["US", "CA", "IN"].indexOf(state[name].data['country'] && state[name].data['country'].value) > -1) {
        return false;
      }
      else if(f == "state_code" && ["US", "CA", "IN"].indexOf(state[name].data['country'] && state[name].data['country'].value) == -1){
        return false;
      }
      else {
        return !state[name].data[f].value;
      }
    }).length == 0
  },
  get_form_temp: state => (key) => state['temp'][key]
}

export default {
  state,
  actions,
  mutations,
  getters
}
