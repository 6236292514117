<template>
  <div class="cb-body__wrap">
    <div class="cb-body__content">
      <div class="cb-section">
        <slot></slot>
        <account-summary :account="account" :fields-to-show="fields"></account-summary>
        <cb-notes :custom-class="'cb-section--md'" :type="'info'" :message="t('account.note')"></cb-notes>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import AccountSummary from "@/components/modules/portal/PortalAccountSummary"
import Notes from "@/components/collections/elements/Notes"
import {
  mapGetters
} from "vuex"
export default {
  components: {
    'account-summary': AccountSummary,
    'cb-notes': Notes
  },
  computed: {
    ...mapGetters([
      "allowed_actions",
      "entry_point",
      "cancan",
      'is_otp_pwd',
      'customer_email'
    ]),
    account() {
      return this.$store.state.customer.data;
    },
    accountPresent() {
      return this.account && this.fields.filter((f) => this.account[f]).length > 0;
    },
    fields() {
      return this.$store.state.config.sections.customer.fields.map(f => f.name)
    }
  },
  methods: {
    navigate(section) {
      this.$router.customPush({
        name: section
      });
    },
    showPwdSection() {
      //if customer email is changed, then not showing this section
      return this.is_otp_pwd && (this.$store.state.auth.email == this.customer_email);
    },
  },
  mounted() {
    // TODO this should be done at the route level
    if(!this.cancan.allow('account.allow') && !this.showPwdSection()) {
      this.$router.push({name: 'error'});
      return;
    }
    if (!this.accountPresent && !this.showPwdSection()) {
      if (this.entry_point == this.$route.name) {
        this.$store.dispatch("set_entry_point", "portal_edit_account");
      }
      this.$router.customReplace({
        name: "portal_edit_account"
      });
    }
  }
}
</script>
