import cardFormatter from "@/utils/card-formatter"
import Transformer from "@/utils/transformer";
import "@/utils/vendor-polyfills.js"
import { DateTime } from "luxon";
import { replaceWithPlaceholders } from "@/utils/helper-functions"
import countriesWithoutZip from "@/utils/countries-without-zip";
import isEmail from "validator/lib/isEmail";

var checkIfAlternativeFieldIsPresent = function(otherData, field1, field2, fieldName) {
    if(fieldName == field1) {
        return !!otherData[field2].value;
    }
    if(fieldName == field2) {
        return !!otherData[field1].value;
    }
}
export default {
    validationWrapper(key, options, value, otherData, fieldName){
        var func = this[key].bind(this);
        var hasDependsOn = options["depends_on"];
        var allowBlank = options["allow_blank"];
        if(allowBlank && !hasDependsOn && !value) {
            return;
        }
        if(hasDependsOn){
            var dependsOnValid = otherData[hasDependsOn].value.toString() == options["depends_val"];
            if(allowBlank && dependsOnValid) {
                return;
            }
        }

        if(checkIfAlternativeFieldIsPresent(otherData, "state", "state_code", fieldName)) {
            return;
        }
        if(checkIfAlternativeFieldIsPresent(otherData, "billing_state", "billing_state_code", fieldName)) {
            return;
        }
        return func(options, value, otherData, fieldName);
    },

    /*
     * Checks whether the value for the element is present. options -
     * message
     */
    presence : function(options, value, otherData, fieldName) {
        if(otherData[fieldName] && otherData[fieldName].meta && (otherData[fieldName].meta.type == "checkbox")) {
            if(value == "False") {
                return "EMPTY";
            }
        }
        
        if (/^\s*$/.test(value || "")) {
            return "EMPTY";
        }
    },

    zip_presence : function(options, value, otherData, fieldName) {
        let countryFld = options['zip_presence']['country_field'];
        if(!(otherData[countryFld] && otherData[countryFld].value && countriesWithoutZip.find(e => e == otherData[countryFld].value))) {
            return this.presence(options, value, otherData, fieldName);
        }
    },

    custom_date: function(options, value, otherData, fieldName) {
        // validate presence
        let presenceCheck = this.presence(options, value, otherData, fieldName);
        if(presenceCheck) {
            return presenceCheck;
        }

        // format check
        let obj = DateTime.fromFormat(value, window.ifNativeDateSupported ? "yyyy-MM-dd" : "MM-dd-yyyy");
        if(!obj.isValid) {
            return replaceWithPlaceholders(options['custom_date']['message.invalid'], {"regex": window.ifNativeDateSupported ? "yyyy-MM-dd" : "MM-dd-yyyy"});
        }
    },

    custom_time: function(options, value, otherData, fieldName) {
        // validate presence
        let presenceCheck = this.presence(options, value, otherData, fieldName);
        if(presenceCheck) {
            return presenceCheck;
        }
        if(!(DateTime.fromFormat(value, "HH:mm").isValid)) {
            return replaceWithPlaceholders(options['custom_time']['message.invalid'], {"regex": "HH:mm"});
        }

        // TODO make it generic
        if(otherData['gift_schedule_at']) {
            let t = DateTime.fromFormat(`${otherData['gift_schedule_at'].value} ${value}`, window.ifNativeDateSupported ? "yyyy-MM-dd HH:mm" : "MM-dd-yyyy HH:mm");
            if(!t.isValid || t < DateTime.fromJSDate(new Date())) {
                return options['custom_time']['message.exceed'];
            }
        }
    },

    /*
     * Checks whether the input value matches defined format options -
     * message with => format the input value should match without =>
     * format the input value should not match
     */
    format : function(options, value) {
        var validationParams = options["format"];
        if (validationParams['with']) {
            var regex = new RegExp(validationParams['with'], validationParams['modifiers']);
            if(!regex.test(value)) {
                return validationParams.message;
            }
        }
    },
    /*
     * Checks whether the input value contains only alphabets options -
     * message
     */
    alpha : function(options, value) {
        var validationParams = options["alpha"];
        if(!(/^[A-Za-z]+$/.test(value))) {
            return validationParams.message;
        }
    },
    /*
     * Checks whether the input value contains alphanumeric characters
     * options - message
     */
    alphaNumeric : function(options, value) {
        var validationParams = options["alphanumeric"];
        if(!(/^[0-9A-Za-z]+$/.test(value))) {
            return validationParams.message;
        }
    },
    /*
     * Checks whether the input is a numeric value.
     * options  - message - message to show on error
     *          - strict_integer - true if strictly a integer without any decimals otherwise false
     *    - minimum - minimum value to compare with (inclusive)
     *    - maximum - maximum value to compare with (inclusive)
     */
    numeric : function(options, value) {
        var validationParams = options["numeric"];
        if (!/^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d*)?$/.test(value)) {
            return validationParams.message;
        }
    },
    /*
     * Checks whether the value is strictly integer without any decimal values.
     * options - message
     */
    strictInt : function(options, value) {
        var validationParams = options["strictInt"];
        if (!/^\d+$/.test(value)) {
            return validationParams.message;
        }
    },
    /*
     * Check the range of the input value.
     * options - message
     *         - minimum - the minimum value excluded
     *         - maximum - the maximum value included
     */
    range : function(options, value) {
        var validationParams = options["range"];
        if(validationParams.minimum && parseFloat(value) < validationParams.minimum) {
            return validationParams.message;
        }
        if(validationParams.maximum && parseFloat(value) > validationParams.maximum) {
            return validationParams.message;
        }
    },
    /*
     * Check the length of the input value options - message
     * options - message
     *       - is - length should be exactly equal to
     *       - minimum - the minimum length of the field value
     *       - maximum - the maximum value of the field value
     */
    length : function(options, value) {
        var validationParams = options["length"];
        value = value || "";
        if(validationParams.is && value.length != validationParams.is) {
            return validationParams.message;
        }
        if(validationParams.minimum && value.length < validationParams.minimum) {
            return validationParams.message;
        }
        if(validationParams.maximum && value.length > validationParams.maximum) {
            return validationParams.message;
        }
    },

    email : function(options, value) {
        var validationParams = options["email"];
        if(!isEmail(value)) {
            return validationParams.message;
        }
    },

    vpa : function(options, value) {
        var validationParams = options["vpa"];
        if(!(/^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/i.test(value))) {
            return validationParams.message;
        }
    },

    dateFormat : function(options, value) {
        var validationParams = options["dateFormat"];
        if(!Transformer.isDate(value)){
            return validationParams.message;
        }
    },

    timestampFormat : function(options, value) {
        var validationParams = options["timestampFormat"];
        //internal value will get updated iff date is in correct format
        if(!Transformer.isNumber(value)){
            return validationParams.message;
        }
    },

    urlFormat: function(options, value) {
        if(!(new RegExp('^(http|https)://[-A-Za-z0-9\\.]+\\/?.*$').test(value))){
            return options["urlFormat"].message;
        }
    },
    /*
     * Check whether the string passed is of currency format
     */
    money : function(options, value) {
        var validationParams = options["money"];
        if(!(/^[\+|-]?[\d]+([\.][\d]{1,2})?$/.test(value))) {
            return validationParams.message;
        }
    },

    allowNegative : function(options, value) {
        var validationParams = options["allowNegative"];
        if(parseFloat(value < 0)) {
            return validationParams.message;
        }
    },

    allowZero : function(options, value) {
        var validationParams = options["allowZero"];
        if(parseFloat(value == 0)) {
            return validationParams.message;
        }
    },
    /*
     * Checks the validity of credit card.
     */
    luhnCheck : function(options, value)
    {
        var validationParams = options["luhnCheck"];
        var cc = value;
        var sum = 0;
        var numdigits = cc.length;
        var parity = numdigits % 2;
        for(var i=0; i < numdigits; i++) {
            var digit = parseInt(cc.charAt(i))
            if(i % 2 == parity) digit *= 2;
            if(digit > 9) digit -= 9;
            sum += digit;
        }
        if((sum % 10) != 0) {
            return validationParams.message;
        }
    },
    /*
     * validates credit card number.
     */
    cardNumber: function(options, value) {
        var validationParams = options["cardNumber"];
        if (!cardFormatter.validateCardNumber(value)) {
            return validationParams.message;
        }
    },
    /*
     * validates cvv based on card type.
     */
    cardCVV: function(options, value, otherData) {
        // TODO change the logic of fetching card number
        var validationParams = options["cardCVV"];
        var cardNumber = otherData['number'];
        if (typeof cardNumber == 'undefined' || cardNumber == '') {
            return;
        }
        var cardType = cardFormatter.cardType(cardNumber);
        if (!cardFormatter.validateCardCVC(value, cardType)) {
            return validationParams.message;
        }
    },
    /*
     * validates card expiry.
     */
    cardExpiry: function(options, value, otherData) {
        var validationParams = options["cardExpiry"];
        var monthValue = otherData["expiry_month"].value;
        if (/^\s*$/.test(value || "") && /^\s*$/.test(monthValue || "")) {
            return "EMPTY";
        }
        if (!cardFormatter.validateCardExpiry(monthValue.toString(), value.toString())) {
            return validationParams.message;
        }
    }
}