class SubContractConfig {
    allowedFields = [];
    enabled = false;
    allowedActions = [];

    init(contractConfig={}) {
        this.setEnabled(contractConfig.enabled)
        this.setAllowedFields(contractConfig.fields)
        this.setAllowedActions(contractConfig.allowed_actions)
    }

    setEnabled(bool=false) {
        this.enabled = bool
    }

    setAllowedFields(allowedFields=[]) {
        this.allowedFields = allowedFields
    }

    setAllowedActions(allowedActions=[]) {
        this.allowedActions = allowedActions;
    }

    isEnabled() {
        return this.enabled
    }

    isFieldAllowed(field) {
        // ENUM: ["TCV", "BILL_CYC", "CUT_OFF", "END_ACTION"]
        return !!~this.allowedFields.indexOf(field) || field == 'CTRT_DUR'
    }

    isActionAllowed(action) {
        return !!~this.allowedActions.indexOf(action)
    }

    isCancellationAllowed() {
        return this.isActionAllowed('cancel-subscription.action')
    }

    isCancellationAllowedInCutOffPeriod() {
        return this.isActionAllowed('cancel-subscription.action.allow_in_contract_cutoff')
    }

    isEditAllowed() {
        return this.isActionAllowed('change-subscription.action')
    }

    isRemoveScheduledChangesAllowed() {
        return this.isActionAllowed('scheduled-changes.allow_remove_scheduled_changes.allow')
    }

    isReactivateAllowed() {
        return this.isActionAllowed('reactivate-subscription.action.reactivate')
    }
}

export default new SubContractConfig();