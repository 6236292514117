<template>
  <div>
    <div class="cb-bar__wrap cb-bar__link cb-bar__link--bt" v-cb-link="'shadow'" @click="navigate_to('sub_template')"
      v-if="subscriptionLimit && subscriptionsToShow.length > subscriptionLimit" role="link" data-cb-id="load_more">
      <!-- When there are 3 subscriptions in the portal, then show the text "+1 subscription" in singular as we display only two subscriptions. -->
      <!-- When there are more than 3 subscriptions in the portal, then show the text "+x subscriptions" in plural as we display only two subscriptions.-->
      <template v-if="showNumberOfItemsMore">+{{ subscriptionsToShow.length - 2 }} {{ subscriptionsToShow.length ==
        (parseInt(subscriptionLimit) + 1) ? st('more_subscriptions_txt') : st('more_subscriptions_in_plural_txt')
      }}</template>
      <template v-else>{{ t('subscriptions.more') }}</template>
    </div>
  </div>
</template>
<script type="text/javascript">
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "subDataDisplayList"
    ]),
    subscriptionsToShow() {
      return this.subDataDisplayList?.filter(i =>
        i.isGift ? i.isGiftClaimed : true
      );
    },
    showNumberOfItemsMore() {
      return !this.$store.state.customer.subscription_next_offset;
    },
  },
  props: ["subscriptionLimit"],
  methods: {
    navigate_to(section, options) {
      options = options || {};
      options.name = section;
      this.$router.customPush(options);
    },
  }
};
</script>
<style lang="stylus">
.cb-portal-section {
  .cb-bar__wrap:hover {
    .cb-notebar {
      background: #e7eff7;
    }
  }
  .cb-notebar__content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

