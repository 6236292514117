
export const Gateway = {
  STRIPE : "stripe",
  ADYEN : "adyen",
  BRAINTREE : "braintree",
  SPREEDLY : "spreedly",
  CHARGEBEE : "chargebee",
  CHECKOUT_COM : "checkout_com",
  CYBERSOURCE : "cybersource",
  BLUESNAP : "bluesnap",
  INGENICO_DIRECT : "ingenico_direct",
  WORLDPAY : "worldpay",
  AUTHORIZE_NET : "authorize_net",
  MOLLIE : "mollie",
  RAZORPAY : "razorpay",
  CHARGEBEE_PAYMENTS : "chargebee_payments",
  BANK_OF_AMERICA : "bank_of_america",
  PAYPAL_EXPRESS_CHECKOUT : "paypal_express_checkout",
  PAYPAL : "paypal",
  AMAZON_PAYMENTS : "amazon_payments",
  GOCARDLESS : "gocardless",
  EBANX : "ebanx",
  DLOCAL: "dlocal",
  PAY_COM: "pay_com"
}

export const UpperCaseGateway = {
  STRIPE : "STRIPE",
  ADYEN : "ADYEN",
  BRAINTREE : "BRAINTREE",
  SPREEDLY : "SPREEDLY",
  CHARGEBEE : "CHARGEBEE",
  CHECKOUT_COM : "CHECKOUT_COM",
  CYBERSOURCE : "CYBERSOURCE",
  BLUESNAP : "BLUESNAP",
  INGENICO_DIRECT : "INGENICO_DIRECT",
  WORLDPAY : "WORLDPAY",
  AUTHORIZE_NET : "AUTHORIZE_NET",
  MOLLIE : "MOLLIE",
  RAZORPAY : "RAZORPAY",
  CHARGEBEE_PAYMENTS : "CHARGEBEE_PAYMENTS",
  BANK_OF_AMERICA : "BANK_OF_AMERICA",
  PAYPAL_EXPRESS_CHECKOUT: "PAYPAL_EXPRESS_CHECKOUT",
  PAYPAL : "PAYPAL",
  AMAZON_PAYMENTS : "AMAZON_PAYMENTS",
  GOCARDLESS : "GOCARDLESS",
  EBANX : "EBANX",
  DLOCAL: "DLOCAL"
}
