<template>
  <transition mode="out-in" @enter="enter" @before-leave="beforeLeave">
    <slot></slot>
  </transition>
</template>
<script type="text/javascript">
import {
  EventBus
} from '@/utils/event-bus'
export default {
  props: ["parent", 'reCalculateMargin'],
  data() {
    return {
      app: document.getElementById("app"),
    }
  },
  methods: {
    enter(el) {
      if(!this.parent){
        return;
      }
      this.parent.style['opacity'] = '0';
      el.style.opacity = '0';
      window.cbTimeout(() => {
        this.parent.style['height'] = el.clientHeight + 'px';
        this.resetElement(el);
      }, 100);
    },
    calculateAppMargin(){
      let isPreview = window && window.initInfo && window.initInfo.is_preview;
      let minMarginTop = (isPreview) ? 90 : 20;
      if ((window.innerHeight - this.app.clientHeight) > minMarginTop) {
        let diff = window.innerHeight - this.app.clientHeight;
        this.app.style.marginTop = (diff / 2.0) + "px";
      } else {
        this.app.style.marginTop = minMarginTop + "px";
      }
    },
    resetElement(el){
      window.cbTimeout(() => {
        this.parent.style['opacity'] = '1';
        el.style.opacity = '1';
        this.parent.style['height'] = 'auto';
        EventBus.setPrevHeight();
        this.reCalculateMargin && !window.isMobile && this.calculateAppMargin();
        this.$emit('toggled');
      },500);
    },
    beforeLeave(el) {
      if(!this.parent){
        return;
      }
      this.parent.style['height'] = el.clientHeight + 'px';
      this.parent.style['opacity'] = '0';
    },
  },
}
</script>
