<style src="@/assets/styles/portal.styl" lang="stylus"></style>
<template>
  <div class="cb-hp__wrapper">
    <div ref="parent" class="cb-header cb-transition__slide" id="cb-header">
      <component :is="headerType" v-cb-slide="{ isnew : isNewHeader , ref: $refs.parent }">
      </component>
    </div>
    <div class="cb-body" id="cb-body" role="main">
      <transition name = "scale-down-y">
        <cb-modal :icon="current_notification.type" :show-icon="current_notification.showIcon" :text="current_notification.message" v-if="showNotification" :is-dismissible="current_notification.isDismissible" @clearModalDialog="showNotification = false">
        </cb-modal>
      </transition>
        <cb-loader v-if="showLoader"></cb-loader>
        <template v-if="portal_loaded">
          <router-view v-cb-animate='canAnimate()' v-if="allowed">
          </router-view>
          <div v-else class="cb-body__wrap">
            {{st('not_allowed')}}
          </div>
        </template>
        <div v-else class="cb-body__wrap">
          <portal-loader/>
        </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import {
  mapGetters
} from "vuex"
import PortalHeader from "@/components/collections/headers/PortalHeader"
import Type2 from "@/components/collections/headers/Type2"
import ModalBox from "@/components/collections/elements/ModalBox"
import CBNotification from "@/models/cb-notification"
import Loader from "@/components/collections/elements/Loader"
import { fetchForwardParams, resolveRouteName, checkForSafeFwd } from "@/utils/helper-functions"
import PortalLoader from "@/components/modules/portal/PortalLoader"
import Transformer from "@/utils/transformer"
import ExtendSubThankYouHeader from "@/components/collections/headers/ExtendSubscriptionThankYouHeader"
import { ToastNotificationTimeout } from "@/utils/helper-functions"

export default {
    mounted() {
      document.getElementById("app").style.height = document.getElementById("app").$prevHeight;
      this.transitionName = "fade";
    },
    computed: {
      ...mapGetters([
        "portal_loaded",
        "authenticated",
        "allowed_actions",
        "authType",
        "current_notification",
        "customer_handle",
        'set_loader',
        'portal_forward_details',
        'cancan'
      ]),
      allowed() {
        // TODO refactor this method
        switch(this.$route.name) {
          case "portal_account":
            // return this.allowed_actions.indexOf("account.allow") != -1;
            // break;
          // TODO write it for other routes
          default:
            return true;
        }
      },
      titleText() {
        return this.$store.getters.translate(this.$route.meta.headerTitle);
      },
      headerType() {
        // if source is null or current route name is not equal to source
        this.isNewHeader = false;
        if(this.$route.meta.header) { //use if any header is particullary specified in route
          this.currentHeader = this.$route.meta.header;
        }
        else if(!this.$route.query.source || this.$route.query.source == this.$route.name){
          this.currentHeader = "header-1";
        }
        else if(this.$route.query.source == 'extend_subscription' && this.$route.name == 'extend_sub_thankyou'){
          this.currentHeader = "extend-header-thankyou";
        }
        else{
          this.currentHeader = "header-2";
        }
        if(this.prevHeader != this.currentHeader && this.$route.name != 'extend_sub_thankyou'){
          this.isNewHeader = true;
        }
        this.prevHeader = this.currentHeader;
        return this.currentHeader;
      }
    },
    data() {
      return {
        transitionName: "",
        errMessage: "",
        showNotification: false,
        isNewHeader:false,
        prevHeader: "",
        currentheader: "",
        showLoader: false,
      }
    },
    watch: {
      set_loader(newValue, oldValue) {
        this.showLoader = newValue ? true : false;
      },
      authenticated(newValue, oldValue) {
        if (oldValue && !newValue) {
          if(this.authType == "email") {
            window.cbReload();
          }
          else {
            this.markAsUnauthenticated();
            this.$router.push({
              name: "error"
            });
          }
        }
      },
      current_notification(newValue, oldValue) {
        if(!newValue) {
          this.showNotification = false;
          return;
        }
        if(newValue.message) {
          this.showNotification = true;
          if(newValue.type == 'error') {
            window.clearTimeout(this.notificationTimeout);
            if(!newValue.isDismissible) {
              this.notificationTimeout = window.cbTimeout(() => this.showNotification = false, ToastNotificationTimeout);
            }
          }
        }
      },
      portal_loaded(newValue, oldValue) {
        if(newValue && !oldValue) {
          window.cbPostMessage("cb.loaded");
          window.cb_loaded = true;
          window.cbTimeout(() => document.getElementById("app").focus(), 1000);
          // check if fwt is present
          // if present
          // forward and reset
          if(this.portal_forward_details && Object.keys(this.portal_forward_details).length > 0) {
            let name = this.portal_forward_details.name;
            name && this.cancan.checkAndForward(name, this.$router, this.$store, this.portal_forward_details);
            this.$store.dispatch('set_fwt', undefined);
          }
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if(to.name == "portal_main") {
          // if forward not present
          // redirect to main page
          if(vm.authenticated) {
            let fwdName = resolveRouteName(to.query.forward);
            fwdName ? vm.$router.replace({name: fwdName, params: fetchForwardParams(to.query)}) : vm.$router.replace({name: "home"});
            vm.$store.dispatch('set_entry_point', fwdName || "home");
            return;
          }
        }
        var source = to.query.source;
        if(source && to.name != source) {
          vm.$router.replace({name: source});
        }
      });
    },
    created() {
      this.$store.dispatch('set_entry_point', this.$route.query.source ? this.$route.query.source : this.$route.name);
      if(this.authenticated) {
        logger.addMetaData('customer_handle', this.$store.state.auth.customer_handle);
        this.$store.dispatch('set_page_type', 'portal');
        this.$store.dispatch('load_portal_config').catch(error => {
          this.$store.dispatch('set_current_notification', new CBNotification('error', error));
          this.$router.push({
            name: "error"
          });
          logger.e(error);
        });
      }
      else {
        if(this.authType == "email") {
          var forward = resolveRouteName(this.$route.query.forward) || this.$route.query.source || this.$route.name;
          if(forward == "extend_subscription") {
            this.throwRefreshError('extend_sub_click_from_link');
            return;
          }
          forward = checkForSafeFwd(forward);
          // pass params
          let params = {};
          if(this.$route.name == "portal_main") {
            params = fetchForwardParams(this.$route.query);
          } else {
            params = this.$route.params;
          }
          this.$router.push({name: "portal_login", query: Object.assign({forward}, Transformer.flatten(params, 'fw'))});
        }
        else {
          // alert("Please authenticate")
          this.$store.dispatch('set_current_notification', new CBNotification('error', {message: "Session expired. Please try again"}));
          this.markAsUnauthenticated();
          this.$router.push({
            name: "error"
          });
        }
      }
    },
    components: {
      "header-1": PortalHeader,
      "header-2": Type2,
      "cb-modal": ModalBox,
      "cb-loader": Loader,
      "portal-loader": PortalLoader,
      "extend-header-thankyou": ExtendSubThankYouHeader,
    },
    methods: {
      canAnimate() {
        return true;
      },
      markAsUnauthenticated() {
        window.cbPostMessage("cb.unauthenticated");
      },
      throwRefreshError(key) {
        let msg = (window.portalConfig && window.portalConfig.txts[key]) || "Session expired. Please try again";
        this.$store.dispatch('set_current_notification', new CBNotification('error', {message: msg}));
        this.markAsUnauthenticated();
        this.$router.push({
          name: "error"
        });
      }
    },
}
</script>
