export const INTENT_FOR_NEW_CUSTOMER = "INTENT_FOR_NEW_CUSTOMER"
export const INTENT_FOR_EXISTING_CUSTOMER = "INTENT_FOR_EXISTING_CUSTOMER"
export const INTENT_FOR_ADD_PM = "INTENT_FOR_ADD_PM"
export const INTENT_FOR_EXISTING_PM = "INTENT_FOR_EXISTING_PM"

import apiClient from "@/utils/cb-client"

class IntentHolder {
  set(action, params) {
    return this[action](params)
  }

  [INTENT_FOR_NEW_CUSTOMER]({amount, currencyCode, gatewayId, intent, paymentMethodType, postActionJson, tempPaymentIntent}) {
    if(!intent || (intent && intent.status == "authorized")) {
      return apiClient.payment_intents.create({}, {
        amount,
        currency_code: currencyCode,
        gateway_account_id: gatewayId,
        payment_method_type: paymentMethodType,
        post_action: JSON.stringify(postActionJson)
      });
    }
    else {
      return this.updateIntentIfRequired({intent, amount, currencyCode, gatewayId, paymentMethodType, tempPaymentIntent});
    }
  }

  [INTENT_FOR_EXISTING_CUSTOMER](params) {
    if(params.paymentSourceId) {
      return this[INTENT_FOR_EXISTING_PM](params);
    }
    else {
      return this[INTENT_FOR_ADD_PM](params);
    }
  }

  [INTENT_FOR_ADD_PM]({amount, currencyCode, gatewayId, customerId, intent, paymentMethodType, postActionJson, tempPaymentIntent}) {
    if(!intent || (intent && intent.status == "authorized") || (intent.reference_id)) {
      return apiClient.payment_intents.create({}, {
        amount,
        currency_code: currencyCode,
        gateway_account_id: gatewayId,
        customer_id: customerId,
        payment_method_type: paymentMethodType,
        post_action: JSON.stringify(postActionJson)
      });
    }
    else {
      return this.updateIntentIfRequired({intent, amount,  currencyCode, gatewayId, customerId, paymentMethodType, tempPaymentIntent});
    }
  }

  [INTENT_FOR_EXISTING_PM]({amount, currencyCode, gatewayId, customerId, intent, paymentMethodType, paymentSourceId, tempPaymentIntent}) {
    if(!intent || (intent && intent.status == "authorized") || (intent.reference_id)) {
      return apiClient.payment_intents.create({}, {
        amount, 
        currency_code: currencyCode,
        gateway_account_id: gatewayId,
        customer_id: customerId, 
        payment_method_type: paymentMethodType,
        reference_id: paymentSourceId
      });
    }
    else {
      return this.updateIntentIfRequired({intent, amount,  currencyCode, gatewayId, customerId, paymentMethodType, referenceId: paymentSourceId, tempPaymentIntent});
    }
  }

  updateIntentIfRequired({intent, amount, currencyCode, gatewayId, referenceId, customerId, paymentMethodType, tempPaymentIntent}) {
    if ((intent.amount != amount ||
      intent.currency_code != currencyCode ||
      intent.gateway_account_id != gatewayId ||
      intent.reference_id != referenceId ||
      intent.customer_id != customerId  ||
      intent.payment_method_type != paymentMethodType ) || (
      // we have update intent in backend but not in frontend so we will update now.
      tempPaymentIntent && tempPaymentIntent.id == intent.id && tempPaymentIntent.payment_method_type != intent.payment_method_type
    )) {
      return apiClient.payment_intents.update({id: intent.id}, {amount, gateway_account_id: gatewayId, currency_code: currencyCode, reference_id: referenceId, customer_id: customerId, payment_method_type: paymentMethodType});
    }
    return Promise.resolve({payment_intent: intent});
  }

}

export default new IntentHolder()