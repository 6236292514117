import Vue from "vue"
export default {
	computed: {
		_config() {
      return this.$store.getters.field_config(this.pName, this.name)
    },
    config() {
      return this.useparent ? this._parentConfig : this._config;
    },
    _parentConfig() {
      return this.useparent && this.$store.getters.vField_config(this.pName, this._config.pName);
    },
    label() {
      return this.config.meta.label;
    },
    internalValue() {
      return this._config && this._config.value;
    },
    invalid() {
      return this._config.invalid;
    },
    optional() {
      return !this.config.meta.required;
    },
    required() {
      return this.config.meta.required;
    },
    readonly() {
      return this.config.meta.read_only;
    },
    isShort() {
      return (this.config.meta.short_field && this.displayLabel.length > 15) ? true : (this.displayLabel.length > 35 ? true : false);
    },
    isFocussed() {
      return this._config && this._config.focussed
    },
    errorMessage() {
      return this._config && this._config.error;
    },
    errorMessageWrapper() {
      return this.errorMessage == "EMPTY" ? "" : this.errorMessage;
    },
    placeholderText() {
      return this.isDate ? (this.config.meta.validations.dateFormat && this.config.meta.validations.dateFormat.format) : this.config.meta.placeholder;
    },
    isDate() {
      return (this.config.meta.type == "date" || this.config.meta.type == "timestamp") ? true : false;
    },
    displayLabel() {
      // TODO hack
      // find an alternate solution for zip code
      // if (["zip", "billing_zip"].indexOf(this.name) >= 0) {
      //   return this.label;
      // }
      return this.readonly ? this.label : (!this.optional ? this.label : this.label + this.optionaltxt());
    },
	},
  watch: {
    errorMessage(newValue, oldValue) {
      if (newValue) {
        this.$emit("informError");
      } else {
        this.$emit("clearError");
      }
    },
    isFocussed(value) {
      if (value !== this.focussed) { // prevents duplicate triggers
        const input = this.$refs.input
        if (input) {
          if (value) {
            input.focus()
            this.focus()
          } else {
            input.blur()
            this.blur()
          }
        }
      }
    }
  },
	methods: {
    blur() {
      this.focussed = false;
      this.$emit("blur");
      this.$store.dispatch('blur_field', {
        formName: this.pName, 
        fieldName: this.name
      })
      this.helpTextShown = false;
      this.validate(true);
    },
    focus(event) {
      this.focussed = true;
      this.$emit("clearError");
      this.$store.dispatch('focus_field', {
        formName: this.pName, 
        fieldName: this.name
      })
      if (!this.helpTextShown) {
        window.cbTimeout(function() {
          this.helpTextShown = true;
        }.bind(this), 0);
      }
    },
    optionaltxt() {
      return " (" + this.cmt('optional_field.txt') + ")";
    },
    showHelpText(event) {
      if (this.helpTextShown) {
        // remove event listener
        document.removeEventListener('click', this.closeHelpText);
      } else {
        window.cbTimeout(function() {
          document.addEventListener('click', this.closeHelpText);
        }.bind(this));
      }
      this.helpTextShown = !this.helpTextShown;
    },
    closeHelpText(event) {
      var helpTextElement = this.$refs.helpText;
      if (event.target != helpTextElement) {
        document.removeEventListener('click', this.closeHelpText);
        this.helpTextShown = false;
      }
    },
    validate(skipEmpty) {
      this.$store.dispatch('validate_field', {
        name: this.name,
        options: {
          skipEmpty: skipEmpty
        },
        fname: this.pName
      });
    },
	},
	data() {
		return{
      focussed: false,
      helpTextShown: false,
      pName: ""
		}
	},
}