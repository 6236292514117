<template>
  <div class="cb-field" :class="[{'cb-field--active': true}, customClass,{'cb-field--error':(!!errorMessage && !focussed)},{'cb-field--sibling':siblingError},{'cb-field--focus': focussed && !readonly},{'cb-field--select-alt': this.config.meta.help_text}]">
    <input ref="input" type="time" :placeholder="placeholder" @input="input" @click="focus($event)" :value="internalValue" class="cb-field__control" :readonly="readonly" @focus="focus($event)" @blur="blur($event)" :name='name' :aria-disabled="readonly" :disabled="readonly" :class="{'cb-field__readonly':readonly}" :aria-required='required' :id="name" />
    <label class="cb-field__label" :class="{'cb-field__label--s': isShort}" :for='name'> {{displayLabel}}</label>
    <slot></slot>
    <div class="cb-field__error" v-if="errorMessageWrapper && !focussed">
      {{errorMessageWrapper}}
    </div>
  </div>
</template>
<script type="text/javascript">
import FieldMixin from "@/mixins/field-mixin";
export default {
  name: "time",
  props: ["name", "customClass", "siblingError", "type"],
  mixins: [FieldMixin],
  data() {
    return {
      placeholder: ""
    };
  },
  created() {
    this.pName = this.findParentForm();
    this.placeholder = !window.ifNativeTimeSupported && "HH:mm";
  },

  mounted() {
    if (!window.ifNativeTimeSupported) {
      this.$store.dispatch("update_form_input", {
        name: this.name,
        value: this.internalValue || "00:00",
        formName: this.pName
      });
    }
  },

  methods: {
    input(event) {
      var tmp = event.target.value;
      this.$store.dispatch("update_form_input", {
        name: this.name,
        value: tmp,
        formName: this.pName
      });
    }
  }
};
</script>
