<template>
  <div class="cb-body__wrap" v-if="agreements">
    <div class="cb-body__content">
      <div class="cb-section cb-guarantee">
        <div class="cb-note cb-note--left cb-note--align cb-note--fw cb-note--overflow">
          <div class="cb-note__content">
            <template v-for="(value, key) in agreements">
              <div
                class="cb-note__p"
                v-cb-html="sanitizeTags(getAgreement(value))"
                v-bind:key="key"
              ></div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  mounted() {},
  computed: {
    ...mapGetters(["pm_list"]),
    scheme() {
      return this.$route.query.scheme;
    },
    pm() {
      return this.pm_list.filter(
        pm => pm.configuration && pm.configuration.scheme == this.scheme
      )[0];
    },
    pmConfiguration() {
      return this.pm && this.pm.configuration;
    },
    agreements() {
      if (this.pmConfiguration) {
        let agreement_list = this.pmConfiguration.agreement_list.filter(
          a => a.type == "TERMS_AND_CONDITION"
        );
        return agreement_list && agreement_list.length > 0
          ? agreement_list
          : this.pmConfiguration.agreement_list;
      }
    }
  },
  methods: {
    sanitizeTags(content) {
      return {
        text: content,
        tags: ["b", "i", "a", "section", "br", "li", "h3", "div"]
      };
    },
    getAgreement(agreement) {
      if (agreement.text) {
        return agreement.text;
      }
      return agreement;
    }
  }
};
</script>

<style>
.cb-guarantee li {
  padding-left: 12px;
}
.cb-guarantee .cb-note--overflow {
  max-height: 400px;
  overflow: scroll;
  margin-bottom: 32px;
}
.cb-guarantee h3 {
  font-weight: bold;
  margin-bottom: 6px;
}
.cb-guarantee section {
  margin-bottom: 20px;
}
.cb-guarantee div {
  margin-bottom: 8px;
}
</style>
