var isDev = process.env.NODE_ENV != 'production'
class CbStorage {
  constructor() {
    this.initLocalStorage();
  }

  setItem(key, value) {
    if (this.localStorageEnabled) {
      try {
        window.localStorage.setItem(key, value);
      } catch (error) {
        this.localStorageEnabled = false;
      }
    }
  }

  getItem(key) {
    if (this.localStorageEnabled) {
      return window.localStorage.getItem(key);
    }
  }

  removeItem(key) {
    if (this.localStorageEnabled) {
      window.localStorage.removeItem(key);
    }
  }

  isLocalStorageEnabled() {
    return this.localStorageEnabled;
  }

  initLocalStorage() {
    try {
      window.localStorage;
      if (!window.localStorage) {
        this.localStorageEnabled = false;
        return;
      }
      if (isDev) {
        this.localStorageEnabled = true;
        return;
      }
      if (window.initInfo && window.localStorage) {
        if (!window.initInfo.is_preview || window.initInfo.sso_enabled || window.initInfo.checkout_sso_enabled) {
          this.localStorageEnabled = true;
          return;
        }
      }
    } catch (error) {
      this.localStorageEnabled = false;
    }
  }
}

export default new CbStorage();