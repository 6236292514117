<template>
  <div class="cb-bar">
    <div v-if="canShowName || withoutLabel.length > 0 || withLabel.length > 0 || showConsent() || showPwdSection">
      <div class="cb-bar__wrap" v-cb-link = "'shadow'" @click="accountEvent()" v-if="canShowName || withoutLabel.length > 0" :class="{'cb-bar__wrap--readonly': !(canEditName || canEditEmailPhone)}" role="navigation" aria-describedby="edit_text" data-cb-id="portal_account_detail">
        <div class="cb-content">
          <div v-if="canShowName" class="cb-content__title">
            {{name()}}
          </div>
          <div v-if="withoutLabel" v-for="(fieldName,index) in withoutLabel" v-bind:key="index" class="cb-content__text">
            {{fieldName}}
          </div>
        </div>
      </div>
      <div v-if="showConsent()" class = "cb-bar__wrap" v-cb-link = "'shadow'" @click="navigate('portal_consent_details')" aria-describedby="edit_text" data-cb-id="portal_consent_detail">
        <div class="cb-content">
          <div class="cb-content__title">
            {{t('consent_section.name')}}
          </div>
          <div class="cb-content__text">
          </div>
        </div>
      </div>
      <div v-if="showPwdSection" class = "cb-bar__wrap" v-cb-link = "'shadow'" @click="navigatePwd()" aria-describedby="edit_text" data-cb-id="portal_password_detail">
        <div class="cb-content">
          <div class="cb-content__title">
            {{passwordSectionName}}
          </div>
          <div class="cb-content__text">
          </div>
        </div>
      </div>
      <div class="cb-bar__wrap" v-cb-link = "'shadow'" @click="canEdit(fieldName) && navigate('portal_edit_account', {params: {field: fieldName}})" v-if="withLabel.length > 0" v-bind:key="index" v-for="(fieldName,index) in withLabel" :class="{'cb-bar__wrap--readonly': !canEdit(fieldName)}" role="navigation" aria-describedby="edit_text" data-cb-id="portal_account_detail">
        <div class="cb-content">
          <div v-if="name" class="cb-content__title">
            {{getLabel(fieldName)}}
          </div>
          <div v-if="getValue(fieldName)" class="cb-content__text" v-cb-html="sanitizeBrTags(getValue(fieldName))"></div>
          <div v-else>
            -
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="cb-bar__wrap" v-cb-link = "'shadow'" @click="accountEvent()" role="navigation" aria-describedby="edit_text" data-cb-id="portal_account_detail">
        Click to Edit Account details
      </div>
    </div>
    <div class="no-display" aria-hidden="true" id="edit_text">{{st('account_edit')}}
    </div>
  </div>
</template>
<script type="text/javascript">
import {
  mapGetters
} from "vuex"
export default {
  props: ['account', 'customclass', 'fieldsToShow'],
  methods: {
    name() {
      return [this.account.first_name, this.account.last_name].filter(i => i).join(" ")
    },
    escapeHTML(str){
      return str && typeof(str) === 'string' ? str.split("\n").join("<br />") : str;
    },
    getValue(fieldName){
      if(fieldName == "locale" && this.account["locale"]){
        var account_locale = this.sections.customer.fields.filter(f => f.name == "locale")[0].allowed_values.filter(f => f.value == this.account["locale"])[0];
        return account_locale ? account_locale.display_name : "-";
      }
      return this.escapeHTML(this.account[fieldName]);
    },
    getLabel(fieldName) {
      return this.sections.customer.fields.filter(f => f.name == fieldName)[0].label;
    },
    showConsent() {
      return this.sections.consent.fields && this.sections.consent.fields.length;
    },
    navigate(section, options) {
      options = options || {};
      options.name = section;
      this.$router.customPush(options);
    },
    canEdit(field) {
      return this.requestedFields.indexOf(field) > -1;
    },
    accountEvent(){
      let field = this.canEdit('first_name') && 'first_name'
                  || this.canEdit('last_name') && 'last_name'
                  || this.canEdit('email') && 'email'
                  || this.canEdit('phone') && 'phone';
      field && this.navigate('portal_edit_account',{params: {field: field}})
    },
    sanitizeBrTags(content){
      return {
        text:content,
        tags:['br'],
        attrs:{}
      }
    },
    navigatePwd() {
      let fwd;
      if(this.has_password) {
        fwd = this.checkExpired() ? 'portal_change_password_verify' : 'portal_change_password';
      } else {
        fwd = this.checkExpired() ? 'portal_verify_otp' : 'portal_setup_password';
      }
      this.navigate(fwd);
    },
    checkExpired() {
      //if less than 10 minutes, then allow to update password directly
      if(this.login_time && ((new Date().getTime() - Number(this.login_time)) <= this.login_expiry_time)) {
        return false;
      }
      return true;
    }
  },
  computed: {
    ...mapGetters(
      [
        "sections",
        'is_otp_pwd',
        'has_password',
        'customer_email',
        'login_expiry_time',
        'login_time'
      ]
    ),
    canEditName() {
      return this.requestedFields.indexOf('first_name') > -1 || this.requestedFields.indexOf('last_name') > -1;
    },
    canEditEmailPhone() {
      return this.requestedFields.indexOf('email') > -1 || this.requestedFields.indexOf('phone') > -1;
    },
    requestedFields() {
      return this.sections.customer.fields.filter(f => !f.read_only).map(f => f.name);
    },
    withoutLabel() {
      return Object.keys(this.account).filter(k => this.fieldsToShow.indexOf(k) != -1).filter(k => ["email", "phone"].indexOf(k) != -1).map(k => this.account[k]).filter(i => i);
    },
    withLabel(){
      return this.fieldsToShow.filter(k => ["first_name","last_name","email","phone"].indexOf(k) == -1);
    },
    canShowName() {
      return this.fieldsToShow.filter(k => ["first_name","last_name"].indexOf(k) > -1).length > 0;
    },
    showPwdSection() {
      //if customer email is changed, then not showing this section
      return this.is_otp_pwd && (this.$store.state.auth.email.toLowerCase() == this.customer_email.toLowerCase());
    },
    passwordSectionName() {
      return this.t(this.has_password ? 'change_password.section.name' : 'setup_password.section.name');
    }
  }
}
</script>