const BILLING = "billing.allow";
const SHIPPING = "shipping.allow";
const ACCOUNT = "account.allow";
const PAYMENT = "payment.allow";
const INVOICES = "portal-invoices.allow";
const CHANGE_SUBSCRIPTION = "change-subscription.action";
const CANCEL_SUBSCRIPTION = "cancel-subscription.action";
const REACTIVATE_SUBSCRIPTION = "reactivate-subscription.action.reactivate";
const REMOVE_PRIMARY_PAYMENT_METHOD = "remove-payment-method.primary.remove";
import ac, { PORTAL_ACTIONS } from "@/utils/access-control"

export default class CanCan {
  constructor(allowed_actions, sections) {
    this.allowed_actions = allowed_actions;
    this.sections = sections;
  }

  allow(action_name) {
    if(this.allowed_actions.indexOf(action_name) == -1 ) {
      return false;
    }

    if(this[action_name]) {
      return this[action_name]();
    }
    return true;
  }

  [BILLING]() {
    return this.sections.billing_address.fields.length > 0;
  }

  [SHIPPING]() {
    return this.sections.shipping_address.fields.length > 0;
  }

  [ACCOUNT](allowEdit) {
    if(allowEdit) {
      return this.sections.customer.fields.filter(f => !f.read_only).length > 0;
    }
    else {
      return this.sections.customer.fields.length > 0;
    }
  }

  subscription() {
    return this.sections.subscription && this.sections.subscription.fields.filter(f => !f.read_only).length > 0;
  }

  checkAndForward(name, router, store, params) {
    let state = store.state;
    let getters = store.getters;
    let dispatch = store.dispatch;
    switch (name) {
      // TODO as cancellation allowed check, change subscription allowed check, depends on multiple things
      // doing this hack
      // should have designed as a global check
      case "sub_details":
         this.isSubscriptionPresent(state, dispatch, params.subscriptionId).then((isPresent) => {
          isPresent && this.push(router, { name, params });
        });
        break;
      case "sub_cancel":
        this.allow(CANCEL_SUBSCRIPTION) && this.isSubscriptionPresent(state, dispatch, params.subscriptionId).then((isPresent) => {
          if(isPresent && this.isAllowedResource(store, PORTAL_ACTIONS.CANCEL_SUBSCRIPTION, params)) {
            store.dispatch('set_forward_action', 'sub_cancel')
            this.push(router, { name: 'sub_details', params});
          }
        });
        break;
      case "edit_subscription":
        this.allow(CHANGE_SUBSCRIPTION) && this.isSubscriptionPresent(state, dispatch, params.subscriptionId).then((isPresent) => {
          if(isPresent && this.isAllowedResource(store, PORTAL_ACTIONS.EDIT_SUBSCRIPTION, params)) {
            store.dispatch('set_forward_action', 'edit_subscription')
            this.push(router, { name: 'sub_details', params});
          }
        });
        break;
      case "scheduled_changes":
        this.isSubscriptionPresent(state, dispatch, params.subscriptionId).then((isPresent) => {
          if(isPresent && this.isAllowedResource(store, PORTAL_ACTIONS.EDIT_SUBSCRIPTION, params)) {
            store.dispatch('set_forward_action', 'scheduled_changes')
            this.push(router, { name: 'sub_details', params});
          }
        });
        break;
      case "account_details":
        if(this.allow(ACCOUNT)) {
          this.push(router, { name: "portal_account" });
        }
        break;
      case "portal_edit_account":
        if(this.allow(ACCOUNT) && this[ACCOUNT](true)) {
          this.push(router, { name: "portal_edit_account" });
        }
        break;
      case "portal_address":
        if(this.allow(BILLING) || this.allow(SHIPPING)) {
          this.push(router, { name: "portal_address" });
        }
        break;
      case "portal_edit_billing_address":
        if(this.allow(BILLING)) {
          this.push(router, { name: "portal_edit_billing_address" });
        }
        break;
      case "portal_edit_shipping_address":
        this.allow(SHIPPING) && this.isSubscriptionPresent(state, dispatch, params.subscriptionId).then((isPresent) => {
          if(isPresent && this.isAllowedResource(store, PORTAL_ACTIONS.EDIT_SUBSCRIPTION, params)) {
            this.push(router, { name: "portal_edit_shipping_address", params});
          }
        });
        break;
      case "portal_edit_subscription_cf":
        this.allow(CHANGE_SUBSCRIPTION) && this.subscription() && this.isSubscriptionPresent(state, dispatch, params.subscriptionId).then((isPresent) => {
          if(isPresent && this.isAllowedResource(store, PORTAL_ACTIONS.EDIT_SUBSCRIPTION, params)) {
            this.push(router, { name: "edit_subscription_cf", params});
          }
        });
        break;
      case "portal_payment_methods":
        if(this.allow(PAYMENT)) {
          this.push(router, { name: "portal_payment_methods" });
        }
        break;
      case "portal_add_payment_method":
        if(this.allow(PAYMENT) && getters.canAdd) {
          this.push(router, { name: "portal_add_payment_method" });
        }
        break;
      case "portal_edit_payment_method":
        if(this.allow(PAYMENT)) {
          this.push(router, { name: "portal_edit_payment_method" });
        }
        break;
      case "portal_view_payment_method":
        if(this.allow(PAYMENT) && this.isPmPresent(state, params.paymentSourceId)) {
          this.push(router, { name: "portal_view_payment_method", params: {pmId: params.paymentSourceId}});
        }
        break;
      case "portal_choose_payment_method":
        this.isSubscriptionPresent(state, dispatch, params.subscriptionId).then((isPresent) => {
          if(this.isAllowedResource(store, PORTAL_ACTIONS.EDIT_PM_SUBSCRIPTION, params)) {
            store.dispatch('set_forward_action', 'portal_choose_payment_method')
            this.push(router, { name: 'sub_details', params});
          }
        });
        break;
      case "portal_billing_history":
        if(this.allow(INVOICES)) {
          this.push(router, { name: "portal_billing_history" });
        }
        break;
      default:
        break;
    }
  }

  isAllowedResource(store, action, params) {
    let resource = store.getters.fetchSubData(params.subscriptionId);
    return ac.can(action, resource);
  }

  isSubscriptionPresent(state, dispatch, subscriptionId) {
    return dispatch('load_subscription_details', subscriptionId).catch((error) => {
      return false;
    });
  }

  isPmPresent(state, paymentMethodId) {
    return state.customer.payment_method_list.filter(s => s.id == paymentMethodId).length > 0;
  }

  push(router, {name, params, q}) {
    let query = q || {};
    router.customPush({
      name,
      params,
      query
    })
  }
}