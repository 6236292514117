class Helper {
  //   static downloadWindow;

  static setWindow(winName) {
    this.downloadWindow = winName;
  }

  static getWindow() {
    return this.downloadWindow;
  }

  static postTransformRequest(data, headers) {
    var str = [];
    for (var p in data)
      if (data.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
      }
    return str.join("&");
  }

  static fetchSiteConfig() {
    var matchedContents = window.location.hostname.match(/(.*)\.((.*)\.(.*))/);
    return {
      origin: window.location.origin,
      siteName: matchedContents && matchedContents[1],
      protocol: window.location.protocol.substring(0, window.location.protocol.length - 1),
      domain: matchedContents && matchedContents[2]
    }
  }

  static is_iPad() {
    return !!navigator.userAgent.match(/iPad/);
  }

  static is_iPhone() {
    return !!navigator.userAgent.match(/iPhone/);
  }

  static newWindowNotSupported() {
    // #CHKOUTENGG-791 : Issue occurs in both Android & Iphone 
    return (navigator.userAgent.match(/FB/i) || navigator.userAgent.match(/Instagram/i) || navigator.userAgent.match(/GSA/i) || Helper.isWebView());
  }

  // https://www.npmjs.com/package/is-ua-webview
  // https://developer.chrome.com/docs/multidevice/user-agent/#webview-on-android
  static isWebView() {
    let rules = [
      // if it says it's a webview, let's go with that
      'WebView',
      // iOS webview will be the same as safari but missing "Safari"
      '(iPhone|iPod|iPad)(?!.*Safari)',
      // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
      // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
      'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
      // old chrome android webview agent
      'Linux; U; Android'
    ]
    return !!navigator.userAgent.match(new RegExp('(' + rules.join('|') + ')', 'ig'));
  }

  static downloadUrl(url) {
    if (this.is_iPad() || this.is_iPhone()) {
      if (this.downloadWindow) {
        this.downloadWindow.location.href = url;
      }
    } else {
      var iframe = document.createElement('iframe');
      iframe.style.display = "none";
      iframe.src = url;
      document.body.appendChild(iframe);
    }
  }

  static isAndroidInstagram() {
    return !!navigator.userAgent.match(/Android/) && !!(navigator.userAgent.match(/FB/) || navigator.userAgent.match(/Instagram/i))
  }

  static isMobile() {
    return [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ].some((ua) => {
        return navigator.userAgent.match(ua);
    });
  }

}


export default Helper