<template>
  <div class="cb-field" :class="[{'cb-field--active': month || year || focussed || placeholder}, {'cb-field--error':!!errorMessage && !focussed},{'cb-field--focus': focussed}, {'cb-field--sibling': siblingError}]">
    <label class="cb-field__label">
      {{label}}
    </label>
    <div class="cb-field__expiry" @click="focusMonth($event)">
      <input id="exp_month" @blur="blur($event)" @focus="focus($event)" maxlength='2' class="cb-field__control cb-field__month" type="number" :value="month" placeholder="MM" pattern="[0-9]*" @input="updateMonth($event)" ref="monthElement" @keydown="keydown($event)" :autocomplete="autocompleteMonth" :class="{'cb-field__month--alt' : month}" aria-required="true" aria-label="Expiration month"/> /
      <input id="exp_year" @blur="blur($event)" @focus="focus($event)" maxlength='4' class="cb-field__control cb-field__year" type="number" placeholder="YY" pattern="[0-9]*" :value="year" @input="updateYear($event)" ref="yearElement" @keyup.delete="previous($event)" @keydown="keydown($event)" :class="{'cb-field__year--alt' : year}" :autocomplete="autocompleteYear" aria-required="true" aria-label="Expiration year"/>
    </div>
    <div class="cb-field__error" v-if="!!errorMessageWrapper && !focussed">
      {{errorMessageWrapper}}
    </div>
  </div>
</template>
<script type="text/javascript">
import AutoCompleteMapping from "@/utils/auto-complete-mapping.js"
export default {
  props: ['name', 'siblingError'],
  data() {
    return {
      pName: "",
      focussed1: false,
      focussed2: false,
    }
  },
  created() {
    this.pName = this.findParentForm();
  },
  computed: {
    autocompleteMonth() {
      var mapping = AutoCompleteMapping[`${this.pName}[expiry_month]`];
      return mapping ? mapping : "off"
    },
    autocompleteYear() {
      var mapping = AutoCompleteMapping[`${this.pName}[expiry_year]`];
      return mapping ? mapping : "off"
    },
    config() {
      return this.$store.getters.vField_config(this.pName, this.name)
    },
    label() {
      return this.config.meta.label;
    },
    monthConfig() {
      return this.pName && this.$store.getters.field_config(this.pName, "expiry_month")
    },
    yearConfig() {
      return this.pName && this.$store.getters.field_config(this.pName, "expiry_year")
    },
    month() {
      return this.monthConfig && this.monthConfig.value
    },
    year() {
      return this.yearConfig && this.yearConfig.value
    },
    isYearFocussed() {
      return this.yearConfig && this.yearConfig.focussed
    },
    errorMessage() {
      return this.monthConfig.error || this.yearConfig.error;
    },
    errorMessageWrapper() {
      return this.errorMessage == "EMPTY" ? "" : this.errorMessage;
    },
    invalid() {
      return this.config.invalid;
    },
    focussed() {
      return this.focussed1 || this.focussed2
    },
    placeholder() {
      return this.config.meta.placeholder;
    }
  },
  watch: {
    month(newValue, oldValue) {
      if (this.month.length == 2) {
        // this.$refs.yearElement.focus();
      }
    },
    year(newValue, oldValue) {
      if (this.year.length == 4) {
        // this.validate(this.yearConfig.name);
      }
    },
    errorMessage(newValue, oldValue) {
      if (newValue) {
        this.$emit("informError");
      } else {
        this.$emit("clearError");
      }
    },
    /**
     * validation happens on card expiry input as a single entity,
     * NOT on month and year as separate fields.
     * hence validation will happen on blur of year input 
     * and error will be reported on expiry year
     * For any error on expiry, always focus on month input to avoid ambiguity
     */
    isYearFocussed(value) {
      const monthInput = this.$refs.monthElement
      if (value && value !== this.focussed2) { // check if focus is set from outside component
        monthInput && monthInput.focus()
        // Reset the focussed prop for year field in store
        // since month is getting focussed and not year
        this.$store.dispatch('blur_field', {
          formName: this.pName, 
          fieldName: 'expiry_year'
        })
      }
    }
  },
  methods: {
    updateYear(event) {
      var tmp = event.target.value;
      if (tmp.length > 4) {
        tmp = this.year.toString().substring(0, 4);
        event.target.value = tmp;
      }
      this.$store.dispatch('update_form_input', {
        name: "expiry_year",
        value: tmp,
        formName: this.pName
      });
    },
    focusMonth(event){
      event.stopPropagation();
      if(!(this.focussed || this.placeholder))
        this.$refs.monthElement.focus();
    },
    updateMonth(event) {
      var tmp = event.target.value;
      if (tmp.length > 2) {
        tmp = this.month.toString().substring(0, 2);
        event.target.value = tmp;
      }
      this.$store.dispatch('update_form_input', {
        name: "expiry_month",
        value: tmp,
        formName: this.pName
      });
    },

    blur(event) {
      let fieldName;
      if (event.target == this.$refs.monthElement) {
        fieldName = 'expiry_month'
        this.focussed1 = false;
        if (this.month || this.year) {
          this.validate("expiry_month", true);
        }
      }
      if (event.target == this.$refs.yearElement) {
        fieldName = 'expiry_year'
        this.focussed2 = false;
        if (this.month || this.year) {
          this.validate("expiry_year", true);
        }
      }
      if (!this.month && !this.year) {
        this.validate("expiry_month", true);
        this.validate("expiry_year", true);
      }
      this.$store.dispatch('blur_field', {
        formName: this.pName, 
        fieldName: fieldName
      })
    },

    validate(name, skipEmpty) {
      this.$store.dispatch('validate_field', {
        name: name,
        options: {
          skipEmpty: skipEmpty
        },
        fname: this.pName
      });
    },

    keydown(e) {
      var key = e.which || e.keyCode;
      var target = e.target || e.srcElement;
      if (target == this.$refs.monthElement && this.month.length == 2 &&
        (key == 191 || key == 189 ||
          (key >= 48 && key <= 57) ||
          (key >= 96 && key <= 105)
        )) {
        this.$refs.yearElement.focus();
      }
      // Move this to a library or a filter
      if (!e.altKey && !e.ctrlKey &&
        // numbers
        key >= 48 && key <= 57 ||
        // Numeric keypad
        key >= 96 && key <= 105 ||
        // comma, period and minus, . on keypad
        key == 190 || key == 188 || key == 109 || key == 110 ||
        // Backspace and Tab and Enter
        key == 8 || key == 9 || key == 13 ||
        // Home and End
        key == 35 || key == 36 ||
        // left and right arrows
        key == 37 || key == 39 ||
        // Del and Ins
        key == 46 || key == 45
        // Hungarian 0 
        || key == 192)
        return true;

      e.preventDefault();
    },
    focus(event) {
      let fieldName;
      if (event.target == this.$refs.monthElement) {
        this.focussed1 = true;
        fieldName = 'expiry_month'
      }
      if (event.target == this.$refs.yearElement) {
        this.focussed2 = true;
        fieldName = 'expiry_year'
      }
      this.$store.dispatch('focus_field', {
        formName: this.pName, 
        fieldName: fieldName
      })
    },

    previous(event) {
      if (event.keyCode == 8 && this.year.length == 0) {
        this.$refs.monthElement.focus();
      }
    }
  }
}
</script>
