<template>
  <div class="cb-hp__wrapper" v-cb-animate='true'>
    <div class="cb-body" id="cb-body" v-cb-animate="false">
      <div class="cb-body__wrap">
        <div class="cb-error">
          <div class="cb-error__content">
            <div class="cb-error_icon">
              <i class="icon-exclamation-circle"></i>
            </div>
            {{message}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import {
  mapGetters
} from 'vuex'
export default {
  computed: {
    ...mapGetters(["current_error"]),
    message() {
      // TODO Revisit this logic
      if (this.current_error && this.current_error.errorSource.status == 401 || this.current_error && this.current_error.errorSource.status == 403) {
        return this.st('token_error') || "Token has expired.";
      } else if (this.current_error && this.current_error.errorSource.status == 404) {
        return this.st('url_error') || "Invalid url" ;
      } else if (this.current_error && this.current_error.message) {
        return this.current_error.message;
      } else {
        return this.st('url_error') || "Invalid url" ;
      }
    }
  },
  mounted() {
    if(!((this.$store.state.checkout && this.$store.state.checkout.partially_loaded) ||
      (this.$store.state.config && this.$store.state.config.loaded))) {
        window.cbPostMessage("cb.loaded");
    }
  }
}
</script>
