<style src="@/assets/styles/components/collections/elements/modalBox.styl" lang="stylus" scoped></style>
<template>
  <!-- <transition name = "scale-down-y"> -->
    <div class="cb-modal" :class="{'cb-modal--overlay': isDismissible || overlay}" role="alert" ref="modal" v-if="showModal">
      <div class="cb-modal__wrap" :style="!(isDismissible || overlay) && backgroundColor">
        <div class="cb-modal__content" :class="{'cb-modal__content--inline': !(isDismissible || overlay)}">
          <div class="cb-modal__icon" v-if="icon" :style="(isDismissible || overlay) &&themeColor">
            <i class="icon-info-circle" v-if="icon == 'info'"></i>
            <i class="icon-exclamation-circle" v-else></i>
          </div>
          <div class="cb-modal__text" v-if="text">
            {{text}}
          </div>
          <cb-notes slot="notes" class="cb-notebar--footer" :custom-class="'cb-section'" :type="'muted'" :message="extraInfo"> </cb-notes>
        </div>
        <div class="cb-modal__actions" v-if="isDismissible">
          <button-field :custom-class="'primary'" :showLoader="showLoader" @next="closeModal()" v-if="isDismissible" class="cb-modal__action" :attr="'modal_secondary_action'">
            {{t('modal_button.cancel')}}
          </button-field>
        </div>
        <div class="cb-modal__actions" v-if="primaryAction || secondaryAction">
          <button-field :custom-class="'primary'" :showLoader="showLoader" @next="next()" v-if="primaryAction" class="cb-modal__action" :attr="'modal_primary_action'">
            {{primaryAction}}
          </button-field>
          <div class="cb-modal__action" @click="closeModal" v-cb-link="'color'" v-if="secondaryAction"> {{secondaryAction}} </div>
        </div>
      </div>
    </div>
  <!-- </transition> -->
</template>
<script type="text/javascript">
import Button from "@/components/collections/form/ButtonComponent"
import Notes from "@/components/collections/elements/Notes"
export default {
  name: 'modal-box',
  props: ['text', 'primaryAction', 'secondaryAction', 'icon', 'isDismissible', 'showIcon', 'overlay', 'extraInfo'],
  components: {
    'button-field': Button,
    'cb-notes': Notes
  },
  data() {
    return {
      showLoader: false,
      showModal: false
    }
  },
  methods: {
    closeModal() {
      this.$emit("clearModalDialog");
    },
    next() {
      this.$emit("submit");
    }
  },
  mounted(){
    var timeInterval = setInterval(() => {
      if(app.$animation == "end" || window.isMobile){
        this.showModal = true;
        this.$nextTick(function() {
          this.$refs.modal && (this.$refs.modal.style.top = (this.isDismissible || this.overlay) ? -(document.getElementById("cb-header").clientHeight) + "px" : "0px");
        });
        clearInterval(timeInterval);
      }
    }, 500);
  }
}
</script>
