import Helper from "./url-helper.js"
import axios from 'axios'

var urlConfig = Helper.fetchSiteConfig();

var isDev = process.env.NODE_ENV != "production";
var constructLocalDevDomain = (urlConfig) => urlConfig.protocol + "://" + urlConfig.siteName + "." + urlConfig.domain + ":8082";

var isLocalDev = (domain) => domain == "localcb.in"

export default class RestClient {
    constructor() {
        this.axios = axios.create({
            baseURL: isDev ? constructLocalDevDomain(urlConfig) : urlConfig.origin,
            transformRequest: [(data, headers) => {
                if (headers["Content-Type"] == "application/x-www-form-urlencoded") {
                    var str = [];
                    for (var p in data) {
                        if (data.hasOwnProperty(p) && (!(data[p] == undefined || data[p] == null))) {
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
                        }
                    }
                    return str.join("&");
                } else {
                    return JSON.stringify(data);
                }
            }]
        });
    }

    get(args) {
        return this.axios.request(this._get.apply(this, arguments));
    }

    post(args) {
        return this.axios.request(this._post.apply(this, arguments));
    }

    _get(path, params, headers = {}) {
        var config = {
            method: "get",
            params: params,
            url: path,
            headers: Object.assign({
                'CbSource': 'viaHp'
            }, headers)
        }

        return config;
    }

    _post(path, data, headers = {}) {
        var config = {
            method: "post",
            data: data,
            url: path,
            headers: Object.assign({
                'Content-Type': 'application/x-www-form-urlencoded',
                'CbSource': 'viaHp'
            }, headers)
        }

        return config;
    }
}