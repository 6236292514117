import Vue from "vue"
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      previewEmail: "",
    }
  },
  computed: {
    ...mapGetters(
      [
        "is_preview"
      ]
    ),
  },
  mounted() {
    // if(this.is_preview) {
      this.attachMessageListener();
      this.getPreviewPortalEmail();
    // }
  },
  destroyed() {
    // if(this.is_preview) {
      this.detachMessageListener();
    // }
  },
  methods: {
    attachMessageListener() {
      const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
      const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
      window[eventMethod](messageEvent, this.messageListener)
    },
    detachMessageListener() {
      const eventMethod = window.addEventListener ? "removeEventListener" : "detachEvent";
      const messageEvent = eventMethod == "detachEvent" ? "onmessage" : "message";
      window[eventMethod](messageEvent, this.messageListener)
    },
    getPreviewPortalEmail() {
      const HOST_WINDOW = "__HOST__"
      window.parent.postMessage({
        cbEvent: true,
        targetWindowName: HOST_WINDOW,
        srcWindowName: HOST_WINDOW,
        message: {
          action: "getPreviewPortalEmail",
        }
      }, "*")
    },
    messageListener(e) {
      if (e && e.data) {
        const message = e.data;
        switch (message.action) {
          case "setPreviewPortalEmail": {
            this.previewEmail = message.data.preview_portal_email
            break;
          }
        }
      }
    }
  },
} 