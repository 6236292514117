var QJ = function() {
  var preventDefault = function(eventObject) {
    if (typeof eventObject.preventDefault === "function") {
      eventObject.preventDefault();
      return;
    }
    eventObject.returnValue = false;
    return false;
  };

  var normalizeEvent = function(e) {
    var original;
    original = e;
    e = {
      which: original.which != null ? original.which : void 0,
      target: original.target || original.srcElement,
      preventDefault: function() {
        return preventDefault(original);
      },
      originalEvent: original,
      data: original.data || original.detail
    };
    if (e.which == null) {
      e.which = original.charCode != null ? original.charCode : original.keyCode;
    }
    return e;
  };

  var on = function(element, eventName, callback) {
    var el, i, j, len, len1, multEventName, originalCallback, ref;
    if (element.length) {
      for (i = 0, len = element.length; i < len; i++) {
        el = element[i];
        QJ.on(el, eventName, callback);
      }
      return;
    }
    if (eventName.match(" ")) {
      ref = eventName.split(" ");
      for (j = 0, len1 = ref.length; j < len1; j++) {
        multEventName = ref[j];
        QJ.on(element, multEventName, callback);
      }
      return;
    }
    originalCallback = callback;
    callback = function(e) {
      e = normalizeEvent(e);
      return originalCallback(e);
    };
    if (element.addEventListener) {
      return element.addEventListener(eventName, callback, false);
    }
    if (element.attachEvent) {
      eventName = "on" + eventName;
      return element.attachEvent(eventName, callback);
    }
    element['on' + eventName] = callback;
  };

  var rreturn = /\r/g;

  var val = function(el, val) {
	  var ret;
	  if (arguments.length > 1) {
	    return el.value = val;
	  } else {
	    ret = el.value;
	    if (typeof ret === "string") {
	      return ret.replace(rreturn, "");
	    } else {
	      if (ret === null) {
	        return "";
	      } else {
	        return ret;
	      }
	    }
	  }
	};

	var trigger = function(el, name, data) {
	  var e, error, ev;
	  try {
	    ev = new CustomEvent(name, {
	      detail: data
	    });
	  } catch (error) {
	    e = error;
	    ev = document.createEvent('CustomEvent');
	    if (ev.initCustomEvent) {
	      ev.initCustomEvent(name, true, true, data);
	    } else {
	      ev.initEvent(name, true, true, data);
	    }
	  }
	  return el.dispatchEvent(ev);
	};

	var addClass = function(el, className) {
	  var e;
	  if (el.length) {
	    return (function() {
	      var i, len, results;
	      results = [];
	      for (i = 0, len = el.length; i < len; i++) {
	        e = el[i];
	        results.push(QJ.addClass(e, className));
	      }
	      return results;
	    })();
	  }
	  if (el.classList) {
	    return el.classList.add(className);
	  } else {
	    return el.className += ' ' + className;
	  }
	};

	var hasClass = function(el, className) {
	  var e, hasClass, i, len;
	  if (el.length) {
	    hasClass = true;
	    for (i = 0, len = el.length; i < len; i++) {
	      e = el[i];
	      hasClass = hasClass && QJ.hasClass(e, className);
	    }
	    return hasClass;
	  }
	  if (el.classList) {
	    return el.classList.contains(className);
	  } else {
	    return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
	  }
	};

	var removeClass = function(el, className) {
	  var cls, e, i, len, ref, results;
	  if (el.length) {
	    return (function() {
	      var i, len, results;
	      results = [];
	      for (i = 0, len = el.length; i < len; i++) {
	        e = el[i];
	        results.push(QJ.removeClass(e, className));
	      }
	      return results;
	    })();
	  }
	  if (el.classList) {
	    ref = className.split(' ');
	    results = [];
	    for (i = 0, len = ref.length; i < len; i++) {
	      cls = ref[i];
	      results.push(el.classList.remove(cls));
	    }
	    return results;
	  } else {
	    return el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	  }
	};

	var toggleClass = function(el, className, bool) {
	  var e;
	  if (el.length) {
	    return (function() {
	      var i, len, results;
	      results = [];
	      for (i = 0, len = el.length; i < len; i++) {
	        e = el[i];
	        results.push(QJ.toggleClass(e, className, bool));
	      }
	      return results;
	    })();
	  }
	  if (bool) {
	    if (!QJ.hasClass(el, className)) {
	      return QJ.addClass(el, className);
	    }
	  } else {
	    return QJ.removeClass(el, className);
	  }
	};

  var rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;

  var trim = function(text) {
    if (text === null) {
      return "";
    } else {
      return (text + "").replace(rtrim, "");
    }
  };

  return {
    on : on,
    val : val,
    trigger : trigger,
    addClass : addClass,
    hasClass : hasClass,
    removeClass : removeClass,
    toggleClass : toggleClass,
    trim : trim,
  }
}();

var paymentUtil = function() {
  var defaultFormat = /(\d{1,4})/g;
  var indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };
  var cards = [{
    type: 'amex',
    pattern: /^3[47]/,
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    length: [15],
    cvcLength: [4],
    luhn: true
  },{
    type: 'argencard',
    pattern: /^(501105)/, // https://developer.ebanx.com/docs/resources/validation-rules/
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
  }, {
    type: 'dankort',
    pattern: /^5019/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'hipercard',
    pattern: /^(606282\d{10}(\d{3})?)|(3841\d{02}\d{10})/, // https://pkg.go.dev/github.com/goloop/is#section-readme
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
  },
  {
    type: 'cmrfalabella',
    pattern: /^(557039)/, //https://developer.ebanx.com/docs/resources/validation-rules/
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
  },{
    type: "dinersclub",
    pattern: /^(30|38|39)/, // https://github.com/stripe/stripe-android/pull/2211
    format: defaultFormat,
    length: [16], // Card numbers starting with "30", "38", or "39" represent 16-digit Diners Club cards
    cvcLength: [3],
    luhn: true
  }, {
    type: "dinersclub",
    pattern: /^36/, // https://github.com/stripe/stripe-android/pull/2211
    format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
    length: [14],   // Card numbers starting with "36" represent 14-digit Diners Club cards
    cvcLength: [3],
    luhn: true
  }, {
    type: 'discover',
    pattern: /^(6011\d{0,12}|(644|645|646|647|648|649)\d{0,13}|65\d{0,14})/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'jcb',
    pattern: /^(352[8,9]\d{0,15}|35[4-8]\d{0,16})/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'laser',
    pattern: /^(6706|6771|6709)/,
    format: defaultFormat,
    length: [16, 17, 18, 19],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'maestro',
    pattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
    format: defaultFormat,
    length: [12, 13, 14, 15, 16, 17, 18, 19],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'mastercard',
    pattern: /^(5[1-5]\d{0,14}|2[2-7]\d{0,14})/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'unionpay',
    pattern: /^(62|81)\d/,
    format: defaultFormat,
    length: [16, 17, 18, 19],
    cvcLength: [3],
    luhn: false
  }, {
    type: 'visaelectron',
    pattern: /^4(026|17500|405|508|844|91[37])/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'elo',
    pattern: /^((((506699)|(506770)|(506771)|(506772)|(506773)|(506774)|(506775)|(506776)|(506777)|(506778)|(401178)|(438935)|(451416)|(457631)|(457632)|(504175)|(627780)|(636368)|(636297))\d{0,10})|((50676)|(50675)|(50674)|(50673)|(50672)|(50671)|(50670))\d{0,11})/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'visa',
    pattern: /^4/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  },{
    type: 'tarjetanaranja',
    pattern: /^(589562)/, // https://developer.ebanx.com/docs/resources/validation-rules/
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
  },{
    type: 'cencosud',
    pattern: /^(603493)/, // https://developer.ebanx.com/docs/resources/validation-rules/
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true,
  },
];

  var hasTextSelected = function(target) {
	  var e, error, ref;
	  try {
	    if ((target.selectionStart != null) && target.selectionStart !== target.selectionEnd) {
	      return true;
	    }
	    if ((typeof document !== "undefined" && document !== null ? (ref = document.selection) != null ? ref.createRange : void 0 : void 0) != null) {
	      if (document.selection.createRange().text) {
	        return true;
	      }
	    }
	  } catch (error) {
	    e = error;
	  }
	  return false;
	};

  var cardFromNumber = function(num) {
	  var card, j, len;
	  num = (num + '').replace(/\D/g, '');
	  for (j = 0, len = cards.length; j < len; j++) {
	    card = cards[j];
	    if (card.pattern.test(num)) {
	      return card;
	    }
	  }
	};

	var formatCardNumber = function(e) {
    var card, digit, i, j, len, length, re, target, upperLength, upperLengths, value;
    digit = String.fromCharCode(e.which);
    if (!/^\d+$/.test(digit)) {
      return;
    }
    target = e.target;
    value = QJ.val(target);
    card = cardFromNumber(value + digit);
    length = (value.replace(/\D/g, '') + digit).length;
    upperLengths = [16];
    if (card) {
      upperLengths = card.length;
    }
    for (i = j = 0, len = upperLengths.length; j < len; i = ++j) {
      upperLength = upperLengths[i];
      if (length >= upperLength && upperLengths[i + 1]) {
        continue;
      }
      if (length >= upperLength) {
        return;
      }
    }
    if (hasTextSelected(target)) {
      return;
    }
    if (card && card.type === 'amex') {
      re = /^(\d{4}|\d{4}\s\d{6})$/;
    } else {
      re = /(?:^|\s)(\d{4})$/;
    }
    if (re.test(value)) {
      e.preventDefault();
      QJ.val(target, value + ' ' + digit);
      return QJ.trigger(target, 'input');
    }
	};

	var restrictNumeric = function(e) {
	  var input;
	  if (e.metaKey || e.ctrlKey) {
	    return true;
	  }
	  if (e.which === 32) {
	    return e.preventDefault();
	  }
	  if (e.which === 0) {
	    return true;
	  }
	  if (e.which < 33) {
	    return true;
	  }
	  input = String.fromCharCode(e.which);
	  if (!/[\d\s]/.test(input)) {
	    return e.preventDefault();
	  }
	};

  var restrictCardNumber = function(e) {
    var card, digit, length, target, value;
    target = e.target;
    digit = String.fromCharCode(e.which);
    if (!/^\d+$/.test(digit)) {
      return;
    }
    if (hasTextSelected(target)) {
      return;
    }
    value = (QJ.val(target) + digit).replace(/\D/g, '');
    card = cardFromNumber(value);
    length = 16;
    if (card) {
      length = card.length[card.length.length - 1];
    }
    if (!(value.length <= length)) {
      return e.preventDefault();
    }
	};

	var formatCardNumberImpl = function(num) {
    var card, groups, ref, upperLength;
    card = cardFromNumber(num);
    if (!card) {
      return num;
    }
    upperLength = card.length[card.length.length - 1];
    num = num.replace(/\D/g, '');
    num = num.slice(0, upperLength);
    if (card.format.global) {
      return (ref = num.match(card.format)) != null ? ref.join(' ') : void 0;
    } else {
      groups = card.format.exec(num);
      if (groups == null) {
        return;
      }
      groups.shift();
      groups = groups.filter(function(n) {
        return n;
      });
      return groups.join(' ');
    }
  }

  var safeVal = function(value, target) {
    var currPair, cursor, digit, error, last, prevPair;
    try {
      cursor = target.selectionStart;
    } catch (_error) {
      error = _error;
      cursor = null;
    }
    last = QJ.val(target);
    QJ.val(target, value);
    if (cursor !== null && document.activeElement == target) {
      if (cursor === last.length) {
        cursor = value.length;
      }
      if (last !== value) {
        prevPair = last.slice(cursor - 1, +cursor + 1 || 9e9); //9e9 - random number - to avoid error if end range is not present
        currPair = value.slice(cursor - 1, +cursor + 1 || 9e9); //9e9 - sets the end range till last number possible
        digit = value[cursor];
        if (/\d/.test(digit) && prevPair === ("" + digit + " ") && currPair === (" " + digit)) {
          cursor = cursor + 1;
        }
      }
      target.setSelectionRange(cursor,cursor);
    }
  };

	var reFormatCardNumber = function(e) {
    var target, value, tmp;
    target = e.target;
    return setTimeout(function() {
      value = QJ.val(target);
      let tmp = value;
      if (!/^\d+$/.test(value)) {
        value = value.replace(/[^0-9]/g,"");
      }
      value = formatCardNumberImpl(value);
      if(tmp == value){
        return;
      }
      safeVal(value, target);
      QJ.trigger(target, 'input');
    });
	};

	var cardTypeImpl = function(num) {
    var ref;
    if (!num) {
      return null;
    }
    return ((ref = cardFromNumber(num)) != null ? ref.type : void 0) || null;
  }

  var allTypes = function() {
    var card, j, len, results;
    results = [];
    for (j = 0, len = cards.length; j < len; j++) {
      card = cards[j];
      results.push(card.type);
    }
    return results;
  };

  var getCardsForLogos = function() {
    var results, defaultCards;
    results = [];
    defaultCards = ['visa','mastercard','amex','discover','jcb'];
    // TODO: intorduce enabled cards backend setting
    // if(enabledCards){
    //   return;  
    // }
    return defaultCards;
  }

  var getCardClass = function(type) {
    switch(type) {
      case 'visa' : return 'icon-cc-visa';
      case 'amex' :  return 'icon-cc-amex';
      case 'american_express' : return 'icon-cc-amex';
      case 'mastercard' : return 'icon-cc-mastercard-new';
      case 'discover' : return 'icon-cc-discover';
      case 'jcb' : return 'icon-cc-jcb';
      case 'unionpay' :  return 'icon-cc-unionpay';
      case 'maestro' : return 'icon-cc-maestro';
      case 'dinersclub' : return 'icon-cc-diners-club';
      case 'visaelectorn' : return 'icon-cc-visa-electron';
      case 'dankort' : return 'icon-cc-dankort';
      case 'elo' :  return 'icon-cc-elo';
      case 'laser' : return 'icon-cc-laser';
      case 'hipercard' :  return 'icon-cc-hipercard';
      case 'argencard' :  return 'icon-cc-argencard';
      case 'cencosud' :  return 'icon-cc-cencosud';
      case 'cmrfalabella' :  return 'icon-cmr-falabella';
      case 'tarjetanaranja' :  return 'icon-cc-naranjax';
    }
  }

	var setCardType = function(e) {
	  var cardType, target, val;
	  target = e.target;
	  val = QJ.val(target);
	  cardType = cardTypeImpl(val) || 'unknown';
	  if (!QJ.hasClass(target, cardType)) {
	    QJ.removeClass(target, 'unknown');
	    QJ.removeClass(target, allTypes().join(' '));
	    QJ.addClass(target, cardType);
	    QJ.toggleClass(target, 'identified', cardType !== 'unknown');
	    QJ.trigger(target, 'payment.cardType', cardType);
	  }
	};

	var restrictCVC = function(e) {
	  var digit, target, val;
	  target = e.target;
	  digit = String.fromCharCode(e.which);
	  if (!/^\d+$/.test(digit)) {
	    return;
	  }
	  if (hasTextSelected(target)) {
	    return;
	  }
	  val = QJ.val(target) + digit;
	  if (!(val.length <= 4)) {
	    return e.preventDefault();
	  }
	};

  var luhnCheck = function(num) {
    var digit, digits, j, len, odd, sum;
    odd = true;
    sum = 0;
    digits = (num + '').split('').reverse();
    for (j = 0, len = digits.length; j < len; j++) {
      digit = digits[j];
      digit = parseInt(digit, 10);
      if ((odd = !odd)) {
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }
      sum += digit;
    }
    return sum % 10 === 0;
  };

  var validateCardNumber =  function(num) {
    var card, ref;
    num = (num + '').replace(/\s+|-/g, '');
    if (!/^\d+$/.test(num)) {
      return false;
    }
    card = cardFromNumber(num);
    if (!card) {
      return false;
    }
    return (ref = num.length, indexOf.call(card.length, ref) >= 0) && (card.luhn === false || luhnCheck(num));
  };

  var cardFromType = function(type) {
    var card, j, len;
    for (j = 0, len = cards.length; j < len; j++) {
      card = cards[j];
      if (card.type === type) {
        return card;
      }
    }
  };

  var validateCardCVC = function(cvc, type) {
    var ref, ref1;
    cvc = QJ.trim(cvc);
    if (!/^\d+$/.test(cvc)) {
      return false;
    }
    if (type && cardFromType(type)) {
      return ref = cvc.length, indexOf.call((ref1 = cardFromType(type)) != null ? ref1.cvcLength : void 0, ref) >= 0;
    } else {
      return cvc.length >= 3 && cvc.length <= 4;
    }
  };

  var cardExpiryVal = function(value) {
    var month, prefix, ref, year;
    value = value.replace(/\s/g, '');
    ref = value.split('/', 2), month = ref[0], year = ref[1];
    if ((year != null ? year.length : void 0) === 2 && /^\d+$/.test(year)) {
      prefix = (new Date).getFullYear();
      prefix = prefix.toString().slice(0, 2);
      year = prefix + year;
    }
    month = parseInt(month, 10);
    year = parseInt(year, 10);
    return {
      month: month,
      year: year
    };
  };

  var validateCardExpiry = function(month, year) {
    var currentTime, expiry, prefix, ref, ref1;
    if (typeof month === 'object' && 'month' in month) {
      ref = month, month = ref.month, year = ref.year;
    } else if (typeof month === 'string' && indexOf.call(month, '/') >= 0) {
      ref1 = cardExpiryVal(month), month = ref1.month, year = ref1.year;
    }
    if (!(month && year)) {
      return false;
    }
    month = QJ.trim(month);
    year = QJ.trim(year);
    if (!/^\d+$/.test(month)) {
      return false;
    }
    if (!/^\d+$/.test(year)) {
      return false;
    }
    month = parseInt(month, 10);
    if (!(month && month <= 12)) {
      return false;
    }
    if (year.length === 2) {
      prefix = (new Date).getFullYear();
      prefix = prefix.toString().slice(0, 2);
      year = prefix + year;
    }
    expiry = new Date(year, month);
    currentTime = new Date;
    expiry.setMonth(expiry.getMonth() - 1);
    expiry.setMonth(expiry.getMonth() + 1, 1);
    return expiry > currentTime;
  };

  return {
    formatCardNumber : formatCardNumber,
    restrictNumeric : restrictNumeric,
    restrictCardNumber : restrictCardNumber,
    reFormatCardNumber : reFormatCardNumber,
    setCardType : setCardType,
    cardTypeImpl : cardTypeImpl,
    restrictCVC : restrictCVC,
    validateCardNumber : validateCardNumber,
    validateCardCVC : validateCardCVC,
    validateCardExpiry : validateCardExpiry,
    getCardsForLogos: getCardsForLogos,
    getCardClass: getCardClass
  }
}();

export default{
  formatCardNumber(el, callbaks){
  	// QJ.on(el, 'keypress', paymentUtil.restrictNumeric);
  	QJ.on(el, 'keypress', paymentUtil.restrictCardNumber);
    QJ.on(el, 'keypress', paymentUtil.formatCardNumber);
    QJ.on(el, 'paste input', paymentUtil.reFormatCardNumber);
    QJ.on(el, 'keyup blur', paymentUtil.setCardType);
    QJ.on(el, 'blur', callbaks.blur);
    return el;
  },
  getCardsForLogos(){
    return paymentUtil.getCardsForLogos();
  },
  getCardClass(type){
    return paymentUtil.getCardClass(type);
  },
  cardType(val){
  	return paymentUtil.cardTypeImpl(val);
  },
  formatCardCVC(el){
  	QJ.on(el, 'keypress', paymentUtil.restrictCVC);
  },
  validateCardNumber(val){
    return paymentUtil.validateCardNumber(val);
  },
  validateCardCVC(cvc, type){
    return paymentUtil.validateCardCVC(cvc, type);
  },
  validateCardExpiry(month, year){
    return paymentUtil.validateCardExpiry(month, year);
  },
}