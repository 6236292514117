<template src="@/components/collections/templates/AccountTemplate.html"></template>
<script type="text/javascript">
  import { mapGetters } from "vuex"
  import FormMixin from "@/mixins/form-mixin"
  import cbClient from "@/utils/cb-client"
  import CBNotification from "@/models/cb-notification"
  import animate from "@/utils/scroll-animation"
  import Notes from "@/components/collections/elements/Notes"
  export default {
    data() {
      return {
        fname: "customer",
        showLoader: false,
        skip_cart: false,
        timeInterval: "",
      }
    },
    mixins: [FormMixin],
    props: ['editMode'],
    components: {
      'cb-notes': Notes
    },
    computed: {
      ...mapGetters(
        ["customer_handle", "entry_point"]
      ),
      buttonTxt() {
        return this.t(this.$route.meta.headerTitle + ".submit");
      },
      accountNote() {
        return this.t('edit_account.note');
      }
    },
    created() {
      var config = this.$store.state.config.sections['customer'].fields;
      this.$store.dispatch('create_form', {
        name: this.fname,
        config: config,
        def_values: this.$store.state.customer.data
      });
    },
    mounted(){
      if(!this.$route.params.field && !document.getElementById(this.$route.params.field)){
        return;
      }
      this.timeInterval = setInterval(() => {
        if(app.$animation == "end"){
          this.scrollOnFocus();
          clearInterval(this.timeInterval);
        }
      }, 500);
    },
    methods: {
      scrollOnFocus(){
        var el = document.getElementById(this.$route.params.field);
        var target = document.documentElement || document.body;
        if(!el && !el.offsetParent){
          return;
        }
        var duration = el.offsetParent.offsetTop + 300;
        animate.smooth_scroll_to(target, el.offsetParent.offsetTop, duration);
        window.cbTimeout(() => {
          el.focus();
        },duration);
      },

      submit() {
        this.showLoader = true;
        this.$store.dispatch('validate_form', this.fname).then(success => {
          if (success) {
            var customerData = this.$store.getters.values(this.fname);
            this.$store.dispatch('update_customer', {data: customerData}).then(() => {
              // shouldnt we show flash message here
              if(this.entry_point == this.$route.name) {
              // There can be two possible things
              // either redirect or close
              // handle close later
                this.$store.dispatch('set_entry_point', "portal_account");
                this.$router.customPush({name: "portal_account"});
              }
              else {
                this.$router.go(-1);
              }
              this.$store.dispatch('clear_form', this.fname);
              this.showLoader = false;
            }).catch((error) => {
              this._displayErrorMessage(error)
              this.showLoader = false;
              logger.e(error);
            });
          }
          else {
            this.showLoader = false;
            this.$store.dispatch('set_current_notification', new CBNotification('error', {message: this.st('error')}));
          }
        }).catch(error => logger.e(error));
      },
      _displayErrorMessage(error) {
        if(error.isParamException && error.isParamException() && error.paramName){
          this.$store.dispatch('set_error', {name: error.paramName, fname: this.fname,
            error: error.paramMessage
            });
        }
        this.$store.dispatch('set_current_notification', new CBNotification('error', error));
      }
    },
    beforeRouteLeave (to, from, next) {
      //todo confirm with vivek.. this is getting undefined
      this && this.timeInterval && clearInterval(this.timeInterval);
      next();
    }
  }
</script>