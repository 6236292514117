import currencyFilter from "@/filters/currency";
import MultiDecimalHelper from "@/utils/multi-decimal"
var canShowQuantityItem = (item) => {
  switch (item.type) {
    case "plan":
    case "addon":
      return item.details && item.details.pricing_model != "flat_fee"
  }
  return false;
}


class TempItem {
  constructor(title, description, amount, type, source) {
    this.title = title;
    this.description = description;
    this.amount = amount;
    this.type = type;
    // source will be present if there is only one cart item
    this.quantity = source && canShowQuantityItem(source) && MultiDecimalHelper.getQuantity(source) || 1;
    this.quantity_in_decimal = source && canShowQuantityItem(source) && MultiDecimalHelper.getQuantity(source) || MultiDecimalHelper.getDefaultQuantity();
  }
}


class CartTypeResolver {
  constructor(getters, orderEstimateHolder, orderItem) {
    this.getters = getters;
    this.orderItem = orderItem;
    this.orderEstimateHolder = orderEstimateHolder;
  }

  isCartCombined() {
    if(this.getters.skip_cart_type_check) {
        return true;
    }
    return this.cartType();
  }

  cartType() {
    let estimates = [];
    this.orderEstimateHolder.immediateEstimate && estimates.push(this.orderEstimateHolder.immediateEstimate);
    if(this.orderEstimateHolder.creditnoteEstimates && this.orderEstimateHolder.creditnoteEstimates.length > 0) {
      return false
    }
    if(this.orderEstimateHolder.nextEstimates && this.orderEstimateHolder.nextEstimates.length > 0) {
        estimates = estimates.concat(this.orderEstimateHolder.nextEstimates);
    }
    if(estimates.length == 0) { // Dunno the cases -> Check with subscription team
        return true;
    }
    if(CartTypeResolver.otherThanOrderItemPresent(estimates[0], this.orderItem)){
        return false;
    } else if(estimates.length > 1 && CartTypeResolver.isDifferentEstimate(estimates[0], estimates[1])){
        return false;
    }
    return true;
  }

  static matchItems(items1, items2, equalMatcher) {
      ([].concat(items1, items2)).forEach(i => i.matched = false);
      items1.forEach(i1 => {
          let matchedItem = items2.filter(i2 => equalMatcher(i1, i2))[0];
          if(matchedItem) {
          matchedItem.matched = true;
          i1.matched = true;
          }
      });
      return items1.some(i => i.matched == false) || items2.some(i => i.matched == false);
  }

  static otherThanOrderItemPresent(estimate, orderItem) {
      let orderItems = [].concat(orderItem.plan, orderItem.addons).filter(i => !!i);
      let lineItems = CartTypeResolver.removePlanSetup(estimate.line_items || []);
      return CartTypeResolver.matchItems(orderItems, lineItems, this.areItemsEqual);
  }

  static isDifferentEstimate(estimate1, estimate2) {
      let lineItems1 = CartTypeResolver.removePlanSetup(estimate1.line_items || []);
      let lineItems2 = CartTypeResolver.removePlanSetup(estimate2.line_items || []);
      return CartTypeResolver.matchItems(lineItems1, lineItems2, this.areLineItemsEqual);
  }

  static removePlanSetup(lineItems) {
      return lineItems.filter(li => li.entity_type != "plan_setup");
  }

  static getLineItemEntityType(li) {
    // For PC 2.0 backwards compatibility
    let entityType =  li.entity_type;
    if(entityType == "plan_item_price") entityType = "plan";
    else if(entityType == "addon_item_price") entityType = "addon";
    return entityType;
  }

  static areItemsEqual(item, li) {
    // TODO: Should handle multi decimal quantity?
      return (CartTypeResolver.getLineItemEntityType(li) == item.type
          && li.entity_id == item.id
          && li.quantity == item.quantity);
  }

  static areLineItemsEqual(li1, li2) {
      return (CartTypeResolver.getLineItemEntityType(li1) == CartTypeResolver.getLineItemEntityType(li2)
          && li1.entity_id == li2.entity_id
          && li1.amount == li2.amount
          && li1.quantity == li2.quantity);
  }
}



const CartHelper = {

  canCombineCart(getters, orderEstimateHolder, orderItem) {
    return new CartTypeResolver(getters, orderEstimateHolder, orderItem).isCartCombined();
  },

  isDifferentEstimate(estimate1, estimate2) {
    return CartTypeResolver.isDifferentEstimate(estimate1, estimate2);
  },

  canShowQuantity(item, getters) {
    if (item.metered) {
      return false
    }
    switch (item.type) {
      case "plan":
        return getters.can_allow("change-subscription.action.allow_plan_quantity_update") && item.details && item.details.pricing_model != "flat_fee"
      case "addon":
        if(item.added_type != "auto" && item.details && item.details.pricing_model != "flat_fee") {
          // For checkout, we don't have addon_type based settings
          if(getters.hosted_page_type == "checkout") {
            return (getters.can_allow("change-subscription.action.allow_change_quantity_addons")
              || (getters.can_allow("one-time-checkout.action.allow_edit_quantity") && item.details.charge_type == "non_recurring"));
          }
          return ((getters.can_allow("change-subscription.action.allow_change_quantity_addons") && item.details.charge_type == "recurring")
              || (getters.can_allow("change-subscription.action.allow_change_quantity_non_rec_addons") && item.details.charge_type == "non_recurring"))
        }
        return  false;
    }
    return false;
  },

  canRemove(item, getters) {
    switch (item.type) {
      case "addon":
        if(item.added_type != "auto" && item.details) {
          // For checkout, we don't have type based settings
          if(getters.hosted_page_type == "checkout") {
            return (getters.can_allow("change-subscription.action.allow_remove_addons")
            || (getters.can_allow("one-time-checkout.action.allow_remove_products") && item.details.charge_type == "non_recurring"));
          }
          return ((getters.can_allow("change-subscription.action.allow_remove_addons") && item.details.charge_type == "recurring")
          || (getters.can_allow("change-subscription.action.allow_remove_non_rec_addons") && item.details.charge_type == "non_recurring"))
        }
        return false;
    }
    return false;
  },

  amount(item) {
    if(MultiDecimalHelper.isMultiDecimal(item)) {
      if (item.lineItem) { // PC1.0
        return item.lineItem.amount_in_decimal;
      } else if(item.amount_in_decimal != undefined) { // PC2.0
        return item.amount_in_decimal
      }
      return item.details && item.details.price_in_decimal;
    }
    if (item.lineItem) {
      return item.lineItem.amount;
    }
    return (item.details && item.details.price) || item.amount;
  },

  amountAsNumber(item) {
    let amt = this.amount(item);
    return isNaN(amt) ? amt : Number(amt);
  },

  name(item) {
    if (item.lineItem) {
      return item.lineItem.description;
    }
    return item.details && (item.details.invoice_name || item.details.name);
  },

  description(item) {
    return item.details && item.details.description;
  },

  consolidateEstimate(product_items, taxes, discounts, coupon_list) {
    let t = [].concat(this._product(product_items), this._taxes(taxes), this._discounts(discounts, coupon_list)).filter(i => !!i);
    return t;
  },

  _product(items) {
    if (items.length == 1) {
      var item = items[0];
      return new TempItem(() => this.name(item), this.description(item), this.amountAsNumber(item), "product", item);
    } else {
      return new TempItem((st,t) => `${items.length}` + ' ' +  this.getItemtxt(st,t), '', items.reduce((sum, item) => {
        sum += this.amountAsNumber(item);
        return sum;
      }, 0), "product")
    }
  },

  getItemtxt(st,t) {
    return (t && t('items_in_total.custom_label')) || st('cart.item_text.total');
  },

  _taxes(taxes) {
    if (taxes.length == 0) {
      return;
    }
    var name = taxes.length == 1 ?
      (taxes[0].description || taxes[0].name) :
      this.getConcatinatedTaxes(taxes);
    return new TempItem(name, '', taxes.reduce((sum, tax) => {
      sum += tax.amount;
      return sum;
    }, 0), "tax")
  },

  getConcatinatedTaxes(taxes) {
    var names = taxes.map((t) => t.name)
    let uniqueNames = Array.from(new Set(names));
    if(uniqueNames.length == 1) {
      return uniqueNames[0];
    }
    return uniqueNames.slice(0, uniqueNames.length - 1).join(', ') + " & " + uniqueNames[uniqueNames.length - 1];
  },

  _discounts(_discounts, coupon_list) {
    let discounts = (_discounts || []).filter(d => ["item_level_coupon", "document_level_coupon", "item_level_discount", "document_level_discount"].indexOf(d.entity_type) > -1);
    if (discounts.length == 0) {
      return;
    }

    if (discounts.length == 1) {
      var discount = discounts[0];
      var output = new TempItem(discount.description, '', discount.amount, "discount");
      output.coupon_id = discount.entity_id;
      output.entity_type = discount.entity_type || "";
      this.addSingleCouponDesc(output, coupon_list);
      return output;
    } else {
      return new TempItem('Discounts applied', () => '', discounts.reduce((sum, item) => {
        sum += item.amount;
        return sum;
      }, 0), "discount")
    }
  },

  addSingleCouponDesc(output, coupon_list) {
    if(coupon_list && coupon_list.length > 0) {
      let cp = coupon_list.filter(c => c.id == output.coupon_id)[0];
      if(cp && cp.details) {
        output.description = () => {
          if(cp.details.discount_type == "percentage") {
            return cp.details.discount_percentage + "%";
          } else if(cp.details.discount_type == "fixed_amount") {
            return currencyFilter(cp.details.discount_amount, cp.details.currency_code)
          }
        };
      }
    }
  },

  getItemFromLineItem(estimate, lineItem) {
    var item = {
      type: lineItem.entity_type,
      pricing_model: lineItem.pricing_model,
      amount: lineItem.amount,
      unit_amount: lineItem.unit_amount,
      quantity: lineItem.quantity,
    }
    if(MultiDecimalHelper.isMultiDecimal(lineItem)) {
      item.amount_in_decimal = lineItem.amount_in_decimal;
      item.unit_amount_in_decimal = lineItem.unit_amount_in_decimal;
      item.quantity_in_decimal = lineItem.quantity_in_decimal;
    }
    let liTiers = estimate.line_item_tiers || [];
    item.tiers = liTiers.filter(tier => tier.line_item_id == lineItem.id);
    return item;
  },

  getTotalCreditsFromDiscounts(discounts, creditsApplied, amountPaid) {
    let credits = (discounts || []).filter(d => ["promotional_credits", "prorated_credits"].indexOf(d.entity_type) > -1);
    let discountCredits =  credits.reduce((sum, item) => {
      sum += (item.amount || 0);
      return sum;
    }, 0);
    creditsApplied = creditsApplied || 0;
    amountPaid = amountPaid || 0; //excess payment case
    return (discountCredits + creditsApplied + amountPaid);
  },


}

export default CartHelper;
