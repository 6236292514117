
export const JsEvents = {
  SetCustomerHandle: 'setCustomerHandle',
  SetAuthToken: 'setAuthToken',
}

export function isEventAllowed(eventName) {
  return !!~Object.keys(JsEvents).map(name => JsEvents[name]).indexOf(eventName)
}

export const HOST_WINDOW = '__HOST__';

export const sendToCbjs = (action, data) => {
  if(isEventAllowed(action)) {
    // send message to cbInstance in parent window, event will be handled by chargebee.js host actions
    window.cbjsLoader.then(() => {
      window.parent.postMessage({
        cbEvent: true,
        targetWindowName: HOST_WINDOW,
        srcWindowName: HOST_WINDOW,
        message: {
          action: action,
          data: data,
        }
      }, '*')
    })
  }
}

// There are chances of collisions
// same uuid can be generated across different clients
// should rely on site and other params at the request side to differentiate the request
export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
    return v.toString(16);
  });
}
