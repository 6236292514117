<template>
  <div class="cb-body__wrap">
    <template v-if="portal_loaded">
      <div class="cb-body__content">
        <cb-notification v-if="paramPmId">
          <div class="cb-notification__wrapper">
              Your payment method has been successfully added. Click
              <a class="cb-notification__link" @click="navigateToPM(paramPmId)">here</a> to see details.
          </div>
        </cb-notification>
        <div class="cb-transition__tab" ref="parent">
          <cb-toggle :parent="$refs.parent">
            <template v-if="subDataLoaded && giftedSubsDataLoaded">
              <subscription-template :subscription-limit="'2'"></subscription-template>
            </template>
            <template v-else>
              <portal-loader />
            </template>
          </cb-toggle>
        </div>
        <div class="cb-section--md">
          <!-- TODO check if its supported -->
          <div
            v-if="showAccountInfo()"
            @click="chooseNav()"
            class="cb-portal__link"
            v-cb-link="'color'"
            role="link"
            data-cb-id="portal_account"
          >
            <div class="cb-portal__icon icon-account"></div>
            <div class="cb-portal__text">
              {{ t('home.account.link_text') }}
              <span
                class="cb-bar__flag"
                :style="themeColor"
                v-if="promotionalCredits"
              >{{st('credit_balance')}}: {{promotionalCredits | cbCurrency(currency_code)}}</span>
            </div>
          </div>
          <div
            v-if="allowed('billing.allow') || (allowed('shipping.allow') && hasShippingAddress())"
            @click="navigate('portal_address')"
            class="cb-portal__link"
            v-cb-link="'color'"
            role="link"
            data-cb-id="portal_address"
          >
            <div class="cb-portal__icon icon-location"></div>
            <div class="cb-portal__text">{{ t('home.address.link_text') }}</div>
          </div>
          <div
            v-if="allowed('payment.allow')"
            @click="navigate('portal_payment_methods')"
            class="cb-portal__link"
            v-cb-link="'color'"
            role="link"
            data-cb-id="portal_payment_methods"
          >
            <div class="cb-portal__icon icon-credit-card"></div>
            <div class="cb-portal__text">{{ t('home.payment_methods.link_text') }}</div>
            <template v-if="expiryCards">
              <div class="cb-portal__error" v-for="(card, index) in filteredExpiryCardsForAlert" v-bind:key="index">
                <span class="cb-portal__error-icon icon-exclamation-circle"></span>
                <span
                  class="cb-portal__error-text"
                >{{st('card_details',{
                  card_brand: card.brand === 'other' ? '' : card.brand,
                  card_last4: card.last4
                  })}}</span>
                <template v-if="card.status == 'expired'">
                  <span>{{st('card_expired')}}</span>
                </template>
                <template v-else-if="card.status == 'expiring'">
                  <span>{{st('card_expiring')}}</span>
                </template>
                <template v-else>
                  <span>is {{card.status}}</span>
                </template>
              </div>
            </template>
            <template v-if="hasPendingVerificationPm">
              <div class="cb-portal__error cb-portal__error--xs">
                <span class="cb-portal__error-icon icon-exclamation-circle"></span>
                <span class="cb-portal__error-text">{{cmt('direct_debit.pending_verification')}}</span>
              </div>
            </template>
          </div>
          <div
            v-if="allowed('portal-invoices.allow')"
            @click="navigate('portal_billing_history')"
            class="cb-portal__link billing-history-link"
            v-cb-link="'color'"
            role="link"
            data-cb-id="portal_billing_history"
          >
            <div class="cb-portal__icon icon-history"></div>
            <div class="cb-portal__text">{{ t('home.billing_history.link_text') }}</div>
          </div>
          <tool-tip :showOnhover="true" :text="this.$store.getters.get_text('hp_v3.portal.unpaid_invoice.alert_tooltip')" :isInline="true" :isSmall="true" toolTipPos="right" v-if="canPayUnpaidInvoices">
            <div class="cb-tooltip__alert"></div>
          </tool-tip>
          <div
            v-if="campaignDetails && campaignDetails.referral_url"
            @click="navigate('portal_promotions')"
            class="cb-portal__link"
            v-cb-link="'color'"
            role="link"
            data-cb-id="portal_promotions"
          >
            <div class="cb-portal__icon icon-multiple-account"></div>
            <div class="cb-portal__text">{{ t('home.promotions.link_text') }}</div>
          </div>
          <div
            class="cb-portal__link cb-portal__link--muted"
            tabindex="0"
            v-if="showClose"
            @click="close()"
            role="link"
            data-cb-id="portal_close"
          >
            <div class="cb-portal__icon icon-logout-variant"></div>
            <div class="cb-portal__text">{{ t('portal_home.close') }}</div>
          </div>
        </div>
      </div>
      <div class="cb-body__footer cb-body__footer--text" role="footer">
        <div
          class="cb-portal__link cb-portal__link--note"
          v-if="checkMultipleAccounts && subDataLoaded && giftedSubsDataLoaded"
          @click="navigate('choose_account')"
        >{{st('switch_account')}}</div>
        <cb-notes :custom-class="'cb-section'" :type="'info'" :message="t('home.note')"></cb-notes>
        <div class="cb-portal__help">{{ t('home.footer') }}</div>
      </div>
    </template>
    <template v-else>
      <portal-loader />
    </template>
  </div>
</template>
<script type="text/javascript">
import { mapGetters } from "vuex";
import PortalLoader from "@/components/modules/portal/PortalLoader";
import PortalSubscriptionTemplateCommon from "@/components/modules/portal/PortalSubscriptionTemplateCommon";
import { EventBus } from "@/utils/event-bus";
import cbClient from "@/utils/cb-client";
import Notes from "@/components/collections/elements/Notes";
import Notification from "@/components/collections/elements/Notification";
import { getHostFromUrl } from "@/utils/helper-functions"
import ToolTip from "@/components/collections/elements/ToolTip";
import MultiEntityMixin from "@/mixins/multi-entity-mixin";

export default {
  data() {
    return {
      expiryCards: [],
      hasPendingVerificationPm: false,
      unpaidInvoiceCount: 0
    };
  },
  mixins: [MultiEntityMixin],
  computed: {
    ...mapGetters([
      "cancan",
      "linked_handles",
      "portal_loaded",
      "subDataLoaded",
      "giftedSubsDataLoaded",
      "payment_method_list",
      "portal_redirect",
      "sections",
      "campaignDetails",
      "is_otp_pwd",
      "customer_email"
    ]),
    checkMultipleAccounts() {
      if (this.linked_handles) {
        return true;
      }
      return false;
    },
    promotionalCredits() {
      return (
        this.$store.state.customer &&
        this.$store.state.customer.data &&
        this.$store.state.customer.data.promotional_credits +
          this.$store.state.customer.data.refundable_credits +
          this.$store.state.customer.data.excess_payments
      );
    },
    currency_code() {
      return (
        this.$store.state.customer &&
        this.$store.state.customer.data.preferred_currency_code
      );
    },
    showClose() {
      if (window.isOpenedByCb) {
        return window.isMobile;
      } else {
        // if redirect url is present
        return !!this.portal_redirect;
      }
    },
    paramPmId() {
      return this.$route.params.forEdgeBrowser && this.$route.params.pmId;
    },
    /**
     * if there are multiple expiry cards with masked last 4 digits,
     * display alert only once
     */
    filteredExpiryCardsForAlert() {
      let isUnknownCardNumberFound = false
      return this.expiryCards.filter(card => {
        if (/^\d{4}$/.test(card.last4)) {
          return true
        }
        if (!isUnknownCardNumberFound) {
          isUnknownCardNumberFound = true
          return true
        }
        return false
      })
    },
    canPayUnpaidInvoices() {
      return this.unpaidInvoiceCount > 0;
    }
  },
  created() {
    if(this.$store.getters.cancan.allow('portal-paynow.action')){
      this.$store.dispatch('get_unpaid_invoice_count').then((unpaidInvoiceCount) => {
        this.unpaidInvoiceCount = unpaidInvoiceCount
      })
    }
    this.payment_method_list.forEach(pm => {
      if (pm.type != "card") {
        if (pm.status == "pending_verification") {
          this.hasPendingVerificationPm = true;
        }
        return false;
      }
      pm.card.status = pm.status;
      return pm.status != "valid" ? this.expiryCards.push(pm.card) : false;
    });
  },
  components: {
    "portal-loader": PortalLoader,
    "subscription-template": PortalSubscriptionTemplateCommon,
    "cb-notes": Notes,
    "cb-notification": Notification,
    "tool-tip": ToolTip
  },
  methods: {
    allowed(name) {
      return this.cancan.allow(name);
    },
    navigate(section) {
      this.$router.customPush({ name: section });
    },
    goTo(section) {
      this.$router.replace({ name: section });
    },
    showAccountInfo() {
      return (
        this.allowed("account.allow") ||
        this.permissions() ||
        this.showPwdSection()
      );
    },
    permissions() {
      return (
        this.sections.consent.fields && this.sections.consent.fields.length
      );
    },
    chooseNav() {
      if (this.allowed("account.allow") || this.showPwdSection()) {
        this.navigate("portal_account");
      } else {
        this.navigate("portal_consent_details");
      }
    },
    showPwdSection() {
      //if customer email is changed, then not showing this section
      return (
        this.is_otp_pwd && this.$store.state.auth.email == this.customer_email
      );
    },
    hasShippingAddress() {
      return this.$store.state.customer.shipping_address_list.length > 0;
    },
    close() {
      if (window.isOpenedByCb) {
        if (window.isMobile) {
          window.cbPostMessage("cb.close");
        }
      } else {
        window.cbPostMessage("cb.close");
        if (this.portal_redirect) {
          this.$store.dispatch("remove_token_and_logout").then(() => {
            logger.kvl({
              window_url: window.location.href,
              redirectUrl: getHostFromUrl(this.portal_redirect),
              module: "portal_v2",
              customerId: this.$store.getters.customer_id
            })
            window.location.href = this.portal_redirect;
          });
        }
      }
    },
    navigateToPM(pmId) {
      this.$router.customPush({name: "portal_payment_methods", params: {pmId: pmId}});
    }
  },
  mounted() {
    window.cbPostMessage({
      key: "cb.page_visited",
      value: "home",
      type: "portal"
    });
  }
};
</script>
<style scoped>
  .billing-history-link{
    display: inline !important;
  }
</style>
