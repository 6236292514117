<template>
  <div class="cb-header__wrap cb-transition__slide" id="cb-header-wrap" ref="parent" role="banner">
    <div class="cb-header__logo" v-if="logo">
      <img class="cb-header__image" :src="logo" alt="logo">
    </div>
    <!-- check with vivek -->
    <cb-slide-n-fade>
		<div class="cb-header__title" :style="themeColor" v-if="this.$route.meta.headerTitle && this.$route.meta.nonLoggedInUser" :class="{'cb-header__title--xl': !logo}" id="cb-header-title">
		  {{ nlt(this.$route.meta.headerTitle) }}
		</div>
		<div v-else class="cb-header__title" :style="themeColor" :class="{'cb-header__title--xl': !logo}" id="cb-header-title">
			{{ this.$route.meta.commonTranslate ? cmt(this.$route.meta.headerTitle) : t(this.$route.meta.headerTitle) }}
		</div>
	</cb-slide-n-fade>
  </div>
</template>
<script type="text/javascript">
export default {
	
}
</script>
