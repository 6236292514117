<style src="@/assets/styles/components/modules/checkout/referral.styl" lang="stylus" scoped></style>
<template>
  <div class="cb-referral">
    <div class="cb-referral__heading" v-if="headerText">
      {{ headerText }}
    </div>
    <div class="cb-referral__desc" v-if="text1 || text2">
      {{ text1 }}
      {{ text2 }}
    </div>
    <div class="cb-section cb-text">
      <input ref="input" type="text" :value="referral_url" :class="{'cb-field__readonly-dark': hosted_page_type != 'portal'}" class="cb-text__control cb-text__control--left" readonly="true" id="referral_url"/>
      <button-field @next="doCopy()" :custom-class="'primary icon field'" :attr="'referral_copy'">
        <span class="cb-sm">{{copyText}}</span>
      </button-field>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { copyTextToClipboard } from "@/utils/helper-functions";
import Button from "@/components/collections/form/ButtonComponent";
var truncateDecimals = function(num, digits) {
  var numS = num.toString(),
    decPos = numS.indexOf("."),
    substrLength = decPos == -1 ? numS.length : 1 + decPos + digits,
    trimmedResult = numS.substr(0, substrLength),
    finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

  return parseFloat(finalResult);
};

export default {
  data() {
    return {
      copied: false
    };
  },
  components: {
    "button-field": Button
  },
  props: ["fadeHeaderContent"],
  methods: {
    doCopy() {
      if (!this.copied) {
        copyTextToClipboard(this.referral_url);
        this.copied = true;
        window.setTimeout(() => (this.copied = false), 2000);
      }
    }
  },
  computed: {
    headerText() {
      return this.ct("referral.title");
    },
    referral_credits() {
      return this.$options.filters.cbCurrency(
        this.campaignDetails.referrer_reward_amount,
        this.campaignDetails.currency_code
      );
    },
    displayLabel() {
      return this.ct("referral.link_label");
    },
    copyText() {
      return this.copied ? this.cmt("common.copied") : this.cmt("common.copy");
    },
    text1() {
      if (
        this.campaignDetails.referrer_reward_type == "custom_promotional_credit"
      ) {
        return this.ct("referral.cb_reward", {
          referral_credits: this.referral_credits
        });
      }
      if (this.campaignDetails.referrer_reward_type == "none") {
        return this.ct("referral.custom_reward");
      }
    },
    referral_friend_discount() {
      if (this.campaignDetails.friend_offer_coupon_percentage) {
        return (
          truncateDecimals(
            this.campaignDetails.friend_offer_coupon_percentage,
            2
          ) + "%"
        );
      }
      if (this.campaignDetails.friend_offer_coupon_reward) {
        return this.$options.filters.cbCurrency(
          this.campaignDetails.friend_offer_coupon_reward,
          this.campaignDetails.currency_code
        );
      }
    },
    referral_url() {
      return this.campaignDetails.referral_url;
    },
    text2() {
      if (this.campaignDetails.friend_offer_type == "coupon") {
        return this.ct("referral.friend_discount", {
          referral_friend_discount: this.referral_friend_discount
        });
      }
    },
    ...mapGetters(["campaignDetails", "hosted_page_type"])
  }
};
</script>

<style lang="scss" scoped>
.cb-sm {
  font-size: 12px;
}
</style>
