export default [
  "AO", // Angola
  "AG", // Antigua and Barbuda
  "AW", // Aruba
  "AC", // Ascension island
  "BS", // Bahamas
  "BZ", // Belize
  "BJ", // Benin
  "BO", // Bolivia
  "BQ", // Bonaire, Sint Eustatius and Saba
  "BW", // Botswana
  "BF", // Burkina Faso
  "BI", // Burundi
  "CM", // Cameroon
  "CF", // Central African Republic
  "KM", // Comoros
  "CG", // Congo
  "CD", // Congo the Democratic Republic of the
  "CK", // Cook Islands
  "CI", // Cote d’Ivoire
  "CW", // Curaçao
  "DJ", // Djibouti
  "DM", // Dominica
  "TL", // East Timor
  "GQ", // Equatorial Guinea
  "ER", // Eritrea
  "FJ", // Fiji
  "TF", // French Southern Territories
  "GA", // Gabon
  "GM", // Gambia
  "GH", // Ghana
  "GD", // Grenada
  "GY", // Guyana
  "HM", // Heard and McDonald Islands
  "HK", // Hong Kong
  "KI", // Kiribati
  "LY", // Libya
  "MO", // Macau
  "MW", // Malawi
  "ML", // Mali
  "MR", // Mauritania
  "NR", // Nauru
  "AN", // Netherlands Antilles
  "NU", // Niue
  "KP", // North Korea
  "QA", // Qatar
  "RW", // Rwanda
  "KN", // Saint Kitts and Nevis
  "ST", // Sao Tome and Principe
  "SC", // Seychelles
  "SL", // Sierra Leone
  "SB", // Solomon Islands
  "SR", // Suriname
  "SY", // Syria
  "TG", // Togo
  "TK", // Tokelau
  "TO", // Tonga
  "TV", // Tuvalu
  "UG", // Uganda
  "AE", // United Arab Emirates
  "VU", // Vanuatu
  "YE", // Yemen
  "ZW" // Zimbabwe
];
