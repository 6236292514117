<template>
  <div class="cb-field cb-field--toggle" :class="[{'cb-field--error':(!!errorMessage && !focussed)},{'cb-field--focus': focussed && !readonly}]">
    <toggle-button v-cb-link = "'shadow'" :value="_internalValue" :style="themeColor" :color="{checked: 'currentColor'}" role="checkbox" :id="name" :aria-disabled="readonly" :disabled="readonly" :name='name' @change="change($event)"/>
    <label class="cb-field__label" :for='name'> {{displayLabel}}
    </label>
    <div class="cb-field__help" v-if="this.config && this.config.meta && this.config.meta.help_text">
      <template>
        <div class="cb-field__trigger" :class="{'active-opacity': helpTextShown}" @click="showHelpText($event)" ref="helpText">
        </div>
        <transition name="help-text">
          <div class="cb-field__menu" v-show="helpTextShown">
            {{this.config.meta.help_text}}
          </div>
        </transition>
      </template>
    </div>
    <div class="cb-textarea__error" v-if="errorMessageWrapper && !focussed">
      {{errorMessageWrapper}}
    </div>
  </div>
</template>
<script type="text/javascript">
import ToggleButton from "@/components/collections/form/ToggleButton"
import FieldMixin from "@/mixins/field-mixin"
export default {
  name: 'Checkbox',
  props: ['name', 'useparent', 'gid', 'type'],
  components: {
    "toggle-button": ToggleButton,
  },
  created() {
    this.pName = this.findParentForm();
  },
  mixins: [FieldMixin],
  computed:{
      _internalValue(){
        return this.internalValue == "True";
      }
  },
  methods: {
    input(event) {
      this.updateVal(event.target.value);
    },
    change($event){
      this.updateVal($event.value);
    },
    updateVal(val) {
      let _value = val ? "True" : "False";
      this.$store.dispatch('update_form_input', {
        name: this.name,
        value: _value,
        formName: this.pName
      });
    }
  },
}
</script>