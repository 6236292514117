import store from '@/store/portal'
import pauseConfig from "@/utils/pause-config"
import OmniHelper from "@/utils/omni-helper"

export const PORTAL_ACTIONS = {
  // subscription
  EDIT_SUBSCRIPTION: "subscription.edit",
  EDIT_PM_SUBSCRIPTION: "subscription.payment_method.update",

  PAUSE_SUBSCRIPTION: "subscription.pause",
  CANCEL_SUBSCRIPTION: "subscription.cancel",
  
  // invoice
  DOWNLOAD_INVOICE: "invoice.download"
};

class AccessControl {

  can(action, resource) {
    if (this[action]) {
      return this[action](resource);
    }
    return true;
  }

  customerHandle() {
    return store.getters.customer_handle;
  }

  skipValidate(subData) {
    return !store.getters.account_hierarchy_enabled || (subData ? subData.subscription.override_relationship : false); 
  }

  checkAction(action){
    return store.getters.cancan.allow(action);
  }

  [PORTAL_ACTIONS.EDIT_SUBSCRIPTION](subData) {
    if (OmniHelper.isRestrictedChannel(subData.subscription)) {
      return false;
    }
    if(this.skipValidate(subData)) {
      return true;
    }
    let customer = store.getters.getCustomer(subData.customerId());
    return customer && customer.access && customer.access.can_edit_subscription;
  }

  [PORTAL_ACTIONS.EDIT_PM_SUBSCRIPTION](subData) {
    if (OmniHelper.isRestrictedChannel(subData.subscription)) {
      return false;
    }
    if(subData.displayer().isPaidViaOfflinePm(store) && !store.getters.cancan.allow('change-subscription.action.allow_switch_betw_online_and_offline_pm')) {
      return false;
    }
    if(this.skipValidate(subData)) {
      return true;
    }
    if(!this[PORTAL_ACTIONS.EDIT_SUBSCRIPTION](subData)) {
      return false;
    }
    let customer = store.getters.getCustomer(subData.customerId());
    return customer && this.customerHandle() == customer.payment_owner_id;
  }

  [PORTAL_ACTIONS.PAUSE_SUBSCRIPTION](subData) {
    if(!this[PORTAL_ACTIONS.EDIT_SUBSCRIPTION](subData) || !pauseConfig.isEnabled() || subData.displayer().isScheduledForPause) {
      return false;
    }
    let sd = subData.displayer();
    return sd.state == "active" || (sd.state == "non_renewing" && pauseConfig.isImmediatePause());
  }

  [PORTAL_ACTIONS.CANCEL_SUBSCRIPTION](subData) {
    if(!this[PORTAL_ACTIONS.EDIT_SUBSCRIPTION](subData)) {
      return false;
    }
    let sd = subData.displayer();
    if(sd.isGift) {
      return sd.state != "cancelled" && this.checkAction('cancel-subscription.gift-subscription.reciever.cancel');
    }
    if(sd.state == "paused"){
      return this.checkAction('cancel-subscription.action.cancel_paused_sub');
    }
    if(sd && this.checkAction('cancel-subscription.action')){
        return !(sd.state == "cancelled" || sd.isScheduledForCancellation());
    }
    return false;
  }

  [PORTAL_ACTIONS.DOWNLOAD_INVOICE](invoice) {
    let subData = undefined;
    if(invoice.subscription_id) {
      subData = store.getters.fetchSubData(invoice.subscription_id);
    }
    if(this.skipValidate(subData)) {
      return true;
    }
    let customer = store.getters.getCustomer(invoice.customer_id);
    return customer && customer.access && customer.access.can_download_invoice && this.canDownloadSponsoredInvoice(invoice);
  }

  canDownloadSponsoredInvoice(invoice) {
    let sponsoredCustomer = invoice.line_items.filter(line_item => line_item.customer_id && line_item.customer_id != this.customerHandle());
    return sponsoredCustomer.every(li => {
      let customer = store.getters.getCustomer(li.customer_id);
      return customer && customer.access && customer.access.can_download_invoice;
    });
  }

}

export default new AccessControl();
