class VatHelper {
  setVatConfig(vat_config) {
    this.vat_config = vat_config;
  }

  showVat(countryCode) {
    if(!this.vat_config || !countryCode) return false;
    if(this.vat_config.collect_for_non_tax_configured_country) {
      return this.vat_config.not_to_collect_tax_reg_numbers.indexOf(countryCode) == -1;
    }
    else {
      return this.vat_config.to_collect_tax_reg_numbers.indexOf(countryCode) != -1;
    }
  }

  countryConfig(countryCode) {
    return this.showVat(countryCode) && this.vat_config.country_config && this.vat_config.country_config[countryCode];
  }

  getLabel(countryCode) {
    return (
      (this.countryConfig(countryCode) &&
        this.countryConfig(countryCode).label) ||
      this.defaultLabel()
    );
  }

  getVatNumberPrefixes(countryCode) {
    if (this.countryConfig(countryCode)) {
      if (this.countryConfig(countryCode).prefixes) {
        return this.countryConfig(countryCode).prefixes;
      } else {
        return [this.countryConfig(countryCode).prefix];
      }
    }
    return [];
  }

  defaultLabel() {
    return this.vat_config && this.vat_config.default_label;
  }

  vatMandatory() {
    return this.vat_config && this.vat_config.is_vat_mandatory;
  }
  
  prettyPrint(vatNumber, vatNumberPrefix, countryCode) {
    if (countryCode && !this.showVat(countryCode)) {
      return "";
    }
    return vatNumberPrefix && vatNumber ? [vatNumberPrefix, vatNumber].join("-") : vatNumber;
  }
}

export default new VatHelper();