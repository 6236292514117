<template>
  <div class="cb-pmcard__wrap">
    <text-field :type="'card'" ref="ccNumber" @keyup="setCardType" :name="name" @focus="showTestCard($event)" @navigate="navigate()">
      <div class="cb-pmcard__icons cb-pmcard__icons--payment" :class="{'identified': !!selectedCardType}">
        <span v-if="!selectedCardType && pm_list[0].gateway_name == 'CHARGEBEE'">
          <i class="cb-pmcard_icon" :key="card" v-for="card in cards" style="height:20px; width:40px; margin-right:5px"
             :class="getCardClass(card)" />
        </span>
        <transition name="fade" mode="out-in">
          <i class="cb-pmcard__icon" aria-hidden="true" v-bind:key="selectedCardType" 
             :class="getCardClass(selectedCardType)" ></i>
        </transition>
      </div>
    </text-field>
    <transition name="scale-down-y">
      <div v-show="showCard" class="cb-pmcard__options" :class="{'cb-pmcard__options--top': position}">
        <template v-if="testCards">
          <div ref = "card_options" class="cb-pmcard__option" v-for="(tc, index) in testCards" v-bind:key="index" @keyup.up="navigateUp($event)" @keyup.down="navigateDown($event)" @mousedown="setTestCard(tc)" @keyup.enter="setTestCardKeyBoardEvent($event,tc)" tabindex="0" @blur="blur($event)">
            <div class="cb-pmcard__content">
              <div class="cb-pmcard__title" v-text="tc.name"></div>
              <div class="cb-pmcard__details" v-text="tc.number"></div>
              <div class="cb-pmcard__info" v-text="tc.desc"></div>
            </div>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>
<script type="text/javascript">
import { mapGetters } from "vuex"
import TextField from "@/components/collections/form/TextField"
import cardFormatter from "@/utils/card-formatter"
export default {
  computed: {
     ...mapGetters([
      "pm_list",
      "field_config"
    ]),
    cardPaymentMethod(){
      if(this.pName == "bancontact") {
        return this.$store.getters.pm_by_type("bancontact");
      }
      return this.$store.getters.pm_by_type("card");
    },
    testCards() {
      if(!this.cardPaymentMethod){
        return [];
      }
      return this.cardPaymentMethod.test_cards;
    },
    cards() {
      return cardFormatter.getCardsForLogos();
    }
  },
  props: ['name'],
  data() {
    return {
      pName:"",
      selectedCardType: "",
      showCard: false,
      position: false,
    }
  },
  components: {
    "text-field": TextField
  },
  created() {
    this.pName = this.findParentForm();
  },
  mounted() {
    var paymentInput = this.$refs.ccNumber.$refs.input;
    cardFormatter.formatCardNumber(paymentInput, {
      blur: this.blur
    });
  },
  methods: {
    setCardType(value) {
      this.selectedCardType = cardFormatter.cardType(value);
    },
    blur(event) {
      window.setTimeout(() => {
        if (document.activeElement && document.activeElement.getAttribute("class") != 'cb-pmcard__option' && this.showCard) {
          this.showCard = false;
        }
      })
    },
    calculatePosition() {
      var _this = this.$refs.x;
      var appHeight = document.getElementById("app").offsetHeight;
      if (appHeight - _this.getBoundingClientRect().top < 150) {
        this.position = true;
        this.$refs.dropdownContainer.style.bottom = _this.clientHeight + "px";
        this.$refs.dropdownContainer.style.top = "auto";
      } else {
        this.position = false;
        this.$refs.dropdownContainer.style.top = _this.clientHeight + "px";
        this.$refs.dropdownContainer.style.bottom = "auto";
      }
    },
    showTestCard($event) {
      this.showCard = true;
      if (this.showCard) {
        this.$nextTick(() => {
          window.cbTimeout(() => document.addEventListener('click', this.closeDropDown));
        });
      } else {
        document.removeEventListener('click', this.closeDropDown);
      }
    },
    navigate(){
      this.$refs.card_options && this.$refs.card_options[0].focus();
    },
    navigateDown($event){
      if($event.target && $event.target.nextElementSibling)
        $event.target.nextElementSibling.focus();
    },
    navigateUp($event){
      if($event.target && $event.target.previousElementSibling)
        $event.target.previousElementSibling.focus();
    },
    closeDropDown(event) {
      if(event.target == (this.$refs.ccNumber && this.$refs.ccNumber.$refs.input)){
        return;
      }
      if (this.showCard) {
        this.showCard = false;
      }
      document.removeEventListener('click', this.closeDropDown);
    },
    setTestCardKeyBoardEvent(event,tc){
      event.stopPropagation();
      this.setTestCard(tc);
      if (this.showCard) {
        this.showCard = false;
      }
    },
    validate(name,skipEmpty){
      this.$store.dispatch('validate_field', {
        name: name,
        options: {
          skipEmpty: skipEmpty
        },
        fname: this.pName
      });
    },
    setTestCard(tc){
      let inpMap = {
        "number": tc.number,
        "expiry_year": tc.exp_year,
        "expiry_month": tc.exp_month,
        "cvv": tc.cvv  
      }

      Object.keys(inpMap).forEach(key => {
        if(this.field_config(this.pName, key)) {
          this.validate(key, true);
          this.$store.dispatch('update_form_input', {
            name: key,
            value: inpMap[key],
            formName: this.pName
          });
        }
      });
      if (this.showCard) {
        this.showCard = false;
      }
      this.setCardType(tc.number);
    },
    getCardClass(type) {
      return cardFormatter.getCardClass(type);
    }
  },
}
</script>
