class GstHelper {
	setGstConfig(gst_settings) {
		this.gst_settings = gst_settings;
	}

	isGstMandatory(){
		return this.gst_settings && this.gst_settings.mandatory;
	}

	isGstRequested(){
		return this.gst_settings && this.gst_settings.requested;
	}

	defaultLabel() {
		return this.gst_settings && this.gst_settings.default_label;
	}

}
export default new GstHelper();