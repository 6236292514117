let prefix = "hp_pg_";

export function getRouteKey(routeName) {
  if (routeName == null) return;
  let routeKey;
  Object.keys(routesToTrack).forEach(function (key, index) {
    if(routesToTrack[key].includes(routeName)) {
      routeKey = prefix + key;
      return routeKey;
    }
  });
  return routeKey;
}
export function addPrefixToRoute(route) {
  return prefix + route;
}

export function checkIfEntryEqualsExit(fromRoute, toRoute) {
  return fromRoute == toRoute;
}

export const routesToTrack = {
  'cart': ['cart', 'cart_screen', 'cart_new'],
  'user' : ['check_user'],
  'authenticate': ['authenticate'],
  'customer': ['customer'],
  "billing_address": ['billing_address'],
  'shipping_address': ['shipping_address'],
  'payment_method': ['payment_method'],
  'subscription': ['subscription'],
  'invoice': ['invoice'],
  'review': ['review'],
  "single_step_checkout": ['pay_review'],
  "thank_you": ['thankyou'],
  'choose_payment_method': ['choose_pm'],
  "edit_cart": ['edit_cart_new','edit_order'],
}