import CbError from "@/utils/cb-error"
export default class CBNotification {
  constructor(type, pl) {
    this.type = type;
    this.showIcon = true;
    this.constructMessage(pl);
  }

  constructMessage(pl) {
    if(this.type == "error") {
      this.isDismissible = pl.isDismissible;
      if(pl instanceof CbError) {
        this.message = pl.reviewScreenMessage();
        this.isDismissible = true;
      }
      else {
        this.message = pl.displayMessage || pl.message;
        this.showIcon = false;
      }
      this.errorSource = pl;
    }
  }
}