import apiClient from "@/utils/cb-client"
import SubItemsModel from "@/utils/items-model";
import Vue from 'vue'
import { paymentStep, paymentMethods } from '@/utils/payment-helper';
import PaymentHelper from "@/utils/payment-helper";
const { FIRST_STEP, SECOND_STEP } = paymentStep;

const state = {
  data: {},
  billing_address: {},
  entity_identifiers: [],
  entity_identifiers_hp: [],
  entity_identifiers_combined: [],
  payment_method_list: [],
  master_pm_list: [],
  transient_payment_method: undefined,
  retain_pm: undefined,
  replace_pm: undefined,
  shipping_address_list: [],
  transient_shipping_address: undefined,
  subscriptions: [],
  subscription_next_offset: undefined,
  child_sub_next_offset: undefined,
  gifted_subs_next_offset: undefined,
  loaded: false,
  tmp_storage: {},
  gifted_subscriptions: [],
  child_subscriptions: [],
  hierarchy_accounts: [],
  payment_intent: undefined,
  payment_data: undefined,
  // Flags to remember modifications - used in Bulk API
  modified: {
    data: false,
    billing_address: false,
    payment: false,
  },
  modified_invoice: {
    data: false,
    billing_address: false,
  },
  mandate_step: FIRST_STEP,
  retention_hosted_pages: {},
  
  // collect now
  transactions: [],
  collect_now_txn_max_limit_reached: false,
  paynow_pm_role: undefined
}

const RESET_CUSTOMER_DATA = "RESET_CUSTOMER_DATA";
const SET_CUSTOMER_DATA = "SET_CUSTOMER_DATA";
const UPDATE_CUSTOMER_DATA = "UPDATE_CUSTOMER_DATA";
const SET_SHIPPING_ADDRESS_LIST = "SET_SHIPPING_ADDRESS_LIST";
const SET_PAYMENT_METHOD_LIST = "SET_PAYMENT_METHOD_LIST";
const SET_MASTER_PM_LIST = "SET_MASTER_PM_LIST";
const ADD_SHIPPING_ADDRESS = "ADD_SHIPPING_ADDRESS";
const SET_EMAIL = "SET_EMAIL";
export const ADD_BILLING_ADDRESS = "ADD_BILLING_ADDRESS";
const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
const ADD_TRANSIENT_PAYMENT_METHOD = "ADD_TRANSIENT_PAYMENT_METHOD";
const SET_CARD_ADDITIONAL_INFO = "SET_CARD_ADDITIONAL_INFO";
const SET_RETAIN_PM = "SET_RETAIN_PM";
const SET_REPLACE_PM = "SET_REPLACE_PM";
const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
const UPDATE_SHIPPING_ADDRESS = "UPDATE_SHIPPING_ADDRESS";
const SET_BILLING_ADDRESS_ALREADY_PRESENT = "SET_BILLING_ADDRESS_ALREADY_PRESENT";
const RESET_BILLING_ADDRESS = "RESET_BILLING_ADDRESS";
const SET_TMP_STORAGE = "SET_TMP_STORAGE";
const REMOVE_PAYMENT_SOURCE = "REMOVE_PAYMENT_SOURCE";
const OVERRIDE_CUSTOMER_DATA = "OVERRIDE_CUSTOMER_DATA";
const REPLACE_PRIMARY_PM = "REPLACE_PRIMARY_PM";
const SET_VAT_NUMBER = "SET_VAT_NUMBER";
const SET_VAT_NUMBER_PREFIX = "SET_VAT_NUMBER_PREFIX";
const SET_ENTITY_IDENTIFIER_SCHEME = "SET_ENTITY_IDENTIFIER_SCHEME"; 
const SET_GST = "SET_GST";
const SET_EINVOICE_SCHEMES = "SET_EINVOICE_SCHEMES";
const SET_EINVOICE_SCHEMES_HP = "SET_EINVOICE_SCHEMES_HP";
const SET_EINVOICE_SCHEMES_COMBINED = "SET_EINVOICE_SCHEMES_COMBINED";
const SET_SUBSCRIPTION_OFFSET = "SET_SUBSCRIPTION_OFFSET";
const SET_CHILD_SUBSCRIPTION_OFFSET = "SET_CHILD_SUBSCRIPTION_OFFSET";
const SET_CHILD_SUBSCRIPTION_COUNT = "SET_CHILD_SUBSCRIPTION_COUNT";
const ADD_SUBSCRIPTIONS = "ADD_SUBSCRIPTIONS";
const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
const ADD_BILLING_ADDRESS_EMAIL = "ADD_BILLING_ADDRESS_EMAIL";
const ADD_BILLING_ADDRESS_COUNTRY = "ADD_BILLING_ADDRESS_COUNTRY";
const SET_GIFTED_SUBS_OFFSET = "SET_GIFTED_SUBS_OFFSET";
const ADD_GIFTED_SUBSCRIPTIONS = "ADD_GIFTED_SUBSCRIPTIONS";
const ADD_CHILD_SUBSCRIPTIONS = "ADD_CHILD_SUBSCRIPTIONS";
const SET_CHILD_SUBSCRIPTIONS = "SET_CHILD_SUBSCRIPTIONS";
const SET_HIERARCHY_ACCOUNTS = "SET_HIERARCHY_ACCOUNTS";
const SET_MODIFIED_FLAG = "SET_MODIFIED_FLAG";
const SET_PAYMENT_INTENT = "SET_PAYMENT_INTENT";
const SET_TEMP_PAYMENT_INTENT = "SET_TEMP_PAYMENT_INTENT";
const SET_PAYMENT_DATA = "SET_PAYMENT_DATA";
const SET_MANDATE_STEP = "SET_MANDATE_STEP";
const SET_RETENTION_HOSTED_PAGE = "SET_RETENTION_HOSTED_PAGE";
const REMOVE_RETENTION_HOSTED_PAGE = "REMOVE_RETENTION_HOSTED_PAGE";
const SET_COLLECT_NOW_TRANSACTIONS = "SET_COLLECT_NOW_TRANSACTIONS";
const SET_COLLECT_NOW_TXN_MAX_LIMIT_REACHED = "SET_COLLECT_NOW_TXN_MAX_LIMIT_REACHED";
const SET_NON_SMART_ROUTING_GW_CONFIG = "SET_NON_SMART_ROUTING_GW_CONFIG";
const SET_PAYNOW_PM_ROLE = "SET_PAYNOW_PM_ROLE";

const isSupportedPaymentSource = (hpType, ps) => {
  // we don't add unsupported payment source to the pm_list so, we can expect undefined here.
  return !(hpType != 'portal' && (!ps || ['razorpay'].includes(ps.gateway))) && PaymentHelper.isSupportedPayment(hpType, ps);
}

function setGWConfig(commit, getters, pm_source_list = []) {
  const config = PaymentHelper.loadGatewayConfig(getters, pm_source_list);
  if(!config.hasGw) {
    const payload = { gateway_account_id: config.cardPm.gateway_account_id, payment_method_type: config.cardPm.type}
    return apiClient.gateway.retrieve_gw_payment_method_config({
      hp_token: getters.hpToken
    }, {
      gateway_account_id: payload.gateway_account_id,
      currency_code: getters.currency_code || getters?.subscription_data?.preferred_currency_code || "USD",
      payment_method_type: payload.payment_method_type
    })
    .then(data => {
      if (data?.pm_list) {
        commit(SET_NON_SMART_ROUTING_GW_CONFIG, { [payload.gateway_account_id] : data.pm_list[0] });
      }
    })
    .catch(error => {
      logger.e(error)
    });
  }
}

const actions = {
  load_customer({commit, state, dispatch, getters, rootState}, options) {
    options = options || {}
    var promises = [];
    return apiClient.customers.get({id: rootState.auth.customer_handle}).then(data => {
      if(!options.skipBillingAddress) {
        if(data.customer.billing_address && Object.keys(data.customer.billing_address).length > 0) {
          if(!(getters.hosted_page_type == 'checkout' && 
                window?.hpData?.content.billing_address && 
                Object.keys(window.hpData.content.billing_address).length > 0)) {
            commit(ADD_BILLING_ADDRESS, data.customer.billing_address);
          }
          else{
            dispatch('set_billing_address_modified');
          }
          commit(SET_BILLING_ADDRESS_ALREADY_PRESENT);
          delete data.customer.billing_address;
        } else {
          dispatch('reset_billing_address')
        }
      }
      commit(SET_CUSTOMER_DATA, data.customer);
      dispatch('set_einvoice_schemes', data.customer.entity_identifiers);
      if(data.customer.campaign_details) {
        dispatch('set_campaign_details', data.customer.campaign_details);
        delete data.customer.campaign_details;
      }
      if(data.customer.locale && ((data.customer.locale != rootState.config.init_info_locale) ||(data.customer.locale != state.configuration_locale))){
        promises.push(dispatch('resync_texts',data));        
      }
      if(!options.skipResyncPmList && rootState.auth.hpToken) {
        promises.push(dispatch('resync_pm_list'));
      }
      if(promises.length > 0) {
        return Promise.all(promises);
      }
    });
  },

  // Should not get called for gift checkout
  update_customer({commit, state, dispatch, getters, rootState}, {data, skipBillingAddress}) {
    if(getters.hosted_page_type == "portal") {
      var promises = [];
      return apiClient.customers.update({id: rootState.auth.customer_handle, hp_token: data.hp_token}, data).then(data => {
        if(!skipBillingAddress) {
          commit(ADD_BILLING_ADDRESS, data.customer.billing_address);
          delete data.customer.billing_address;
        }
        commit(SET_CUSTOMER_DATA, data.customer);
        if(data.customer.locale && ((data.customer.locale != rootState.config.init_info_locale) ||(data.customer.locale != state.configuration_locale))){
          promises.push(dispatch('resync_texts',data));
        }
        if(rootState.auth.hpToken) {
          promises.push(dispatch('resync_pm_list'));
        }
        if(promises.length > 0) {
          return Promise.all(promises);
        }
      });
    } else {
      return Promise.resolve().then(() => {
        Object.keys(data).map(name => {
          const value = data[name]
          commit(UPDATE_CUSTOMER_DATA, {name, value});
        })
        commit(SET_MODIFIED_FLAG, 'data')
      })
    }
  },

  set_non_smart_routing_gw_config({commit, state, rootGetters}, pm_source_list) {
    setGWConfig(commit, rootGetters, pm_source_list);
  },

  init_returning_user({commit, state, getters, dispatch}, options) {
    var promises = [];
    options = options || {};
    if(!options.skipPaymentMethodList) {
      promises.push(dispatch('load_payment_method_list'))
    }

    if(!options.skipCustomerDetails) {
      promises.push(dispatch('load_customer', options));
    }

    if(!options.skipShippingAddressList) {
      // with shipping address list, subscriptions will also be loaded
      promises.push(dispatch('load_shipping_address_list'));
    }
    if(options.loadRelations) {
      promises.push(dispatch('load_relations'));
    }
    if(options.getGiftSubscriptions) {
      promises.push(dispatch('load_gifted_subscriptions'));
    }

    return Promise.all(promises).then(() => {
      !options.skipPaymentMethodList && dispatch('check_and_load_primary_pm');
    });
  },

  load_gifted_subscriptions({commit, state, rootState}) {
    return apiClient.gifts.list({id: rootState.auth.customer_handle}, {
      "gifter[customer_id][is]": rootState.auth.customer_handle,
      "limit": "5",
      "sort_by[desc]": "created_at",
      "offset": state.gifted_subs_next_offset
    }).then(data => {
      commit(SET_GIFTED_SUBS_OFFSET, data.next_offset);
      commit(ADD_GIFTED_SUBSCRIPTIONS,data.list);
    });
  },

  load_shipping_address_list({commit, state, rootState, dispatch}) {
    return apiClient.customers.shipping_address_list({id: rootState.auth.customer_handle}, {
      "customer_id[is]": rootState.auth.customer_handle,
      "limit": "5",
      "sort_by[desc]": "created_at",
      "offset": state.subscription_next_offset
    }).then(data => {
      commit(SET_SUBSCRIPTIONS, data.list.map(({subscription}) => {
        return subscription;
      }));
      commit(SET_SUBSCRIPTION_OFFSET,data.next_offset);
      commit(SET_SHIPPING_ADDRESS_LIST, data.list.map(({subscription}) => {
        let shipping_address = subscription.shipping_address;
        if(shipping_address) {
          shipping_address.subscriptionId = subscription.id;
        }
        return shipping_address;
      }).filter(i => !!i));
    });
  },

  load_relations({commit, state, rootState, dispatch}, load_more) {
    return apiClient.customers.fetch_relations({}, {
      "customer_id": rootState.auth.customer_handle,
      "limit": "5",
      "sort_by[desc]": "created_at",
      "offset": rootState.customer.child_sub_next_offset
    }).then((data) => {
      if(data.accounts && data.child_subscriptions) {
        if(!load_more) {
          commit(SET_HIERARCHY_ACCOUNTS, data.accounts);
        }
        commit(ADD_CHILD_SUBSCRIPTIONS, data.child_subscriptions.list);
        commit(SET_CHILD_SUBSCRIPTION_OFFSET, data.child_subscriptions.next_offset);
        commit(SET_CHILD_SUBSCRIPTION_COUNT, data.child_subscriptions.count || data.child_subscriptions.list.length);
        
        let itemPriceIds = []
        if(data.child_subscriptions && data.child_subscriptions.list){
        data.child_subscriptions.list.filter(({subscription})=>subscription.is_items_model).map(({subscription})=>{
          subscription.subscription_items.filter(itemPrice => itemPriceIds.push(itemPrice.item_price_id))
        })
        }
        itemPriceIds = Array.from(new Set(itemPriceIds))

        const promiseList = []
        if(itemPriceIds.length) {
          promiseList.push(dispatch('fetch_item_prices', itemPriceIds))
        }

        if(load_more) {
          var _sub = data.child_subscriptions.list.map(({subscription}) => subscription);
          promiseList.push(Promise.all([
            dispatch('fetch_recurring_estimate_for_given_child_subs', _sub),
            dispatch('fetch_plan_details_of_subscriptions', _sub),
          ]).then(() => {
            dispatch('link_child_sub_data_details');
          }))
        }
        return promiseList
      }
    }).catch(error => {
      console.log(error);
      logger.e(error)
      return Promise.reject(true);
    });
  },

  
  load_more_relations({dispatch}) {
    dispatch("load_relations", true);
  },

  load_more_subscriptions({commit, state, rootState,dispatch}) {
    apiClient.customers.shipping_address_list({id: rootState.auth.customer_handle}, {
      "customer_id[is]": rootState.auth.customer_handle,
      "limit": "5",
      "sort_by[desc]": "created_at",
      "offset": rootState.customer.subscription_next_offset
    }).then((data)=>{
      var reqList= (data.list.filter((sub)=> state.subscriptions.filter(subscription => subscription.id == sub.subscription.id).length == 0));
      commit(ADD_SUBSCRIPTIONS,reqList);
      commit(SET_SUBSCRIPTION_OFFSET,data.next_offset);
      var _sub = reqList.map(({subscription}) => subscription);
      Promise.all([
        dispatch('fetch_recurring_estimate_for_subs', _sub),
        dispatch('fetch_plan_details_of_subscriptions', _sub),
        dispatch('fetch_gift_data_for_subs', _sub)
      ]).then(() => {
        dispatch('link_sub_data_details')
      });
    }).catch(error => {
      console.log(error);
      reject(true);
      logger.e(error)
    });
  },

  load_subscription({commit, state, rootState, dispatch}, subId) {
    return apiClient.subscriptions.get({id: subId}).then(data => {
      commit(ADD_SUBSCRIPTION, data.subscription);
      dispatch('load_sub_data', data.subscription);
    });
  },

  update_subscription({commit, state, rootState, getters}, subscription) {
    commit(UPDATE_SUBSCRIPTION, subscription);
    let shipping_address = subscription.shipping_address;
    let self = (getters.account_hierarchy_enabled) ? getters.getCustomerRole(subscription.customer_id) == "self" : true;
    if(shipping_address && self){
      shipping_address.subscriptionId = subscription.id;
      commit(UPDATE_SHIPPING_ADDRESS, shipping_address);
    }
  },

  load_payment_method_list({commit, state, rootState, rootGetters }) {
    return apiClient.customers.payment_method_list({id: rootState.auth.customer_handle},
      {
      "customer_id[is]": rootState.auth.customer_handle,
      "limit": "10",
      "sort_by[desc]": "created_at"
    }).then(data => {
      data.list = data.list.filter(e => isSupportedPaymentSource(rootGetters.hp_type || rootGetters.hosted_page_type, e.payment_source));
      commit(SET_PAYMENT_METHOD_LIST, data.list.map(({payment_source}) => payment_source));
      commit(SET_MASTER_PM_LIST, data.list.map(({payment_source}) => payment_source));
      setGWConfig(commit, rootGetters, data.list.map(({payment_source}) => payment_source))
    });
  },

  check_and_load_primary_pm({commit, state, rootState, getters, dispatch}) {
    if(!getters.primary_pm && state.data.primary_payment_source_id) {
      dispatch('load_payment_method', state.data.primary_payment_source_id);
    }
  },

  update_customer_payment_roles({commit}, customer) {
      commit(UPDATE_CUSTOMER_DATA, {name:'primary_payment_source_id', value: customer['primary_payment_source_id']});
      commit(UPDATE_CUSTOMER_DATA, {name:'backup_payment_source_id', value: customer['backup_payment_source_id']})
  },

  load_payment_method({commit, state, rootGetters }, paymentSourceId) {
    return apiClient.payment_sources.get({id: paymentSourceId}).then(data => {
      if(isSupportedPaymentSource(rootGetters.hp_type || rootGetters.hosted_page_type, data.payment_source)) {
        commit(ADD_PAYMENT_METHOD, data.payment_source);
        setGWConfig(commit, rootGetters, [data.payment_source])
      }
    });
  },

  add_shipping_address({commit, state}, shipping_address) {
    if(shipping_address && Object.keys(shipping_address).length > 0){
      commit(ADD_SHIPPING_ADDRESS, shipping_address);
    }
  },

  reset_billing_address({commit, state, dispatch}) {
    commit(RESET_BILLING_ADDRESS);
    commit(SET_BILLING_ADDRESS_ALREADY_PRESENT, false);
  },

  add_billing_address({commit, state, dispatch}, pl) {
    if(pl.data && Object.keys(pl.data).length > 0) {
      /**
       * Return if all the fields are empty
       * TODO: to check whether values can be reset by submitting empty values
       */
      let validAddress = {}
      for(let key in pl.data) {
        const value = pl.data[key]
        if (value && value !== '') {
          validAddress[key] = value
        }
      }
      if (Object.keys(validAddress).length <= 0) {
        return pl.resolve()
      }

      let old = state.billing_address;
      if(pl.data && Object.keys(pl.data).length > 0) {
        commit(ADD_BILLING_ADDRESS, pl.data);
      }
      if(!pl.skip_est_calc) {
        let promise = new Promise((resolve, reject) => {
          dispatch('calculate_estimate', { resolve, reject});
        }).then(data => pl.resolve && pl.resolve(data)).catch(error => {
          if(pl.reject) {
            commit(ADD_BILLING_ADDRESS, old);
            pl.reject(error);
          }
        });
      }
      commit(SET_MODIFIED_FLAG, 'billing_address');
    }
  },

  set_billing_address_modified({commit, state, dispatch}) {
    commit(SET_MODIFIED_FLAG, 'billing_address');
  },

  add_billing_address_email({commit, state, dispatch}, email) {
    commit(ADD_BILLING_ADDRESS_EMAIL, email);
  },

  add_billing_address_country({commit, state, dispatch}, country) {
    commit(ADD_BILLING_ADDRESS_COUNTRY, country);
  },

  // Should not get called for gift checkout
  add_customer_data({commit, state}, data) {
    // For any sub action, We update our customer object so we should have check to ignore child sub action.
    if(data && (!state.data.id || state.data.id == data.id)) {
      commit(SET_CUSTOMER_DATA, data);
    }
  },

  // Should not get called for gift checkout
  override_customer_data({commit, state}, data) {
    commit(OVERRIDE_CUSTOMER_DATA, data);
  },

  add_payment_method({commit, state, getters}, {pm, replacePrimary}) {
    if(replacePrimary || (state.payment_method_list.length > 0 && !getters.mpm_supported)) {
      // replace primary payment method
      commit(REPLACE_PRIMARY_PM, pm);
    }
    else {
      commit(ADD_PAYMENT_METHOD, pm);
    }
  },

  remove_payment_source({commit, state, dispatch}, {pmId, customer}) {
    commit(REMOVE_PAYMENT_SOURCE, pmId);
    dispatch('update_customer_payment_roles', customer);
  },

  add_transient_payment_method({commit, state}, transient_payment_method) {
    commit(ADD_TRANSIENT_PAYMENT_METHOD, transient_payment_method);
    commit(SET_MODIFIED_FLAG, 'payment');
  },

  set_card_additional_info({commit, state}, additionalInfo) {
    commit(SET_CARD_ADDITIONAL_INFO, additionalInfo)
  },

  set_retain_pm({commit, state}, retain) {
    commit(SET_RETAIN_PM, retain);
  },

  set_replace_pm({commit, state}, replace) {
    commit(SET_REPLACE_PM, replace);
  },

  set_email({commit, state}, email) {
    commit(SET_EMAIL, email);
  },

  set_tmp_storage({commit, state}, tmp) {
    commit(SET_TMP_STORAGE, tmp);
  },

  // This should not have been present here
  validate_vat({commit, state}, {vat_number, vat_number_prefix, billing_country_code}) {
    return apiClient.vat_service.validate(
      {},
      {
        "customer[vat_number]": vat_number,
        "customer[vat_number_prefix]": vat_number_prefix,
        "credit_card.billing_country": billing_country_code,
        from_hp_v3: true,
      }
    );
  },
  
  validate_po({commit, state}, po_number) {
    return apiClient.po_number.validate(
      {},
      {
        po_number: po_number,
      }
    );
  },

  set_vat_number({commit, state}, vatNumber) {
    commit(SET_VAT_NUMBER, vatNumber);
  },

  set_vat_number_prefix({ commit, state }, vatNumberPrefix) {
    commit(SET_VAT_NUMBER_PREFIX, vatNumberPrefix);
  },

  set_entity_identifier_scheme({commit, state}, entity_identifier_scheme) {
    commit(SET_ENTITY_IDENTIFIER_SCHEME, entity_identifier_scheme);
  },

  set_gst({commit,state},registered_for_gst){
    commit(SET_GST,registered_for_gst);
  },

  set_einvoice_schemes({ commit, state }, einvoiceSchemes) {
    commit(SET_EINVOICE_SCHEMES, einvoiceSchemes);
  },

  set_einvoice_schemes_hp({ commit, state }, einvoiceSchemesHP) {
    commit(SET_EINVOICE_SCHEMES_HP, einvoiceSchemesHP);
  },

  set_einvoice_schemes_combined({ commit, state }, einvoiceSchemesCombined) {
    commit(SET_EINVOICE_SCHEMES_COMBINED, einvoiceSchemesCombined);
  },

  add_subscription({commit,state}, subscription){
    commit(ADD_SUBSCRIPTION, subscription);
  },

  // Should be called for gift checkout to update consent
  update_customer_data({commit,state}, { name, value }){
    commit(SET_MODIFIED_FLAG, 'data')
    commit(UPDATE_CUSTOMER_DATA, {name,value});
  },

  set_modified_flag({commit, state}, key) {
    commit(SET_MODIFIED_FLAG, key)
  },

  set_payment_intent({commit}, payment_intent) {
    commit(SET_PAYMENT_INTENT, payment_intent);
  },

  set_temp_payment_intent({commit}, payment_intent) {
    commit(SET_TEMP_PAYMENT_INTENT, payment_intent);
  },

  set_payment_data({commit}, paymentData) {
    commit(SET_PAYMENT_DATA, paymentData);
  },

  set_mandate_step({commit, state}, step) {
    commit(SET_MANDATE_STEP, step);
  },

  set_retention_hosted_page({commit}, data) {
    commit(SET_RETENTION_HOSTED_PAGE, data)
  },
  set_collect_now_transactions({commit, rootGetters}, txns) {
    commit(SET_COLLECT_NOW_TRANSACTIONS, txns)
    
    const transactionsMaxLimit = rootGetters.collect_now_txn_max_limit;
    if(transactionsMaxLimit && txns && txns.length >= transactionsMaxLimit) {
      commit(SET_COLLECT_NOW_TXN_MAX_LIMIT_REACHED, true)
    }
  },
  remove_retention_hosted_page({commit}, data) {
    commit(REMOVE_RETENTION_HOSTED_PAGE, data)
  },
  set_paynow_pm_role({commit}, paynow_pm_role) {
    commit(SET_PAYNOW_PM_ROLE, paynow_pm_role)
  }
}

const mutations = {
  [RESET_CUSTOMER_DATA](state) {
    const KEEP_IF_EXISTS = ["email"];
    Object.keys(state.data).forEach((key) => {
      return KEEP_IF_EXISTS.includes(key) || Vue.delete(state.data, key)
    });
  },
  [SET_CUSTOMER_DATA](state, details) {
    // const {vat_number, registered_for_gst} = state.data;
    // Vue.set(state, 'data', details);
    // Vue.set(state.data, 'vat_number', vat_number);
    // Vue.set(state.data, 'registered_for_gst', registered_for_gst);
    /* omitting entity_identifiers from this to avoid Bulk-Api array parse error at BE */
    Object.keys(details).forEach((key) => { 
      if (key != "entity_identifiers") 
        Vue.set(state.data, key, details[key])
    });
    Vue.set(state, 'loaded', true);
  },
  [UPDATE_CUSTOMER_DATA](state, {name, value}) {
    Vue.set(state.data, name, value);
  },
  [SET_EMAIL](state, email) {
    Vue.set(state.data, 'email', email);
  },
  [SET_SHIPPING_ADDRESS_LIST](state, shipping_address_list) {
    Vue.set(state, 'shipping_address_list', shipping_address_list);
  },
  [SET_PAYMENT_METHOD_LIST](state, payment_method_list) {
    Vue.set(state, 'payment_method_list', payment_method_list);
  },
  [SET_MASTER_PM_LIST](state, payment_method_list) {
    Vue.set(state, 'master_pm_list', payment_method_list);
  },
  [REPLACE_PRIMARY_PM](state, payment_method) {
    var index = state.payment_method_list.findIndex(o => o.id == state.data.primary_payment_source_id);
    if(index != -1) {
      Vue.set(state.payment_method_list, index, payment_method);
    }
  },
  [ADD_SHIPPING_ADDRESS](state, shipping_address) {
    Vue.set(state, 'transient_shipping_address', shipping_address);
  },
  [RESET_BILLING_ADDRESS](state) {
    Vue.set(state, 'billing_address', {});
  },
  [ADD_BILLING_ADDRESS](state, billing_address) {
    Vue.set(state, 'billing_address', billing_address);
  },
  [ADD_BILLING_ADDRESS_EMAIL](state, billing_address_email) {
    Vue.set(state.billing_address, 'email', billing_address_email);
  },
  [ADD_BILLING_ADDRESS_COUNTRY](state, billing_address_country) {
    Vue.set(state.billing_address, 'country', billing_address_country);
  },
  [ADD_PAYMENT_METHOD](state, payment_method) {
    let index = state.payment_method_list.findIndex(pm => !pm.transient && pm.id == payment_method.id);
    if(index == -1) {
      /**
       PS: Only one transient payment method is allowed to exist (Bulk Api)
       Check if transient payment method already exists, if so replace it
       */
      const transient_pm_index = state.payment_method_list.findIndex(pm => pm.transient);
      const has_transient_pm = !!~transient_pm_index;
      if(has_transient_pm) {
        state.payment_method_list[transient_pm_index] = payment_method;
      } else {
        state.payment_method_list.push(payment_method);
      }
    }
  },
  [ADD_TRANSIENT_PAYMENT_METHOD](state, transient_payment_method) {
    Vue.set(state, 'transient_payment_method', transient_payment_method);
  },
  [SET_CARD_ADDITIONAL_INFO](state, additionalInfo) {
    Vue.set(state, 'card_additional_info', additionalInfo);
  },
  [SET_RETAIN_PM](state, retain) {
    Vue.set(state, 'retain_pm', retain);
  },
  [SET_REPLACE_PM](state, retain) {
    Vue.set(state, 'replace_pm', retain);
  },
  [SET_SUBSCRIPTIONS] (state, subscriptions) {
    Vue.set(state, "subscriptions", subscriptions);
  },
  [SET_CHILD_SUBSCRIPTIONS] (state, child_subscriptions) {
    Vue.set(state, "child_subscriptions", child_subscriptions || []);
  },
  [SET_HIERARCHY_ACCOUNTS] (state, accounts) {
    Vue.set(state, "hierarchy_accounts", accounts || []);
  },
  [ADD_GIFTED_SUBSCRIPTIONS] (state,gifts) {
    gifts.forEach(item => {
      var index = state.gifted_subscriptions.findIndex(o => o.gift.id == item.gift.id);
      if(index == -1){
        state.gifted_subscriptions.push(item);
      }
    });
  },
  [ADD_SUBSCRIPTIONS] (state, subscriptions) {
    subscriptions.forEach(listItem => {
      var index = state.subscriptions.findIndex(o => o.id == listItem.subscription.id);
      if(index == -1){
        state.subscriptions.push(listItem.subscription);
      }
    });
  },
  [SET_SUBSCRIPTION_OFFSET] (state, next_offset) {
    Vue.set(state, "subscription_next_offset" , next_offset);
  },
  [ADD_CHILD_SUBSCRIPTIONS] (state, child_subscriptions) {
    child_subscriptions.forEach(listItem => {
      var index = state.child_subscriptions.findIndex(o => o.id == listItem.subscription.id);
      if(index == -1){
        state.child_subscriptions.push(listItem.subscription);
      }
    });
  },
  [SET_CHILD_SUBSCRIPTION_OFFSET] (state, next_offset) {
    Vue.set(state, "child_sub_next_offset" , next_offset);
  },
  [SET_CHILD_SUBSCRIPTION_COUNT] (state, count) {
    Vue.set(state, "child_sub_count" , count);
  },
  [SET_GIFTED_SUBS_OFFSET] (state, next_offset) {
    Vue.set(state, "gifted_subs_next_offset" , next_offset);
  },
  [UPDATE_SUBSCRIPTION] (state, subscription) {
    var index = state.subscriptions.findIndex(o => o.id == subscription.id);
    if(index != -1) {
      Vue.set(state.subscriptions, index, subscription);
    }
  },
  [UPDATE_SHIPPING_ADDRESS] (state, shipping_address) {
    var index = state.shipping_address_list.findIndex(o => o.subscriptionId == shipping_address.subscriptionId);
    if(index != -1) {
      Vue.set(state.shipping_address_list, index, shipping_address);
    }
    else {
      state.shipping_address_list.push(shipping_address);
    }
  },

  [SET_BILLING_ADDRESS_ALREADY_PRESENT](state, isPresent = true) {
    Vue.set(state, 'billing_address_already_present', isPresent);
  },

  [SET_TMP_STORAGE](state, {key, value}) {
    Vue.set(state.tmp_storage, key, value);
  },

  [REMOVE_PAYMENT_SOURCE] (state, pmId) {
    let index = state.payment_method_list.findIndex(pm => pm.id == pmId);
    state.payment_method_list.splice(index, 1);
  },

  [OVERRIDE_CUSTOMER_DATA] (state, data) {
    Object.keys(data).forEach((key) => {
      Vue.set(state.data, key, data[key]);
    });
    Vue.set(state, 'data_overridden_from_hp', true);
  },


  [SET_VAT_NUMBER] (state, vat_number) {
    Vue.set(state.data, 'vat_number', vat_number);
  },

  [SET_VAT_NUMBER_PREFIX](state, vat_number_prefix) {
    Vue.set(state.data, "vat_number_prefix", vat_number_prefix);
  },

  [SET_ENTITY_IDENTIFIER_SCHEME] (state, entity_identifier_scheme) {
    Vue.set(state.data, "entity_identifier_scheme", entity_identifier_scheme);
  },

  [SET_GST] (state,registered_for_gst) {
    Vue.set(state.data, 'registered_for_gst', registered_for_gst);
  },

  [SET_EINVOICE_SCHEMES] (state, entity_identifiers) {
    Vue.set(state, 'entity_identifiers', entity_identifiers);
  }, 

  [SET_EINVOICE_SCHEMES_HP] (state, entity_identifiers_hp) {
    Vue.set(state, 'entity_identifiers_hp', entity_identifiers_hp);
  },

  [SET_EINVOICE_SCHEMES_COMBINED] (state, entity_identifiers_combined) {
    Vue.set(state, 'entity_identifiers_combined', entity_identifiers_combined);
  },

  [ADD_SUBSCRIPTION] (state, subscription) {
    state.subscriptions.push(subscription);
  },

  [SET_MODIFIED_FLAG] (state, key) {
    Vue.set(state.modified, key, true)
  },

  [SET_PAYMENT_INTENT](state, payment_intent) {
    Vue.set(state, 'payment_intent', payment_intent);
  },

  [SET_TEMP_PAYMENT_INTENT](state, payment_intent) {
    Vue.set(state, 'temp_payment_intent', payment_intent);
  },

  [SET_PAYMENT_DATA](state, payment_data) {
    Vue.set(state, 'payment_data', payment_data);
  },

  [SET_MANDATE_STEP](state, step) {
    Vue.set(state, 'mandate_step', step);
  },
  [SET_RETENTION_HOSTED_PAGE](state, data) {
    Vue.set(state.retention_hosted_pages, data.id, data.retentionHostedPage)
  },
  [SET_COLLECT_NOW_TRANSACTIONS](state, transactions) {
    Vue.set(state, 'transactions', transactions)
  },
  [SET_COLLECT_NOW_TXN_MAX_LIMIT_REACHED](state, value) {
    Vue.set(state, 'collect_now_txn_max_limit_reached', value)
  },
  [REMOVE_RETENTION_HOSTED_PAGE](state, id) {
    Vue.delete(state.retention_hosted_pages, id)
  },
  [SET_NON_SMART_ROUTING_GW_CONFIG] (state, non_smart_routing_gw_config) {
    Vue.set(state, 'non_smart_routing_gw_config', non_smart_routing_gw_config);
  },
  [SET_PAYNOW_PM_ROLE] (state, paynow_pm_role) {
    Vue.set(state, 'paynow_pm_role', paynow_pm_role);
  }
}


const getters = {
  customer_loaded: state => state.loaded,
  customer_id: state => state.data && state.data.id,
  shipping_address_list: state => state.shipping_address_list,
  transient_shipping_address: state => state.transient_shipping_address,
  total_shipping_address_list: state => state.shipping_address_list.concat(state.transient_shipping_address),
  payment_method_list: state => state.payment_method_list,
  master_pm_list: state => state.master_pm_list,
  has_transient_pm: state => state.payment_method_list.some(pm => pm.transient),
  subscription_count: state => state.subscriptions.length,
  subscriptions: state => state.subscriptions,
  supported_primary_pm: (state, getters, rootState, rootGetters) => isSupportedPaymentSource(rootGetters.hp_type, getters.primary_pm),
  primary_pm: state => state.payment_method_list.filter(pm => pm.id == state.data.primary_payment_source_id)[0],
  transient_payment_method: state => state.transient_payment_method,
  card_additional_info: state => state.card_additional_info,
  retain_pm: state => state.retain_pm,
  replace_pm: state => state.replace_pm,
  backup_pm: state => state.payment_method_list.filter(pm => pm.id == state.data.backup_payment_source_id)[0],
  is_primary_pm: (state, getters) => (pmId) => getters.primary_pm && (getters.primary_pm.id == pmId),
  is_backup_pm: (state, getters) => (pmId) => getters.backup_pm && (getters.backup_pm.id == pmId),
  fetch_pm: state => pmId => state.payment_method_list.filter(pm => pm.id == pmId)[0],
  billing_address_already_present: state => state.billing_address_already_present,
  tmp_storage_get: state => key => state.tmp_storage[key],
  customer_email: state => state.data && state.data.email,
  data_overridden_from_hp: state => state.data_overridden_from_hp,
  customer_data: state => state.data,
  customer_autocollection_off: state => state.data.auto_collection == "off",
  account_hierarchy_enabled: state => state.hierarchy_accounts && state.hierarchy_accounts.length > 0,
  getCustomer: state => (handle) => state.hierarchy_accounts.filter(account => account.customer_id == handle)[0],
  getCustomerDisplayName: (state, getters) => (handle)  => {
    let acc = getters.getCustomer(handle);
    if(!acc) {
      return '#' + handle;
    }
    return acc.customer_title;
  },
  customerHasRelatedPaymentOwner: (state, getters) => (handle) => {
    let acc = getters.getCustomer(handle);
    return acc && acc.payment_owner_id != acc.customer_id;

  },
  getPayOwnerIdOfCustomer: (state, getters) => (handle) => {
    let acc = getters.getCustomer(handle);
    return acc && acc.payment_owner_id;
  },
  customerHasRelatedInvoiceOwner: (state, getters) => (handle) => {
    let acc = getters.getCustomer(handle);
    return acc && acc.invoice_owner_id != acc.customer_id;
  },
  getInvoiceOwnerIdOfCustomer: (state, getters) => (handle) => {
    let acc = getters.getCustomer(handle);
    return acc && acc.invoice_owner_id;
  },
  subscriptionPaidByMe: (state, getters) => (subData) => {
    return  subData.subscription.override_relationship == true ||
    (getters.account_hierarchy_enabled && getters.getPayOwnerIdOfCustomer(subData.customerId()) == getters.customer_id);
  },
  subscriptionInvoicedToMe: (state, getters) => (subData) => {
    return subData.subscription.override_relationship == true ||
    (getters.account_hierarchy_enabled &&  getters.getInvoiceOwnerIdOfCustomer(subData.customerId()) == getters.customer_id);
  },
  childSubscriptionPaidByMe: (state, getters) => (subData) => {
    return (getters.account_hierarchy_enabled && getters.getPayOwnerIdOfCustomer(subData.customerId()) == getters.customer_id);
  },
  childSubscriptionInvoicedToMe: (state, getters) => (subData) => {
    return (getters.account_hierarchy_enabled &&  getters.getInvoiceOwnerIdOfCustomer(subData.customerId()) == getters.customer_id);
  },
  customerHasParent: (state, getters) => (handle) => {
    let acc = getters.getCustomer(handle);
    return acc && !!acc.parent_id;
  },
  getCustomerRole: (state, getters) => (handle) => {
    let acc = getters.getCustomer(handle);
    return acc && acc.role;
  },
  getChildCustomers: state => state.hierarchy_accounts.filter(account => account.role == 'child'),
  payment_intent: (state) => state.payment_intent,
  payment_data: (state) => state.payment_data,
  child_subscription_count: (state) => state.child_sub_count,
  mandate_step: (state) => state.mandate_step,
  getRetentionHostedPage: (state) => (key) => state.retention_hosted_pages[key] || {},
  einvoice_schemes: (state) => state.entity_identifiers,
  transactions: (state) => state.transactions || [],
  collect_now_txn_max_limit_reached: (state) => state.collect_now_txn_max_limit_reached,
  non_smart_routing_gw_config: state => state.non_smart_routing_gw_config,
  paynow_pm_role: state => state.paynow_pm_role
}

export default {
  state,
  actions,
  mutations,
  getters
}
