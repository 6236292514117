import TraceKit from "tracekit"
export default {
  install: function(Vue) {
    var _oldOnError = Vue.config.errorHandler;
    Vue.config.errorHandler = function(error, vm, info){
      // Tracekit is rethrowing exception
      try{
        TraceKit.report(error);
      }
      catch(error) {

      }
      if (typeof _oldOnError === 'function') {
        _oldOnError.call(this, error, vm, info);
      }
      console.error(error);
    }
  }
}