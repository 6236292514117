<template>
  <div class="cb-field cb-field--labelTransform" :class="[{'cb-field--active': (internalValue || focussed || isDate)}, customClass,{'cb-field--error':(!!errorMessage && !focussed)},{'cb-field--sibling':siblingError},{'cb-field--focus': focussed && !readonly},{'cb-field--select-alt': this.config.meta.help_text}]">
    <input ref="input" :type="getType" @click="focus($event)" :value="internalValue" class="cb-field__control" :readonly="readonly" :placeholder="isDate && placeholderText" @focus="focus($event)" @blur="blur($event)" @input="input($event)" @keyup.up="navigate($event)" @keyup.down="navigate($event)" @keydown="keydown($event)" @keyup="keyup($event)" @keypress="checkKeypress($event)" @paste="checkPaste($event)" :autocomplete="autocompleteMapping" :name='autocompleteMapping ? autocompleteMapping : name' :aria-disabled="readonly" :disabled="readonly" :class="{'cb-field__readonly':readonly}" :aria-required='required' :id="name" :aria-autocomplete="enableScreenReader && 'inline'" :aria-describedby="getAriaDescribedBy" step="any" :spellcheck="getSpellCheck" />
    <label class="cb-field__label" :class="{'cb-field__label--s': isShort}" :for='name'> {{displayLabel}}</label>
    <slot></slot>
    <div class="cb-field__help" v-if="this.config.meta.help_text">
      <template>
        <div class="cb-field__trigger" :class="{'active-opacity': helpTextShown}" @click="showHelpText($event)" ref="helpText">
        </div>
        <transition name="help-text">
          <div v-if="name === 'cvv'" class="cb-field__menu" v-show="helpTextShown" role="tooltip" id="cb-field__help-text">
            {{this.config.meta.help_text}}
          </div>
          <div v-else class="cb-field__menu" v-show="helpTextShown">
            {{this.config.meta.help_text}}
          </div>
        </transition>
      </template>
    </div>
    <div class="cb-field__error" v-if="errorMessageWrapper && !focussed">
      {{errorMessageWrapper}}
    </div>
    <div id="initInstr" class="no-display">When autocomplete results are available use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.</div>
  </div>
</template>
<script>
import AutoCompleteMapping from "@/utils/auto-complete-mapping.js"
import FieldMixin from "@/mixins/field-mixin"
// TODO move somewhere
var isJsonString = function(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export default {
  name: 'text-field',
  props: ['name', 'customClass', 'useparent', 'gid', 'siblingError', 'type'],
  created() {
    this.pName = this.findParentForm();
  },
  mixins: [FieldMixin],
  data() {
    return {
    }
  },
  methods: {
    showHelpText(event) {
      if (this.helpTextShown) {
        // remove event listener
        document.removeEventListener('click', this.closeHelpText);
      } else {
        window.cbTimeout(function() {
          document.addEventListener('click', this.closeHelpText);
        }.bind(this));
      }
      this.helpTextShown = !this.helpTextShown;
    },
    closeHelpText(event) {
      var helpTextElement = this.$refs.helpText;
      if (event.target != helpTextElement) {
        document.removeEventListener('click', this.closeHelpText);
        this.helpTextShown = false;
      }
    },
    click(event){
      if(this.type == "card"){
        this.$emit('click');
      }
    },
    focus(event) {
      this.focussed = true;
      this.$emit("clearError");
      this.$emit("focus", event);
      this.$store.dispatch('focus_field', {
        formName: this.pName, 
        fieldName: this.name
      })
      if (!this.helpTextShown) {
        window.cbTimeout(function() {
          this.helpTextShown = true;
        }.bind(this), 0);
      }
    },
    input(event) {
      var tmp = event.target.value;
      this.$store.dispatch('update_form_input', {
        name: this.name,
        value: tmp,
        formName: this.pName
      });
    },
    keyup(event) {
      this.$emit('keyup', this.internalValue);
    },
    keydown(event) {
      if (event.which === 38 || event.which === 40) {
        event.preventDefault();
    }
    },
    navigate(event) {
      this.$emit('navigate');
    },
    checkKeypress(event){
      return this.getType === 'number' ? this.validateKeypress(event) :null; 
    },
    checkPaste(event){
      return this.getType === 'number' ? this.validatePaste(event) :null; 
    },
    validatePaste(event){
      let pastedValue = event.clipboardData.getData('text');
      let validNumber = pastedValue.match(/^\d*\.?\d*$/);
      if(!validNumber) return event.preventDefault();
    },
    validateKeypress(event){
      var character = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      let validCharacter = character.match(/^\d*\.?\d*$/);
      if (!validCharacter) return event.preventDefault();
    }
  },
  computed: {
    autocompleteMapping() {
      var mapping = AutoCompleteMapping[`${this.pName}[${this.name}]`];
      return mapping ? mapping : "off"
    },
    getType() {
      if (this.type == "card") {
        return "tel";
      }
      if (this.type == "email" || this.config.meta.type == "email") {
        return "text";
      }
      return this.type || this.config.meta.type || "text";
    },
    enableScreenReader() {
      return this.config.meta.extra;
    },
    getAriaDescribedBy() {
      return this.name === 'cvv' ? 'cb-field__help-text' : this.enableScreenReader && 'initInstr'
    },
    getSpellCheck() {
      return this.name === 'email' ? false : true;
    }
  },
  mounted() {
    if (this.config.meta.extra && isJsonString(this.config.meta.extra)) {
      var tmp = JSON.parse(this.config.meta.extra);
      if (window.googleMapsLoaded && this.$store.getters.autocomplete_enabled(this.pName) && tmp.autocomplete) {
        this.autocomplete = new google.maps.places.Autocomplete(this.$refs.input, {
          types: ['address'],
          output: 'json',
          // basic data fields which do not incur any additional charges unlike contact/ atmosphere data (CHKOUTENGG-4531)
          // https://developers.google.com/places/web-service/usage-and-billing#ac-with-details-session
          fields: ['address_components', 'formatted_address', 'geometry', 'icon', 'name'],
        });
        this.$refs.input.placeholder = "";
        new google.maps.event.addDomListener(this.$refs.input, 'keyup', function(event){
          if (event.keyCode === 13) {
            event.stopPropagation();
          }
        });
        let countryName = this.pName == "card" ? "billing_country" : "country";
        var allowedContryList;
        if(this.$store.getters.fields(this.pName) && this.$store.getters.field_config(this.pName, countryName) && this.$store.getters.field_config(this.pName, countryName).meta) {
          var countryData = this.$store.getters.field_config(this.pName, countryName);
          allowedContryList = (countryData.meta.read_only && countryData.value)? [countryData.value] : countryData.meta.allowed_values;
        }
        if (allowedContryList && allowedContryList.length <= 5) {
          var countryList = [];
          for (var country of allowedContryList) {
            countryList.push(country.value || country);
          }
          this.autocomplete.setComponentRestrictions({
            'country': countryList
          });
        }
        this.autocomplete.addListener('place_changed', () => {
          var placeDetails = this.autocomplete.getPlace();
          var addressComponents = placeDetails.address_components;
          if(addressComponents){
            addressComponents.push({
              long_name: placeDetails.name,
              short_name: placeDetails.name,
              types: ['name']
            })
          }
          addressComponents && this.$emit('place-changed', {
             addressComponents,
             enabledFields: this.$store.getters.fields(this.pName)
             });
        });
        // #CHKOUTENGG-680
        setTimeout(() => this.$refs.input.setAttribute("autocomplete", this.autocompleteMapping), 500)
      }
    }
  }
}
</script>
