<template>
  <div class="cb-action">
    <div class="cb-note" v-if="this.$slots['note']">
      <slot slot="content" name="note" :class="'cb-note__content'"></slot>
    </div>
    <button :aria-disabled="btnDisabled" :disabled='btnDisabled || showLoader' class="cb-button" :style="!btnDisabled && [backgroundColor,themeColor]" :class="[deriveClass, {'cb-button__disabled': btnDisabled}]" @click="next($event)" :data-cb-id='attr' @keyup="enter">
      <span class="cb-button__text">
        <slot></slot>
      </span>
    </button>
    <div class="cb-note" v-if="this.$slots['warning']" :class="'cb-note__warning'">
      <slot name="warning"></slot>
    </div>
  </div>
</template>
<style lang="scss">
  .cb-note__warning {
    font-size: 13px;
    margin: 8px 4px;
    color: #F83030;
  }
</style>
<script>
export default {
  props: ['customClass', 'showLoader', 'attr', 'btnDisabled'],
  methods: {
    next($event) {
      this.$emit("next", $event);
    },
    enter($event) {
      $event.stopPropagation();
    },
    containsClass(className) {
      return this.classList.indexOf(className) > -1;
    },
    otherClasses() {
      return this.classList.filter(c => ['primary', 'secondary', 'sm', 'left', 'icon', 'field'].indexOf(c) == -1);
    }
  },
  data() {
    return {
    }
  },
  computed: {
    deriveClass() {
      var output = {
        'cb-button__primary': this.containsClass('primary'),
        'cb-button__secondary': this.containsClass('secondary'),
        'cb-button__sm': this.containsClass('sm'),
        'cb-button__left': this.containsClass('left'),
        'cb-button__loader': this.showLoader,
        'cb-button__loader--black': this.showLoader && this.containsClass('secondary'),
        'cb-button__icon': this.containsClass('icon'),
        'cb-button__field': this.containsClass('field'),
      }

      this.otherClasses().forEach(c => output[c] = true);

      return output;
    },
    classList(){
      return this.customClass.split(' ');
    }
  }
}
</script>
