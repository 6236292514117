<template></template>
<script type="text/javascript">
  import { mapGetters } from "vuex";
  import cbClient from "@/utils/cb-client";
  export default {
    methods: {

    },
    computed: {
      ...mapGetters(['authType'])
    },
    data() {
      return {

      }
    },
    mounted() {
      var token = this.$store.state.auth.jwt;
      if(token) {
        cbClient.auth_service.logout({}, {token: token});
      }
      this.$store.dispatch('reset_auth');
      if(this.authType == "email") {
        this.$router.push({name: 'portal_login'});
      }
    }
  }
</script>