<template>
  <div class="cb-section">
    <template v-if="placeholder_list">
      <div class="cb-placeholder__section" v-if="index < 2" v-for="(item,index) in placeholder_list" v-bind:key="index">
        <cb-placeholder :custom-class="'sm'"></cb-placeholder>
        <cb-placeholder :custom-class="'sm rect-sm'"></cb-placeholder>
      </div>
      <div class="cb-placeholder__section" v-if="placeholder_list >= 3">
        <cb-placeholder :custom-class="'sm rect-md'" class="cb-placeholder__center"></cb-placeholder>
      </div>
    </template>
    <div v-else>
      <div class="cb-placeholder__section" v-for="i in (1,2)" v-bind:key="i">
        <cb-placeholder :custom-class="'sm'"></cb-placeholder>
        <cb-placeholder :custom-class="'sm rect-sm'"></cb-placeholder>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import Placeholder from "@/components/collections/elements/Placeholder"
export default {
  data() {
      return {
        placeholder_list: "",
      }
    },
    components: {
      "cb-placeholder": Placeholder,
    },
    mounted() {
      this.placeholder_list = this.$store.state.customer ? this.$store.state.customer.subscriptions.length : 0;
    }
}
</script>