export default {
  "customer[email]": "email",
  "customer[first_name]": "given-name",
  "customer[last_name]": "family-name",
  "customer[phone]": "tel",
  "billing_address[first_name]": "billing given-name",
  "billing_address[last_name]": "billing family-name",
  "billing_address[line1]": "billing address-line1",
  "billing_address[line2]": "billing address-line2",
  "billing_address[line3]": "billing address-line3",
  // Ref: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#attr-fe-autocomplete-organization
  "billing_address[company]": "billing organization",
  "billing_address[email]": "billing email",
  "billing_address[city]": "billing address-level2",
  "billing_address[state]": "billing address-level1",
  "billing.region": "billing address-level1",
  "billing_address[zip]": "billing postal-code",
  "billing_address[country]": "billing country",
  "billing_address[phone]": "billing tel",
  "shipping_address[first_name]": "shipping given-name",
  "shipping_address[last_name]": "shipping family-name",
  "shipping_address[line1]": "shipping address-line1",
  "shipping_address[line2]": "shipping address-line2",
  "shipping_address[line3]": "shipping address-line3",
  "shipping_address[email]": "shipping email",
  "shipping_address[company]": "shipping organization",
  "shipping_address[city]": "shipping address-level2",
  "shipping_address[state]": "shipping address-level1",
  "shipping_address[zip]": "shipping postal-code",
  "shipping_address[country]": "shipping country",
  "shipping_address[phone]": "billing tel",
  "shipping.region": "shipping address-level1",
  "card[cvv]": "cc-csc",
  "card[expiry_month]": "cc-exp-month",
  "card[expiry_year]": "cc-exp-year",
  "card[first_name]": "cc-given-name",
  "card[last_name]": "cc-family-name",
  "card[number]": "cc-number",
  "card[billing_addr1]": "billing address-line1",
  "card[billing_addr2]": "billing address-line2",
  "card[billing_city]": "shipping address-level2",
  "card[billing_country]": "billing country",
  "card[billing_state]": "billing address-level1",
  "card[billing_state_code]": null,
  "card[billing_zip]": "billing postal-code"
}