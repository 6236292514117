window.hasClass = function (ele,cls) {
  return !!ele.className.match(new RegExp('(\\s|^)'+cls+'(\\s|$)'));
}

window.addClass = function(ele,cls) {
  if (!hasClass(ele,cls)) ele.className += " "+cls;
}

window.removeClass = function(ele,cls) {
  if (hasClass(ele,cls)) {
    var reg = new RegExp('(\\s|^)'+cls+'(\\s|$)');
    ele.className=ele.className.replace(reg,' ');
  }
}

window.isDescendant = function(parent, child) {
  var node = child.parentNode;
  while (node != null) {
     if (node == parent) {
         return true;
     }
     node = node.parentNode;
  }
  return false;
}

window.ifNativeDateSupported = function() {
  var input = document.createElement('input');
  input.setAttribute('type','date');
  var notADateValue = 'not-a-date';
  input.setAttribute('value', notADateValue);
  return (input.value !== notADateValue);
}();

window.ifNativeTimeSupported = function() {
  var input = document.createElement('input');
  input.setAttribute('type','time');
  var notATimeValue = 'not-a-time';
  input.setAttribute('value', notATimeValue);
  return (input.value !== notATimeValue);
}();

if (typeof Object.assign != 'function') {
  Object.assign = function(target, varArgs) { // .length of function is 2
    'use strict';
    if (target == null) { // TypeError if undefined or null
      throw new TypeError('Cannot convert undefined or null to object');
    }

    var to = Object(target);

    for (var index = 1; index < arguments.length; index++) {
      var nextSource = arguments[index];

      if (nextSource != null) { // Skip over if undefined or null
        for (var nextKey in nextSource) {
          // Avoid bugs when hasOwnProperty is shadowed
          if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
    }
    return to;
  };
}

Array.prototype.findIndex = function(predicate) {
  var position = -1;
  this.some(function(value, index) {
    if(predicate(value)) {
      position = index;
      return true;
    }
  });
  return position;
}


if (!String.prototype.endsWith) {
  String.prototype.endsWith = function(searchString, position) {
      var subjectString = this.toString();
      if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
        position = subjectString.length;
      }
      position -= searchString.length;
      var lastIndex = subjectString.lastIndexOf(searchString, position);
      return lastIndex !== -1 && lastIndex === position;
  };
}

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(search, pos) {
    return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
  };
}

(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode.removeChild(this);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

window.range10 = function(min, max, step = 1) {
  min = parseInt(min);
  max = parseInt(max);
  step = parseInt(step);
  let noOfElements = parseInt((max - min) / step) + 1;
  let moreThan10 = false;
  if(noOfElements > 10) {
    noOfElements = 10;
    moreThan10 = true;
  }
  return {array: [...Array(noOfElements)].map((el, index) => {
    return min + (index * step);
  }), moreThan10};
}

window.assert = function(value, message) {
  if(!value) {
    throw new Error(message || "should not happen");
  }
}


window.asyncLoad = function(url, successCall, failureCall) {
    var d = document, t = 'script',
        o = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
    o.src = url;
    if (successCall) { 
      // reject after 30s
      let timeout = window.cbTimeout(() => failureCall, 30000);
      o.addEventListener('load', (event)  => {
        clearTimeout(timeout);
        successCall.call(null, event);
      });      
    }
    s.parentNode.insertBefore(o, s);
}

// Polyfill for isNaN (Source: MDN)
Number.isNaN = Number.isNaN || function(value) {     
  return value !== value;
}

// Polyfill for Array.prototype.find (Source: MDN)
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function(predicate) {
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }
      let o = Object(this);
      let len = o.length >>> 0;
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }
      let thisArg = arguments[1];
      let k = 0;
      while (k < len) {
        let kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        k++;
      }
      return undefined;
    },
    configurable: true,
    writable: true
  });
}