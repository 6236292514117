class PauseConfig {
	setPauseSettings(pause_settings) {
		this.pause_settings =pause_settings;
	}

	isEnabled() {
		return this.pause_settings.enabled;
	}

	isImmediatePause() {
		return this.pause_settings.pause_when == "IMMEDIATELY";
	}

	isEotPause() {
		return this.pause_settings.pause_when == "EOT";
	}

	pauseType() {
		return this.pause_settings.pause_till;
	}

	isIndefinitePause() {
		return this.pause_settings.pause_till == "INDEFINITELY";
	}

	isChooseOptions() {
		return this.pause_settings.pause_till == "CUSTOMER_CHOICE";
	}

	isFixedPeriodDuration() {
		return this.pause_settings.pause_till == "SET_PERIOD";
	}

	getFixedPauseDuration() {
		return this.isFixedPeriodDuration() && this.pause_settings.pause_period;
	}

	isDurationCustomerChoice() {
		return this.isChooseOptions() && this.pause_settings.customer_choice;
	}

	getDurationOptions() {
		return this.isChooseOptions() && this.pause_settings.pause_options;
	}

	getSettings() {
		return this.pause_settings;
	}
}

export default new PauseConfig();