export default class OmniHelper {
  static isRestrictedChannel(data) {
    if (!data) {
      return false;
    }
    let RESTRICTED_CHANNELS = ['app_store', 'play_store'];
    return RESTRICTED_CHANNELS.includes(data.channel);
  }

  static getHelpLink(channel) {
    switch (channel) {
      case 'app_store':
        return 'https://support.apple.com/en-us/HT202039';
      case 'play_store':
        return 'https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid';
      default:
        return 'https://www.chargebee.com/docs/2.0/mobile-app-store-connect.html#frequently-asked-questions-faq';
    }
  }
}
