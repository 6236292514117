export default {
    // When the bound element is inserted into the DOM...
    inserted: function(el, binding, vnode) {
      if (!binding.value && !window.app) {
        return;
      }
      AnimationFactory.call(el,vnode).start();
    }
}

const Helpers = {
  isAndroid: () => {
    return !!navigator.userAgent.match(/Android/)
  },
  isInstagram: () => {
    return navigator.userAgent.match(/FB/) || navigator.userAgent.match(/Instagram/i)
  },
}

const durations = {
  'headerTransitionDuration' : 400,
  'appHeightTransitionDuration' : 400,
  'appHeightTransitionTimeOut' : 100
}

class AnimationFactory {
  static call(el, vnode){
    return window.isMobileResolution ? new MobileAnimation(el, vnode) : (window.app.$inited ? new TranslateAnimation(el, vnode) : new AppearAnimation(el, vnode));
  }
}

class Animation{
  h = 'string'; headerHeight = 0; appHeight = 0; minheight = 0;
  constructor(el, vnode){
    this.el = el;
    this.vnode = vnode;
    Object.assign(this, {
        app: document.getElementById("app"),
        header: document.getElementById("cb-header"),
        header_title: document.getElementById("cb-header-title"),
        body_wrap: document.getElementsByClassName("cb-body__wrap")[0],
        header_wizard : document.getElementById("cb-header-wizard"),
    });
  }
  focusOnFirstChild(){
    var firstChild = this.app.querySelector("input:not([readonly=true])");
    window.setTimeout(() => {
      firstChild && firstChild.focus();
    },1000);
  }
}

class MobileAnimation extends Animation{
  constructor(el, vnode) {
    super(el, vnode);
  }

  start(){
    this.beforeStart();
    window.cbTimeout(() => {
      this._start();
      this.afterStart();
    },durations.headerTransitionDuration);
  }

  //------------------------------------------------------------------------------
  //  Fade the body content and start the animation
  //------------------------------------------------------------------------------

  beforeStart(){
    if (!(Helpers.isAndroid() && Helpers.isInstagram())) {
      window.addClass(this.el, "cb-body__animate");
    }
  }

  //------------------------------------------------------------------------------
  // Calculate body min height - flex property used in css
  //------------------------------------------------------------------------------

  _start(){
    this.headerHeight = this.header ? this.header.clientHeight : 0;
    this.body_wrap && (this.body_wrap.style.minHeight = window.innerHeight - this.headerHeight + "px");
  }

  afterStart(){
    window.removeClass(this.app, "cb-hp--hidden");
    window.removeClass(this.el, "cb-body__animate");
    super.focusOnFirstChild();
  }

}

class WebAnimation  extends Animation{
  constructor(el, vnode) {
    super(el,vnode)
    this.app.$animation = "start";
  }

  //------------------------------------------------------------------------------
  //  Calculate app height - header height + body content height
  //  Fade the body content and start the animation
  //------------------------------------------------------------------------------

  beforeStart() {
    this.h = this.el.clientHeight;
    this.headerHeight = this.header ? this.header.clientHeight : 0;
    //Final review hide full summary details
    let instance = this.vnode.componentInstance;
    if ((this.h + this.headerHeight) > window.innerHeight && instance && instance.$data.heightCheck != undefined) {
      instance.$data.heightCheck = true;
    }
    this.appHeight = this.h + this.headerHeight;
    window.addClass(this.el, "cb-body__animate");
    this.app.focus();//donot remove - for accessibility
  }

  //------------------------------------------------------------------------------
  // set app height - min app height value : 480px
  // Calculate app margin => window height - app height
  // Min top margin - 20px
  //------------------------------------------------------------------------------

  _start(){
    //reconfirm header height
    this.headerHeight = this.header ? this.header.clientHeight : 0;
    this.appHeight = this.h + this.headerHeight;
    this.minHeight = (this.appHeight > 480) ? this.appHeight : 480;
    this.app.style['height'] = this.minHeight + "px";
    let isPreview = window && window.initInfo && window.initInfo.is_preview;
    let minMarginTop = (isPreview) ? 90 : 20;
    let diff = window.innerHeight - this.minHeight;
    this.app.style.marginTop = (diff > minMarginTop ? (diff / 2.0) : minMarginTop) + "px";
    this.body_wrap && (this.body_wrap.style.minHeight = 480 - this.headerHeight + "px");
  }

  //------------------------------------------------------------------------------
  // reset app height to auto
  // fade in body content
  // update prevHeight variable to current app height
  //------------------------------------------------------------------------------


  afterStart(){
    this.app.$prevHeight = this.app.clientHeight + "px";
    this.app.style.height = 'auto';
    this.app.$animation = "end";
    window.removeClass(this.app, "cb-hp--hidden");
    window.removeClass(this.el, "cb-body__animate");
  }
}

class AppearAnimation extends WebAnimation{
  constructor(el, vnode) {
    super(el, vnode);
  }

  start() {
    this.beforeStart();
    window.cbTimeout(() => {
      this._start();
      this.afterStart();
    }, durations.headerTransitionDuration);
  }

  beforeStart() {
    super.beforeStart();
    if (this.header) {
      this.header.style['height'] = '64px'; //set max height to animate header title on page load
      this.header.style['overflow'] = 'hidden'; //set max height to animate header title on page load
    }
    this.header_title && (this.header_title.style['opacity'] = "0"); //hide title to equate the min height and then start animate the title
  }
  _start(){
    super._start();
  }
  afterStart() {
    window.removeClass(this.app, "cb-hp--hidden"); //remove fade in effect and add slide effect
    this.header && this.header_title && this.animateHeaderTitle();
    super.afterStart();
    this.app.$inited = true;
    super.focusOnFirstChild();
  }
  animateHeaderTitle(){
    window.cbTimeout(() => {
      this.header_wizard && (this.header_wizard.style.opacity = "0"); //hide title to equate the min height and then start animate the title
      this.header.style.height = document.getElementById("cb-header-wrap").clientHeight + "px"; //remove max height and show title
      window.cbTimeout(() => {
        this.header.style.height = "auto";
        this.header.style.overflow = "initial";
        this.header.$prevHeight = this.header.clientHeight + "px"; //set prev header height
        this.app.$prevHeight = this.app.clientHeight + "px"; //set prev app height
        this.header_title && (this.header_title.style.opacity = "1"); //show header title
        this.header_wizard && (this.header_wizard.style.opacity = "1"); //show header title
      }, 400); //reset header behaviour
    }, 400); //show header title after 400ms - app fade in duration
  }
}

class TranslateAnimation extends WebAnimation{
  constructor(el, vnode) {
    super(el, vnode);
  }

  start(){
    this.beforeStart();
    window.cbTimeout(() => {
      this._start();
      window.cbTimeout(() => this.afterStart(), this.afterStartWindowTimeout);
    }, durations.headerTransitionDuration);
  }

  beforeStart(){
    super.beforeStart();
    window.addClass(this.app, "cb-hp--transition");
    this.app.style.height = this.app.$prevHeight;
    document.documentElement.scrollTop = document.body.$scrollTop ? document.body.$scrollTop : 0;
  }

  _start(){
    super._start();
    let timeOut = durations.appHeightTransitionDuration;
    this.afterStartWindowTimeout = durations.appHeightTransitionTimeOut;
    if (this.minHeight >= 480) {
      let diff = this.minHeight - parseInt(this.app.$prevHeight, 10);
      diff = diff > 0 ? diff : -diff;
      if(diff > 0){
        timeOut = timeOut + diff; //transition duration changes in accordance with the height difference between the consecutive screens
        this.afterStartWindowTimeout = timeOut;
      }
    }
    this.app.style.transitionDuration = timeOut + "ms";
  }

  afterStart(){
    super.afterStart();
    document.body.$scrollTop = 0;
  }
}