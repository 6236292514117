<template>
  <div class="grecaptcha-text" v-cb-html="sanitizeTags(cmt('recaptcha_text'),['a'])"></div>
</template>

<script>
export default {
    name: "GrecaptchaText",
    methods:{
      sanitizeTags(content, allowedTags) {
        return {
          text: content,
          tags: allowedTags,
          attrs: {a: ["href"]}
        }
      }
    }
}
</script>

<style>
.grecaptcha-text {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.3rem;
    padding-bottom: 20px;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
    color: #6B7280;
    flex: none;
    order: 0;
    flex-grow: 1;
}
</style>