export default {
  methods : {
    keydown(event) {
      if ([46, 8, 9, 27, 13, 110].indexOf(event.keyCode) !== -1 ||
          (event.keyCode == 65 || event.keyCode == 86 || event.keyCode == 88 || event.keyCode == 67) && (event.ctrlKey === true || event.metaKey === true) ||
          (event.keyCode >= 35 && event.keyCode <= 39)) {
              return;
      }
      if(event.target.value && event.target.value.length == 6 || !((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))) event.preventDefault();
    },
    dispatchOtp(){
      this.$store.dispatch('set_loader', true);
      this.$store.dispatch("check_user", {email : this.email, send_otp : true})
        .then(data => {
          this.$store.dispatch('set_loader', false);
          window.cbTimeout(() => {
            this.$refs.x1.focus();
          }, 400);
          if (!data.user_identified) {
            this.errorMessage = this.st('no_account');
          }
        })
        .catch(error => {
          this.$store.dispatch('set_loader', false);
          this.errorMessage = error.message;
          logger.e(error);
        });
    }
  }
}