
let routesStack = []
window.routesStack = routesStack;
/**
 *
 * @param {Router} router
 * this function will add custom implementation of push, replace and go
 */
export default function(router) {
  router._push = router.push;
  router.push = function(options) {
    let previous = this.currentRoute;
    this._push(options);
    routesStack.push(previous)
  }

  let _go = router.go;
  router.go = function(count) {
    if(count != -1) {
      throw new Error("Chargebee does not support this")
    }
    if(routesStack.length > 0) {
      let route = routesStack.pop();
      this.replace({name: route.name, params: route.params, query: route.query});
    }
  }
}