import StateCodeList from "@/utils/state-code-list"
import {paymentMethods} from "@/utils/payment-helper"

export default {
    
    isStateListPresent : (countryCode) => {
        return StateCodeList[countryCode] != undefined
    },

    getStateFromStateCode(stateCode, countryCode) {
        let stateCodes = StateCodeList[countryCode]
        for (let key in stateCodes) {
            if (stateCodes.hasOwnProperty(key) && stateCodes[key] === stateCode) {
                return key;
            }
        }
        return '';
    }, 

    canShowStateDropdown(store,country) {
        var pm_list = store.getters.pm_list
        if(['US', 'CA', 'IN'].indexOf(country) >= 0)
            return true
        else if(country == 'BR' && pm_list.find(pm => pm.type == paymentMethods.BOLETO) )
            return true
        return false;
    }
}