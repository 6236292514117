var applyGroupingSeperator = function (x, grouping, groupingSeparator, decimalSeparator) {
  var parts = x.toString().split(".");
  var regex = new RegExp("\\B(?=(\\d{" + grouping + "})+(?!\\d))", "g");
  // grouping separator
  parts[0] = parts[0].replace(regex, groupingSeparator);
  // decimal separator
  return parts.join(decimalSeparator);
}

function currencyFilter(value, currencyCode, negationNeeded, ignorePrefixSuffix, ignoreGrouping) {
  if (window.currency_list && window.currency_list[currencyCode]) {
    var format = window.currency_list[currencyCode];
    var maximumFractionDigits = format.maximumFractionDigits;
    var moneyValue = ((negationNeeded ? -1 : 1) * value) / (10 ** maximumFractionDigits);
    var moneyValuePositive = value / (10 ** maximumFractionDigits);
    var roundedValue = Number(Math.round(moneyValuePositive * (10 ** maximumFractionDigits)) / (10 ** maximumFractionDigits)).toFixed(maximumFractionDigits);
    /*
      TODO check if this can be used
      Number.prototype.round = function(places) {
        return +(Math.round(this + "e+" + places)  + "e-" + places);
      }
    */
    if (!ignoreGrouping && format.groupingUsed) {
      var output = applyGroupingSeperator(roundedValue, format.groupingSize, format.decimalFormatSymbols.groupingSeparator, format.decimalFormatSymbols.monetaryDecimalSeparator);
    }
    else {
      var output = roundedValue;
    }

    if (ignorePrefixSuffix) {
      return output;
    }

    if (moneyValue >= 0) {
      return format.positivePrefix + output + format.positiveSuffix;
    }
    else {
      return format.negativePrefix + output + format.negativeSuffix;
    }
  }
  else {
    return currencyCode && new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(value / 100.0);
  }
}

export default currencyFilter;

export function mdCurrencyFilter(value, currencyCode, negationNeeded, ignorePrefixSuffix, ignoreGrouping) {
  if (value &&
    typeof value === 'string'
    && value.includes(".")) {
    let formmater = new MDSCurrencyFormatter(value, currencyCode);
    return formmater.format(negationNeeded, ignorePrefixSuffix, ignoreGrouping)
  }
  return currencyFilter(value, currencyCode, negationNeeded, ignorePrefixSuffix, ignoreGrouping);
}

export class MDSCurrencyFormatter {
  formatter;
  monetaryDecimalSeparator;
  currencyCode;

  wholeNumberAmountValueWithLeadingZeroes;
  decimalNumberValue;

  actualAmountValueInCents
  

  constructor(value, currencyCode) {
    this.currencyCode = currencyCode;
    if (window.currency_list && window.currency_list[this.currencyCode]) {
      this.formatter = window.currency_list[this.currencyCode];
      this.monetaryDecimalSeparator = this.formatter.decimalFormatSymbols.monetaryDecimalSeparator;
      this.wholeNumberAmountValueWithLeadingZeroes = this.getWholeAmountValueWithLeadingZeroes(value, '.');
      this.decimalNumberValue = this.getDecimalValue(value, '.');
    } else {
      this.actualAmountValueInCents = this.convertToCents(value);
    }
  }

  format(negationNeeded, ignorePrefixSuffix, ignoreGrouping) {
    if (typeof this.formatter !== 'undefined') {
      let amountValue = this.getAmountValue();
      amountValue = this.applyGrouping(amountValue, ignoreGrouping);
      return this.appendPrefixOrSuffix(amountValue, negationNeeded, ignorePrefixSuffix)
    }
    else {
      return this.currencyCode && new Intl.NumberFormat('en-US', { style: 'currency', currency: this.currencyCode }).format(this.actualAmountValueInCents / 100.0);
    }
  }

  convertToCents(value) {
    return value * 100;
  }

  convertToWholeNumber(value) {
    return value * (10 ** this.formatter.maximumFractionDigits)
  }

  getWholeAmountValueWithLeadingZeroes(value, separator) {
    return value && this.convertToWholeNumber(value.split(separator)[0]);
  }
  getDecimalValue(value, separator) {
    return value && value.split(separator)[1];
  }
  getAmountValue() {
    var moneyValuePositive = this.wholeNumberAmountValueWithLeadingZeroes / (10 ** this.formatter.maximumFractionDigits);
    if (typeof this.decimalNumberValue !== 'undefined' && this.formatter.maximumFractionDigits > 0) {
      return moneyValuePositive + this.monetaryDecimalSeparator + this.decimalNumberValue;
    } else {
      return Number(Math.round(moneyValuePositive * (10 ** this.formatter.maximumFractionDigits)) / (10 ** this.formatter.maximumFractionDigits)).toFixed(this.formatter.maximumFractionDigits);
    }
  }

  applyGrouping(amountValue, ignoreGrouping) {
    if (!ignoreGrouping && this.formatter.groupingUsed) {
      amountValue = applyGroupingSeperator(amountValue,
        this.formatter.groupingSize,
        this.formatter.decimalFormatSymbols.groupingSeparator,
        this.monetaryDecimalSeparator);
    }
    return amountValue;
  }

  appendPrefixOrSuffix(amountValue, negationNeeded, ignorePrefixSuffix) {
    var wholePartamount = ((negationNeeded ? -1 : 1) * this.wholeNumberAmountValueWithLeadingZeroes) / (10 ** this.formatter.maximumFractionDigits);
    if (ignorePrefixSuffix) {
      return amountValue;
    }
    if (wholePartamount >= 0) {
      return this.formatter.positivePrefix + amountValue + this.formatter.positiveSuffix;
    }
    else {
      return this.formatter.negativePrefix + amountValue + this.formatter.negativeSuffix;
    }
  }
}