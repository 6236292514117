import CartHelper from '@/utils/cart-helper'

export default class OrderEstimateHolder {
  constructor(cartEstimate, getters) {
    this.immediateEstimate = cartEstimate.immediate_invoice_estimate || cartEstimate.order.immediate_invoice_estimate;
    this.creditnoteEstimates = cartEstimate.credit_note_estimates;
    this.contractTerm = cartEstimate.contract_term
    this.nextEstimates = cartEstimate.next_invoice_estimates || [];
    this.estimate = this.immediateEstimate || this.nextEstimates[0] || {};
    this.getters = getters;
    this.subscriptionEstimate = cartEstimate.subscription_estimate;
    this.init();
  }

  init() {
    if(this.immediateEstimate && this.nextEstimates[0]) {
      if(!CartHelper.isDifferentEstimate(this.immediateEstimate, this.nextEstimates[0])) {
        this.nextEstimates.shift();
      }
    }
  }

  getDiscounts() {
    if(this.getters.is_cart_with_estimate) {
      return this.estimate.discounts || [];
    } else {
        let discounts = (this.immediateEstimate && this.immediateEstimate.discounts) || [];
        this.nextEstimates.forEach(est => {
          est.discounts = est.discounts || [];
          est.discounts.forEach(discount => {
            let index = discounts.findIndex(d => d.entity_id == discount.entity_id);
            if(index == -1) {
              discounts = discounts.concat(discount);
            }
          });
        });
        return discounts;
    }
  }
}
