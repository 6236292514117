<style src="@/assets/styles/components/modules/checkout/thankyou.styl" lang="stylus" scoped></style>
<template src="@/components/collections/templates/ThankyouTemplate.html"></template>
<script type="text/javascript">
import { EventBus } from '@/utils/event-bus'
import {
  mapGetters
} from "vuex"
import ThankyouMixin from "@/mixins/thank-you-mixin"
export default {
  mixins: [ThankyouMixin],
  data() {
    return {
      term: undefined,
      goto_app: false
    }
  },
  mounted() {
    EventBus.$on('extension_period', d => {
      this.term = d;
    });
  },
  computed: {
    headerText() {
      return this.t('subscription.extend.thankyou.header', { extension_period: this.term });
    },
    bodyText() {
      return this.t('subscription.extend.thankyou.body', { extension_period: this.term });
    },
    thankYouClose() {
      return window.isOpenedByCb && this.t('subscription.extend.thankyou.close');
    }
  },
  methods: {
    close() {
      window.cbPostMessage("cb.close");
    }
  }
}
</script>
