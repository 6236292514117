import ImportHelper from '@/utils/chunkImportWrapper'
import DlocalHelper from "@/utils/dlocal-helper"
import cardFormatter from "@/utils/card-formatter";
import { UpperCaseGateway } from '@/models/gateway-type';
import { paymentMethods } from "@/utils/payment-helper";

const PaymentGatewayError = null // Import Component for Payment Gateway Error
var isDev = process.env.NODE_ENV != "production";
export const CDN = {
    braintree: 'https://js.braintreegateway.com/web/3.96.1/js/client.min.js',
    braintreDataCollector: 'https://js.braintreegateway.com/web/3.96.1/js/data-collector.min.js',
    plaid: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
    stripe: 'https://js.stripe.com/v2/',
    applePayViaBraintree: 'https://js.braintreegateway.com/web/3.96.1/js/apple-pay.min.js',
    adyenSelfHosted: `${window.location.origin}${getStaticFolder()}/adyen.encrypt.nodom.min.js`,
    finix:'https://forms.finixpymnts.com/finix.js',
    chargebeejs: (window.initInfo && window.initInfo.chargebee_js_url) || 'https://js.chargebee.com/v2/chargebee.js'
}

let store;
// Dynamically load Store
if(window.portalConfig) {
    import('@/store/portal')
        .then(_store => store = _store.default)
        .catch(e => window.logger.e(e))
} else if(window.hpConfig && window.hpConfig.ctype === 'collect_now') {
    import('@/store/paynow')
        .then(_store => store = _store.default)
        .catch(e => window.logger.e(e))
} else if(window.hpConfig && window.hpConfig.ctype === 'claim_gift') {
    import('@/store/claim-gift')
        .then(_store => store = _store.default)
        .catch(e => window.logger.e(e))
} else if(window.hpConfig) {
    import('@/store/checkout')
        .then(_store => store = _store.default)
        .catch(e => window.logger.e(e))
}

function has(parent, child) {
  if(parent && (typeof parent === "string" || parent.constructor === Array))
      return !!~parent.indexOf(child)
  return false
}

export function threedsNeeded(gateway_account_id, type, paymentInfo) {
    const pm_list = store.getters.pm_list;
    const gwConfig = store.getters.non_smart_routing_gw_config || {};
    let pm = pm_list.find(gw => ( gw.id == gateway_account_id && gw.type == type));
    if(!pm && type === paymentMethods.CARD && gwConfig[gateway_account_id]) {
        pm = gwConfig[gateway_account_id];
    }
    if(!pm || DlocalHelper.skipThreeDS(pm, store, paymentInfo)) {
        return false;
    }
	if(skipSpreedly3ds(pm, paymentInfo)) {
		return false;
	}
   return !!pm.enabled3ds;
}

export function intentFlowEnabled(gateway_account_id, type) {
    const pm_list = store.getters.pm_list;
    const gwConfig = store.getters.non_smart_routing_gw_config || {};
    let pm = pm_list.find(gw => ( gw.id == gateway_account_id && gw.type == type));
    if(!pm && type === paymentMethods.CARD && gwConfig[gateway_account_id]) {
        pm = gwConfig[gateway_account_id];
    }
   return pm && !!pm.enabled_intent_flow;
}

export function skipSpreedly3ds(pm, paymentInfo) {
    if(pm && pm.spreedly_config && paymentInfo && (paymentInfo.number || (paymentInfo.card && paymentInfo.card.brand))) {
		let allowedBrands = pm.spreedly_config.allowed_3ds_card_networks || [];
		let givenBrand = paymentInfo.number ? cardFormatter.cardType(paymentInfo.number) : paymentInfo.card.brand;
		return allowedBrands.findIndex(brand => brand.toUpperCase() == givenBrand.toUpperCase()) < 0;
    }
	return false;
}

export function checkPaymentGateway() {
    // Fallback configuration
    let fallbackConfig = {
        hasPaypal: true,
        hasBraintree: true,
        hasStripe: true,
        hasAdyen: true,
        hasAmazonPay: true,
        hasApplePayViaBraintree: true,
        hasPaypalViaBraintree: true,
        loadChargebeejs: true,
        hasBraintreeDataCollector: true
    };
    if(!store) return fallbackConfig;
    try {
        const pm_list = store.getters.pm_list
        const gatewayNames = pm_list.map(gw => gw.gateway_name)
        return {
            hasPlaid: pm_list.some(pm => pm.plaid_config),
            loadChargebeejs: pm_list.some(pm => pm.enabled3ds || pm.enabled_intent_flow),
            hasPaypal: has(gatewayNames, UpperCaseGateway.PAYPAL_EXPRESS_CHECKOUT) || has(gatewayNames, UpperCaseGateway.PAYPAL),
            hasBraintree: has(gatewayNames, UpperCaseGateway.BRAINTREE),
            hasStripe: has(gatewayNames, UpperCaseGateway.STRIPE),
            hasAdyen: pm_list.some(pm => (pm.gateway_name === UpperCaseGateway.ADYEN && pm.client_side_encryption_config)),
            hasPayPalViaAdyen: pm_list.some(pm => (pm.gateway_name === UpperCaseGateway.ADYEN && pm.type === 'paypal_express_checkout')),
            hasApplePayViaAdyen: pm_list.some(pm => (pm.gateway_name === UpperCaseGateway.ADYEN && pm.type === 'apple_pay')),
            hasApplePayViaCheckoutCom: pm_list.some(pm => (pm.gateway_name === UpperCaseGateway.CHECKOUT_COM && pm.type === 'apple_pay')),
            hasApplePayViaStripe: pm_list.some(pm => (pm.gateway_name === UpperCaseGateway.STRIPE && pm.type === 'apple_pay')),
            hasAmazonPay: has(gatewayNames, UpperCaseGateway.AMAZON_PAYMENTS),
            hasPaypalViaBraintree: pm_list.some(pm => (pm.gateway_name === UpperCaseGateway.BRAINTREE && pm.type === 'paypal_express_checkout')),
            hasApplePayViaBraintree: pm_list.some(pm => pm.gateway_name === UpperCaseGateway.BRAINTREE && pm.type === 'apple_pay'),
            amazonPayWidgetUrl: (pm_list.find(pm => pm.gateway_name === UpperCaseGateway.AMAZON_PAYMENTS) || {configuration: {}}).configuration.widget_url,
            hasBraintreeDataCollector: pm_list.some(pm => (pm.gateway_name === UpperCaseGateway.BRAINTREE && pm.type === 'card')),
            hasVenmo : pm_list.some(pm => (pm.gateway_name === UpperCaseGateway.BRAINTREE && pm.type === 'venmo'))
        }
    } catch(e) {
        window.logger.e(e)
        return fallbackConfig
    }
}

function getStaticFolder() {
    var isDev = process.env.NODE_ENV != "production";
    return isDev ? "/static/javascripts" : "/assets/hp_v3/javascripts";
}

export default {
    CheckoutCardSection: () => new Promise((resolve, reject) => {
        Promise.resolve()
            .then(() => checkPaymentGateway().hasBraintree
                ? ImportHelper.loadScript(CDN.braintree, null, 'braintree')
                : Promise.resolve()
            ).then(() => checkPaymentGateway().hasStripe
                ? ImportHelper.loadScript(CDN.stripe, null, 'Stripe')
                : Promise.resolve()
            ).then(() => checkPaymentGateway().hasAdyen
                ? ImportHelper.loadScript(CDN.adyenSelfHosted, {appendIntoBody: true}, 'adyen')
                : Promise.resolve()
            ).then(() => checkPaymentGateway().hasBraintreeDataCollector
                ? ImportHelper.loadScript(CDN.braintreDataCollector, null, 'dataCollector', window.braintree)
                : Promise.resolve()
            ).then(() => resolve(import('@/components/modules/hosted-pages/CardSection').then(m => m.default)))
    }),
    PortalCardSection: () => new Promise((resolve, reject) => {
        Promise.resolve()
            .then(() => checkPaymentGateway().hasBraintree
                ? ImportHelper.loadScript(CDN.braintree, null, 'braintree')
                : Promise.resolve()
            ).then(() => checkPaymentGateway().hasStripe
                ? ImportHelper.loadScript(CDN.stripe, null, 'Stripe')
                : Promise.resolve()
            ).then(() => checkPaymentGateway().hasAdyen
                ? ImportHelper.loadScript(CDN.adyenSelfHosted, {appendIntoBody: true}, 'adyen')
                : Promise.resolve()
            ).then(() => checkPaymentGateway().hasBraintreeDataCollector
                ? ImportHelper.loadScript(CDN.braintreDataCollector, null, 'dataCollector', window.braintree)
                : Promise.resolve()
            ).then(() => resolve(import('@/components/modules/portal/payment-methods/CardSection').then(m => m.default)))
    }),
    PaypalSection: () => new Promise((resolve, reject) => {
        Promise.resolve()
            .then(() => {
                resolve(ImportHelper.loadComponent('PayPalV2Section'));
            })
            .catch(e => {
                window.logger.e(e)
                resolve(PaymentGatewayError)
            })
    }),
    DirectDebitSection: () => new Promise((resolve, reject) => {
        Promise.resolve()
            .then(() => checkPaymentGateway().hasStripe
                ? ImportHelper.loadScript(CDN.stripe, null, 'Stripe')
                : Promise.resolve()
            ).then(() => checkPaymentGateway().hasPlaid
                ? ImportHelper.loadScript(CDN.plaid, null, 'Plaid')
                : Promise.resolve()
            ).then(() => resolve(ImportHelper.loadComponent('DirectDebitSection')))
            .catch(e => {
                window.logger.e(e)
                resolve(PaymentGatewayError)
            })
    }),
    AmazonPaymentSection:  () => new Promise((resolve, reject) => {
        Promise.resolve()
            .then(() => checkPaymentGateway().hasAmazonPay
                ? ImportHelper.loadScript(checkPaymentGateway().amazonPayWidgetUrl, null, 'amazon')
                : Promise.resolve()
            ).then(() => resolve(ImportHelper.loadComponent('AmazonPaymentSection')))
            .catch(e => {
                window.logger.e(e)
                resolve(PaymentGatewayError)
            })
    }),
    ApplePaySection: () => new Promise((resolve, reject) => {
        Promise.resolve()
            .then(() => checkPaymentGateway().hasBraintree
                ? ImportHelper.loadScript(CDN.braintree, null, 'braintree')
                : Promise.resolve()
            ).then(() => checkPaymentGateway().hasApplePayViaBraintree
                ? ImportHelper.loadScript(CDN.applePayViaBraintree, null, 'applePay', window.braintree)
                : Promise.resolve()
            ).then(() => {
                if(checkPaymentGateway().hasApplePayViaStripe || checkPaymentGateway().hasApplePayViaAdyen || checkPaymentGateway().hasApplePayViaCheckoutCom || checkPaymentGateway().hasApplePayViaBraintree) {
                    resolve(ImportHelper.loadComponent('ApplePayV2Section'));
                } else {
                    resolve(ImportHelper.loadComponent('ApplePaySection'));
                }
            })
            .catch(e => {
                window.logger.e(e)
                resolve(PaymentGatewayError)
            })
    }),
    iDealSection: () => new Promise((resolve, reject) => {
        resolve(ImportHelper.loadComponent('IDealSection'))
            .catch(e => {
                window.logger.e(e)
                resolve(PaymentGatewayError)
            })
    }),
    OnlineBankingPolandSection: () => new Promise((resolve, reject) => {
      resolve(ImportHelper.loadComponent('OnlineBankingPolandSection'))
        .catch(e => {
          window.logger.e(e)
          resolve(PaymentGatewayError)
        })
    }),
    SofortSection: () => new Promise((resolve, reject) => {
        resolve(ImportHelper.loadComponent('SofortSection'))
            .catch(e => {
                window.logger.e(e)
                resolve(PaymentGatewayError)
            })
    }),
    BancontactSection: () => new Promise((resolve, reject) => {
        resolve(ImportHelper.loadComponent('BancontactSection'))
            .catch(e => {
                window.logger.e(e)
                resolve(PaymentGatewayError)
            })
    }),
    GooglePaySection: () => new Promise((resolve, reject) => {
        resolve(ImportHelper.loadComponent('GooglePaySection'))
            .catch(e => {
                window.logger.e(e)
                resolve(PaymentGatewayError)
            })
    }),
    GiropaySection: () => new Promise((resolve, reject) => {
        resolve(ImportHelper.loadComponent('GiropaySection'))
            .catch(e => {
                window.logger.e(e)
                resolve(PaymentGatewayError)
            })
    }),
    DotpaySection: () => new Promise((resolve, reject) => {
        resolve(ImportHelper.loadComponent('DotpaySection'))
            .catch(e => {
                window.logger.e(e)
                resolve(PaymentGatewayError)
            })
    }),
    OfflinePaymentSection: () => new Promise((resolve, reject) => {
        resolve(ImportHelper.loadComponent('OfflinePaymentSection'))
    }),
    NetbankingSection: () => new Promise((resolve, reject) => {
        resolve(ImportHelper.loadComponent('NetbankingSection'))
        .catch(e => {
            window.logger.e(e)
            resolve(PaymentGatewayError)
        })
    }),
    UpiSection: () => new Promise((resolve, reject) => {
        resolve(ImportHelper.loadComponent('UpiSection'))
        .catch(e => {
            window.logger.e(e)
            resolve(PaymentGatewayError)
        })
    }),
    BoletoSection: () => new Promise((resolve, reject) => {
        resolve(ImportHelper.loadComponent('BoletoSection'))
            .catch(e => {
                window.logger.e(e)
                resolve(PaymentGatewayError)
            })
    }),
    VenmoSection: () => new Promise((resolve,reject)=>{
        resolve(ImportHelper.loadComponent('VenmoSection'))
        .catch(e =>{
            window.logger.e(e)
            resolve(PaymentGatewayError)
        })
    }),
    KlarnaPayNowSection: () => new Promise((resolve,reject)=>{
        resolve(ImportHelper.loadComponent('KlarnaPayNowSection'))
        .catch(e =>{
            window.logger.e(e)
            resolve(PaymentGatewayError)
        })
    }),
}
