import Vue from "vue"
import { mapGetters } from "vuex"
import Button from "@/components/collections/form/ButtonComponent"
import ReferralComponent from "@/components/modules/hosted-pages/ReferralComponent"
export default {
  mounted(){
    this.triggerHeaderAnimation();
  },
	computed: {
    ...mapGetters([
      'action_pages_redirect_url'
    ]),
    header() {
      if (this.$route.meta.headerTitle) {
        return this.$store.getters.translate(this.$route.meta.headerTitle);
      }
      return this.$store.getters.translate(this.editMode ? `${this.$route.name}.header.edit` : `${this.$route.name}.header`);
    },
    headerText() {
      return this.$store.getters.translate("thankyou.header");
    },
    bodyText() {
      return this.$store.getters.translate("thankyou.body");
    },
    thankYouClose() {
      return this.t('thankyou.close');
    },
    goToAppText() {
      return this.t('redirect.label');
    },
    showReferralSection() {
      return false
    }
	},
	methods: {
    triggerHeaderAnimation() {
      this.$refs.parent.style.height = document.getElementById("cb-header").$prevHeight ? document.getElementById("cb-header").$prevHeight : "0px";
      window.cbTimeout(() => {
        if (!window.isMobile) {
          this.$refs.parent.style.height = "480px";
        } else {
          this.$refs.parent.style.height = window.innerHeight + "px";
        }
        this.fadeHeaderContent = false;
        this.scaleLogo = true;
      }, 400);
    },
    gotoApp() {
      this.$store.dispatch('action_pages_redirect');
    }
	},
	data() {
		return{
      startContentAnimation: false,
      scaleLogo: false,
      fadeHeaderContent: true,
      editMode: false
		}
	},
	components: {
    'button-field': Button,
    'referral-section': ReferralComponent
	}
}