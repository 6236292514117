<template>
  <div>
    <div class="cb-section cb-bar cb-portal-section">
      <cb-slide-n-fade :isList="true" :is-list="true" :timeOut="true" :reCalculateMargin="true">
          <!-- TODO: rename item to subscription in v-for loop variable -->
        <div
          class="cb-bar__wrap"
          :class="{'cb-bar__wrap--noborder': index == limit - 1  && subscriptionLimit && subscriptionsToShow.length >= subscriptionLimit}"
          v-cb-link="'shadow'"
          v-for="(item, index) in subscriptionsToShow"
          @click="navigate_to('sub_details', {params: {subscriptionId: item.id}})"
          v-if="(index < limit)"
          role="navigation"
          data-cb-id="subscription_details"
          v-bind:key="index"
        >
          <div class="cb-bar__title" v-if="item.isMeteredPlan || !item.hide_plan">
            <span v-if="item.isGiftClaimed">
              <i class="icon-gift" :style="themeColor"></i>
            </span>
            <span
              class="cb-bar__flag"
              v-if="item.state == 'cancelled'"
            >{{st('subscription_status.canceled')}}</span>
            <span
              class="cb-bar__flag"
              v-if="item.state == 'paused'"
            >{{st('subscription_status.paused')}}</span>
            <span class="cb-bar__middle">{{item.plan_name}}</span>
            <span
              class="cb-bar__middle"
              v-if="canShowQuantity(item)"
            >&times; {{quantity(item)}}</span>
          </div>
          <template v-if="item.addons && item.addons.length > 0">
            <div
              class="cb-bar__title"
              v-for="(addon, index) in item.addons"
              v-if="index < 1"
              v-bind:key="index"
            >
              <template v-if="!item.isMeteredPlan || item.hide_plan">
                <span
                  class="cb-bar__flag"
                  v-if="item.state == 'cancelled'"
                >{{st('subscription_status.canceled')}}</span>
                <span
                  class="cb-bar__flag"
                  v-if="item.state == 'paused'"
                >{{st('subscription_status.paused')}}</span>
              </template>
              {{addon.details.invoice_name || addon.details.external_name || addon.details.name }}
              <span
                v-if="addon.metered_quantity"
              >&times; {{addon.metered_quantity}}</span>
              <span
                v-if="addon.quantity_in_decimal && canShowAddonQty(addon)"
              >&times; {{addon.quantity_in_decimal}}</span>
              <span
                v-else-if="addon.quantity > 1"
              >&times; {{addon.quantity}}</span>
            </div>
          </template>
          <div
            v-if="item.addons && item.addons.length > 1"
          >+{{item.addons.length - 1}} {{getMoreItemTxt()}}</div>
          <div class="cb-bar__content">
            <template v-if="item.hide_plan && (!item.addons || item.addons.length == 0)">
              <span
                class="cb-bar__flag"
                v-if="item.state == 'cancelled'"
              >{{st('subscription_status.canceled')}}</span>
              <span
                class="cb-bar__flag"
                v-if="item.state == 'paused'"
              >{{st('subscription_status.paused')}}</span>
            </template>
            <template v-if="item.isAllItemsMetered">
              <p class="cb-metered-billing__desc">{{cmt('metered_billing_desc')}}</p>
            </template>
            <span
              class="cb-bar__price"
              v-if="canShowPrice(item)"
            >{{ item.recurring_price | cbCurrency(item.currency_code) }}</span>
            <span
              class="cb-bar__desc"
              v-if="!isGift(item) && billingText(item)"
            >{{ billingText(item) }}</span>
            <div class="cb-truncate" v-if="additionalSummaryInfo(item)"
             v-cb-html="additionalSummaryInfo(item)"
             >
              <!-- {{additionalSummaryInfo(item)}} -->
            </div>
            <span v-if="isGift(item)">{{gifterInfo(item)}}</span>

            <div
              class="cb-bar__desc"
              v-if="item.description && subscriptionsToShow.length == 1"
              v-cb-html="sanitizeTags(item.description)"
            ></div>
          </div>

          <cb-notes v-if="isContractSub(item)"
            type="suggestion"
            class="cb-mar--t-sm cb-notebar--sm cb-bar__note"
            :message="t('contract_term.subscription.note')"
          />
          <cb-notes
            v-if="isPaidByParent(item)"
            type="suggestion"
            class="cb-mar--t-sm cb-notebar--sm cb-bar__note"
            :message="getPaidByParentInfo(item)"
          />
          <cb-notes
            v-if="canShowMandateID(item)"
            type="warning"
            class="cb-mar--t-sm cb-notebar--sm cb-bar__note"
            :message="'E-mandate Required'"
          />
        </div>
      </cb-slide-n-fade>
      <list-sub-limit :subscriptionLimit=this.subscriptionLimit></list-sub-limit>
    </div>
    <div class="cb-section" v-if="this.subscriptionLimit">
      <div
        class="cb-section__wrap"
        v-if="subscriptionsToShow.length == 0 && giftedSubsDisplayList.length > 0"
      >
        <div
          class="cb-section__title cb-section__title--xs cb-section__title--muted"
        >&ndash; {{t('home.gift_header')}} &ndash;</div>
        <gift-template giftLimit="1"></gift-template>
      </div>
      <div v-if="showGiftBar" class="cb-bar cb-bar--dashed">
        <div
          class="cb-bar__wrap cb-bar__muted"
          v-cb-link="'shadow'"
          @click="navigate_to('gift_template')"
          v-if="giftedSubsDisplayList.length > 0"
          role="link"
          data-cb-id="load_more"
        >
          <i class="icon-gift" :style="themeColor"></i>
          <span class="cb-bar__middle">{{t('home.gift_bar',{number:giftedSubsDisplayList.length })}}</span>
        </div>
      </div>
    </div>
    <div class="cb-section" v-if="this.subscriptionLimit">
      <div
        class="cb-section__wrap"
        v-if="subscriptionsToShow.length == 0 && giftedSubsDisplayList.length == 0 && childSubDataDisplayList.length >0"
      >
        <div
          class="cb-section__title cb-section__title--xs cb-section__title--muted"
        >&ndash; {{t('home.child_sub_header') || 'Child subscriptions'}} &ndash;</div>
        <child-sub-template childSubLimit="1"></child-sub-template>
      </div>
      <div v-if="showChildSubBar" class="cb-bar cb-bar--dashed">
        <div class="cb-bar__wrap cb-bar__muted" v-cb-link="'shadow'" @click="navigate_to('child_sub_template')" v-if="childSubDataDisplayList.length > 0" role="link" data-cb-id="load_more">
          <i class="icon-parent-child" :style="themeColor"></i>
          <span class="cb-bar__middle">
            {{getChildSubBarTxt()}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { mapGetters } from "vuex";
import PortalGiftTemplateCommon from "@/components/modules/portal/PortalGiftTemplateCommon";
import PortalChildSubTemplateCommon from "@/components/modules/portal/PortalChildSubTemplateCommon";
import ListSubscriptionLimit from "@/components/modules/portal/ListSubscriptionLimit"
import Notes from "@/components/collections/elements/Notes";
import MultiDecimalHelper from "@/utils/multi-decimal"
export default {
  computed: {
    ...mapGetters([
      "subDataDisplayList",
      "giftedSubsDisplayList",
      "childSubDataDisplayList",
      "child_subscription_count",
      "payment_method_list",
      "primary_pm"
    ]),
    limit() {
      return this.subscriptionLimit
        ? this.subscriptionLimit
        : this.subDataDisplayList.length;
    },
    showGiftBar() {
      return this.subscriptionsToShow.length == 0
        ? this.giftedSubsDisplayList.length > 1
        : this.giftedSubsDisplayList.length > 0;
    },
    showChildSubBar() {
      return (
        ((this.subscriptionsToShow.length != 0 ||
          this.giftedSubsDisplayList.length != 0) &&
          this.childSubDataDisplayList.length > 0) ||
        this.childSubDataDisplayList.length > 1
      );
    },
    showNumberOfItemsMore() {
      return !this.$store.state.customer.subscription_next_offset;
    },
    subscriptionsToShow() {
      return this.subDataDisplayList.filter(i =>
        i.isGift ? i.isGiftClaimed : true
      );
    },
  },
  props: ["subscriptionLimit"],
  components: {
    "gift-template": PortalGiftTemplateCommon,
    "child-sub-template": PortalChildSubTemplateCommon,
    "cb-notes": Notes,
    "list-sub-limit": ListSubscriptionLimit
  },
  methods: {
    navigate_to(section, options) {
      options = options || {};
      options.name = section;
      this.$router.customPush(options);
    },
    isContractSub(item) {
      return !!item.subscription.contract_term
    },
    canShowQuantity(item) {
      if(item.isMeteredPlan) return true
      if(item.isMultiDecimal) return !(parseFloat(item.plan_quantity_in_decimal) == 1)
      return item.plan_quantity > 1
    },
    canShowAddonQty(addon) {
      if(!addon) return false;
      // if(addon.hasOwnProperty('metered_quantity')) {
      //   return !!addon.metered_quantity;
      // }
      if(addon.pricing_model == 'flat_fee') return false;
      const qty = parseFloat(addon.quantity_in_decimal || addon.quantity)
      return !isNaN(qty) && qty != 1;
    },
    billingText(item) {
      return this.$store.getters.billingText(item);
    },
    additionalSummaryInfo(item) {
      return this.dataTranslate('subscription.summary.additionalInfo', {subscription: item.subscription});
    },
    getChildSubBarTxt(){
      return this.t(((this.childSubDataDisplayList.length > 1) ? 'home.child_sub_bar.multiple' : 'home.child_sub_bar.single') ,{number: this.child_subscription_count });

    },
    gifterInfo(item) {
      return this.t("home.gift_sub.gifted_by", {
        name: item.gift_data.gifter.signature
      });
    },
    isPaidByParent(item) {
      return item.isPaidByParent(this.$store);
    },
    getPaidByParentInfo(item) {
      return this.t("home.my_subscription.parent_pays", {
        customer_display_info: item.getPaymentOwnerDisplayName(this.$store)
      });
    },
    getMoreItemTxt() {
      return this.t("more_items_txt.custom_label") || this.st("more_items_txt");
    },
    isGift(item) {
      return item.gift_id;
    },
    sanitizeTags(content) {
      return {
        text: content,
        tags: ["br"],
        attrs: {}
      };
    },
    quantity(item) {
      return item.plan_quantity_metered || item.plan_quantity_in_decimal || item.plan_quantity;
    },
    canShowPrice(subscription) {
      return !this.isGift(subscription) && !subscription.isAllItemsMetered
    },
    paymentMethod(item) {
      let pm = null;
      if(item && item.payment_source_id) {
        let payment_source_id = item.payment_source_id;
        pm = payment_source_id && this.payment_method_list.filter(({id}) => id == payment_source_id)[0];
      }
      return pm || this.primary_pm;
    },
    canShowMandateID(item) {
      const pm_by_mandate_enabled = this.$store.getters.pm_by_mandate_enabled;
      const pm = this.paymentMethod(item) || {};
      if(!pm_by_mandate_enabled || item.offline_payment_method || (pm_by_mandate_enabled.type != pm.type)) {
        return false;
      }
      if(['non_renewing', 'cancelled'].includes(item.subscription.status)) {
        return false;
      } else if(!this.$store.getters.pm_by_mandate_enabled || !(item.subscription.payment_source_id || this.$store.getters.customer_data.primary_payment_source_id)) {
        return false;
      } else if(item.subscription && item.subscription.payment_mandates && item.subscription.payment_mandates[0] && item.subscription.payment_mandates[0].mandate_id) {
         const paymentSourceId = item.subscription.payment_source_id || this.$store.getters.customer_data.primary_payment_source_id;
        const mandate = item.subscription.payment_mandates.filter(mandate => mandate.payment_source_id === paymentSourceId);
        return !mandate.length;
      } else {
        return true;
      }

    },
  }
};
</script>
<style lang="stylus">
.cb-portal-section {
  .cb-bar__wrap:hover {
    .cb-notebar {
      background: #e7eff7;
    }
  }
  .cb-notebar__content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.cb-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cb-metered-billing__desc {
  margin-bottom: 2px;
}
</style>
