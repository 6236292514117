import cart from '@/store/common/cart'
import apiClient from "@/utils/cb-client"
import Transformer from "@/utils/transformer"
import CartHelper from '@/utils/cart-helper'
import Vue from 'vue'
import HostedPageHelper from "@/utils/hosted-page-helper"
import EstimateData from "@/models/estimate-data"
import CBNotification from "@/models/cb-notification";
import PortalHelper from '@/utils/portal-helper';
import SubItemsModel from '@/utils/items-model';

// TODO try to reuse order.js or share functions
// DRY it
const state = {
  estimate: {},
  addons_master_list: [],
  extend_sub_billing_cycle: undefined,
  addon_item_selection: {
    selected_item_list: [],
    selected_item_price: {}, // item-id map
  }
}

const SET_CONSOLIDATED_ITEMS = "SET_CONSOLIDATED_ITEMS";
const SET_ESTIMATE = "SET_ESTIMATE";
const SET_SUBSCRIPTION_HANDLE = "SET_SUBSCRIPTION_HANDLE";
const SET_ADDON_MASTERLIST = "SET_ADDON_MASTERLIST";
const SET_MISSIN_ADDON_DETAILS = "SET_MISSIN_ADDON_DETAILS";
const SET_EXTEND_SUB_BILLING_CYCLE = "SET_EXTEND_SUB_BILLING_CYCLE";
const SET_ADDON_ITEMS = "SET_ADDON_ITEMS";
const ADD_ADDON_ITEM = "SELECT_ADDON_ITEM";
const REMOVE_ADDON_ITEM = "REMOVE_ADDON_ITEM";
const SET_ADDON_ITEM_PRICE = "SET_ADDON_ITEM_PRICE";
const SELECT_ADDON_ITEM_PRICE = "SELECT_ADDON_ITEM_PRICE";

function mergeArray(a, b) {
  return a.concat(b.filter(k => a.map(i => i.id).indexOf(k.id) == -1)).map(i => i.id);
}

const actions = {
  calculate_estimate({ commit, state, getters, rootState, dispatch }, options) {
    options = options || {};
    dispatch('set_loader', true);
    return apiClient.cart.update_subscription({id: getters.subscription_handle}, Transformer.estimateRequestForUpdateSubscription(state, rootState, getters)).then(data => {
        dispatch('replace_order_item', HostedPageHelper.constructOrderItemsFromCart(data, state.cart.orderItems[0]));
        dispatch('add_plan_to_list', data.order.plan.details);
        if (state.cart.orderItems[0].addons.length > 0 && data.order.addons.length > 0) {
          dispatch('add_addons_to_list', data.order.addons.map(({details}) => details));
        }
        commit(SET_MISSIN_ADDON_DETAILS, rootState.subData.addonList);
        commit(SET_ESTIMATE, new EstimateData(data, getters.cart_items, getters, true));
        dispatch('set_loader', false);
        if (options.resolve) {
          options.resolve(true);
        }
      }).catch(error => {
        dispatch('set_loader', false);
        if (options.reject) {
          options.reject(error);
        } else {
          dispatch('set_current_notification', new CBNotification('error', error));
          error.notifyBackend = true;
          logger.e(error);
        }
      });
  },

  set_subscription_handle({ commit, state }, subscription_handle) {
    commit(SET_SUBSCRIPTION_HANDLE, subscription_handle);
  },

  clear_estimates({ commit, state }) {
    commit(SET_ESTIMATE, {})
  },

  set_extend_sub_billing_cycle({ commit, state }, billingCycle) {
    commit(SET_EXTEND_SUB_BILLING_CYCLE, billingCycle);
  },

  trigger_cart_change({ commit, state, dispatch, getters}) {
    window.cbTimeout(() => {
      let estimate = getters.estimate;
      dispatch('clear_estimates');
      commit(SET_ESTIMATE, estimate);
    }, 750);
  },

  set_master_addon_list_from_other_products({commit, state, getters, dispatch}, {other_products, allowed_addons, is_items_model, other_product_items}) {
    // set master list of addons
    // initiliaze with current list of addons

    // if product item has available addons
    // then add it to the master list and ignore global addons

    // else add global addons

    let plan = state.cart.orderItems[0].plan;
    let productItem = other_products && other_products.filter(p => p.plan_id == plan.id)[0];
    const allowNonRec = getters.allow_non_rec_addons;
    // addons already attached in cart will be checked against period and period unit
    if(productItem) {
      let addonList = state.cart.orderItems[0].addons || [];
      let tempAddonList = productItem.allowed_addons || [];
      tempAddonList = PortalHelper.filterAddonsBasedOnPlanTerm(tempAddonList, productItem, allowNonRec);
      allowed_addons = PortalHelper.filterAddonsBasedOnPlanTerm(allowed_addons, productItem, allowNonRec)
      addonList = (productItem.is_addons_restricted || tempAddonList.length > 0) ? mergeArray(addonList, tempAddonList) : mergeArray(addonList, allowed_addons);
      dispatch('set_addon_list', {addonList, isItemsModel: is_items_model});
      commit(SET_ADDON_MASTERLIST, addonList);

      // let itemsAddonList = ((other_product_items || {}).item_prices || [])
      //   .filter(itemPrice => {
      //     if(productItem.is_addons_restricted && addonList.indexOf(itemPrice.id) == -1) return false;
      //     if(itemPrice.item_type == 'addon') return true;
      //     if(allowNonRec && itemPrice.item_type == 'charge') return true;
      //     return false;
      //   }).map(itemPrice => itemPrice.id)
      // commit(SET_ADDON_MASTERLIST, is_items_model ? itemsAddonList : addonList);
      
    }
    else {
      commit(SET_ADDON_MASTERLIST, []);
    }
  },

  set_overrides_from_other_products({ commit, state, getters, dispatch }, { other_products, allowed_addons }) {

    let plan = state.cart.orderItems[0].plan;
    let productItem = other_products && other_products.filter(p => p.plan_id == plan.id)[0];

    if (productItem && productItem.meta_data && productItem.meta_data.quantity_meta) {
      dispatch('set_quantity_meta_overrides', { meta: productItem.meta_data.quantity_meta, item: plan })
    }


    state.cart.orderItems[0].addons.forEach(a => {
      // for addons first check in addons in product item
      let chosenOne = productItem && productItem.addons.filter(addon => addon.id == a.id)[0];
      if (!chosenOne) {
        // available addons in product item
        chosenOne = productItem && productItem.allowed_addons.filter(addon => addon.id == a.id)[0];
      }
      if (!chosenOne) {
        // available addons globally
        chosenOne = allowed_addons.filter(addon => addon.id == a.id)[0];
      }
      if (chosenOne && chosenOne.meta_data && chosenOne.meta_data.quantity_meta) {
        dispatch('set_quantity_meta_overrides', { meta: chosenOne.meta_data.quantity_meta, item: a })
      }
    });
  },

  add_addon_item({ commit, state, getters, dispatch }, itemId) {
    commit(ADD_ADDON_ITEM, itemId);
  },

  remove_addon_item({ commit, state, getters, dispatch }, itemId) {
    commit(REMOVE_ADDON_ITEM, itemId);
  },

  select_addon_item_price({ commit, state, getters, dispatch }, itemPrice) {
    commit(SELECT_ADDON_ITEM_PRICE, itemPrice)
  },

  set_default_addon_item_prices({ commit, state, getters, rootState, dispatch }, {subData, force}) {
    const itemAnditemPriceMap = state.addon_item_selection.selected_item_price;

    // Set default addon items only once, ignore if already set
    if(!force && Object.keys(itemAnditemPriceMap).length > 0) return;

    const otherProductItems = subData && subData.other_product_items;
    const subDataDisplay = subData && subData.displayer() || {}
    let showCharges = false;

    if(subDataDisplay.state == "in_trial") {
      showCharges = (
        getters.order_estimate_holder.immediateEstimate &&
        getters.order_estimate_holder.immediateEstimate.line_items &&
        getters.order_estimate_holder.immediateEstimate.line_items.length > 0
      ) || false;
    } else {
      showCharges = true;
    }

    const addonItems = (otherProductItems && otherProductItems.items &&
      otherProductItems.items.filter(item => {
        return item.type == 'addon' || (showCharges ? item.type == 'charge' : false);
      })
    ) || [];

    const addonItemIds = addonItems.map(addonItem => addonItem.id)

    const addonItemPrices = (otherProductItems && otherProductItems.item_prices &&
      otherProductItems.item_prices.filter(itemPrice => addonItemIds.indexOf(itemPrice.item_id) > -1)
    ) || [];

    const currentPlan = state.cart.orderItems[0].plan.id;
    const currentPlanItemPrice = (otherProductItems && otherProductItems.item_prices &&
      otherProductItems.item_prices.find(itemPrice => itemPrice.id == currentPlan)
    ) || {}

    const itemIdAndItemPriceMap = {}
    addonItems.map(item => {
      const addonItemPricesByItemId = addonItemPrices.filter(itemPrice => itemPrice.item_id == item.id);
      const itemPrice = SubItemsModel.getSuitableAddonItemPrice(currentPlanItemPrice, item, addonItemPricesByItemId)
      itemIdAndItemPriceMap[item.id] = itemPrice
    })

    commit(SET_ADDON_ITEM_PRICE, itemIdAndItemPriceMap);
    commit(SET_ADDON_ITEMS, [])
  },

  set_addon_item_price({ commit, state, getters, rootState, dispatch }, itemIdAndItemPriceMap) {
    commit(SET_ADDON_ITEM_PRICE, itemIdAndItemPriceMap);
  }
}

const mutations = {
  [SET_CONSOLIDATED_ITEMS](state, consolidated_items) {
    Vue.set(state, "consolidated_items", consolidated_items);
  },

  [SET_ESTIMATE](state, estimate) {
    Vue.set(state, 'estimate', estimate);
  },

  [SET_SUBSCRIPTION_HANDLE](state, subscriptionHandle) {
    Vue.set(state, 'subscription_handle', subscriptionHandle);
  },

  [SET_ADDON_MASTERLIST](state, addonList) {
    Vue.set(state, 'addons_master_list', addonList);
  },

  [SET_MISSIN_ADDON_DETAILS](state, addon_details) {
    if (addon_details.length > 0) {
      state.cart.orderItems[0].addons.forEach(function(addon) {
        if (!addon.details) {
          Vue.set(addon, 'details', addon_details.filter(detail => detail.id == addon.id)[0]);
        }
      });
    }
  },

  [SET_EXTEND_SUB_BILLING_CYCLE](state, billingCycle) {
    Vue.set(state, 'extend_sub_billing_cycle', billingCycle);
  },

  [SET_ADDON_ITEMS](state, addonItemIds) {
    Vue.set(state.addon_item_selection, 'selected_item_list', addonItemIds);
  },

  [ADD_ADDON_ITEM](state, addonItemId) {
    const addonItemIds = state.addon_item_selection.selected_item_list.map(id => id);
    addonItemIds.push(addonItemId);
    Vue.set(state.addon_item_selection, 'selected_item_list', addonItemIds);
  },

  [REMOVE_ADDON_ITEM](state, addonItemId) {
    let addonItemIds = state.addon_item_selection.selected_item_list;
    let filteredItemIds = addonItemIds.filter(itemId => itemId !== addonItemId);
    Vue.set(state.addon_item_selection, 'selected_item_list', filteredItemIds);
  },

  [SELECT_ADDON_ITEM_PRICE](state, itemPrice) {
    const itemPriceMap = Object.assign({}, state.addon_item_selection.selected_item_price);
    itemPriceMap[itemPrice.item_id] = itemPrice;
    Vue.set(state.addon_item_selection, 'selected_item_price', itemPriceMap);
  },

  [SET_ADDON_ITEM_PRICE](state, itemIdAndItemPriceMap) {
    Vue.set(state.addon_item_selection, 'selected_item_price', itemIdAndItemPriceMap);
  }
}

const getters = {
  line_items: state => state.estimate.lineItems || [],
  get_line_item_by_entity_id: (state, getters) => (entityId) => {
    return getters.line_items.find(item => item.entity_id == entityId);
  },
  discounts: state => state.estimate.discounts || [],
  taxes: state => state.estimate.taxes || [],
  currency_code: (state, getters) => state.estimate.currencyCode || (getters.change_order_plan && getters.change_order_plan.details && getters.change_order_plan.details.currency_code),
  estimate_total: state => state.estimate.total,
  consolidated_items: state => state.estimate.consolidated_items || [],
  total_discount: (state, getters) => getters.discounts.reduce((a, i) => { a += i.amount; return a }, 0),
  consolidated_discount: (state, getters) => getters.consolidated_items.filter(i => i.type == "discount")[0],
  consolidated_tax: (state, getters) => getters.consolidated_items.filter(i => i.type == "tax")[0],
  is_tax_inclusive: state => state.estimate && state.estimate.priceType == "tax_inclusive",
  is_tax_exclusive: state => state.estimate && state.estimate.priceType == "tax_exclusive",
  subscription_handle: state => state.subscription_handle,
  order_items: (state) => state.cart.orderItems[0],
  change_order_plan: (state) => state.cart.orderItems[0] && state.cart.orderItems[0].plan,
  cart_items_loaded: (state, getters) => {
    return getters.order_cart_items && getters.order_cart_items.some(i => i.details)
  },
  order_cart_items: (state, getters) =>  getters.cart_items,
  addons_master_list: (state) => state.addons_master_list,
  estimate: (state) => state.estimate,
  is_cart_with_estimate: (state) => false,
  order_estimate_holder: (state) => state.estimate.orderEstimateHolder || {},
  amount_due: (state, getters) => getters.estimate.amountDue || 0,
  current_amount_due: (state, getters) => ((getters.order_estimate_holder.immediateEstimate && getters.order_estimate_holder.immediateEstimate.amount_due) || 0),
  total_credits: (state, getters) => CartHelper.getTotalCreditsFromDiscounts(getters.estimate.discounts, getters.estimate.creditsApplied),
  estimate_loaded: state => (Object.keys(state.estimate).length > 0),
  extend_sub_billing_cycle: state => state.extend_sub_billing_cycle,
  allow_non_rec_addons: (state, getters) => getters.can_allow("change-subscription.action.allow_add_non_rec_addons"),
  is_discount_present: (state, getters) => (getters.coupon_list.length > 0) && getters.consolidated_discount,
  recommended_addons: (state, getters) => {
    // Code duplication between change-order.js & order.js
    // TODO DRY
    if(!(state.cart.orderItems && state.cart.orderItems[0])) {
      return [];
    }
    let plan = state.cart.orderItems[0].plan;
    let applied_addons = state.cart.orderItems[0].addons || [];
    let plan_attached_addons = plan && plan.details && plan.details.attached_addons|| [];
    let plan_attached_items = plan && plan.details && plan.details.attached_items || []

    if(plan_attached_items.length){
      plan_attached_items = plan_attached_items.map(({attached_item}) => attached_item)

      let plan_recommended_items = plan_attached_items.filter(i => i.type == "recommended") || [];
      const appliedItemPriceIds = applied_addons.map(addon => addon.id);

      // Filter applied attached items
      return plan_recommended_items.filter(attachedItem => {
        return attachedItem.item_prices.every(({item_price: itemPrice}) => appliedItemPriceIds.indexOf(itemPrice.id) == -1)
      })
    } else {
      let plan_recommended_addons = plan_attached_addons.filter(i => i.type == "recommended") || [];
      return plan_recommended_addons.filter(i => !applied_addons.some( e => e.id == i.id)) || [];
    }
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  modules: {
    cart
  }
}
