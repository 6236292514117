export default {
    getQuantity(entity) {
        if (!entity) return;
        const quantity = entity.quantity_used_in_decimal || entity.quantity_in_decimal_used || entity.quantity_used // entity type is tier object
            || entity.quantity_in_decimal // entity type is line item
            || entity.plan_quantity_in_decimal
            || entity.quantity_in_decimal || entity.quantity // entity type applicable for plans or addons
            || entity.plan_quantity; // entity type for portal subscription
        return quantity;
    },
    removeTrailingZeros(value) {
        if(!value || typeof value != 'string') return value;
        if(value.indexOf('.') > -1) {
            let [num, decimal] = value.split('.')
            decimal = decimal.replace(/0+$/, '')
            return [num, decimal].join('.')
        }
        return value
    },
    getDefaultQuantity() {
        return (window.initInfo.multi_decimal && window.initInfo.multi_decimal.enabled) ? "1" : undefined;
    },
    getInitialQuantity(entity) {
        if (!entity) return;
        const quantity = entity.plan_quantity_in_decimal || entity.plan_quantity ||
            entity.quantity_in_decimal || entity.quantity;
        return quantity;
    },
    getFreeQuantity(entity) {
        if (!entity) return;
        let quantity = entity.free_quantity_in_decimal || entity.free_quantity;
        if (parseFloat(quantity) == 0) return 0;
        return quantity;
    },
    getUnitAmount(entity) {
        if (!entity) return;
        const unitAmount = entity.unit_amount_in_decimal ||
            entity.unit_amount_in_decimal || //entity type is tier for portal subscription
            entity.unit_amount;
        return unitAmount;
    },
    getAmount(entity) {
        if (!entity) return;
        const amount = entity.amount_in_decimal || entity.amount;
        return amount;
    },
    isMultiDecimal(item) {
        return item && (!!item.amount_in_decimal
            || !!item.plan_amount_in_decimal
            || !!item.quantity_in_decimal
            || !!item.plan_quantity_in_decimal
        );

    },
    isGreaterThan(value1, value2) {
        return parseFloat(value1) > parseFloat(value2) ? true : false;
    },
    parseValue(value, options) {
        if (value
            && typeof value === 'string'
            && value.includes(".")) {
            return (options && options.raw_float) ? value : parseFloat(value);
        }
        return (options && options.raw_integer) ? value : parseInt(value);
    }
    // getUnitAmount(entity1, entity2) {
    //     const unitAmount = (entity1 && entity1.unit_amount_in_decimal)
    //         || (entity2 && entity2.unit_amount_in_decimal) || (entity1 && entity1.unit_amount)
    //         || (entity2 && entity2.unit_amount)
    //     return unitAmount;
    // },
}
