import CbError from "@/utils/cb-error"
import Transformer from "@/utils/transformer"
import apiClient from "@/utils/cb-client"
import {updateBTClientInstance} from "@/models/three-ds-handler"

const STRIPE_TOKENIZE = "STRIPE_TOKENIZE";
const BRAINTREE_TOKENIZE = "BRAINTREE_TOKENIZE";

export default class TokenizationHandler {
  constructor(config, skipInit) {
    let gwName = Object.keys(config).filter(k => k != "common_config")[0];
    // TODO assert gwName
    this.gwConfig = config[gwName];
    this.commonConfig = config.common_config;
    if(!skipInit) {
      this.initializeHandler();
    }
  }

  static isTokenizationAllowed(pm) {
    return pm && (pm.gateway_name == "STRIPE" || pm.gateway_name == "BRAINTREE")
  }

  // card details should be transformed card details
  tokenize(cardDetails, identifier) {
    // map chargebee card fields to stripe's card fields
    switch(this.gwConfig.handler){
      case "stripe":
        return this[STRIPE_TOKENIZE](cardDetails, identifier);
      case "braintree":
        return this[BRAINTREE_TOKENIZE](cardDetails, identifier);
      default:
        return Promise.reject(`Tokenization not available for ${this.gwConfig.handler}`)
    }
  }

  initializeHandler() {
    switch(this.gwConfig.handler){
      case "stripe":
        return Stripe.setPublishableKey(this.gwConfig.client_id);
    }
  }

  [STRIPE_TOKENIZE](cardDetails, identifier) {
    // map chargebee card fields to stripe's card fields
    cardDetails = Transformer.flatten(cardDetails, 'card');
    let payloadObject = Object.keys(this.gwConfig.fields).reduce((prev, key) => {
      if(cardDetails[key]){
        prev[this.gwConfig.fields[key]] = cardDetails[key];
      }
      return prev;
    }, {});

    // normalize first name and last name to name
    payloadObject['name'] = [payloadObject.first_name, payloadObject.last_name].filter(i => !!i).join(" ");
    delete payloadObject.first_name;
    delete payloadObject.last_name;
    payloadObject["number"] = payloadObject["number"].replace(/\s+/g, '');

    return new Promise((resolve, reject) => {
      var startTime = new Date().getTime();
      Stripe.card.createToken(payloadObject, (status, response) => {
        if (response.error) {
          reject(new CbError(status, response.error, "stripe"));
        } else {
          resolve({status: status, token: response.id});
        }
        this.logTokenization(status, response.error, identifier, startTime);
      });
    });
  }

  static generateBraintreeDeviceData(braintree, braintreeInstance){
		if(!braintree || !braintreeInstance) {
			return Promise.resolve(null);
		}
		return braintree.dataCollector.create({
			client: braintreeInstance,
      kount: true
		}).then((dataCollectorInstance) => {
			try {
				return JSON.parse(dataCollectorInstance.deviceData);
			} catch (err) {
				return null;
			}
		});
	}

  [BRAINTREE_TOKENIZE](cardDetails, identifier) {
    // map chargebee card fields to stripe's card fields
    cardDetails = Transformer.flatten(cardDetails, 'card');
    let payloadObject = Object.keys(this.gwConfig.fields).reduce((prev, key) => {
      if(cardDetails[key]){
        let arr = this.gwConfig.fields[key].split(".");
        prev[arr[0]] = prev[arr[0]] || {};
        prev[arr[0]][arr[1]] = cardDetails[key];
      }
      return prev;
    }, {});

    payloadObject['creditCard']['number'] = payloadObject['creditCard']['number'].replace(/\s+/g, '');
    payloadObject['creditCard']['cardholderName'] = [payloadObject.creditCard.firstName, payloadObject.creditCard.lastName].filter(i => !!i).join(" ");
    delete payloadObject.creditCard.firstName;
    delete payloadObject.creditCard.lastName;
    payloadObject['creditCard']['expirationDate'] = [payloadObject.creditCard.expirationMonth, payloadObject.creditCard.expirationYear].filter(i => !!i).join("/");
    delete payloadObject.creditCard.expirationMonth;
    delete payloadObject.creditCard.expirationYear;
    payloadObject['creditCard']['billingAddress'] = payloadObject['billingAddress'];
    delete payloadObject['billingAddress'];
    payloadObject['creditCard']["options"] = {validate: false};

    return new Promise((resolve, reject) => {
      var startTime = new Date().getTime();
      braintree.client.create({
        authorization: this.gwConfig.client_id
      }, (err, clientInstance) => {
          if (err) {
            let status = err.details ? err.details.httpStatus : err.code;
            reject(new CbError(status, err, "braintree"));
            this.logTokenization(status, err, identifier, startTime);
          } else {
            updateBTClientInstance(clientInstance);
            TokenizationHandler.generateBraintreeDeviceData(braintree, clientInstance)
            .then((deviceData) => {
              let fDeviceData = null;
              if(deviceData) {
                fDeviceData = {
                  braintree: {
                    fraud: { 
                      device_session_id: deviceData.device_session_id, 
                      fraud_merchant_id:  deviceData.fraud_merchant_id
                    } 
                  }
                }
              }
              clientInstance.request({
                endpoint: 'payment_methods/credit_cards',
                method: 'post',
                data: payloadObject
              }, (err, response) => {
                  let status;
                  if (err) {
                    status = err.details.httpStatus;
                    reject(new CbError(status, err, "braintree"));
                  } else {
                    status = response._httpStatus;
                    resolve({status: status, token: response.creditCards[0].nonce, deviceData: fDeviceData});
                  }
                  this.logTokenization(status, err, identifier, startTime);
              });
            }).catch(err => {
              reject(new CbError(err.code||err.status, err, "braintree"));
            })
          }
      });
    });
  }

  logTokenization(status, error, identifier, startTime) {
    try{
      var d = {};
      var endTime = new Date().getTime();
      var timeTaken =  endTime - startTime;
      d['gw_time'] = timeTaken;
      d['customer_id'] = identifier;
      d['gw_type'] = this.gwConfig.gw_name;
      d['gw_http_code'] = status;
      if (error) {
          d['gw_error_message'] = error.message;
      }
      apiClient.loggers.tokenization({}, d);
    }
    catch(error) {
      logger.e(error);
    }
  }

  loadScript(resolve, reject) {
    switch(this.gwConfig.handler){
      case "stripe":
        if(!window.Stripe) {
          asyncLoad(this.gwConfig.js_url, () => resolve(true), () => reject("Stripe js taking time to load"));
        }
        else {
          resolve(true)
        }
        break;
    }
  }
}