export default {
  // When the bound element is inserted into the DOM...
  inserted: function(el, binding, vnode) {
    if (!vnode.context.$store || ['color', 'shadow'].indexOf(binding.value) == -1) {
      return;
    }
    Handler.common(el);
    return binding.value == 'shadow' ? Handler.castShadow(el, vnode) : Handler.castColor(el, vnode);
  },
}
var Handler = {
  //shadow to brand color on focus
  //2 shadows - inner shadow - app color, outer shadow - brand color
  castShadow: function(el, vnode) {
    var shadow = vnode.context.$store.getters.shadow_color ? vnode.context.$store.getters.shadow_color["color"] : "#00ABC9";
    el.addEventListener('focus', function(event) {
      el.$prevShadow = Handler.getComputedStyle(el, "box-shadow");
      var shadow_primary = Handler.getComputedStyle(el, "background-color");
      shadow_primary = (shadow_primary == "rgba(0, 0, 0, 0)") ? "#f4f5f9" : shadow_primary; //#f4f5f9 - app modal color
      el.style['box-shadow'] = "0 0 0 2px " + shadow_primary + ", 0 0 6px 3px " + shadow;
    });
    el.addEventListener('focusout', function(event) {
      el.style['box-shadow'] = el.$prevShadow;
    });
  },
  //highlight color to brand color on focus
  castColor: function(el, vnode) {
    var color = vnode.context.$store.getters.color ? vnode.context.$store.getters.color["color"] : "#00ABC9";
    el.addEventListener('focus', function(event) {
      el.$color = Handler.getComputedStyle(el, "color");
      el.style['color'] = color;
      el.style['transform'] = "translateX(3px)";
      el.style['transition'] = "all 0.2s ease";
    });
    el.addEventListener('focusout', function(event) {
      el.style['color'] = el.$color;
      el.style['transform'] = "none";
    });
  },
  //common actions for both cast shadow and cast color
  common: function(el) {
    el.addEventListener('keydown', function(event) {
      var keyCode = event.which || event.keyCode;
      if (keyCode == 13) {
        el.click();
      }
    });
    el.tabIndex = '0';
    el.style.outline = 'none';
  },
  getComputedStyle: function(element, prop) {
    if (element.currentStyle) {
      return element.currentStyle[prop];
    } else if (window.getComputedStyle && window.getComputedStyle(element, null)) {
      return window.getComputedStyle(element, null).getPropertyValue(prop);
    } else {
      return element.style[prop];
    }
  }
}