
// LATM Countries - Argentina, Brazil, Chile, Colombia, Ecuador, Mexico, Peru, Uruguay
const LATM_COUNTRIES = ["AR", "BR", "CL", "CO", "EC", "MX", "PE", "UY"];

export default class DlocalHelper {

	static getLatmCountryCodes() {
		return LATM_COUNTRIES;
	}

  // https://mychargebee.atlassian.net/browse/CHKOUTENGG-9413
  static canShowDocumentIdField(pm, store) {
		if(!pm || !pm.enabled_dlocal) {
			return false;
		}
    let country = store.getters.field_config("card", "billing_country") && store.getters.field_config("card", "billing_country").value;
    return LATM_COUNTRIES.find((e) => e == country);
  }

	static skipThreeDS(pm, store, paymentInfo) {
		if(!pm.enabled_dlocal) {
			return false;
		}
		let country = DlocalHelper.getBillingCountry(store, paymentInfo);
		return country && LATM_COUNTRIES.find((e) => e == country);
	}

	static getBillingCountry(store, paymentInfo) {
		let pm = paymentInfo || store.getters.selected_payment_method;
		if(pm && pm.type == 'card') {
			return pm.billing_country || (pm.card && pm.card.billing_country);
		}
		return null;
	}

	static getSavedFormData(store) {
		return store.getters.card_additional_info && store.getters.card_additional_info.form_data
	}

	static saveAtTempStore(pm, store, documentId, countryOfResidence) {
		if(DlocalHelper.canShowDocumentIdField(pm, store)) {
			store.dispatch("set_form_temp", {
				dlocal_form: {
          document_id: documentId,
					country_of_residence: countryOfResidence,
          country_code: store.getters.field_config("card", "billing_country").value,
        }
			});
		}
	}

	static clearTempStore(store) {
		store.dispatch('delete_form_temp',  'dlocal_form');
	}

	static saveFormData(pm, store) {
		let form = store.getters.get_form_temp('dlocal_form');
		if(form && DlocalHelper.canShowDocumentIdField(pm, store) 
			&& form.document_id
			&& form.country_of_residence
			&& form.country_code
			&& store.getters.field_config("card", "billing_country").value == form.country_code) {
			
			let transformed = { checkout_com : { payer: { document: form.document_id, country_of_residence: form.country_of_residence}}};
			store.dispatch('set_card_additional_info', {transformed_data: transformed, form_data: form}); 
			DlocalHelper.clearTempStore(store);

		} else {
			store.dispatch('set_card_additional_info', null); 
		}
	}

	// https://docs.dlocal.com/api-documentation/payins-api-reference/country-reference#documents
	static getDocumentIdFieldConfig(countryCode) {
		switch(countryCode) {
			case 'AR': // Argentina
				return { type: 'num', min: 7, max: 11 };
			case 'BR': // Brazil
				return { type: 'num', min: 11, max: 14 };
			case 'CL': // Chile
				return { type: 'text', min: 8, max: 9 };
			case 'CO': // Colombia
				return { type: 'num', min: 6, max: 10 };
			case 'EC': // Ecuador
				return { type: 'num', min: 5, max: 20 };
			case 'MX': // Mexico
				return { type: 'text', min: 10, max: 18 };
			case 'PE': // Peru
				return { type: 'num', min: 8, max: 9 };
			case 'UY': // Uruguay
				return { type: 'num', min: 6, max: 8 };
			default:
				return { type: 'text', min: 1, max: 20 };
		}
	}

}