/* eslint-disable */
import sanitizeHtml from "sanitize-html";

export default {
  inserted: function(el, binding, vnode) {
    if (!binding && !binding.value) {
      return;
    }
    sanitizeHtmlHandler(el, vnode, binding);
  },

  bind: function(el, binding, vnode) {
    if (!binding && !binding.value) {
      return;
    }
    sanitizeHtmlHandler(el, vnode, binding);
  },

  update: function(el, binding, vnode) {
    if (
      el.tagName == "TEXTAREA" ||
      el.contentEditable == "true" ||
      (!binding && !binding.value)
    ) {
      return;
    }
    sanitizeHtmlHandler(el, vnode, binding);
  }
};

function sanitizeHtmlHandler(el, vnode, binding) {
  let value =
    typeof binding.value === "object" && binding.value !== null
      ? binding.value.text || ""
      : binding.value || "";
  let allowedTags = binding.value.tags || ["b", "i", "em", "strong", "a"];
  let allowedAttrs = binding.value.attrs || {
    a: ["href", "target"]
  };
  let sanitizedContent = sanitizeHtml(value, {
    allowedTags: allowedTags,
    allowedAttributes: allowedAttrs
  });
  el.innerHTML = sanitizedContent;
}
