import LogTracker from '@/utils/log-helper';
const triggerBeacon = (store) => {
    return () => {
        try {
            if (store.getters.has_logs_pushed) return;
            new LogTracker(store.getters, store.dispatch).logUserExit();
            const payload = JSON.stringify({
                data: {
                    hp_user_action: "exit",
                    ...store.getters.sanitized_logs,
                    hp_log_agent: "beacon"
                },
                type: "kvl"
            });
            if (navigator && navigator?.sendBeacon) {
              navigator.sendBeacon('/api/internal/kvl', payload);
            }
        } catch (error) {
            console.log(error);
        }
    };
}

export default triggerBeacon;
