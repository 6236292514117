import Vue from "vue"
import apiClient from "@/utils/cb-client"
import CanCan from "@/utils/can-can"
import vatHelper from "@/utils/vat-helper"
import { MANAGE_PAYMENTS, EXTEND_SUBSCRIPTION, FULL_ACCESS } from "@/store/common/auth"
import TokenizationHandler from "@/utils/tokenization-handler"
import gstHelper from "@/utils/gst-helper"
import { baseState, baseActions, baseMutations, baseGetters } from "@/store/common/base-config"
import { reorderFields } from "@/utils/helper-functions"
import pauseConfig from "@/utils/pause-config"
import contractConfig from "@/utils/subscription-contract-config";
import subCancelReasonCodeConfig from "@/utils/sub-cancel-reason-code-config"
import { checkPaymentGateway, CDN } from "@/components/modules/hosted-pages/LazyLoadPaymentComponents"
import ImportHelper from '@/utils/chunkImportWrapper'

// TODO
// Need to have a base config and share it with hosted page config
const state = {
  ...baseState,
  sections: {},
  loaded: false,
  entry_point: undefined,
  subinfo_loaded: false,
  fwt: undefined,
  forwardAction: undefined,
  requestContent: undefined,
  backToEntryPoint: false, // used in header type 2
  configLoader: undefined,
  show_mandate_screen: false, // currently we don't support mandate in portal for razorpay
}

// Non logged in texts
const SET_LOADED = "SET_LOADED";
const SET_CONFIG_LOADER = "SET_CONFIG_LOADER";
const SET_SECTIONS = "SET_SECTIONS";
const SET_ALLOWED_ACTIONS = "SET_ALLOWED_ACTIONS";
const SET_ENTRY_POINT = "SET_ENTRY_POINT";
const SET_CAN_CAN = "SET_CAN_CAN";
const SET_SUBINFO_LOADED = "SET_SUBINFO_LOADED";
const SET_NL_TEXTS = "SET_NL_TEXTS";
const SET_LOCALE = "SET_LOCALE";
const SET_CONFIG_LOCALE = "SET_CONFIG_LOCALE";
const SET_TEXTS = "SET_TEXTS";
const SET_FWT = "SET_FWT";
const SET_FORWARD_ACTION = "SET_FORWARD_ACTION";
const SET_REQUEST_CONTENT = "SET_REQUEST_CONTENT";
const SET_BACK_TO_ENTRY_POINT = "SET_BACK_TO_ENTRY_POINT";
const SET_CONFIGURED_OPM_LIST = "SET_CONFIGURED_OPM_LIST";
const SET_STYLE_CONFIG = "SET_STYLE_CONFIG";

var loadOptionsForCustomer = function(scopes) {
  let output = {
    "skipResyncPmList" : true
  }
  if (scopes.indexOf(MANAGE_PAYMENTS) > -1) {
    output["skipShippingAddressList"] = true;
  }
  if (scopes.indexOf(EXTEND_SUBSCRIPTION) > -1) {
    output["skipShippingAddressList"] = output["skipBillingAddress"] = true;
  }
  if (scopes.indexOf(FULL_ACCESS) > -1) {
    output["getGiftSubscriptions"] = true;
    output["loadRelations"] = window.initInfo && window.initInfo.account_hierarchy_enabled;
  }
  return output;
}

const actions = {
  ...baseActions,

  load_portal_config({ commit, state, dispatch, getters, rootState }) {
    return Promise.all([
      dispatch('_load_portal_config'),
      // TODO load only required things
      dispatch('init_returning_user', loadOptionsForCustomer(rootState.auth.scopes)),
      dispatch('_init_gateway_lists')
    ]).then(() => {
      // TODO this should be loaded only for portal home
      commit(SET_LOADED, true);
      if (rootState.customer.subscriptions.length == 0 && rootState.customer.gifted_subscriptions.length == 0 && rootState.customer.child_subscriptions.length == 0) {
        commit(SET_SUBINFO_LOADED, true);
      }
      else {
        const configLoader = new Promise((resolve, reject) => {
          dispatch('init_sub_data').then(() => {
            return Promise.all([
              dispatch('fetch_recurring_estimate'),
              dispatch('fetch_estimates_for_gifts'),
              dispatch('fetch_recurring_estimate_for_child_subs'),
              dispatch('fetch_plan_details'),
              dispatch('fetch_einvoice_details'),
              dispatch('fetch_gift_data')
            ]).then(() => Promise.all([
              dispatch('link_sub_data_details'),
              dispatch('link_gift_data_details'),
              dispatch('link_child_sub_data_details')
            ])).then(() => {
              commit(SET_SUBINFO_LOADED, true);
              resolve();
            });
          }).catch(err => {
            reject(err)
          });
        })
        commit(SET_CONFIG_LOADER, configLoader)
      }
    });
  },

  resync_texts({ rootState, commit, dispatch }, options) {
    var promises = [];
    promises.push(apiClient.field_configs.retrieve_init_info(options.customer).then((data) => {
      commit(SET_STYLE_CONFIG, data);
      commit(SET_NL_TEXTS, data.non_loggedin_texts);
      commit(SET_LOCALE, data.init_info_locale);
      window.currency_list = data.currency_list;
    }));

    promises.push(apiClient.field_configs.portal(options.customer).then((data) => {
      commit(SET_CONFIG_LOCALE, data.configuration_locale);
      commit(SET_TEXTS, data.txts);
      commit(SET_SECTIONS, data.sections.reduce((o, section) => {
        section['fields'] = reorderFields(section.fields);
        o[section.type] = section;
        if (section.type == "billing_address" && section.tax_config) {
          vatHelper.setVatConfig(JSON.parse(section.tax_config));
        }
        if (section.type == "billing_address" && section.gst_config) {
          gstHelper.setGstConfig(JSON.parse(section.gst_config));
        }
        return o;
      }, {}))
      subCancelReasonCodeConfig.init(data.sub_cancel_reason_config);
    }));
    return Promise.all(promises);
  },
  resync_pm_list({rootState, dispatch}) {
    return new Promise((resolve, reject) => {
      apiClient.gateway.retrieve({}, {
        entity_type: "CUSTOMER",
        entity_id: rootState.auth.customer_handle
      }).then((data) => {
        if(getters.configured_offline_pm_list.length > 0) {
          dispatch('set_configured_opm_list', data.opm_configured_list);
        }
        dispatch('set_pm_list', data.pm_list);
        resolve(true);
      }).catch((error) => {
        logger.e(error);
        reject(error);
      });
    });
  },
  _remove_allowed_action({ commit, state, getters }, action) {
    let actions = getters.allowed_actions.filter(e => e != action);
    commit(SET_ALLOWED_ACTIONS, actions);
    commit(SET_CAN_CAN, new CanCan(state.allowed_actions, state.sections));
  },

  _add_allowed_action({ commit, state, getters }, newAction) {
    let actions = getters.allowed_actions;
    if (actions.indexOf(newAction) == -1) {
      actions.push(newAction);
      commit(SET_ALLOWED_ACTIONS, actions);
      commit(SET_CAN_CAN, new CanCan(state.allowed_actions, state.sections));
    }
  },

  _load_portal_config({ commit, state, rootState, dispatch }) {
    return new Promise((resolve, reject) => {
      if (!state.loaded) {
        try {
          var data = window.portalConfig;
          dispatch('set_base_config', data);
          pauseConfig.setPauseSettings(data.pause_config);
          contractConfig.init(data.sub_contract_config);
          subCancelReasonCodeConfig.init(data.sub_cancel_reason_config);
          commit(SET_ALLOWED_ACTIONS, data.allowed_actions);
          commit(SET_SECTIONS, data.sections.reduce((o, section) => {
            section['fields'] = reorderFields(section.fields);
            o[section.type] = section;
            if (section.type == "billing_address" && section.tax_config) {
              // beware of the name vat_config tax_config
              vatHelper.setVatConfig(JSON.parse(section.tax_config));
            }
            if (section.type == "billing_address" && section.gst_config) {
              gstHelper.setGstConfig(JSON.parse(section.gst_config));
            }
            return o;
          }, {}));

          commit(SET_CAN_CAN, new CanCan(state.allowed_actions, state.sections));
          resolve(true);
        } catch (error) {
          reject(error);
        };
      } else {
        resolve(true);
      }
    });
  },

  _init_gateway_lists({ commit, state, rootState, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      if (rootState.auth.scopes.indexOf(MANAGE_PAYMENTS) > -1 || rootState.auth.scopes.indexOf(EXTEND_SUBSCRIPTION) > -1) {
        // load token details
        apiClient.hosted_pages.get({}, { token: rootState.auth.hpToken }).then((data) => {
          dispatch('set_pm_list', data.pm_list);
          commit(SET_REQUEST_CONTENT, data.content);
          dispatch('set_action_pages_redirect_url', data.action_pages_redirect_url);
          let promises = data.pm_list.filter(pm => {
            if (pm.tokenization_config) {
              return new Promise((resolve, reject) => {
                // add third party scripts dynamically
                new TokenizationHandler(pm.tokenization_config, true).loadScript(resolve, reject);
              });
            }
          });
          Promise.all(promises).then(() => resolve(true)).catch((error) => reject(error));
        }).catch((error) => {
          reject(error)
        });
      } else {
        apiClient.gateway.retrieve({}, {
          entity_type: "CUSTOMER",
          entity_id: rootState.auth.customer_handle
        }).then((data) => {
          dispatch('set_pm_list', data.pm_list);
          dispatch('set_configured_opm_list', data.opm_configured_list);
          if (data.pm_list.length > 0 || data.opm_configured_list.length > 0) {
            dispatch("_add_allowed_action", "payment.allow");
          } else {
            dispatch("_remove_allowed_action", "payment.allow");
          }
          resolve(true);
        }).catch((error) => {
          reject(true);
          logger.e(error);
        });
      }
    }).then(() => {
      if(checkPaymentGateway().loadChargebeejs) {
        // Do not wait for 3DS load
        window.cbjsLoader.then(() => {
          // For Edge browser, Form post is not affecting the history.length but affect the history stack. so we ignore this fix
          let forCbCheckout = !(/Edge|MSIE|Trident/.test(navigator.userAgent));
          const cbInstance = Chargebee.getInstance();
          cbInstance.options.forCbCheckout = forCbCheckout; 
          return cbInstance.load3DSHandler();
        });
      }
      return true;
    });
  },

  resync_subscription_details({ commit, state, rootState, dispatch }) {
    return new Promise((resolve, reject) => {
      if (rootState.customer.subscriptions.length == 0) {
        resolve(true);
        return;
      }
      commit(SET_SUBINFO_LOADED, false);
      return Promise.all([
        dispatch('load_shipping_address_list')
        ]).then(() => {
        dispatch('init_subscriptions', true);
        return Promise.all([
          dispatch('fetch_recurring_estimate'),
          dispatch('fetch_recurring_estimate_for_child_subs'),
          dispatch('fetch_plan_details'),
          dispatch('fetch_einvoice_details'),
          dispatch('fetch_gift_data')
        ]).then(() => {
          dispatch('link_sub_data_details');
          dispatch('link_child_sub_data_details');
          commit(SET_SUBINFO_LOADED, true);
          resolve(true);
        });
      }).catch(error => {
        reject(error);
        logger.e(error)
      });
    });
  },

  load_subscription_details({ commit, state, rootState, dispatch, getters}, subscriptionId) {
    return new Promise((resolve, reject) => {
      let index = rootState.customer.subscriptions.findIndex(o => o.id == subscriptionId);
      let childIndex = rootState.customer.child_subscriptions.findIndex(o => o.id == subscriptionId);
      if (index != -1 || childIndex != -1) {
        //if subscription already loaded
        state.configLoader.then(() => resolve(true)).catch(err => reject(err))
        return;
      }
      commit(SET_SUBINFO_LOADED, false);
      let _subData;
      dispatch('load_subscription', subscriptionId).then(() => {
        _subData = getters.fetchSubData(subscriptionId);
        return Promise.all([
          dispatch('fetch_recurring_estimate_sub', {subData: _subData, skipLinkSubData: true}),
          dispatch('fetch_plan_details')
        ])
      }).then(() => {
        dispatch('link_sub_data_detail', _subData);
        commit(SET_SUBINFO_LOADED, true);
        resolve(true);
      }).catch(error => {
        reject(error);
      });
    });
  },

  set_entry_point({ commit, state }, entryPoint) {
    commit(SET_ENTRY_POINT, entryPoint);
  },

  reset_load({ commit, state }) {
    commit(SET_LOADED, false);
  },

  // forward params
  set_fwt({ commit, state }, fwt) {
    commit(SET_FWT, fwt);
  },

  // this can be used if you want to do a precheck before forwarding to a different domain
  set_forward_action({ commit, state }, forwardAction) {
    commit(SET_FORWARD_ACTION, forwardAction);
  },

  set_back_to_entry_point({commit}, backToEntryPoint) {
    commit(SET_BACK_TO_ENTRY_POINT, backToEntryPoint)
  },

  set_configured_opm_list({commit}, opmList) {
    commit(SET_CONFIGURED_OPM_LIST, opmList)
  },
}


const mutations = {
  ...baseMutations,
  [SET_LOADED](state, loaded) {
    Vue.set(state, 'loaded', loaded);
  },

  [SET_SECTIONS](state, sections) {
    Vue.set(state, 'sections', sections);
  },

  [SET_ALLOWED_ACTIONS](state, allowedActions) {
    Vue.set(state, 'allowed_actions', allowedActions);
  },

  [SET_ENTRY_POINT](state, entryPoint) {
    Vue.set(state, 'entry_point', entryPoint);
  },

  [SET_CAN_CAN](state, cancan) {
    Vue.set(state, 'cancan', cancan);
  },

  [SET_SUBINFO_LOADED](state, loaded) {
    Vue.set(state, 'subinfo_loaded', loaded);
  },

  [SET_FWT](state, fwt) {
    Vue.set(state, 'fwt', fwt);
  },

  [SET_FORWARD_ACTION](state, forwardAction) {
    state.forwardAction = forwardAction;
  },

  [SET_REQUEST_CONTENT](state, reqContent) {
    Vue.set(state, 'requestContent', reqContent);
  },

  [SET_BACK_TO_ENTRY_POINT](state, backToEntryPoint) {
    Vue.set(state, 'backToEntryPoint', backToEntryPoint)
  },

  [SET_CONFIG_LOADER](state, loader) {
    Vue.set(state, 'configLoader', loader)
  },

  [SET_CONFIGURED_OPM_LIST](state, opmList) {
    Vue.set(state, 'configured_opm_list', opmList)
  },
}

const getters = {
  ...baseGetters,
  hosted_page_type: () => "portal",
  translate: state => (key) => {
    return state.texts['inapp_portal.' + key];
  },
  ctranslate: state => (key) => {
    return state.texts['inapp_checkout.' + key];
  },
  nl_translate: state => (key) => {
    return (state.nl_texts && state.nl_texts['inapp_nl_portal.' + key]) || "";
  },
  stranslate: state => (key) => {
    return state.texts['hp_v3.portal.' + key] || (state.nl_texts && state.nl_texts['hp_v3.portal.' + key]) || "";
  },
  portal_loaded: state => state.loaded,
  sections: state => state.sections,
  allowed_actions: state => state.allowed_actions || [],
  entry_point: state => state.entry_point,
  cancan: state => state.cancan,
  can_allow: (state, getters) => (action) => getters.allowed_actions.indexOf(action) > -1,
  coupon_enabled: (state, getters) => getters.can_allow('change-subscription.apply-coupon.action'),
  multi_coupon_enabled: (state, getters) => getters.coupon_enabled && getters.can_allow('change-subscription.apply-coupon.action.allow_multiple'),
  subinfo_loaded: state => state.subinfo_loaded,
  autocomplete_enabled: (state, getters) => (pname) => state.sections[pname] && state.sections[pname].use_address_autocomplete,
  portal_forward_details: state => state.fwt,
  portal_forward_action: state => state.forwardAction,
  request_content: state => state.requestContent,
  ac: state => state.access_control,
  configured_offline_pm_list: state => state.configured_opm_list || [],
}

export default {
  state,
  actions,
  mutations,
  getters
}