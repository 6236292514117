import Transformer from "@/utils/transformer";
import cloneDeep from 'lodash-es/cloneDeep';

class EinvoiceHelper {
    isEnabled = false;
    countryList = [];
    static get POST_STATES() {
        return {
            CREATE: "CREATE",
            UPDATE: "UPDATE",
            DELETE: "DELETE",
            EMPTY: ""
        };
    }

    setEinvoiceBaseConfig(baseConfig) {
        if (baseConfig && baseConfig.isEnabled && baseConfig.collectPublicIdentifiersCheckout)
            this.isEnabled = true;
    }

    /* enabled country schemes : Tax, Legal, Other */
    setEinvoiceCheckoutConfig(checkoutConfig) {
        if (checkoutConfig && checkoutConfig.checkoutCountrySchemes) {
            checkoutConfig.checkoutCountrySchemes.forEach(element => {
                this.countryList.push(element.country);
            });
        }
    }

    /* filter allowed countries with current selected country */
    showEinvoice(countryCode) {
        if (countryCode && this.countryList && this.countryList.includes(countryCode)) 
            return true;
        return false;
    }

    /* 
        # for a matched & allowed country, prepares data & uses API passed / existing saved values for :
            vat_number
            entity_identifier_scheme
            entity_identifiers array 
        # 'customeSchemes' => union of API passed values & any existing / store-saved values : processHpWithCustomerEinvoiceScheme() 
        # 'schemes' => all possible schemes of einvoice-enabled countries
    */
    getEinvoiceSchemes(schemes, countryCode, customerSchemes = [], vatNumber = "", entity_identifier_scheme = "") {
        if (countryCode && schemes && schemes.checkoutCountrySchemes) {
            let currentCountryScheme = schemes.checkoutCountrySchemes.find((cScheme) => cScheme.country == countryCode);
            if (currentCountryScheme) {
                let combinedSchemes = currentCountryScheme.taxSchemes.concat(currentCountryScheme.otherSchemes, currentCountryScheme.legalSchemes, currentCountryScheme.routingSchemes);
                /*  allow schemes of type : OPTIONAL, MANDATORY  */
                let filteredSchemes = combinedSchemes.filter((filterScheme) => filterScheme.type != "HIDDEN"); 
                let finalResult = filteredSchemes.map(fScheme => {
                    return {
                        value: "",
                        errorMessage: "",
                        status: "",
                        isDefault: fScheme.isDefault,
                        country: countryCode,
                        id: "",
                        isVatField: false,
                        ...fScheme
                    };
                });

                /* for PORTAL / API passed values : take UNION of customer's current and available entity identifiers */
                finalResult = this.getEinvoicePortalSchemes(finalResult, customerSchemes);

                /* for PORTAL / API passed values : update if VAT fields already saved / passed by HP API */
                finalResult = this.appendVatFields(finalResult, vatNumber, entity_identifier_scheme);

                return finalResult;
            }
        }
        return [];
    }

    reset(schemes, countryCode) {
        return this.getEinvoiceSchemes(schemes, countryCode);
    }

    resetEinvoiceError(schemes) {
        return schemes.map(scheme => scheme.errorMessage = "");
    }

    checkMandatoryFilled(schemeList) {
      /* this is the country-filtered list */
        let errorIndices = [];
        if (!schemeList) 
            return errorIndices;
        
        
        for (let index in schemeList) {
            if (schemeList[index].type == "MANDATORY" && schemeList[index].value === "") {
                errorIndices.push(index);
            }         
        }
        
        return errorIndices;
    }

    /*
    * isVatField = 'false' by default, 'true' only when in PORTAL customer already has a VAT field / explicitly passed in API
    * a scheme can be saved as VAT for 'isDefault' = true + on configuration : HIDDEN or OPTIONAL / MANDATORY (HIDDEN -> cant be saved as VAT)
    * for few countries, isDefault = true for multiple schemes, by convention -> choose the first entry
    */
    getVatField(schemes) {
        if (schemes) {
            /* filter for 'isVatField' = true */
            for (let item of schemes) {
                if (item.isVatField) {
                    //return [item.value, item.schemeName];
                    return item;
                }
            }

            /* filter for 'isDefault' = true */
            for (let item of schemes) {
                if (item.isDefault) {
                    //return [item.value, item.schemeName];
                    return item;
                }
            }
        }

        //return ["", ""];
        return undefined;
    }

    /* removes errors & resets status, id + keeps only one entry with 'isDefault' = true */
    prepareDataToSend(einvoiceSchemes) {
        if (einvoiceSchemes && einvoiceSchemes.length) {
            let found = false;
            let preparedData = einvoiceSchemes.map(obj => {
                obj.errorMessage = "";
                obj.status = "";
                obj.id = "";

                if (obj.isDefault) {
                    if (!found) {
                        found = true;
                    } else {
                        obj.isDefault = false; /* CONDITION: only one field that has 'isDefault' = true is passed as VAT for CREATE operation */
                    }
                }

                return obj;
            });
            return preparedData;
        }

        return [];
    }

    getMatch(baseSchemes, schemeName) {
        if (baseSchemes && schemeName) {
            for (let item of baseSchemes) {
                if (item.scheme == schemeName) {
                    return [item.id, item.value];
                }
            }
        }

        return undefined;
    }

    /* use to process customer edited entity identifier fields */
    prepareUpdateEinvoiceField(combinedScheme, customerSchemes) {
        combinedScheme = this.prepareDataToSend(combinedScheme); /* removes errors & reset status, id  */
        let vat_number = "";
        let vat_number_prefix = "";
        let entity_identifier_scheme = "";

        /* assign STATUS based on current field values & previous customer saved values */
        if (combinedScheme) {
            for (let scheme of combinedScheme) {

                if (scheme.isVatField) {
                    vat_number = scheme.value;
                    entity_identifier_scheme = scheme.schemeName;
                    vat_number_prefix = (entity_identifier_scheme != "") ? entity_identifier_scheme.split(':')[0] : "";
                    continue;
                }

                let matchedScheme = this.getMatch(customerSchemes, scheme.schemeName);
                let [id, value] = matchedScheme != undefined ? matchedScheme : ["", ""];
                
                if (scheme.value == "" && value == "") {
                    /* EMPTY : default status, will not be sent */
                    continue;
                } else if (scheme.value == "" && value != "" && !scheme.isVatField) {  /* if VAT is deleted then pass empty */
                    /* DELETE */
                    scheme.status = EinvoiceHelper.POST_STATES.DELETE;
                    scheme.id = id;
                    scheme.value = value; /* value cant be EMPTY, override empty to previous value */
                } else if (scheme.value != "" && value == "") {
                    /* CREATE */
                    scheme.status = EinvoiceHelper.POST_STATES.CREATE;
                    scheme.id = id; /* empty id string */
                } else {
                    /* UPDATE */
                    scheme.status = EinvoiceHelper.POST_STATES.UPDATE;
                    scheme.id = id;
                }
            }
        } 

        let entity_identifiers = this.saveFinalDataToCustomer(combinedScheme); /* BulkApi directly picks data from staore */
        return { combinedScheme, entity_identifiers, vat_number, vat_number_prefix, entity_identifier_scheme };
    }

    /**
     * util used to populate & save customer entered entity-identifier values after saving values in billing-section
     * not used when entering billing-section for first-time as entity_identifiers_hp is used to feed-in HP API passed params
    */
    saveFinalDataToCustomer(currScheme) {
        if (currScheme && currScheme.length) {
            let result = [];
            for (let scheme of currScheme) {
                /* VAT has separate API + EMPTY value not sent */
                if (scheme.isVatField || scheme.isDefault || scheme.value == "") {   
                    continue;
                }

                /* CREATE, UPDATE, DELETE */
                /* value not stored for DELETE operation */
                result.push({
                    scheme: scheme.schemeName,
                    value: (scheme.status == "DELETE") ? "" : scheme.value
                });
            }

            return result;
        }

        return [];
    }

    /* internal method to fetch & merge current customer saved values */
    getEinvoicePortalSchemes(schemes, customerSchemes) {
        if (customerSchemes) {
            let data = schemes;
            data.map(scheme => {
                let matchedContents = customerSchemes.find(item => {
                    return (item.scheme == scheme.schemeName || item.schemeName == scheme.schemeName);
                })

                if (matchedContents) {
                    scheme.value = matchedContents.value;
                    scheme.id = matchedContents.id;
                }

                return scheme;
            });

            return data;
        }

        return schemes;
    }

    /* internal method to update VAT fields if already present */
    appendVatFields(finalResult, vatNumber, entity_identifier_scheme) {
        if (vatNumber && vatNumber != "" && entity_identifier_scheme && entity_identifier_scheme != "") {
            let data = finalResult;
            data.map(scheme => {
                if (scheme.schemeName == entity_identifier_scheme) {
                    scheme.isVatField = true;
                    scheme.value = vatNumber;
                }
                return scheme;
            });

            return data;
        }

        return finalResult;
    }

    /*
        Hosted Page API passed params' values are used:
        # CHECKOUT NEW
        # CHECKOUT EXISTING
        # CHECKOUT ONE-TIME
    */
    processHpWithCustomerEinvoiceScheme(hpScheme, customerScheme) {
        if (!customerScheme && hpScheme) {
            /* only CREATE operation possible when NO customer's existing scheme */
            return hpScheme;            
        } else if (customerScheme && !hpScheme) {
            return customerScheme;
        } else if (!customerScheme && !hpScheme) {
            return [];
        } else {
            let result = cloneDeep(customerScheme); /* customer saved entity_identifiers */

            for (let item of hpScheme) {
                if (!item.value && (item.operation == undefined || item.operation != "DELETE")) /* value must be present for : CREATE / UPDATE; */
                    continue;

                if (!item.scheme && (item.operation == undefined || item.operation != "DELETE")) /* scheme must be present for : CREATE / UPDATE; */
                    continue;

                if (!item.scheme && item.operation && item.operation == "DELETE" && !!item.id) {
                    let matchedData = result.find(cusScheme => cusScheme.id == item.id);
                    if (matchedData) {
                        item.scheme = matchedData.scheme;
                        item.value = "";
                    } else
                        continue;
                }
    
                this.saveToScheme(result, item.scheme, item.value); /* using Hosted Page API sent param value */
            }
    
            return result;
        }
    }

    saveToScheme(targetScheme, scheme, value) {
        for (let item of targetScheme) {
            if (item.scheme == scheme) {
                item.value = value
                return ;
            }
        }

        targetScheme.push({
            scheme: scheme,
            value: value
        });
    }
}

export default new EinvoiceHelper();