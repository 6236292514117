import MultiDecimalHelper from "@/utils/multi-decimal"
import paymentHelper, { paymentMethods } from '@/utils/payment-helper'

import einvoiceHelper from "./einvoice-helper";
import StateListHelper from "@/utils/state-list-helper.js"


const { CARD, IDEAL, SOFORT, BANCONTACT, GOOGLE_PAY, APPLE_PAY, GIROPAY, DOTPAY, DIRECT_DEBIT, NETBANKING_EMANDATES, UPI, BOLETO, PAYPAL_EXPRESS_CHECKOUT, VENMO, FASTER_PAYMENTS, PAY_TO, SEPA_INSTANT_TRANSFER, KLARNA_PAY_NOW, ONLINE_BANKING_POLAND } = paymentMethods || {}

export default class RequestTransformer{

  static collectPaymentExistingPaymentId(getters, payment_source_id) {
    var output = {
      currency_code: getters.currency_code,
      payment_source_id,
      amount: getters.amountToBePaid,
      payment_initiator: "customer"
    }

    RequestTransformer.attachPaymentSourceAdditionalData(output, getters.payment_method_list.filter(({id}) => id == payment_source_id)[0]);
    Object.assign(output, this.flattenMulti(getters.selected_invoices.map(i => {
      return {invoice_id: i.id}
    }), 'invoice_allocations'));
    return output;
  }

  static collectPaymentWithIntent(getters, intentId) {
    var output = {
      currency_code: getters.currency_code,
      amount: getters.amountToBePaid,
      "payment_intent[id]": intentId,
      payment_initiator: "customer"
    }
    Object.assign(output, this.flattenMulti(getters.selected_invoices.map(i => {
      return {invoice_id: i.id}
    }), 'invoice_allocations'));
    return output;
  }

  static attachEmptyPaymentIntent(target) {
    Object.assign(target, { "payment_intent[id]": "" });
  }

  static collectPaymentNewSource(store, data, {replace_primary_payment_source, retain_payment_source, postAction}) {
    var output = {
      currency_code: store.getters.currency_code,
      amount: store.getters.amountToBePaid,
      replace_primary_payment_source,
      retain_payment_source,
      payment_initiator: "customer",
      postAction
    }

    if(postAction) {
      RequestTransformer.attachEmptyPaymentIntent(output)
    } else if(!!~[CARD, IDEAL, SOFORT, BANCONTACT, GOOGLE_PAY, APPLE_PAY,  GIROPAY, DOTPAY, NETBANKING_EMANDATES, UPI, DIRECT_DEBIT, PAYPAL_EXPRESS_CHECKOUT, VENMO, FASTER_PAYMENTS, PAY_TO, SEPA_INSTANT_TRANSFER, KLARNA_PAY_NOW, ONLINE_BANKING_POLAND].indexOf(data.type) && store.state.customer.payment_intent) {
      Object.assign(output, this.flatten({
        id: store.state.customer.payment_intent.id
        // gateway_account_id: state.customer.payment_intent.gateway_account_id
      }, 'payment_intent'))
    }
    else if(data.tmp_token) {
      data['payment_method[tmp_token]'] = data.tmp_token;
      delete data.tmp_token;
      data['payment_method[type]'] = data.type;
      delete data.type;
      Object.assign(output, this.createPaymentSourceUsingTmpToken(store.state, store.getters, data, replace_primary_payment_source, true));
    } else if(data.type == 'direct_debit' && !data.tmp_token) {
      Object.assign(output, this.createBankAccountPaymentSource(store.state, store.getters, data, replace_primary_payment_source, true));
    } else {
      Object.assign(output, this.createCardPaymentSource(store.state, store.getters, data, replace_primary_payment_source, true));
    }

    Object.assign(output, this.flattenMulti(store.getters.selected_invoices.map(i => {
      return {invoice_id: i.id}
    }), 'invoice_allocations'));

    return output;
  }

  static estimateRequest(state, rootState, getters) {
    var orderItem = getters.order_items;
    var output = this.baseEstimateRequest(orderItem, getters);

    this.extractVatParameters(output,getters);
    this.extractGstParameters(output,getters);
    Object.assign(output,
            this.formatAddress(rootState.customer.billing_address, 'billing_address'),
            this.formatAddress(state.shipping_address, 'shipping_address'));

    return output;
  }

  static estimateGiftRequest(state, rootState, getters) {
    var orderItem = getters.order_items;
    var output = this.baseGiftRequest(orderItem, getters);
    this.extractVatParameters(output,getters);
    Object.assign(output,
            this.formatAddress(rootState.customer.billing_address, 'billing_address'),
            this.formatAddress(state.shipping_address, 'shipping_address'));

    return output;
  }

  static estimateRequestForCustomer(state, rootState, getters) {
    // Refactor Note: when adding extractGstParameters, code exactly same as estimateRequest so reusing method
    return this.estimateRequest(state, rootState, getters)
  }

  static estimateCreateInvoiceRequest(state, rootState, getters) {
    var orderItem = getters.order_items;
    var output = this.baseInvoiceRequest(orderItem, getters);
    this.extractVatParameters(output, getters);
    if(getters.customer_handle) {
      output["invoice[customer_id]"] = getters.customer_handle;
    }
    output["currency_code"] = getters.preferred_currency_code;
    Object.assign(
      output,
      this.formatAddress(rootState.customer.billing_address, 'billing_address'),
      this.formatAddress(state.shipping_address, 'shipping_address')
    );
    return output;
  }

  static estimateCreateInvoiceItemsRequest(state, rootState, getters) {
    var orderItem = getters.order_items;
    var output = this.baseInvoiceItemsRequest(orderItem, getters);
    this.extractVatParameters(output,getters);
    if(getters.customer_handle) {
      output["invoice[customer_id]"] = getters.customer_handle;
    }
    output["currency_code"] = getters.preferred_currency_code;
    Object.assign(
      output,
      this.formatAddress(rootState.customer.billing_address, 'billing_address'),
      this.formatAddress(state.shipping_address, 'shipping_address')
    );
    return output;
  }

  static estimateGiftRequestForCustomer(state, rootState, getters) {
    var orderItem = getters.order_items;
    var output = this.baseGiftRequest(orderItem, getters);

    Object.assign(
      output,
      this.formatAddress(rootState.customer.billing_address, 'billing_address'),
      this.formatAddress(state.shipping_address, 'shipping_address')
    );

    return output;
  }

  static estimateRequestForUpdateSubscription(state, rootState, getters) {
    var orderItem = getters.order_items;
    var output = this.baseEstimateRequest(orderItem, getters);
    this.extractVatParameters(output, getters);
    this.extractGstParameters(output,getters);
    //removing expired coupon
    Object.keys(output).forEach((key) => {
      if(key.startsWith("coupon_ids[")){
        let cp = orderItem.coupon_list && orderItem.coupon_list.filter(c => c.code == output[key] || c.id == output[key])[0];
        if(cp && cp.details && cp.details.status != "active") {
          delete output[key];
        }
      }
    });

    if(state.extend_sub_billing_cycle) {
      output["billing_cycles"] = state.extend_sub_billing_cycle;
    }

    Object.assign(output,
            this.formatAddress(rootState.customer.billing_address, 'billing_address'),
            this.formatAddress(state.shipping_address, 'shipping_address'));
    return output;
  }

  static baseEstimateRequest(orderItem, getters) {
    var output = this.baseSubscriptionRequest(orderItem);
    output.exclude_address_tax_calculation = getters.excludeAddressForTaxCalculation();
    return output;
  }

  static baseInvoiceRequest(orderItem, getters) {
    var output = {};
    output.payment_initiator = "customer";
    var addons = this.flattenMulti(orderItem.addons, 'addons');
    var charges = this.flattenMultiCharges(orderItem.charges, 'charges');
    var addons = this.flattenMulti(orderItem.addons.map(addon => {
      if(MultiDecimalHelper.isMultiDecimal(addon)){
        delete addon.quantity;
      }
      return addon;
    }), 'addons');

    if(orderItem.coupon_list) {
      for(let i = 0; i < orderItem.coupon_list.length; i++) {
        output[`coupon_ids[${i}]`] =  orderItem.coupon_list[i].code || orderItem.coupon_list[i].id
      }
    }

    if(orderItem.replace_coupon_list) {
      output["replace_coupon_list"] = true;
    }

    if(orderItem.replace_addon_list) {
      output["replace_addon_list"] = true;
    }
    Object.assign(output, addons);
    Object.assign(output, charges);
    output.exclude_address_tax_calculation = getters.excludeAddressForTaxCalculation();
    return output;
  }

  static baseGiftRequest(orderItem) {
    var output = {
      plan_id: orderItem.plan.id,
      plan_quantity: orderItem.plan.quantity,
      payment_initiator: "customer"
    }

    if(orderItem.plan.quantity_in_decimal && orderItem.plan.pricing_model != "flat_fee")  {
      delete output.plan_quantity
      output.plan_quantity_in_decimal = orderItem.plan.quantity_in_decimal
    }

    var addons = this.flattenMulti(orderItem.addons, 'addons');
    var coupons = {};
    if(orderItem.coupon_list) {
      for(let i = 0; i < orderItem.coupon_list.length; i++) {
        coupons[`coupon_ids[${i}]`] =  orderItem.coupon_list[i].code || orderItem.coupon_list[i].id
      }
    }

    if(orderItem.replace_addon_list) {
      output["replace_addon_list"] = true;
    }

    return Object.assign(this.flatten(output, 'subscription'), addons, coupons);
  }

  static baseSubscriptionRequest(orderItem) {
    var output = {
      plan_id: orderItem.plan.id,
      plan_quantity: orderItem.plan.quantity,
      payment_initiator: "customer",
    }

    if(orderItem.plan.quantity_in_decimal && orderItem.plan.pricing_model != "flat_fee")  {
      delete output.plan_quantity
      output.plan_quantity_in_decimal = orderItem.plan.quantity_in_decimal
    }
    if(orderItem.plan.metered) {
      delete output.plan_quantity
      delete output.plan_quantity_in_decimal
    }
    var addons = this.flattenMulti(orderItem.addons.map(addon => {
      if(MultiDecimalHelper.isMultiDecimal(addon)){
        delete addon.quantity;
      }
      if(addon.metered) {
        delete addon.quantity;
        delete addon.quantity_in_decimal;
      }
      return addon;
    }), 'addons');

    if (orderItem.coupon_list) {
      /* coupons */
      /* discount = 'disabled' => coupon ids may come directly from HP API params and these will not have 'type' key */
      let filteredCouponsList = orderItem.coupon_list.filter(item => !item.type || item.type == "coupon");
      for (let i = 0; i < filteredCouponsList.length; ++i) {
        output[`coupon_ids[${i}]`] =  filteredCouponsList[i].code || filteredCouponsList[i].id;
      }
    }

    if(orderItem.replace_coupon_list) {
      output["replace_coupon_list"] = true;
    }

    if(orderItem.replace_addon_list) {
      output["replace_addon_list"] = true;
    }

    return Object.assign(output, addons);
  }

  static updateSubscription(state, postAction = false) {
    let subscription = this.createSubscriptionForCustomer(state, true, postAction);
    const customerData = state.customer.data
    if(customerData?.registered_for_gst){
      subscription['customer[registered_for_gst]'] = customerData.registered_for_gst
    }
    return subscription
  }

  static updateSubscriptionForItems(state, postAction = false) {
    return this.createSubscriptionForItems(state, true, postAction)
  }

  static createSubscriptionForCustomer(state, skipSubscriptionLevel, postAction = false) {
    var output = {};
    var orderItem = state.order.cart.orderItems[0];
    Object.assign(output, this.baseSubscriptionRequest(orderItem));
    var addons = this.flattenMulti(orderItem.addons, 'addons');
    var shippingAddress = this.formatAddress(state.order.shipping_address, 'shipping_address');
    var billingAddress = this.formatAddress(state.customer.billing_address || {}, 'billing_address');
    Object.assign(output, addons, shippingAddress, billingAddress, state.order.subscription);
    if (state.customer.data && state.customer.data.vat_number) {
      output["customer[vat_number]"] = state.customer.data.vat_number;
      if (state.customer.data.vat_number_prefix) {
        output["customer[vat_number_prefix]"] = state.customer.data.vat_number_prefix;
      }
    }
    if(!state.config.recaptcha_checkout) {
      if (postAction) {
        RequestTransformer.attachEmptyPaymentIntent(output)
      } else if(!!~[CARD, IDEAL, SOFORT, BANCONTACT, GOOGLE_PAY, APPLE_PAY, GIROPAY, NETBANKING_EMANDATES, UPI, DIRECT_DEBIT, PAYPAL_EXPRESS_CHECKOUT, VENMO, FASTER_PAYMENTS, PAY_TO, SEPA_INSTANT_TRANSFER, KLARNA_PAY_NOW, ONLINE_BANKING_POLAND].indexOf(state.order.payment_method.type) && state.customer.payment_intent) {
        Object.assign(output, this.flatten({
          id: state.customer.payment_intent.id
        }, 'payment_intent'));
      } else if(paymentHelper.isOfflinePaymentMethod(state.order.payment_method.type)) {
        Object.assign(output, {
          auto_collection: 'off',
          offline_payment_method: state.order.payment_method.type
        });
        if([BOLETO].includes(state.order.payment_method.type)) {
          Object.assign(output, this.flatten({
            id: state.customer.payment_intent.id,
          }, 'payment_intent'));
        }
      } else if(!skipSubscriptionLevel && state.order.pm_subscription_level) {
        Object.assign(output, {payment_source_id: state.order.payment_method.id});
      }
    }
    Object.assign(output, {tos_agreed: state.order.tos_agreed});
    Object.assign(output, {"payless_checkout": state.config.recaptcha_checkout});
    if(state.customer.data.relationship) {
      Object.assign(output, {"override_relationship": true});
    }
    RequestTransformer.attachPaymentSourceAdditionalData(output, state.order.payment_method);
    return output;
  }

  static createInvoice(state, getters, postAction = false) {
    var output = this.createInvoiceForCustomer(state, true, getters, postAction);
    return this.addPaymentMethod(state, output, postAction);
  }

  static createInvoiceForCustomer(state, skipPm, getters, postAction = false) {
    var output = {};
    var orderItem = state.order.cart.orderItems[0];
    Object.assign(output, this.baseInvoiceRequest(orderItem, getters));
    var addons = this.flattenMulti(orderItem.addons, 'addons');
    var shippingAddress = this.formatAddress(state.order.shipping_address, 'shipping_address');
    Object.assign(output, addons, shippingAddress, state.order.invoice);
    if(!state.config.recaptcha_checkout){
      if(postAction) {
        RequestTransformer.attachEmptyPaymentIntent(output)
      } else if(state.order.payment_method && !!~[CARD, IDEAL, SOFORT, BANCONTACT, GOOGLE_PAY, APPLE_PAY, GIROPAY, NETBANKING_EMANDATES, UPI, DIRECT_DEBIT, PAYPAL_EXPRESS_CHECKOUT, VENMO, FASTER_PAYMENTS, PAY_TO, SEPA_INSTANT_TRANSFER, KLARNA_PAY_NOW, ONLINE_BANKING_POLAND].indexOf(state.order.payment_method.type) && state.customer.payment_intent) {
        Object.assign(output, this.flatten({
          id: state.customer.payment_intent.id
        }, 'payment_intent'));
      } else if(!skipPm && getters.selected_payment_method.id) {
        Object.assign(output, {payment_source_id: getters.selected_payment_method.id});
      }
    }
    Object.assign(output, {tos_agreed: state.order.tos_agreed});
    if(state.customer.data.relationship) {
      Object.assign(output, {"override_relationship": true});
    }
    RequestTransformer.attachPaymentSourceAdditionalData(output, state.order.payment_method);
    return output;
  }

  static createInvoiceItems(state, getters, postAction = false) {
    var output = this.createInvoiceItemsForCustomer(state, true, getters, postAction);
    return this.addPaymentMethod(state, output, postAction);
  }

  static addPaymentMethod(state, output, postAction = false) {
    // May need to change the logic if bank account needs to be supported
    if(!state.config.recaptcha_checkout){
      if(postAction) {
        RequestTransformer.attachEmptyPaymentIntent(output);
      } else if(state.order.payment_method && !!~[CARD, IDEAL, SOFORT, BANCONTACT, GOOGLE_PAY, APPLE_PAY, GIROPAY, NETBANKING_EMANDATES, UPI, DIRECT_DEBIT, PAYPAL_EXPRESS_CHECKOUT, VENMO, FASTER_PAYMENTS, PAY_TO, SEPA_INSTANT_TRANSFER, KLARNA_PAY_NOW, ONLINE_BANKING_POLAND].indexOf(state.order.payment_method.type) && state.customer.payment_intent) {
        Object.assign(output, this.flatten({
          id: state.customer.payment_intent.id,
        }, 'payment_intent'));
      }
      else if(state.order.payment_method && state.order.payment_method.type == "card" && !state.order.payment_method.tmp_token) {
        Object.assign(output, this.flatten(state.order.payment_method, CARD));
      } else if(state.order.payment_method && state.order.payment_method.type == "direct_debit" && !state.order.payment_method.tmp_token) {
        if(state.order.payment_method['suffix']) {
          // gocardless BECS(NZ) should pass in this format
          state.order.payment_method['account_number'] =  state.order.payment_method['account_number'] + "-" + state.order.payment_method['suffix'];
          delete state.order.payment_method['suffix']
        }
        Object.assign(output, this.flatten(state.order.payment_method, 'bank_account'));
      } else if(state.order.payment_method) {
        Object.assign(output, this.flatten(state.order.payment_method, 'payment_method'));
      }
    }
    Object.assign(output, {tos_agreed: state.order.tos_agreed});
    if(state.customer.data.relationship) {
      Object.assign(output, {"override_relationship": true});
    }
    return output;
  }

  static createInvoiceItemsForCustomer(state, skipPm, getters, postAction = false) {
    const orderItem = state.order.cart.orderItems[0];
    const output = this.baseInvoiceItemsRequest(orderItem);
    var addons = this.flattenMulti(orderItem.addons, 'addons');
    var shippingAddress = this.formatAddress(state.order.shipping_address, 'shipping_address');
    Object.assign(output, addons, shippingAddress, state.order.invoice);
    if(!state.config.recaptcha_checkout)  {
      if(postAction) {
        RequestTransformer.attachEmptyPaymentIntent(output);
      } else if(state.order.payment_method && !!~[CARD, IDEAL, SOFORT, BANCONTACT, GOOGLE_PAY, APPLE_PAY, GIROPAY, UPI, DIRECT_DEBIT, PAYPAL_EXPRESS_CHECKOUT, VENMO, FASTER_PAYMENTS, PAY_TO, SEPA_INSTANT_TRANSFER, KLARNA_PAY_NOW, ONLINE_BANKING_POLAND].indexOf(state.order.payment_method.type) && state.customer.payment_intent) {
        Object.assign(output, this.flatten({
          id: state.customer.payment_intent.id
        }, 'payment_intent'));
      } else if(!skipPm && getters.selected_payment_method.id) {
        Object.assign(output, {payment_source_id: getters.selected_payment_method.id});
      }
    }
    Object.assign(output, {tos_agreed: state.order.tos_agreed});
    if(state.customer.data.relationship) {
      Object.assign(output, {"override_relationship": true});
    }
    RequestTransformer.attachPaymentSourceAdditionalData(output, state.order.payment_method);
    return output;
  }

  static createSubscriptionForItems(state, skipSubscriptionLevel, postAction = false) {
    const orderItem = state.order.cart.orderItems[0];
    const output = this.baseSubscriptionForItemsRequest(orderItem);

    if(orderItem.replace_coupon_list) {
      output["replace_coupon_list"] = true;
    }

    if(orderItem.replace_addon_list) {
      output["replace_items_list"] = true;
    }

    Object.assign(output, this.formatAddress(state.order.shipping_address, 'shipping_address'))
    Object.assign(output, this.formatAddress(state.customer.billing_address, 'billing_address'));
    this.extractEinvoiceVatParameters(output, state.customer.data);

    Object.assign(output, state.order.subscription);

    if(!state.config.recaptcha_checkout) {
      if(postAction) {
        RequestTransformer.attachEmptyPaymentIntent(output);
      } else if(state.customer.payment_intent) {
        Object.assign(output, this.flatten({
          id: state.customer.payment_intent.id
        }, 'payment_intent'));
        if([BOLETO].includes(state.order.payment_method.type)) {
          Object.assign(output, {
            auto_collection: 'off',
            offline_payment_method: state.order.payment_method.type
          });
        }
      } else if(state.order.payment_method && paymentHelper.isOfflinePaymentMethod(state.order.payment_method.type)) {
        Object.assign(output, {
          auto_collection: 'off',
          offline_payment_method: state.order.payment_method.type
        });
      }
      else if(!skipSubscriptionLevel && state.order.pm_subscription_level) {
        Object.assign(output, {payment_source_id: state.order.payment_method.id});
      }
    }

    Object.assign(output, {tos_agreed: state.order.tos_agreed});
    Object.assign(output, {"payless_checkout": state.config.recaptcha_checkout});
    if(state.customer.data.relationship) {
      Object.assign(output, {"override_relationship": true});
    }
    RequestTransformer.attachPaymentSourceAdditionalData(output, state.order.payment_method);
    return output;
  }

  static constructSubscriptionItemsInput(entity) {
    if(!entity) return;
    const item = {
      item_price_id: entity.id,
      item_type: entity.type,
    }

    if(!entity.metered) {
      item.quantity = entity.quantity;
      item.quantity_in_decimal = entity.quantity_in_decimal;
    }

    if(item.quantity_in_decimal) delete item.quantity;

    return item;
  }

  static baseSubscriptionForItemsRequest(orderItem) {
    const addons = orderItem.addons;
    const plan = orderItem.plan;
    const coupons = orderItem.coupon_list; // includes both coupons and manual discounts
    const items = []
    const output = { payment_initiator : "customer" }

    // Plan
    items.push(this.constructSubscriptionItemsInput(plan))

    // Addons
    addons.map(addon => items.push(this.constructSubscriptionItemsInput(addon)))

    // Coupons
    /* do not add manual_discount */
    if (coupons) {
      let filteredCouponsList = coupons.filter(item => !item.type || item.type == "coupon");
      filteredCouponsList.map((coupon, index) => {
        output[`coupon_ids[${index}]`] = coupon.code || coupon.id;
      });
    }

    items.map((item, index) => {
      Object.keys(item).map(key => {
        output[`subscription_items[${key}][${index}]`] = item[key]
      })
    })

    if(orderItem.replace_coupon_list) {
      output["replace_coupon_list"] = true;
    }

    if(orderItem.replace_addon_list) {
      output["replace_addon_list"] = true;
    }

    return output;
  }

  static baseInvoiceItemsRequest(orderItem) {
    const addons = orderItem.addons;
    const coupons = orderItem.coupon_list;
    const items = []
    const output = { payment_initiator : "customer" }
    // Addons
    addons.map(addon => {
      items.push({
        item_price_id: addon.id,
        quantity: addon.quantity,
        quantity_in_decimal: addon.quantity_in_decimal,
        item_type: addon.type,
      })
    })
    // Coupons
    if (coupons) {
      /* do not add manual_discount */
      let filteredCouponsList = coupons.filter(item => !item.type || item.type == "coupon");
      filteredCouponsList.map((coupon, index) => {
        output[`coupon_ids[${index}]`] = coupon.code || coupon.id
      });
    }

    items.map((item, index) => {
      Object.keys(item).map(key => {
        output[`item_prices[${key}][${index}]`] = item[key]
      })
    })

    if(orderItem.replace_coupon_list) {
      output["replace_coupon_list"] = true;
    }

    if(orderItem.replace_addon_list) {
      output["replace_addon_list"] = true;
    }

    return output;
  }

  static createSubscription(state, postAction = false) {
    var subscription = this.createSubscriptionForCustomer(state,true);
    // May need to change the logic if bank account needs to be supported
    if(!state.config.recaptcha_checkout){
      if (postAction) {
        RequestTransformer.attachEmptyPaymentIntent(subscription)
      } else if(!!~[CARD, IDEAL, SOFORT, BANCONTACT, GOOGLE_PAY, APPLE_PAY, GIROPAY, NETBANKING_EMANDATES, UPI, DIRECT_DEBIT, PAYPAL_EXPRESS_CHECKOUT, VENMO, FASTER_PAYMENTS, PAY_TO, SEPA_INSTANT_TRANSFER, KLARNA_PAY_NOW, ONLINE_BANKING_POLAND].indexOf(state.order.payment_method.type) && state.customer.payment_intent) {
        Object.assign(subscription, this.flatten({
          id: state.customer.payment_intent.id,
        }, 'payment_intent'));
      }
      else if(state.order.payment_method.type == "card" && !state.order.payment_method.tmp_token) {
        Object.assign(subscription, this.flatten(state.order.payment_method, CARD));
      } else if(state.order.payment_method.type == "direct_debit" && !state.order.payment_method.tmp_token) {
        if(state.order.payment_method['suffix']) {
          // gocardless BECS(NZ) should pass in this format
          state.order.payment_method['account_number'] =  state.order.payment_method['account_number'] + "-" + state.order.payment_method['suffix'];
          delete state.order.payment_method['suffix']
        }
        Object.assign(subscription, this.flatten(state.order.payment_method, 'bank_account'));
      } else if(paymentHelper.isOfflinePaymentMethod(state.order.payment_method.type)) {
        Object.assign(subscription, {
          auto_collection: 'off',
          offline_payment_method: state.order.payment_method.type
        });
        if([BOLETO].includes(state.order.payment_method.type)) {
          Object.assign(subscription, this.flatten({
            id: state.customer.payment_intent.id,
          }, 'payment_intent'));
        }
      } else {
        Object.assign(subscription, this.flatten(state.order.payment_method, 'payment_method'));
      }
    }

    const customerData = state.customer.data
    if(this.isCustomerDirectDebit(state)) {
        customerData['allow_direct_debit'] = 'true'
    }

    // ** PC 1.0 **
    //const einvoiceData = state.customer.entity_identifiers;
    //let transformedData = state.checkout.data.type == "checkout_existing" ? this.transformEinvoiceSchemesUpdatePortal(einvoiceData) : this.transformEinvoiceSchemesCheckout(einvoiceData);

    return Object.assign(subscription,
          this.flatten(customerData, 'customer'),
          this.formatAddress(state.customer.billing_address, 'billing_address'),
          this.formatAddress(state.order.shipping_address, 'shipping_address'),
          { "payless_checkout": state.config.recaptcha_checkout },
          { postAction }
          );
  }

  /* used for CHECKOUT_NEW / CHECKOUT_NEW_FOR_ITEMS */
  static transformEinvoiceSchemesCheckout(schemes) {
    /* Einvoice fields are mapped to : 'entity_identifiers' array & 'vat_number' + 'entity_identifier_scheme' */
    let output = {};
    if (schemes && schemes.length) {
      let filledSchemes = schemes.filter(item => !!item.value); /* only SEND non-empty values */

      let index = 0;
      filledSchemes.map(item => {
        if (item.isDefault) {
          /* when Einvoice is used VAT fields are passed in below format */
          /* condition: only one field has 'isDefault' = true */
          output[`customer[vat_number]`] = item.value;
          output[`customer[entity_identifier_scheme]`] = item.schemeName || item.scheme;
        } else {
          output[`entity_identifiers[scheme][${index}]`] = item.schemeName || item.scheme;
          output[`entity_identifiers[value][${index}]`] = item.value;
          ++index;
        }
      });
    }

    return output;
  }

  /* used for PORTAL : PC 1 & 2 + CHECKOUT_EXISTING_FOR_ITEMS */
  static transformEinvoiceSchemesUpdatePortal(schemes) {
    /* Einvoice fields are mapped to : 'entity_identifiers' array & 'vat_number' + 'entity_identifier_scheme' */
    let output = {};
    if (schemes && schemes.length) {
      let filledSchemes = schemes.filter(item => {
        return item.isVatField || (item.value != "" && (item.status == 'CREATE' || item.id != ""));
      }); /* only SEND non-empty values apart from VAT */

      let index = 0;
      filledSchemes.map(item => {
        if (item.isVatField) {
          /* when Einvoice is used VAT fields are passed in below format */
          /* condition: only one field has 'isVatField' = true */
          output[`vat_number`] = item.value;
          if (item.value != "") {
            output[`entity_identifier_scheme`] = item.schemeName || item.scheme; /* not sent for DELETE operation */
          }
        } else {
          output[`entity_identifiers[scheme][${index}]`] = item.schemeName || item.scheme;
          output[`entity_identifiers[value][${index}]`] = item.value;
          output[`entity_identifiers[operation][${index}]`] = item.status;
          if (item.status != 'CREATE') { /* empty ID cannot be passed for CREATE operation */
            output[`entity_identifiers[id][${index}]`] = item.id;
          }
          ++index;
        }
      });
    }

    return output;
  }

  static transformEinvoiceForBillingApi(schemes) {
    let output = {};
    if (schemes && schemes.length) {
      let index = 0;
      for (let item of schemes) {
        // only pass e-invoice fields, exclude vat
        if (item.isVatField || item.isDefault)
          continue;

        let id = item.id;
        let operation = item.operation || item.status;
        let scheme = item.scheme || item.schemeName;
        let value = item.value;

        switch(operation) {
          case "CREATE":
            if (scheme && value) {
              /* no existing ID for CREATE operation */
              output[`entity_identifiers[operation][${index}]`] = operation;
              output[`entity_identifiers[scheme][${index}]`] = scheme;
              output[`entity_identifiers[value][${index}]`] = value;
              ++index;
            }
            break;
          case "UPDATE":
            if (id && scheme && value) {
              output[`entity_identifiers[id][${index}]`] = id;
              output[`entity_identifiers[operation][${index}]`] = operation;
              output[`entity_identifiers[scheme][${index}]`] = scheme;
              output[`entity_identifiers[value][${index}]`] = value;
              ++index;
            }
            break;
          case "DELETE":
            if (id) {
              output[`entity_identifiers[id][${index}]`] = id;
              output[`entity_identifiers[operation][${index}]`] = operation;
              /* for DELETE operation : scheme + value -> OPTIONAL in API req */
              if (!!scheme) output[`entity_identifiers[scheme][${index}]`] = scheme;
              if (!!value) output[`entity_identifiers[value][${index}]`] = value;
              ++index;
            }
            break;
          default:
            break;
        }
      }
    }

    return output;
  }

  static createCardPaymentSource(state, getters, data, replacePrimary, skipReplacePaymentSource) {
    var output = Object.assign(this.flatten(data, CARD), {
      customer_id: state.auth.customer_handle
    });

    if(!skipReplacePaymentSource) {
      output["replace_primary_payment_source"] = replacePrimary || !getters.mpm_supported
    }

    return output;
  }

  static createBankAccountPaymentSource(state, getters, data, replacePrimary, skipReplacePaymentSource) {
    if(data['suffix']) {
      // gocardless BECS(NZ) should pass in this format
      data['account_number'] =  data['account_number'] + "-" + data['suffix'];
      delete data['suffix']
    }
    var output = Object.assign(this.flatten(data, 'bank_account'), {
      customer_id: state.auth.customer_handle
    });
    output["issuing_country"] = output["bank_account[issuing_country]"];

    if(!skipReplacePaymentSource) {
      output["replace_primary_payment_source"] = replacePrimary || !getters.mpm_supported
    }

    return output;
  }

  static createPaymentSourceUsingTmpToken(state, getters, data, replacePrimary, skipReplacePaymentSource) {
    var output = Object.assign(data,{ customer_id: state.auth.customer_handle})

    if(!skipReplacePaymentSource) {
      output["replace_primary_payment_source"] = replacePrimary || !getters.mpm_supported
    }
    return output;
  }

  static createPaymentSourceUsingPaymentIntent(intent, state, getters, replacePrimary, skipReplacePaymentSource) {
    var output = {
      customer_id: state.auth.customer_handle || getters.customer_id,
      "payment_intent[id]": intent.id,
      // gateway_account_id: intent.gateway_account_id
    }
    if (getters.hosted_page_type == "checkout") {
      // This flow will not be called for checkout
      // output["payment_intent[skip_txn_consumption]"] = true;
    }

    if(!skipReplacePaymentSource) {
      output["replace_primary_payment_source"] = replacePrimary || !getters.mpm_supported
    }
    return output;
  }

  static createVoucherPaymentSource(state, getters, data, replacePrimary, skipReplacePaymentSource) {
    var output = {customer_id: state.auth.customer_handle };

    if(!skipReplacePaymentSource) {
      output["replace_primary_payment_source"] = replacePrimary || !getters.mpm_supported
    }
    output["voucher_payment_source[voucher_type]"] = data.type,
    output["voucher_payment_source[tax_id]"] = data.tax_id,
    output["voucher_payment_source[billing_address]"] = JSON.stringify({
      first_name : data.first_name,
      last_name : data.last_name,
      line1 : data.billing_addr1,
      line2 : data.billing_addr2,
      country_code : data.billing_country,
      state_code : data.billing_state || data.billing_state_code,
      city : data.billing_city,
      postal_code : data.billing_zip,
      phone : data.phone,
      email : data.email
    });
    return output;
  }

  static updatePaymentMethod(data) {
    return this.flatten(data, 'payment_method');
  }

  static flattenMulti(items, name) {
    if(!items) return {};
    return items.reduce((o, item, index) => {
      // Rethink whether should we  whitelist params
      Object.keys(item).filter(i => ['id', 'quantity', 'quantity_in_decimal', 'invoice_id'].indexOf(i) > -1).forEach(keyName => {
        o[`${name}[${keyName}][${index}]`] = item[keyName];
      });
      return o;
    },{});
  }

  static flattenMultiCharges(items, name) {
    if(!items) return {};
    return items.reduce((o, item, index) => {
      // Rethink whether should we  whitelist params
      Object.keys(item).filter(i => ['amount', 'description'].indexOf(i) > -1).forEach(keyName => {
        o[`${name}[${keyName}][${index}]`] = item[keyName];
      });
      return o;
    },{});
  }

  static formatAddress(addressData, addressType) {
    if((addressType == "billing_address" || addressType == "shipping_address")
        && addressData != undefined && addressData.hasOwnProperty("state_code")) {
      let countryCode = addressData["country"]
      if(StateListHelper.isStateListPresent(countryCode) &&
          (addressData['state_code'] != undefined && addressData['state_code'] != "") ) {
        addressData["state"] = StateListHelper.getStateFromStateCode(addressData["state_code"],countryCode)
      }
    }
    return this.flatten(addressData, addressType)
  }

  static flatten(data, name) {
    if(!data) return {};
    return Object.keys(data).reduce((o, key) => {
      o[`${name}[${key}]`] = data[key];
      return o;
    }, {});
  }


  static fetchResourceName(paramName) {
    if (paramName == "customer[vat_number]" || paramName == "customer[vat_number_prefix]") {
      return "billing_address";
    } else if (paramName.startsWith("shipping")){
      return "shipping_address";
    }
    var matchedContents = paramName.match(/(.*)\[(.*)\]/);
    return matchedContents && matchedContents[1];
  }

  static fetchParamNameWithoutResource(paramName) {
    var matchedContents = paramName.match(/(.*)\[(.*)\]/);
    return (matchedContents && matchedContents[2]) || paramName;
  }

  static isNumber (value) {
    return typeof value === 'number' && !isNaN(Number(value));
  }

  static isDate(date) {
    let dateFormat = /^\d{4}-\d{2}-\d{2}$/
    return (dateFormat.test(date)) && (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
  }

  static getTimestampFromDate(d){
    if(this.isDate(d)){
      //should always return as Number
     return (new Date(d).getTime())/1000;
    }
    return d;
  }

  static getDateFromTimestamp(t) {
    let d = new Date(Number(t) * 1000);
    if(!!t && this.isNumber(t) && !isNaN(d)){
        return d.toISOString().slice(0,10);
    }
    return t;
  }

  static convertTimeStampToDateTime(unixtimestamp){
    if(!unixtimestamp) return '';
    // Months array
    const months_arr = ['January','February','March','April','May','June','July','August','September','October','November','December'];

    // Convert timestamp to milliseconds
    const date = new Date(unixtimestamp*1000);

    // Minutes
    const minutes = "0" + date.getMinutes();

    // Seconds
    const seconds = "0" + date.getSeconds();

    // Display date time in MM-dd-yyyy h:m:s format
    const fulldate = months_arr[date.getMonth()]+' '+date.getDate()+'-'+date.getFullYear()+' '+date.getHours() + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

    return fulldate;
  }

  static getPaymentInfo(pmData) {
    if(pmData.type == BANCONTACT && pmData.number) {
      return {
        card: {
          firstName:  pmData.first_name,
          lastName: pmData.last_name,
          number: pmData.number.replace(/\s+/g, ''),
          expiryMonth: pmData.expiry_month,
          expiryYear: pmData.expiry_year > 100 ? pmData.expiry_year : (2000 + parseInt(pmData.expiry_year))
        }
      };
    }
    return undefined;
  }

  static isCustomerDirectDebit(state) {
      if(state.order.payment_method && !!~[IDEAL, BANCONTACT, SOFORT, GIROPAY, DIRECT_DEBIT].indexOf(state.order.payment_method.type)) {
        return true;
      }
      return false;
  }
  static extractVatParameters(output, getters) {
    output["customer[vat_number]"] = getters.customer_data.vat_number;
    if (getters.customer_data.vat_number && getters.customer_data.vat_number_prefix) {
      output["customer[vat_number_prefix]"] = getters.customer_data.vat_number_prefix;
    }
  }

  static extractEinvoiceVatParameters(output, customer_data) {
    if (!customer_data) return;

    output["customer[vat_number]"] = customer_data.vat_number;
    if (customer_data.vat_number && customer_data.vat_number_prefix) {
      output["customer[vat_number_prefix]"] = customer_data.vat_number_prefix;
    }
    if (customer_data.vat_number && customer_data.entity_identifier_scheme) {
      output["customer[entity_identifier_scheme]"] = customer_data.entity_identifier_scheme;
    }
  }
  static extractGstParameters(output, getters) {
    if (getters.customer_data.registered_for_gst) {
      output["customer[registered_for_gst]"] = getters.customer_data.registered_for_gst;
    }
  }

  // For existing payment flows, additional_information is not required for chargebee_payments MVP, so commenting it
  static attachPaymentSourceAdditionalData(output, selectedPM) {
    // if(selectedPM
    //     && selectedPM.object == "payment_source"
    //     && selectedPM.gateway == "chargebee_payments"
    //     && window.cbFinixAuth && window.cbFinixAuth.sessionKey) {
    //   Object.assign(output, {"payment_source_additional_information": getCbPaymentFraudData(window.cbFinixAuth.sessionKey)});
    // }
    // if(selectedPM.document_number && selectedPM.gateway_name && UpperCaseGateway.EBANX===selectedPM.gateway_name){
    // Object.assign(output, {"card[additional_information]": JSON.stringify({ ebanx: { payer: {document: selectedPM.document_number }}}) });
    // }
    // console.log(output)
  }

  static getCbPaymentFraudData(sessionKey) {
     return JSON.stringify({ chargebee_payments: { fraud: {fraud_session_id: sessionKey}}})
  }


  static getEbanxAdditionalInfo(docNumber) {
    return JSON.stringify({ ebanx: { payer: {document: docNumber }}});
 }

}
