export default class BulkApi {
  constructor(id) {
    this.requestBody = {
      list: [],
      id
    }
  }

  addRequest(bulkAction) {
    if(bulkAction) this.requestBody.list.push(bulkAction)
    return this
  }

  addToRequestBody(key, value) {
    this.requestBody[key] = value;
  }

  static setRequestApiName(bulkAction, apiName) {
    return Object.assign(bulkAction, { apiName })
  }

  static generateRequestId() {
    // Generate temporary UUID
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }

  // getResponseFromApi(apiName) {
  //   this.requestBody.response_content_id = this.getRequestIdByApiName(apiName);
  // }

  getRequestIdByApiName(apiName) {
    const req = this.requestBody.list.find(req => req.apiName === apiName)
    if (req) return req.content_id
  }

  sanitizeRequests() {
    this.requestBody.list = this.requestBody.list.map(bulkAction => {
      if(bulkAction.url_params) {
        bulkAction.path
        Object.keys(bulkAction.url_params).map(key => {
          bulkAction.path = bulkAction.path.replace(`{${key}}`, bulkAction.url_params[key])
        })
      }
      return {
        path: bulkAction.path,
        query_params: bulkAction.query_params,
        content_id: bulkAction.content_id
      }
    })
  }

  static getResponseKey(contentId, key) {
    return `$\{output[${contentId}]${key ? `.${key}` : ''}}`
  }

  static addUrlParam(sourceAction, destAction, param, responseKey='') {
    if(destAction.url_params) {
      destAction.url_params[param] = BulkApi.getResponseKey(sourceAction.content_id, responseKey)
    } else {
      destAction.url_params = {
        [param]: BulkApi.getResponseKey(sourceAction.content_id, responseKey)
      }  
    }
  }

  toJSON() {
    this.sanitizeRequests();
    return this.requestBody
  }

}
