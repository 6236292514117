<template>
  <div class="cb-field  cb-field--select cb-field--labelTransform" :class="[{'cb-field--active': internalValue || focussed || defaultVal}, customClass,{'cb-field--focus': focussed}, {'cb-field--error':!!errorMessage && !focussed}, {'cb-field--sibling':siblingError},{'cb-field--select-alt': this.config.meta.help_text}, {'cb-field--select-readonly': readonly}]">
    <label class="cb-field__label" :class="{'cb-field__label--s': isShort}" :for='name'> {{displayLabel}}
    </label>
    <select ref="select" type="text" @click="focus($event)" v-model="internalValue" class="cb-field__control" :class="{'cb-field__readonly':readonly}" :disabled="readonly" :readonly="readonly" @focus="focus($event)" @blur="blur($event)" @change="change($event)" @input="input($event)" :autocomplete="autocompleteMapping" :name="name" :id="name" :aria-required='required'>
      <option selected= "selected" disable=disable value="" v-if="showDefault">{{st('select_field_default')}}</option>
      <option v-for="(option, index) in allowedValues()" :value="option.value || option" v-bind:key="index">
        {{option.display_name || option}}
      </option>
    </select>
    <label class="cb-field__label" :class="{'cb-field__label--s': isShort}" :for='name'> {{displayLabel}}</label>
    <div class="cb-field__help" v-if="this.config.meta.help_text">
      <template>
        <div class="cb-field__trigger" :class="{'active-opacity': helpTextShown}" @click="showHelpText($event)" ref="helpText">
        </div>
        <transition name="help-text">
          <div class="cb-field__menu" v-show="helpTextShown">
            {{this.config.meta.help_text}}
          </div>
        </transition>
      </template>
    </div>
    <div class="cb-field__error" v-if="!focussed && errorMessageWrapper">
      {{errorMessageWrapper}}
    </div>
  </div>
</template>
<script>
import AutoCompleteMapping from "@/utils/auto-complete-mapping"
import FieldMixin from "@/mixins/field-mixin"
import { mapGetters } from "vuex"
import ImportHelper from '@/utils/chunkImportWrapper'

export default {
  name: 'select-field',
  props: ['name', 'customClass', 'useparent', 'options', 'siblingError', 'hideDefault'],
  mixins: [FieldMixin],
  created() {
    this.pName = this.findParentForm();
    const locale = (this.init_info_locale || '').slice(0,2).toLowerCase();
    ImportHelper.loadCountries(locale, this.pName).then(countries => {
      this.countryCodeList = countries;
      if((this.name === 'country' || this.name === 'billing_country') 
          && this._config.meta.allowed_values 
          && this._config.meta.allowed_values.length > 0) {
            let allowedCountries = this._config.meta.allowed_values.map(country => country.value)
            this.countryCodeList = this.countryCodeList.filter((country) => 
                                            allowedCountries.indexOf(country.value) >= 0
                                    )
            this.countriesRestricted = true;
      }
    });
    this.$watch('internalValue', (value) => {
      this.defaultVal = !this.internalValue;
      if(!this.checkIfInAllowedValues(value)) {
        this.$store.dispatch('update_form_input', {
          name: this.name,
          value: "",
          formName: this.pName
        });
      }
    });
  },
  mounted(){
    this.defaultVal = !this.internalValue;
  },
  data() {
    return {
      defaultVal: false,
      countryCodeList: [],
      countriesRestricted: false
    }
  },
  methods: {
    input(event) {
      var tmp = event.target.value;
      this.$store.dispatch('update_form_input', {
        name: this.name,
        value: tmp,
        formName: this.pName
      });
      this.defaultVal = !this.internalValue;
      this.validate(true);
    },
    change(event) {
      this.input(event);
      this.$emit("change", this.internalValue);
    },
    allowedValues() {
      if (this.options) {
        return this.options;
      }
      if((this.name.endsWith('country') && this._config.meta.allowed_values.length == 0) || this.countriesRestricted) {
        return this.countryCodeList;
      }
      if (this._config.meta.allowed_values && !(this._config.meta.allowed_values instanceof Array)) {
        return Object.keys(this._config.meta.allowed_values).map(f => {
          return {
            "display_name": f,
            "value": this._config.meta.allowed_values[f]
          };
        });
      }
      return this._config.meta.allowed_values;
    },
    checkIfInAllowedValues(value) {
      return this.allowedValues().some((av) => av == value || av.value == value);
    }
  },
  computed: {
    ...mapGetters(['init_info_locale']),
    autocompleteMapping() {
      var mapping = AutoCompleteMapping[`${this.pName}[${this.name}]`];
      return mapping ? mapping : "off"
    },
    showDefault() {
      return !this.hideDefault;
    }
  }
}
</script>
