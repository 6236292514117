<template>
  <div class="cb-field" :class="[{'cb-field--active': true}, customClass,{'cb-field--error':(!!errorMessage && !focussed)},{'cb-field--sibling':siblingError},{'cb-field--focus': focussed && !readonly},{'cb-field--select-alt': this.config.meta.help_text}]">
    <input ref="input" type="date" :placeholder="placeholder" @input="input" @click="focus($event)" :value="internalValue" class="cb-field__control" :readonly="readonly" @focus="focus($event)" @blur="blur" :name='name' :aria-disabled="readonly" :disabled="readonly" :class="{'cb-field__readonly':readonly}" :aria-required='required' :id="name" />
    <label class="cb-field__label" :class="{'cb-field__label--s': isShort}" :for='name'> {{displayLabel}}</label>
    <slot></slot>
    <div class="cb-field__help" v-if="this.config && this.config.meta && this.config.meta.help_text">
      <template>
        <div class="cb-field__trigger" :class="{'active-opacity': helpTextShown}" @click="showHelpText($event)" ref="helpText">
        </div>
        <transition name="help-text">
          <div class="cb-field__menu" v-show="helpTextShown">
            {{this.config.meta.help_text}}
          </div>
        </transition>
      </template>
    </div>
    <div class="cb-field__error" v-if="errorMessageWrapper && !focussed">
      {{errorMessageWrapper}}
    </div>
  </div>
</template>
<script type="text/javascript">
import FieldMixin from "@/mixins/field-mixin";
export default {
  name: "date",
  props: ["name", "customClass", "siblingError", "type", "customDate"],
  mixins: [FieldMixin],
  data() {
    return {
      placeholder: ""
    };
  },
  created() {
    this.pName = this.findParentForm();
    if(!window.ifNativeDateSupported) {
      this.placeholder = (this.customDate || !this.placeholderText) ? "MM-dd-yyyy" : this.placeholderText;
    }
  },
  methods: {
    input(event) {
      var tmp = event.target.value;
      this.$store.dispatch("update_form_input", {
        name: this.name,
        value: tmp,
        formName: this.pName
      });
    }
  }
};
</script>
