const isRecaptchaEnabled = () => {
  return !!(window.initInfo && (window.initInfo.recaptcha_site_key || window.initInfo.captcha_site_key))
};

const isRecaptchaTextEnabled = () => {
  if(isRecaptchaEnabled() && window.isMobile){
      if(typeof window.initInfo.captcha_show_privacy_terms == 'boolean') return window.initInfo.captcha_show_privacy_terms;
      return !!window.initInfo.is_recaptcha_text_enabled;
  }
  return false;
}

const isRecaptchaGloballySupported = () => {
  return typeof window.initInfo.supported_global_recaptcha == 'boolean'
      ? window.initInfo.supported_global_recaptcha : false;
}

export default {
    isRecaptchaEnabled,
    isRecaptchaTextEnabled,
    isRecaptchaGloballySupported,
}
