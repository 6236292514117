import StateCodeMapping from "@/utils/google-maps-state-code-mapping"
class AddressExtractor {

    static get NEEDED_FIELDS() {
        return [
            "street_number",
            "route",
            "sublocality_level_2",
            "sublocality_level_1",
            "locality",
            "administrative_area_level_1",
            "country",
            "postal_code",
            "postal_town",
            "subpremise",
            "name"
        ]
    }

    static get ALIASES() {
        return {
            "billing_addr1": "line1",
            "billing_addr2": "line2",
            "billing_addr3": "line3",
            "billing_state": "state",
            "billing_region": "region",
            "billing_state_code": "state_code",
            "billing_zip": "zip",
            "billing_city": "city",
            "billing_country": "country"
        }
    }

    static extractAddress(value) {
        const addressComponents = value.addressComponents;
        const enabledFields = value.enabledFields;
        const extracted = this._extract(addressComponents);
        let output = {};
        output["line1"] = this._constructLine1(extracted);
        output["line2"] = this._constructLine2(extracted);
        logger.kvl({
            "hp_addr_line1_extracted_name": extracted.name.long_name,
            "hp_addr_line1_return_logic": output['line1']
        });
        output["line3"] = "";
        output["zip"] = this._test(extracted['postal_code']);
        output["country"] = extracted['country'] && extracted['country'].short_name;
        if (["GB", "SE"].indexOf(output["country"]) > -1) {
            output["city"] = this._test(extracted['postal_town']);
        } else {
            output["city"] = this._test(extracted['locality']);
        }
        let isCountryEnabled = enabledFields &&
            ((enabledFields.indexOf("country") > -1) || enabledFields.indexOf("billing_country") > -1);
        if (isCountryEnabled && ["US", "CA", "IN", "BR"].indexOf(output["country"]) > -1) {
            let state = extracted['administrative_area_level_1'];
            let country = output["country"];
            if (state && state.short_name) {
                let stateCode = state.short_name;
                let value = StateCodeMapping[country] &&
                    StateCodeMapping[country][stateCode];
                output["state_code"] = value ? value : stateCode;
            }
        } else {
            output["state"] = this._test(extracted['administrative_area_level_1']);
        }
        if (isCountryEnabled && ["US", "CA", "IN"].indexOf(output["country"])) {
            output["region"] = output["state_code"];
        } else {
            output["region"] = output["state"];
        }
        return output;
    }

    static _extract(addressComponents) {
        return addressComponents.reduce((map, value) => {
            var matchedType = value.types.filter((v) => this.NEEDED_FIELDS.indexOf(v) != -1)[0]
            if (matchedType && !map[matchedType]) {
                map[matchedType] = value;
            }
            return map;
        }, {})
    }

    static _constructLine1(extracted) {
        var line1 = [];
        if(extracted['street_number']){
            line1.push(this._test(extracted["street_number"]))
            if(extracted['subpremise'])
                line1.unshift(this._test(extracted["subpremise"]),'/')
        }
        if(extracted['country'] && ['DE', 'NL'].indexOf(extracted['country'].short_name) > -1){
            line1.unshift(this._test(extracted["route"]))
        }
        else{
            line1.push(this._test(extracted["route"]))
        }
        return line1.join(" ").trim();
    }
    
    static _constructLine2(extracted) {
        return [this._test(extracted["sublocality_level_2"]), this._test(extracted["sublocality_level_1"])].filter(v => !!v).join(", ");
    }

    static _test(obj) {
        return obj && obj.long_name;
    }
}

export default AddressExtractor