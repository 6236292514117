<template>
  <div class="cb-notebar" :class="[customClass, notesSecondaryClass, noteTypeClass]" v-if="message">
    <div class="cb-notebar__wrap">
      <div class="cb-notebar__icon" v-if="type">
        <i class="icon-info-circle" :class="'cb-notebar__'+type" v-if="type == 'info' || type == 'warning' || type == 'error' || type == 'suggestion' || type == 'muted'"></i>
        <!--Info: Note with plain bg-->
        <!--Warning: Note with yellow bg and normal font color-->
        <!--Error: Note with yello bg and  danger font color-->
        <!--Suggestion: Info Note with blue bg-->
        <i class="icon-exclamation-circle cb-notebar__alert" v-else></i>
      </div>
      <div class="cb-notebar__content" v-cb-html="message" v-if="message">
      </div>
      <div class="cb-notebar__action" @click="actionHandler" v-if="actionHandler">
        <span v-cb-html="actionText"></span>
        <span class="cb-notebar__icon"><i class="icon-long-arrow-right"></i></span>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import {
  mapGetters
} from 'vuex'
export default {
  props: ['customClass', 'type', 'message', 'actionText', 'actionHandler'],
  data(){
    return {
      // notesSecondaryClass:'',
      // noteTypeClass: ''
    }
  },
  computed: {
    noteTypeClass() {
      switch(this.type) {
        case 'warning':
          return 'cb-notebar--warning';
        case 'error':
          return 'cb-notebar--error';
        case 'suggestion':
          return 'cb-notebar--suggestion';
        case 'muted':
          return 'cb-notebar--muted';
      }
      return '';
    },
    notesSecondaryClass() {
      if(this.type=='warning' || this.type=='error' || this.type=='suggestion'){
        return 'cb-notebar--secondary';
      }
      return '';
    }
  }
}
</script>

<style lang="stylus" scoped>
  .cb-notebar {
    &__{
      &action{
        margin: 10px 0 0 20px;
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

.action .cb-notebar__icon {
  margin-left: 10px;
}
.cb-notebar__content {
  padding-right: 10px;
}
</style>