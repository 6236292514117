import Vue from 'vue'
import Router from 'vue-router'
import AcountChooser from "@/components/modules/portal/AccountChooser"
import ImportHelper from '@/utils/chunkImportWrapper'
import CustomerPortal from "@/components/modules/portal/CustomerPortal"
import PortalHomeComponent from "@/components/modules/portal/PortalHomeComponent"
import PortalAuthentication from "@/components/modules/portal/PortalAuthentication"
import PortalLogout from "@/components/modules/portal/PortalLogout"
import PortalLogin from "@/components/modules/portal/PortalLogin"
import ErrorComponent from "@/components/modules/hosted-pages/ErrorComponent/"
import GocardlessGuarantee from "@/components/modules/hosted-pages/payment-methods/direct-debit/GocardlessGuarantee"
import store from "@/store/portal"
import PortalAccountComponent from "@/components/modules/portal/PortalAccountComponent"
import PortalAccountEditComponent from "@/components/modules/portal/PortalAccountEditComponent"
import { MANAGE_PAYMENTS, FULL_ACCESS, EXTEND_SUBSCRIPTION } from "@/store/common/auth"

//dynamic imports - use prefix 'portal' for webpackChunkName - to avoid name collision
const PortalSubscriptionTemplate = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-subscription-template" */ "@/components/modules/portal/PortalSubscriptionTemplate"))
const SubscriptionDetailsComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-subscription-details" */ "@/components/modules/portal/SubscriptionDetailsComponent"))
const GiftDetailsComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-gift-details" */ "@/components/modules/portal/GiftDetailsComponent"))
const ChildSubscriptionDetailsComponent = ImportHelper.wrapImport(() =>
    import( /* webpackChunkName: "portal-child-subscription-details" */ "@/components/modules/portal/SubscriptionDetailsComponent"))
const ExtendSubscriptionComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-extend-subscription" */ "@/components/modules/extend-subscription/ExtendSubscriptionComponent"))
const ExtendSubscriptionThankYouComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-extend-subscription-thankyou" */ "@/components/modules/extend-subscription/ExtendSubscriptionThankYouComponent"))
const ExtendSubscriptionView = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-extend-subscription-view" */ "@/components/modules/extend-subscription/ExtendSubscriptionView"))
const ExtendSubscriptionCartComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-extend-subscription-cart" */ "@/components/modules/extend-subscription/ExtendSubscriptionCartComponent"))
const PortalEditSubscrption = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-subscription-edit" */ "@/components/modules/portal/PortalEditSubscrption"))
const EditSubscrptionComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-edit-subscription-comp" */ "@/components/modules/portal/EditSubscrptionComponent"))
const Addons = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-edit-subscription-comp" */ "@/components/modules/portal/Addons"))
const ScheduledChangesComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-schedule-changes" */ "@/components/modules/portal/ScheduledChangesComponent"))
const PortalCancelSubscription = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-cancel-sub" */ "@/components/modules/portal/PortalCancelSubscription"))
const PortalAddressComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-address" */ "@/components/modules/portal/PortalAddressComponent"))
const PortalBillingAddressEditComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-billing-address" */ "@/components/modules/portal/PortalBillingAddressEditComponent"))
const PortalShippingAddressEditComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-shipping-address" */ "@/components/modules/portal/PortalShippingAddressEditComponent"))
const PortalPMComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-pm-comp" */ "@/components/modules/portal/PortalPMComponent"))
const PortalAddPaymentComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-add-pm-comp" */ "@/components/modules/portal/PortalAddPaymentComponent"))
const PortalVerifyPaymentComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-verify-pm-comp" */ "@/components/modules/portal/PortalVerifyPaymentComponent"))
const PortalViewPaymentComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-view-payment" */ "@/components/modules/portal/PortalViewPaymentComponent"))
const PortalAssignRolePaymentComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-assign-role-payment" */ "@/components/modules/portal/PortalAssignRolePaymentComponent"))
const PortalBillingHistoryComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-billing-history" */ "@/components/modules/portal/PortalBillingHistoryComponent"))
const PortalChoosePaymentComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-choose-pm" */ "@/components/modules/portal/PortalChoosePaymentMethod"))
const PortalSubscriptionSection = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-subscription-section" */ "@/components/modules/portal/PortalSubscriptionSection"))
const PortalConsentComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-consent-section" */"@/components/modules/portal/PortalConsentComponent"))
const PortalSubscriptionPreviewComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-subscription-preview" */"@/components/modules/portal/PortalSubscriptionPreviewComponent"))
const EstimateDisplayScreen = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-estimate-display-screen" */"@/components/modules/hosted-pages/EstimateDisplayScreen"))
const PortalPromotionsComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-promotional-component" */"@/components/modules/portal/PortalPromotionsComponent"))
const PortalPauseSubscription = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-pause-sub" */ "@/components/modules/portal/PortalPauseSubscription"))
const PortalGiftTemplate = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-gift-template" */ "@/components/modules/portal/PortalGiftTemplate"))
const PortalChildSubTemplate = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-child-sub-template" */ "@/components/modules/portal/PortalChildSubTemplate"))
const PortalSetupPassword = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-setup-password" */ "@/components/modules/portal/PortalSetupPassword"))
const PortalChangePasswordVerify = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-change-password-verify" */ "@/components/modules/portal/PortalChangePasswordVerify"))
const PortalVerifyOTP = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-verify-otp" */ "@/components/modules/portal/PortalVerifyOTP"))
const PortalContractDetailsComponent = ImportHelper.wrapImport(() =>
    import ( /* webpackChunkName: "portal-contract-details-section" */ "@/components/modules/portal/PortalContractDetailsComponent"))
const ItemSelectionComponent = ImportHelper.wrapImport(() => 
    import( /* webpackChunkName: "item-selection-component" */ "@/components/modules/portal/ItemSelectionComponent"))
const AddonItemPriceSelectionComponent = ImportHelper.wrapImport(() => 
    import( /* webpackChunkName: "addon-item-price-selection-component" */ "@/components/modules/portal/AddonItemPriceSelectionComponent"))
const UpiTimer = ImportHelper.wrapImport(() => 
    import(/* webpackChunkName: "portal-upi-timer-screen" */"@/components/modules/hosted-pages/payment-methods/UpiTimer"))
const PaymentLoader = ImportHelper.wrapImport(() => 
    import(/* webpackChunkName: "portal-awaiting-for-confirmation-screen" */"@/components/modules/hosted-pages/payment-methods/PaymentLoader"))



Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [{
            path: "/portal/v2/authenticate",
            component: PortalAuthentication
        },
        {
            path: "/portal/v2/logout",
            component: PortalLogout
        },
        {
            path: "/portal/v2/login",
            component: PortalLogin,
            name: "portal_login",
            meta: { "header": "header-1", "headerTitle": "login.header", "nonLoggedInUser": true }
        },
        {
            path: "/portal/v2/choose_account",
            component: AcountChooser,
            name: "choose_account",
            meta: { "header": "header-1", "headerTitle": "login.header", "nonLoggedInUser": true },
        },
        {
            path: "/portal/v2/",
            component: CustomerPortal,
            name: "portal_main",
            children: [{
                    path: "home",
                    component: PortalHomeComponent,
                    name: "home",
                    meta: { "headerTitle": "home.header", scopes: [FULL_ACCESS] },
                },
                {
                    path: "sub_template",
                    component: PortalSubscriptionTemplate,
                    name: "sub_template",
                    meta: { "headerTitle": "home.header", scopes: [FULL_ACCESS] },
                },
                {
                    path: "gift_template",
                    component: PortalGiftTemplate,
                    name: "gift_template",
                    meta: {"headerTitle": "home.header", scopes: [FULL_ACCESS] },
                },
                {
                    path: "child_subscriptions",
                    component: PortalChildSubTemplate,
                    name: "child_sub_template",
                    meta: {"headerTitle": "child_subs.navigation_header" , scopes: [FULL_ACCESS] },
                },
                {
                    path: "subscriptions/:subscriptionId/details",
                    component: SubscriptionDetailsComponent,
                    name: "sub_details",
                    meta: { "headerTitle": "subscription.header", scopes: [FULL_ACCESS], "gaKey": "sub_details" }
                },
                {
                    path: "subscriptions/:subscriptionId/cancel",
                    component: PortalCancelSubscription,
                    name: "sub_cancel",
                    meta: { "headerTitle": "subscription.cancel.header", scopes: [FULL_ACCESS], "gaKey": "sub_cancel" }
                },
                {
                    path: "subscriptions/:subscriptionId/subscription_preview",
                    component: PortalSubscriptionPreviewComponent,
                    name: "subscription_preview",
                    meta: { "headerTitle": "subscription_preview_navigation.header", scopes: [FULL_ACCESS], "gaKey": "subscription_preview" }
                },
                {
                    path: "subscriptions/:subscriptionId/pause",
                    component: PortalPauseSubscription,
                    name: "sub_pause",
                    meta: {"headerTitle": "subscription.pause.header", scopes:[FULL_ACCESS], "gaKey": "sub_pause"}
                },
                {
                    path: "gifts/:giftId/details",
                    component: GiftDetailsComponent,
                    name: "gift_details",
                    meta: { "headerTitle": "subscription.header", scopes: [FULL_ACCESS], "gaKey": "sub_details" }
                },
                {
                    path: "child_subscriptions/:subscriptionId/details",
                    component: ChildSubscriptionDetailsComponent,
                    name: "child_sub_details",
                    meta: { "headerTitle": "child_subscription.header", scopes: [FULL_ACCESS], "gaKey": "child_sub_details", isChildSub:true }
                },
                {
                    path: "subscriptions/:subscriptionId",
                    component: PortalEditSubscrption,
                    children: [{
                            path: "edit",
                            component: EditSubscrptionComponent,
                            name: "edit_subscription",
                            meta: { "headerTitle": "subscription.header.edit", scopes: [FULL_ACCESS], "gaKey": "edit_subscription" }
                        },
                        {
                            path: "scheduled_changes",
                            component: ScheduledChangesComponent,
                            name: "scheduled_changes",
                            meta: { "headerTitle": "scheduled_changes_navigation.header", scopes: [FULL_ACCESS], "gaKey": "scheduled_changes" }
                        },
                        {
                            path: "add_addons",
                            component: Addons,
                            name: "add_addons",
                            meta: { "headerTitle": "add_addons.txt", scopes: [FULL_ACCESS], "gaKey": "add_addons" }
                        },
                        {
                            path: "estimate_display_screen",
                            component: EstimateDisplayScreen,
                            name: "estimate_display_screen",
                            meta: {"headerTitle": "estimate_display_screen.header" , scopes: [FULL_ACCESS], "gaKey": "estimate_display_screen"}
                        },
                        {
                            path: "item_selection",
                            component: ItemSelectionComponent,
                            name: "item_selection",
                            meta: {"headerTitle": "item_selection.header" , scopes: [FULL_ACCESS], "gaKey": "item_selection"}
                        },
                        {
                            path: "addon_item_price_selection/:addonItemId",
                            component: AddonItemPriceSelectionComponent,
                            name: "addon_item_price_selection",
                            meta: {"headerTitle": "addon_item_price_selection.header" , scopes: [FULL_ACCESS], "gaKey": "addon_item_price_selection"}
                        },
                        {
                            path: "extend",
                            component: ExtendSubscriptionComponent,
                            children: [{
                                path: "view",
                                component: ExtendSubscriptionView,
                                name: "extend_subscription",
                                meta: { "headerTitle": "subscription.extend.header", scopes: [FULL_ACCESS, EXTEND_SUBSCRIPTION], "gaKey": "extend_subscription" }
                            },
                            {
                                path: "cart",
                                component: ExtendSubscriptionCartComponent,
                                name: "cart",
                                meta: {"headerTitle":"back", "gaKey": "extend_subscription_cart_screen"},
                            },
                            {
                                path: "thankyou",
                                component: ExtendSubscriptionThankYouComponent,
                                name: "extend_sub_thankyou",
                                meta: {"header": "extend-header-thankyou", "headerLogo": true, "isHeaderTitle": false},
                            }]
                        }
                    ]
                },
                {
                    path: "details/consent",
                    component: PortalConsentComponent,
                    name: "portal_consent_details",
                    meta: { "headerTitle": "consent.header", scopes: [FULL_ACCESS], "gaKey": "consent_details" },

                },
                {
                    path: "details",
                    component: PortalAccountComponent,
                    name: "portal_account",
                    meta: { "headerTitle": "account.header", scopes: [FULL_ACCESS], "gaKey": "account_details" },
                },
                {
                    path: "edit/:field?",
                    component: PortalAccountEditComponent,
                    name: "portal_edit_account",
                    meta: { "headerTitle": "account.header.edit", scopes: [FULL_ACCESS], "gaKey": "portal_edit_account" },
                },
                {
                    path: "address",
                    component: PortalAddressComponent,
                    name: "portal_address",
                    meta: { "headerTitle": "address.header", scopes: [FULL_ACCESS], "gaKey": "portal_address" },
                },
                {
                    path: "billing_address/edit",
                    component: PortalBillingAddressEditComponent,
                    name: "portal_edit_billing_address",
                    meta: { "headerTitle": "address.billing.header.edit", scopes: [FULL_ACCESS], "gaKey": "portal_edit_billing_address" },
                },
                {
                    path: "shipping_address/:subscriptionId/edit",
                    component: PortalShippingAddressEditComponent,
                    name: "portal_edit_shipping_address",
                    meta: { "headerTitle": "address.shipping.header.edit", scopes: [FULL_ACCESS], "gaKey": "portal_edit_shipping_address" },
                },
                {
                    path: "subscriptions/:subscriptionId/contract_terms",
                    component: PortalContractDetailsComponent,
                    name: "portal_contract_details",
                    meta: { "headerTitle": "contract_term.details.header.title", scopes: [FULL_ACCESS], "gaKey": "portal_contract_details" },
                },
                {
                    path: "subscriptions/:subscriptionId/edit_cf",
                    component: PortalSubscriptionSection,
                    name: "edit_subscription_cf",
                    meta: { "headerTitle": "subscription_cf.header.edit", scopes: [FULL_ACCESS], "gaKey": "portal_edit_subscription_cf" }
                },
                {
                    path: "payment_methods",
                    component: PortalPMComponent,
                    name: "portal_payment_methods",
                    meta: { "headerTitle": "payment_method.header", scopes: [FULL_ACCESS, MANAGE_PAYMENTS, EXTEND_SUBSCRIPTION], "gaKey": "portal_payment_methods" },
                },
                {
                    path: "payment_methods/add",
                    component: PortalAddPaymentComponent,
                    name: "portal_add_payment_method",
                    meta: { "headerTitle": "payment_method.header.add", scopes: [FULL_ACCESS, MANAGE_PAYMENTS, EXTEND_SUBSCRIPTION], "gaKey": "portal_add_payment_method" },
                },
                {
                    path: "payment_method/edit",
                    component: PortalAddPaymentComponent,
                    name: "portal_edit_payment_method",
                    meta: { "headerTitle": "payment_method.header.edit", scopes: [FULL_ACCESS, MANAGE_PAYMENTS, EXTEND_SUBSCRIPTION], "gaKey": "portal_edit_payment_method" },
                },
                {
                    path: "payment_method/:pmId/verify",
                    component: PortalVerifyPaymentComponent,
                    name: "portal_verify_payment_method",
                    meta: { "headerTitle": "payment_method.header.verify", scopes: [FULL_ACCESS, MANAGE_PAYMENTS, EXTEND_SUBSCRIPTION], "gaKey": "portal_verify_payment_method" },
                },
                {
                    path: "payment_method/:pmId/guarantee",
                    component: GocardlessGuarantee,
                    name: "portal_gocardless_guarantee",
                    meta: { "headerTitle": "payment_method.bacs.guarantee.header", scopes: [FULL_ACCESS, MANAGE_PAYMENTS, EXTEND_SUBSCRIPTION], "gaKey": "portal_guarantee_payment_method" },
                },
                {
                    path: "payment_methods/:pmId/view",
                    component: PortalViewPaymentComponent,
                    name: "portal_view_payment_method",
                    meta: { "headerTitle": "payment_method.header.view", scopes: [FULL_ACCESS, MANAGE_PAYMENTS], "gaKey": "portal_view_payment_method" },
                },
                {
                    path: "payment_methods/:pmId/assign_role",
                    component: PortalAssignRolePaymentComponent,
                    name: "portal_assign_role_payment_method",
                    meta: { "headerTitle": "pm.set_role.header", scopes: [FULL_ACCESS, MANAGE_PAYMENTS], "gaKey": "portal_assign_role_payment_method" },
                },
                {
                    path: "payment_methods/:subscriptionId/choose",
                    component: PortalChoosePaymentComponent,
                    name: "portal_choose_payment_method",
                    meta: { "headerTitle": "subscription.header.choose_pm", scopes: [FULL_ACCESS, MANAGE_PAYMENTS, EXTEND_SUBSCRIPTION], "gaKey": "portal_choose_payment_method" },
                },
                {
                    path: "billing_history",
                    component: PortalBillingHistoryComponent,
                    name: "portal_billing_history",
                    meta: { "headerTitle": "billing_history.header", scopes: [FULL_ACCESS], "gaKey": "portal_billing_history" }
                },
                {
                    path: "promotions",
                    component: PortalPromotionsComponent,
                    name: "portal_promotions",
                    meta: { "headerTitle": "promotions.header", scopes: [FULL_ACCESS], "gaKey": "portal_promotions" }
                },
                {
                    path: "setup_password",
                    component: PortalSetupPassword,
                    name: "portal_setup_password",
                    meta: { "headerTitle": "setup_password.header", "header" : "header-2", scopes: [FULL_ACCESS], "gaKey": "portal_setup_password" }
                },
                {
                    path: "change_password_verify",
                    component: PortalChangePasswordVerify,
                    name: "portal_change_password_verify",
                    meta: { "headerTitle": "change_password_verify.header", scopes: [FULL_ACCESS], "gaKey": "portal_change_password_verify" }
                },
                {
                    path: "change_password",
                    component: PortalSetupPassword,
                    name: "portal_change_password",
                    meta: { "headerTitle": "change_password.header", "header" : "header-2", scopes: [FULL_ACCESS], "gaKey": "portal_change_password" }
                },
                {
                    path: "verify_otp",
                    component: PortalVerifyOTP,
                    name: "portal_verify_otp",
                    meta: { "headerTitle": "verify_otp.header", "header" : "header-2", scopes: [FULL_ACCESS], "gaKey": "portal_verify_otp" }
                },
                {
                    path: "upi_timer",
                    component: UpiTimer,
                    name: "upi_timer",
                    meta: {"header": "header-1", "headerTitle":"upi_loader.header",  commonTranslate: true, scopes: [FULL_ACCESS, MANAGE_PAYMENTS], "gaKey": "upi_timer_screen"}
                },
                {
                    path: "awaiting_for_confirmation",
                    component: PaymentLoader,
                    name: "awaiting_for_confirmation",
                    meta: {"header": "header-1", "headerTitle":"payment_loader.header",  commonTranslate: true, scopes: [FULL_ACCESS, MANAGE_PAYMENTS], "gaKey": "awaiting_for_confirmation_screen"}
                },
            ]
        },
        {
            path: '/portal/v2/error',
            component: ErrorComponent,
            name: "error"
        },
        {
            path: '/portal/v2/*',
            redirect: { name: 'error' }
        }
    ]
})