import HostedPageHelper from "@/utils/hosted-page-helper"

export default class GiftData {
  constructor(giftItem) {
  	this.giftInfo = giftItem.gift;
  	this.subscription = giftItem.subscription;
  	this.giftId = giftItem.gift.id;
  	this.subscriptionId = giftItem.subscription.id;
  }

  setEstimate(estimate) {
  	this.estimate = estimate;

  }

  constructOrderItem() {
    // get plans and addons from recurring estimate and construct order item
    if(this.estimate) {
      return HostedPageHelper.constructOrderItemsFromEstimate(this.estimate, this.subscription);
    }
    else {
      return HostedPageHelper.constructOrderItemsFromSubscription(this.subscription);
    }
  }

  constructOrderItemsFromEstimate(estimate) {
    return HostedPageHelper.constructOrderItemsFromEstimate(estimate, this.subscription);
  }

  displayer() {
    return {
      giftId: this.giftId,
      subscriptionId: this.subscriptionId,
      subscription: this.subscription,
      gift: this.giftInfo,
      isGiftClaimed : this.giftInfo.status == "claimed",
      status: this.giftInfo.status,

      price: this.estimate ? this.estimate.total : 0,
      currency_code: this.subscription.currency_code,

      has_shipping_address: this.subscription.shipping_address ? true : false,
      shipping_address: this.subscription.shipping_address,

      receiverName: this.giftInfo.gift_receiver.first_name + " " + this.giftInfo.gift_receiver.last_name,
      plan_id: this.orderItem.plan.id,
      plan_name: this.orderItem.plan.details.invoice_name || this.orderItem.plan.details.name,
      plan_quantity: this.orderItem.plan.quantity,
      plan_quantity_in_decimal: this.orderItem.plan.quantity_in_decimal,
      description: this.orderItem.plan.details.description,
      addons: this.orderItem.addons

    }
  }


}
