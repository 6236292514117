<template>
    <div class="cb-text cb-transition__fade--short cb-text--icon-group">
        <input type="password" v-model="password"  @keyup="keyupCustom()" @keyup.enter="keyupEnter($event)" :style="style" :class="{'cb-field__control--error': error && error.length > 0}" class="cb-text__control cb-text__control--lg" ref="password" autocomplete="off"  name="password" id="password">
        <span @click="toggleVisibility()" class="icon cb-text__icon cb-cursor--pointer" :class="{'icon-eye':showPassword,'icon-eye-closed':!showPassword}"></span>
    </div>       
</template>
<script>
import { passwordAnalyser } from "@/utils/helper-functions"
 
export default {
    props: ['error', 'value', 'refs', 'style'],
    mounted(){
    },
    data() {
        return {
            showPassword:false,
            password: this.value
        }
    },
    methods: {
        keyupEnter($event) {
            this.$emit("keyupEnter", $event);
        },
        keyupCustom() {
            this.$emit("keyupCustom", this.password );
        },
        toggleVisibility(){
            if(this.$refs.password.type == 'text'){
                this.$refs.password.type = 'password';
                this.showPassword = false;
            }
            else{
                this.$refs.password.type = 'text';
                this.showPassword = true;
            }
        }
    }
}
</script>
