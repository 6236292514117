<style src="@/assets/styles/components/collections/form/textarea.styl" lang="stylus" scoped></style>
<template>
  <div class="cb-textarea" :class="[{'cb-textarea--active': (internalValue || focussed)}, customClass ,{'cb-textarea--error':(!!errorMessage && !focussed)},{'cb-textarea--focus': focussed && !readonly}]" @click="$refs.textarea.focus()">
    <textarea :placeholder="placeholder" ref="textarea" class="cb-textarea__control" :value="internalValue" @click="focus($event)" @focus="focus($event)" @blur="blur($event)" @input="input($event)" @keyup="keyup($event)" :name='name' :aria-disabled="readonly" :disabled="readonly" :class="{'cb-textarea__readonly':readonly}" :aria-required='required' :id="name">
    </textarea>
    <label class="cb-textarea__label" :for='name'> {{displayLabel}}</label>
    <div class="cb-field__help" v-if="this.config.meta.help_text">
      <template>
        <div class="cb-field__trigger" :class="{'active-opacity': helpTextShown}" @click="showHelpText($event)" ref="helpText">
        </div>
        <transition name="help-text">
          <div class="cb-field__menu" v-show="helpTextShown">
            {{this.config.meta.help_text}}
          </div>
        </transition>
      </template>
    </div>
    <div class="cb-textarea__error" v-if="errorMessageWrapper && !focussed">
      {{errorMessageWrapper}}
    </div>
  </div>
</template>
<script type="text/javascript">
import FieldMixin from "@/mixins/field-mixin"
export default {
  name: 'text-area',
  props: ['name', 'customClass', 'useparent', 'gid', 'siblingError', 'type', 'placeholder'],
  created() {
    this.pName = this.findParentForm();
  },
  mounted(){
    this.$refs.textarea.style.height = (this.$refs.textarea.scrollHeight) + "px";
  },
  mixins: [FieldMixin],
  methods: {
    input(event) {
      let el = this.$refs.textarea;
      el.style.height = "84px";
      el.style.height = (el.scrollHeight) + "px";
      var tmp = event.target.value;
      this.$store.dispatch('update_form_input', {
        name: this.name,
        value: tmp,
        formName: this.pName
      });
    },
    keyup(event) {
      if (event.keyCode === 13) {
        event.stopPropagation();
      }
      this.$emit('keyup', this.internalValue);
    },
  },
}
</script>