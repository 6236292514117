var formHelpers = {};
formHelpers.install = function(Vue, options){
  var common = {
    methods: {
      findParentForm() {
        if(this.$data.fname || this.$props.fname)
          return this.$data.fname || this.$props.fname;
        else
          return this.$parent && this.$parent.findParentForm && this.$parent.findParentForm();
      }
    }
  }
  Vue.mixin(common);
}

export default formHelpers;