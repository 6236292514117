export default [
	{
	    "name": "Afghanistan",
	    "dial_code": "+93",
	    "code": "AF",
	    "format": "+.. .. ... ...."
	},
	{
	    "name": "Aland Islands",
	    "dial_code": "+358",
	    "code": "AX",
	    "format": "+... .. ... .. .."
	},
	{
	    "name": "Albania",
	    "dial_code": "+355",
	    "code": "AL",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "Algeria",
	    "dial_code": "+213",
	    "code": "DZ",
	    "format": "+... ... .. .. .."
	},
	{
	    "name": "AmericanSamoa",
	    "dial_code": "+1684",
	    "code": "AS"
	},
	{
	    "name": "Andorra",
	    "dial_code": "+376",
	    "code": "AD",
	    "format": "+... ... ..."
	},
	{
	    "name": "Angola",
	    "dial_code": "+244",
	    "code": "AO",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Anguilla",
	    "dial_code": "+1264",
	    "code": "AI"
	},
	{
	    "name": "Antarctica",
	    "dial_code": "+672",
	    "code": "AQ",
	    "format": "+... .. ...."
	},
	{
	    "name": "Antigua and Barbuda",
	    "dial_code": "+1268",
	    "code": "AG",
	},
	{
	    "name": "Argentina",
	    "dial_code": "+54",
	    "code": "AR",
	    "format": "+.. .. ..-....-...."
	},
	{
	    "name": "Armenia",
	    "dial_code": "+374",
	    "code": "AM",
	    "format": "+... .. ......"
	},
	{
	    "name": "Aruba",
	    "dial_code": "+297",
	    "code": "AW",
	    "format": "+... ... ...."
	},
	{
	    "name": "Australia",
	    "dial_code": "+61",
	    "code": "AU",
	    "format": "+.. ... ... ..."
	},
	{
	    "name": "Austria",
	    "dial_code": "+43",
	    "code": "AT",
	    "format": "+.. ... ........."
	},
	{
	    "name": "Azerbaijan",
	    "dial_code": "+994",
	    "code": "AZ",
	    "format": "+... .. ... .. .."
	},
	{
	    "name": "Bahamas",
	    "dial_code": "+1242",
	    "code": "BS"
	},
	{
	    "name": "Bahrain",
	    "dial_code": "+973",
	    "code": "BH",
	    "format": "+... .... ...."
	},
	{
	    "name": "Bangladesh",
	    "dial_code": "+880",
	    "code": "BD",
	    "format": "+... ....-......"
	},
	{
	    "name": "Barbados",
	    "dial_code": "+1246",
	    "code": "BB"
	},
	{
	    "name": "Belarus",
	    "dial_code": "+375",
	    "code": "BY",
	    "format": "+... .. ...-..-.."
	},
	{
	    "name": "Belgium",
	    "dial_code": "+32",
	    "code": "BE",
	    "format": "+.. ... .. .. .."
	},
	{
	    "name": "Belize",
	    "dial_code": "+501",
	    "code": "BZ",
	    "format": "+... ...-...."
	},
	{
	    "name": "Benin",
	    "dial_code": "+229",
	    "code": "BJ",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Bermuda",
	    "dial_code": "+1441",
	    "code": "BM"
	},
	{
	    "name": "Bhutan",
	    "dial_code": "+975",
	    "code": "BT",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Bolivia, Plurinational State of",
	    "dial_code": "+591",
	    "code": "BO",
	    "format": "+... ........"
	},
	{
	    "name": "Bosnia and Herzegovina",
	    "dial_code": "+387",
	    "code": "BA",
	    "format": "+... .. ...-..."
	},
	{
	    "name": "Botswana",
	    "dial_code": "+267",
	    "code": "BW",
	    "format": "+... .. ... ..."
	},
	{
	    "name": "Brazil",
	    "dial_code": "+55",
	    "code": "BR",
	    "format": "+.. .. .....-...."
	},
	{
	    "name": "British Indian Ocean Territory",
	    "dial_code": "+246",
	    "code": "IO",
	    "format": "+... ... ...."
	},
	{
	    "name": "Brunei Darussalam",
	    "dial_code": "+673",
	    "code": "BN",
	    "format": "+... ... ...."
	},
	{
	    "name": "Bulgaria",
	    "dial_code": "+359",
	    "code": "BG",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Burkina Faso",
	    "dial_code": "+226",
	    "code": "BF",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Burundi",
	    "dial_code": "+257",
	    "code": "BI",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Cambodia",
	    "dial_code": "+855",
	    "code": "KH",
	    "format": "+... .. ... ..."
	},
	{
	    "name": "Cameroon",
	    "dial_code": "+237",
	    "code": "CM",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Canada",
	    "dial_code": "+1",
	    "code": "CA"
	},
	{
	    "name": "Cape Verde",
	    "dial_code": "+238",
	    "code": "CV",
	    "format": "+... ... .. .."
	},
	{
	    "name": "Cayman Islands",
	    "dial_code": "+ 345",
	    "code": "KY"
	},
	{
	    "name": "Central African Republic",
	    "dial_code": "+236",
	    "code": "CF",
	    "format": "+... .. .. .. .."
	},
	{
		"name":"Ceuta and Melilla",
		"dial_code": "+34",
		"code":"EA",
		"format": "+.. .. ... .. .."
	  },
	{
	    "name": "Chad",
	    "dial_code": "+235",
	    "code": "TD",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Chile",
	    "dial_code": "+56",
	    "code": "CL",
	    "format": "+.. . .... ...."
	},
	{
	    "name": "China",
	    "dial_code": "+86",
	    "code": "CN",
	    "format": "+.. ... .... ...."
	},
	{
	    "name": "Christmas Island",
	    "dial_code": "+61",
	    "code": "CX",
	    "format": "+.. ... ... ..."
	},
	{
	    "name": "Cocos (Keeling) Islands",
	    "dial_code": "+61",
	    "code": "CC",
	    "format": "+.. ... ... ..."
	},
	{
	    "name": "Colombia",
	    "dial_code": "+57",
	    "code": "CO",
	    "format": "+.. ... ......."
	},
	{
	    "name": "Comoros",
	    "dial_code": "+269",
	    "code": "KM",
	    "format": "+... ... .. .."
	},
	{
	    "name": "Congo",
	    "dial_code": "+242",
	    "code": "CG",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "Congo, The Democratic Republic of the Congo",
	    "dial_code": "+243",
	    "code": "CD",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Cook Islands",
	    "dial_code": "+682",
	    "code": "CK",
	    "format": "+... .. ..."
	},
	{
	    "name": "Costa Rica",
	    "dial_code": "+506",
	    "code": "CR",
	    "format": "+... .... ...."
	},
	{
	    "name": "Cote d'Ivoire",
	    "dial_code": "+225",
	    "code": "CI",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Croatia",
	    "dial_code": "+385",
	    "code": "HR",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "Cuba",
	    "dial_code": "+53",
	    "code": "CU",
	    "format": "+.. . ......."
	},
	{
	    "name": "Cyprus",
	    "dial_code": "+357",
	    "code": "CY",
	    "format": "+... .. ......"
	},
	{
	    "name": "Czech Republic",
	    "dial_code": "+420",
	    "code": "CZ",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Denmark",
	    "dial_code": "+45",
	    "code": "DK",
	    "format": "+.. .. .. .. .."
	},
	{
	    "name": "Djibouti",
	    "dial_code": "+253",
	    "code": "DJ",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Dominica",
	    "dial_code": "+1767",
	    "code": "DM"
	},
	{
	    "name": "Dominican Republic",
	    "dial_code": "+1849",
	    "code": "DO"
	},
	{
	    "name": "Ecuador",
	    "dial_code": "+593",
	    "code": "EC",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "Egypt",
	    "dial_code": "+20",
	    "code": "EG",
	    "format": "+.. ... ... ...."
	},
	{
	    "name": "El Salvador",
	    "dial_code": "+503",
	    "code": "SV",
	    "format": "+... .... ...."
	},
	{
	    "name": "Equatorial Guinea",
	    "dial_code": "+240",
	    "code": "GQ",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Eritrea",
	    "dial_code": "+291",
	    "code": "ER",
	    "format": "+... . ... ..."
	},
	{
	    "name": "Estonia",
	    "dial_code": "+372",
	    "code": "EE",
	    "format": "+... .... ...."
	},
	{
	    "name": "Ethiopia",
	    "dial_code": "+251",
	    "code": "ET",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "Falkland Islands (Malvinas)",
	    "dial_code": "+500",
	    "code": "FK"
	},
	{
	    "name": "Faroe Islands",
	    "dial_code": "+298",
	    "code": "FO",
	    "format": "+... ......"
	},
	{
	    "name": "Fiji",
	    "dial_code": "+679",
	    "code": "FJ",
	    "format": "+... ... ...."
	},
	{
	    "name": "Finland",
	    "dial_code": "+358",
	    "code": "FI",
	    "format": "+... .. ... .. .."
	},
	{
	    "name": "France",
	    "dial_code": "+33",
	    "code": "FR",
	    "format": "+.. . .. .. .. .."
	},
	{
	    "name": "French Guiana",
	    "dial_code": "+594",
	    "code": "GF",
	    "format": "+... ... .. .. .."
	},
	{
	    "name": "French Polynesia",
	    "dial_code": "+689",
	    "code": "PF",
	    "format": "+... .. .. .."
	},
	{
	    "name": "Gabon",
	    "dial_code": "+241",
	    "code": "GA",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Gambia",
	    "dial_code": "+220",
	    "code": "GM",
	    "format": "+... ... ...."
	},
	{
	    "name": "Georgia",
	    "dial_code": "+995",
	    "code": "GE",
	    "format": "+... ... .. .. .."
	},
	{
	    "name": "Germany",
	    "dial_code": "+49",
	    "code": "DE",
	    "format": "+.. ... ........."
	},
	{
	    "name": "Ghana",
	    "dial_code": "+233",
	    "code": "GH",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "Gibraltar",
	    "dial_code": "+350",
	    "code": "GI",
	    "format": "+... ... ....."
	},
	{
	    "name": "Greece",
	    "dial_code": "+30",
	    "code": "GR",
	    "format": "+.. ... ... ...."
	},
	{
	    "name": "Greenland",
	    "dial_code": "+299",
	    "code": "GL",
	    "format": "+... .. .. .."
	},
	{
	    "name": "Grenada",
	    "dial_code": "+1473",
	    "code": "GD"
	},
	{
	    "name": "Guadeloupe",
	    "dial_code": "+590",
	    "code": "GP"
	},
	{
	    "name": "Guam",
	    "dial_code": "+1671",
	    "code": "GU"
	},
	{
	    "name": "Guatemala",
	    "dial_code": "+502",
	    "code": "GT",
	    "format": "+... .... ...."
	},
	{
	    "name": "Guernsey",
	    "dial_code": "+44",
	    "code": "GG",
	    "format": "+.. .... ......"
	},
	{
	    "name": "Guinea",
	    "dial_code": "+224",
	    "code": "GN",
	    "format": "+... ... .. .. .."
	},
	{
	    "name": "Guinea-Bissau",
	    "dial_code": "+245",
	    "code": "GW",
	    "format": "+... ... ...."
	},
	{
	    "name": "Guyana",
	    "dial_code": "+595",
	    "code": "GY",
	    "format": "+... .. ......."
	},
	{
	    "name": "Haiti",
	    "dial_code": "+509",
	    "code": "HT",
	    "format": "+... .. .. ...."
	},
	{
	    "name": "Holy See (Vatican City State)",
	    "dial_code": "+379",
	    "code": "VA"
	},
	{
	    "name": "Honduras",
	    "dial_code": "+504",
	    "code": "HN",
	    "format": "+... ....-...."
	},
	{
	    "name": "Hong Kong",
	    "dial_code": "+852",
	    "code": "HK",
	    "format": "+... .... ...."
	},
	{
	    "name": "Hungary",
	    "dial_code": "+36",
	    "code": "HU",
	    "format": "+.. .. ... ...."
	},
	{
	    "name": "Iceland",
	    "dial_code": "+354",
	    "code": "IS",
	    "format": "+... ... ...."
	},
	{
	    "name": "India",
	    "dial_code": "+91",
	    "code": "IN",
	    "format": "+.. .. .. ......"
	},
	{
	    "name": "Indonesia",
	    "dial_code": "+62",
	    "code": "ID",
	    "format": "+.. ....-....-..."
	},
	{
	    "name": "Iran, Islamic Republic of Persian Gulf",
	    "dial_code": "+98",
	    "code": "IR",
	    "format": "+.. ... ... ...."
	},
	{
	    "name": "Iraq",
	    "dial_code": "+964",
	    "code": "IQ",
	    "format": "+... ... ... ...."
	},
	{
	    "name": "Ireland",
	    "dial_code": "+353",
	    "code": "IE",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "Isle of Man",
	    "dial_code": "+44",
	    "code": "IM",
	    "format": "+.. .... ......"
	},
	{
	    "name": "Israel",
	    "dial_code": "+972",
	    "code": "IL",
	    "format": "+... ..-...-...."
	},
	{
	    "name": "Italy",
	    "dial_code": "+39",
	    "code": "IT",
	    "format": "+.. .. .... ...."
	},
	{
	    "name": "Jamaica",
	    "dial_code": "+1876",
	    "code": "JM"
	},
	{
	    "name": "Japan",
	    "dial_code": "+81",
	    "code": "JP",
	    "format": "+.. ..-....-...."
	},
	{
	    "name": "Jersey",
	    "dial_code": "+44",
	    "code": "JE",
	    "format": "+.. .... ......"
	},
	{
	    "name": "Jordan",
	    "dial_code": "+962",
	    "code": "JO",
	    "format": "+... . .... ...."
	},
	{
	    "name": "Kazakhstan",
	    "dial_code": "+77",
	    "code": "KZ"
	},
	{
	    "name": "Kenya",
	    "dial_code": "+254",
	    "code": "KE",
	    "format": "+... .. ......."
	},
	{
	    "name": "Kiribati",
	    "dial_code": "+686",
	    "code": "KI"
	},
	{
	    "name": "Korea, Democratic People's Republic of Korea",
	    "dial_code": "+850",
	    "code": "KP",
	    "format": "+... ... ... ...."
	},
	{
	    "name": "Korea, Republic of South Korea",
	    "dial_code": "+82",
	    "code": "KR",
	    "format": "+.. ..-....-...."
	},
	{
	    "name": "Kosovo",
	    "dial_code": "+383",
	    "code": "XK",
	    "format": "+... ... ... ...."
	},
	{
	    "name": "Kuwait",
	    "dial_code": "+965",
	    "code": "KW",
	    "format": "+... ... ....."
	},
	{
	    "name": "Kyrgyzstan",
	    "dial_code": "+996",
	    "code": "KG",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Laos",
	    "dial_code": "+856",
	    "code": "LA",
	    "format": "+... .. .. ... ..."
	},
	{
	    "name": "Latvia",
	    "dial_code": "+371",
	    "code": "LV",
	    "format": "+... .. ... ..."
	},
	{
	    "name": "Lebanon",
	    "dial_code": "+961",
	    "code": "LB",
	    "format": "+... .. ... ..."
	},
	{
	    "name": "Lesotho",
	    "dial_code": "+266",
	    "code": "LS",
	    "format": "+... .... ...."
	},
	{
	    "name": "Liberia",
	    "dial_code": "+231",
	    "code": "LR",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Libyan Arab Jamahiriya",
	    "dial_code": "+218",
	    "code": "LY",
	    "format": "+... ..-......."
	},
	{
	    "name": "Liechtenstein",
	    "dial_code": "+423",
	    "code": "LI",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Lithuania",
	    "dial_code": "+370",
	    "code": "LT",
	    "format": "+... ... ....."
	},
	{
	    "name": "Luxembourg",
	    "dial_code": "+352",
	    "code": "LU",
	    "format": "+... .. .. .. ..."
	},
	{
	    "name": "Macao",
	    "dial_code": "+853",
	    "code": "MO",
	    "format": "+... .... ...."
	},
	{
	    "name": "Macedonia",
	    "dial_code": "+389",
	    "code": "MK",
	    "format": "+... .. ... ..."
	},
	{
	    "name": "Madagascar",
	    "dial_code": "+261",
	    "code": "MG",
	    "format": "+... .. .. ... .."
	},
	{
	    "name": "Malawi",
	    "dial_code": "+265",
	    "code": "MW",
	    "format": "+... ... .. .. .."
	},
	{
	    "name": "Malaysia",
	    "dial_code": "+60",
	    "code": "MY",
	    "format": "+.. ...-.... ...."
	},
	{
	    "name": "Maldives",
	    "dial_code": "+960",
	    "code": "MV",
	    "format": "+... ...-...."
	},
	{
	    "name": "Mali",
	    "dial_code": "+223",
	    "code": "ML",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Malta",
	    "dial_code": "+356",
	    "code": "MT",
	    "format": "+... .... ...."
	},
	{
	    "name": "Marshall Islands",
	    "dial_code": "+692",
	    "code": "MH",
	    "format": "+... ...-...."
	},
	{
	    "name": "Martinique",
	    "dial_code": "+596",
	    "code": "MQ",
	    "format": "+... ... .. .. .."
	},
	{
	    "name": "Mauritania",
	    "dial_code": "+222",
	    "code": "MR",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Mauritius",
	    "dial_code": "+230",
	    "code": "MU",
	    "format": "+... .... ...."
	},
	{
	    "name": "Mayotte",
	    "dial_code": "+262",
	    "code": "YT"
	},
	{
	    "name": "Mexico",
	    "dial_code": "+52",
	    "code": "MX",
	    "format": "+.. ... ... ... ...."
	},
	{
	    "name": "Micronesia, Federated States of Micronesia",
	    "dial_code": "+691",
	    "code": "FM",
	    "format": "+... ... ...."
	},
	{
	    "name": "Moldova",
	    "dial_code": "+373",
	    "code": "MD",
	    "format": "+... ... .. ..."
	},
	{
	    "name": "Monaco",
	    "dial_code": "+377",
	    "code": "MC",
	    "format": "+... . .. .. .. .."
	},
	{
	    "name": "Mongolia",
	    "dial_code": "+976",
	    "code": "MN",
	    "format": "+... .... ...."
	},
	{
	    "name": "Montenegro",
	    "dial_code": "+382",
	    "code": "ME",
	    "format": "+... .. ... ..."
	},
	{
	    "name": "Montserrat",
	    "dial_code": "+1664",
	    "code": "MS"
	},
	{
	    "name": "Morocco",
	    "dial_code": "+212",
	    "code": "MA",
	    "format": "+... ...-......"
	},
	{
	    "name": "Mozambique",
	    "dial_code": "+258",
	    "code": "MZ",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "Myanmar",
	    "dial_code": "+95",
	    "code": "MM",
	    "format": "+.. . ... ...."
	},
	{
	    "name": "Namibia",
	    "dial_code": "+264",
	    "code": "NA",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "Nauru",
	    "dial_code": "+674",
	    "code": "NR",
	    "format": "+... ... ...."
	},
	{
	    "name": "Nepal",
	    "dial_code": "+977",
	    "code": "NP",
	    "format": "+... ...-......."
	},
	{
	    "name": "Netherlands",
	    "dial_code": "+31",
	    "code": "NL",
	    "format": "+.. . ........"
	},
	{
	    "name": "Netherlands Antilles",
	    "dial_code": "+599",
	    "code": "AN",
	    "format": "+... . ... ...."
	},
	{
	    "name": "New Caledonia",
	    "dial_code": "+687",
	    "code": "NC",
	    "format": "+... ........"
	},
	{
	    "name": "New Zealand",
	    "dial_code": "+64",
	    "code": "NZ",
	    "format": "+.. .. ... ....."
	},
	{
	    "name": "Nicaragua",
	    "dial_code": "+505",
	    "code": "NI",
	    "format": "+... .... ...."
	},
	{
	    "name": "Niger",
	    "dial_code": "+227",
	    "code": "NE",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Nigeria",
	    "dial_code": "+234",
	    "code": "NG",
	    "format": "+... ... ... ...."
	},
	{
	    "name": "Niue",
	    "dial_code": "+683",
	    "code": "NU"
	},
	{
	    "name": "Norfolk Island",
	    "dial_code": "+672",
	    "code": "NF",
	    "format": "+... .. ...."
	},
	{
	    "name": "Northern Mariana Islands",
	    "dial_code": "+1670",
	    "code": "MP"
	},
	{
	    "name": "Norway",
	    "dial_code": "+47",
	    "code": "NO",
	    "format": "+.. ... .. ..."
	},
	{
	    "name": "Oman",
	    "dial_code": "+968",
	    "code": "OM",
	    "format": "+... .... ...."
	},
	{
	    "name": "Pakistan",
	    "dial_code": "+92",
	    "code": "PK",
	    "format": "+.. ... ......."
	},
	{
	    "name": "Palau",
	    "dial_code": "+680",
	    "code": "PW",
	    "format": "+... ... ...."
	},
	{
	    "name": "Palestinian Territory, Occupied",
	    "dial_code": "+970",
	    "code": "PS",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Panama",
	    "dial_code": "+507",
	    "code": "PA",
	    "format": "+... ....-...."
	},
	{
	    "name": "Papua New Guinea",
	    "dial_code": "+675",
	    "code": "PG",
	    "format": "+... ... ...."
	},
	{
	    "name": "Paraguay",
	    "dial_code": "+595",
	    "code": "PY",
	    "format": "+... .. ......."
	},
	{
	    "name": "Peru",
	    "dial_code": "+51",
	    "code": "PE",
	    "format": "+.. ... ... ..."
	},
	{
	    "name": "Philippines",
	    "dial_code": "+63",
	    "code": "PH",
	    "format": "+.. .... ......"
	},
	{
	    "name": "Pitcairn",
	    "dial_code": "+872",
	    "code": "PN"
	},
	{
	    "name": "Poland",
	    "dial_code": "+48",
	    "code": "PL",
	    "format": "+.. .. ... .. .."
	},
	{
	    "name": "Portugal",
	    "dial_code": "+351",
	    "code": "PT",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Puerto Rico",
	    "dial_code": "+1939",
	    "code": "PR"
	},
	{
	    "name": "Qatar",
	    "dial_code": "+974",
	    "code": "QA",
	    "format": "+... .... ...."
	},
	{
	    "name": "Romania",
	    "dial_code": "+40",
	    "code": "RO",
	    "format": "+.. .. ... ...."
	},
	{
	    "name": "Russia",
	    "dial_code": "+7",
	    "code": "RU",
	    "format": "+. ... ...-..-.."
	},
	{
	    "name": "Rwanda",
	    "dial_code": "+250",
	    "code": "RW",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Reunion",
	    "dial_code": "+262",
	    "code": "RE"
	},
	{
	    "name": "Saint Barthelemy",
	    "dial_code": "+590",
	    "code": "BL"
	},
	{
	    "name": "Saint Helena, Ascension and Tristan Da Cunha",
	    "dial_code": "+290",
	    "code": "SH"
	},
	{
	    "name": "Saint Kitts and Nevis",
	    "dial_code": "+1869",
	    "code": "KN"
	},
	{
	    "name": "Saint Lucia",
	    "dial_code": "+1758",
	    "code": "LC"
	},
	{
	    "name": "Saint Martin",
	    "dial_code": "+590",
	    "code": "MF"
	},
	{
	    "name": "Saint Pierre and Miquelon",
	    "dial_code": "+508",
	    "code": "PM",
	    "format": "+... .. .. .."
	},
	{
	    "name": "Saint Vincent and the Grenadines",
	    "dial_code": "+1784",
	    "code": "VC"
	},
	{
	    "name": "Samoa",
	    "dial_code": "+685",
	    "code": "WS"
	},
	{
	    "name": "San Marino",
	    "dial_code": "+378",
	    "code": "SM",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Sao Tome and Principe",
	    "dial_code": "+239",
	    "code": "ST",
	    "format": "+... ... ...."
	},
	{
	    "name": "Saudi Arabia",
	    "dial_code": "+966",
	    "code": "SA",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "Senegal",
	    "dial_code": "+221",
	    "code": "SN",
	    "format": "+... .. ... .. .."
	},
	{
	    "name": "Serbia",
	    "dial_code": "+381",
	    "code": "RS",
	    "format": "+... .. ......."
	},
	{
	    "name": "Seychelles",
	    "dial_code": "+248",
	    "code": "SC",
	    "format": "+... . ... ..."
	},
	{
	    "name": "Sierra Leone",
	    "dial_code": "+232",
	    "code": "SL",
	    "format": "+... .. ......"
	},
	{
	    "name": "Singapore",
	    "dial_code": "+65",
	    "code": "SG",
	    "format": "+.. .... ...."
	},
	{
	    "name": "Slovakia",
	    "dial_code": "+421",
	    "code": "SK",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Slovenia",
	    "dial_code": "+386",
	    "code": "SI",
	    "format": "+... .. ... ..."
	},
	{
	    "name": "Solomon Islands",
	    "dial_code": "+677",
	    "code": "SB",
	    "format": "+... ... ...."
	},
	{
	    "name": "Somalia",
	    "dial_code": "+252",
	    "code": "SO",
	    "format": "+... .. ......."
	},
	{
	    "name": "South Africa",
	    "dial_code": "+27",
	    "code": "ZA",
	    "format": "+.. .. ... ...."
	},
	{
	    "name": "South Sudan",
	    "dial_code": "+211",
	    "code": "SS",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "South Georgia and the South Sandwich Islands",
	    "dial_code": "+500",
	    "code": "GS"
	},
	{
	    "name": "Spain",
	    "dial_code": "+34",
	    "code": "ES",
	    "format": "+.. ... .. .. .."
	},
	{
	    "name": "Sri Lanka",
	    "dial_code": "+94",
	    "code": "LK",
	    "format": "+.. .. . ......"
	},
	{
	    "name": "Sudan",
	    "dial_code": "+249",
	    "code": "SD",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "Suriname",
	    "dial_code": "+597",
	    "code": "SR",
	    "format": "+... ...-...."
	},
	{
	    "name": "Svalbard and Jan Mayen",
	    "dial_code": "+47",
	    "code": "SJ",
	    "format": "+.. ... .. ..."
	},
	{
	    "name": "Swaziland",
	    "dial_code": "+268",
	    "code": "SZ",
	    "format": "+... .... ...."
	},
	{
	    "name": "Sweden",
	    "dial_code": "+46",
	    "code": "SE",
	    "format": "+.. ..-... .. .."
	},
	{
	    "name": "Switzerland",
	    "dial_code": "+41",
	    "code": "CH",
	    "format": "+.. .. ... .. .."
	},
	{
	    "name": "Syrian Arab Republic",
	    "dial_code": "+963",
	    "code": "SY",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Taiwan(Province Of China)",
	    "dial_code": "+886",
	    "code": "TW",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Tajikistan",
	    "dial_code": "+992",
	    "code": "TJ",
	    "format": "+... ... .. ...."
	},
	{
	    "name": "Tanzania, United Republic of Tanzania",
	    "dial_code": "+255",
	    "code": "TZ",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Thailand",
	    "dial_code": "+66",
	    "code": "TH",
	    "format": "+.. .. ... ...."
	},
	{
	    "name": "Timor-Leste",
	    "dial_code": "+670",
	    "code": "TL",
	    "format": "+... .... ...."
	},
	{
	    "name": "Togo",
	    "dial_code": "+228",
	    "code": "TG",
	    "format": "+... .. .. .. .."
	},
	{
	    "name": "Tokelau",
	    "dial_code": "+690",
	    "code": "TK"
	},
	{
	    "name": "Tonga",
	    "dial_code": "+676",
	    "code": "TO",
	    "format": "+... ... ...."
	},
	{
	    "name": "Trinidad and Tobago",
	    "dial_code": "+1868",
	    "code": "TT"
	},
	{
	    "name": "Tunisia",
	    "dial_code": "+216",
	    "code": "TN",
	    "format": "+... .. ... ..."
	},
	{
	    "name": "Turkey",
	    "dial_code": "+90",
	    "code": "TR",
	    "format": "+.. ... ... ...."
	},
	{
	    "name": "Turkmenistan",
	    "dial_code": "+993",
	    "code": "TM",
	    "format": "+... .. ..-..-.."
	},
	{
	    "name": "Turks and Caicos Islands",
	    "dial_code": "+1649",
	    "code": "TC"
	},
	{
	    "name": "Tuvalu",
	    "dial_code": "+688",
	    "code": "TV"
	},
	{
	    "name": "Uganda",
	    "dial_code": "+256",
	    "code": "UG",
	    "format": "+... ... ......"
	},
	{
	    "name": "Ukraine",
	    "dial_code": "+380",
	    "code": "UA",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "United Arab Emirates",
	    "dial_code": "+971",
	    "code": "AE",
	    "format": "+... .. ... ...."
	},
	{
	    "name": "United Kingdom",
	    "dial_code": "+44",
	    "code": "GB",
	    "format": "+.. .... ......"
	},
	{
	    "name": "United States",
	    "dial_code": "+1",
	    "code": "US"
	},
	{
	    "name": "Uruguay",
	    "dial_code": "+598",
	    "code": "UY",
	    "format": "+... .... ...."
	},
	{
	    "name": "Uzbekistan",
	    "dial_code": "+998",
	    "code": "UZ",
	    "format": "+... .. ... .. .."
	},
	{
	    "name": "Vanuatu",
	    "dial_code": "+678",
	    "code": "VU",
	    "format": "+... ... ...."
	},
	{
	    "name": "Venezuela, Bolivarian Republic of Venezuela",
	    "dial_code": "+58",
	    "code": "VE",
	    "format": "+.. ...-......."
	},
	{
	    "name": "Vietnam",
	    "dial_code": "+84",
	    "code": "VN",
	    "format": "+.. .. ... .. .."
	},
	{
	    "name": "Virgin Islands, British",
	    "dial_code": "+1284",
	    "code": "VG"
	},
	{
	    "name": "Virgin Islands, U.S.",
	    "dial_code": "+1340",
	    "code": "VI"
	},
	{
	    "name": "Wallis and Futuna",
	    "dial_code": "+681",
	    "code": "WF",
	    "format": "+... .. .. .."
	},
	{
	    "name": "Yemen",
	    "dial_code": "+967",
	    "code": "YE",
	    "format": "+... ... ... ..."
	},
	{
	    "name": "Zambia",
	    "dial_code": "+260",
	    "code": "ZM",
	    "format": "+... .. ......."
	},
	{
	    "name": "Zimbabwe",
	    "dial_code": "+263",
	    "code": "ZW",
	    "format": "+... .. ... ...."
	}
]