<template>
	<div class="cb-notification" :style="info && backgroundColor" :class="[{'cb-notification__warning' : type == 'warning'},{'cb-notification__soft-warning' : type == 'soft_warning'},{'cb-notification__alert' : type == 'alert'}]">
		<div class="cb-notification__icon">
			<i class="icon-info-circle" v-if="!alert"></i>
			<i class="icon-exclamation-circle" style='{color: white !important;}' v-else></i>
		</div>
		<div class="cb-notification__content">
			<slot></slot>
		</div>
	</div>
</template>
<script type="text/javascript">
export default {
	props: ['type','alert'],
	computed: {
		info(){
			return this.type != 'warning' && this.type != 'soft_warning' && this.type != 'alert'
		}
	}
}
</script>