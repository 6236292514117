import MultiDecimalHelper from "@/utils/multi-decimal"
import cloneDeep from 'lodash-es/cloneDeep';

export default class Helper {
    static constructOneTimeOrderItemsFromCart(response) {
      var orderItem = {};
      //addons
      orderItem.addons = Object.assign([], response.addons);
      orderItem.addons.forEach(addon => {
        addon.initialQuantity = addon.quantity;
        addon.type = "addon";
      });

      //charges
      orderItem.charges = Object.assign([], response.charges);

      //coupons
      orderItem.coupon_list = Object.assign([], response.coupons);
      orderItem.coupon_list.forEach(coupon => coupon.type = "coupon");

      /* manual discounts append to coupon_list array */
      orderItem.coupon_list = orderItem.coupon_list.concat(this.getManualDiscounts(response));

      orderItem.coupon_list = this.segregateAndSort(orderItem.coupon_list);
      
      return orderItem;
    }
    static constructOrderItemsFromCart({order, subscription_estimate}, oldItem) {
      var orderItem = {};

      //plan
      orderItem.plan = Object.assign({}, order.plan);
      orderItem.plan.type = "plan";
      orderItem.plan.initialQuantity = oldItem && oldItem.plan.initialQuantity ? oldItem.plan.initialQuantity : MultiDecimalHelper.getInitialQuantity(order.plan);
      orderItem.plan.quantity_meta_overrides = oldItem && oldItem.plan.quantity_meta_overrides;
      orderItem.zero_dollar_plan = oldItem && oldItem.zero_dollar_plan;
      /**
       * populating curreny code from subscription_estimate
       * TODO: to check why currency_code is not returned in order plan details
       */
      if (orderItem.plan.details && !orderItem.plan.details.currency_code && subscription_estimate) {
        orderItem.plan.details.currency_code = subscription_estimate.currency_code
      }

      //addons
      orderItem.addons = Object.assign([], order.addons);
      orderItem.addons.forEach(addon => {
          if(oldItem) {
              let oldAddon = oldItem.addons.filter(a => a.id == addon.id)[0];
              addon.initialQuantity = oldAddon ? (oldAddon.initialQuantity || MultiDecimalHelper.getQuantity(oldAddon)) : MultiDecimalHelper.getInitialQuantity(addon);
              addon.quantity_meta_overrides = oldAddon && oldAddon.quantity_meta_overrides
          } else {
              addon.initialQuantity = MultiDecimalHelper.getInitialQuantity(addon);
          }
          addon.type = "addon";
      });
      orderItem.event_based_addons = Object.assign([], order.event_based_addons);
      //coupons
      orderItem.coupon_list = Object.assign([], order.coupons);
      orderItem.coupon_list.forEach(coupon => coupon.type = "coupon");

      //orderItem.coupon = orderItem.coupon_list[0] && orderItem.coupon_list[0].id;

      if(oldItem.replace_coupon_list) {
        orderItem.replace_coupon_list = true;
      }

      if(oldItem.replace_addon_list) {
        orderItem.replace_addon_list = true;
      }

      /* fetch manual discounts & append in coupon_list array */
      orderItem.coupon_list = orderItem.coupon_list.concat(this.getManualDiscounts(order));

      orderItem.coupon_list = this.segregateAndSort(orderItem.coupon_list);
      
      return orderItem;
  }


  static constructOrderItems(hostedPage, getters) {
    var orderItem = this.getOrderItemTemplate(hostedPage.subscription, hostedPage.subscription_items, getters);
    orderItem.zero_dollar_plan = hostedPage.is_zero_dollar_plan;
    orderItem.addons = this.getOrderItemAddons(hostedPage);
    if(hostedPage.coupon) { //TODO: Remove this once multicoupon backend released
      orderItem.coupon_list = Object.assign([], [{code: hostedPage.coupon, type: "coupon" }]);
    } else if(hostedPage.coupons) {
      orderItem.coupon_list = [];
      hostedPage.coupons.forEach(coupon_code => {
        orderItem.coupon_list.push({code: coupon_code, type: "coupon"});
      });
    }

    /* manual discounts passed via HP API params -> append to coupon_list array */
    if (hostedPage.manual_discounts) {
      if (orderItem.coupon_list == undefined) 
        orderItem.coupon_list = [];
      orderItem.coupon_list = orderItem.coupon_list.concat(this.getManualDiscounts(hostedPage));
    }

    orderItem.coupon_list = this.segregateAndSort(orderItem.coupon_list);

    return orderItem;
  }

  static constructOrderItemsFromSubscription(subscription) {
    var orderItem = this.getOrderItemTemplate(subscription);
    orderItem.addons = this.getOrderItemAddons(subscription);
    return orderItem;
  }

  static constructOrderItemsFromEstimate(estimate, subscription) {
    var orderItem = { addons: []}
    estimate.line_items.forEach((line_item) => {
      if(line_item.entity_type == "plan" || line_item.entity_type == "plan_item_price") {
        orderItem["plan"] = this.constructItem("plan", line_item, estimate);
      }

      if(line_item.entity_type == "plan_setup") {
        orderItem["plan_setup"] = {
          type: "plan_setup",
          id: line_item.entity_id,
          lineItem: line_item,
        }
      }

      if(line_item.entity_type == "addon" || line_item.entity_type == "addon_item_price") {
        orderItem["addons"].push(this.constructItem("addon", line_item, estimate));
      }
    });

    // In case of hide zero dollar setting, plan line item would not be present
    // also applies when plan is metered item
    if(!orderItem.plan && subscription) {
      orderItem.plan = {
        id: subscription.plan_id,
        quantity: subscription.plan_quantity,
        quantity_in_decimal: MultiDecimalHelper.getQuantity(subscription),
        initialQuantity: subscription.quantity,
        type: "plan",
        ...this.getMeteredPlanProps(subscription)
      }
    }

    /**
     * Metered addons won't be present as part of estimate
     * hence retrieving them from subscription
    **/
    const subscriptionAddons = this.getOrderItemAddons(subscription)
    const meteredAddons = subscriptionAddons.filter(addon => addon.metered)
    orderItem['addons'] = [...orderItem['addons'], ...meteredAddons]

    // NOT STORING COUPON IN ORDER ITEM - bcoz of expired coupons - cart update api will return valid coupons

    // add discounts
    // add coupon only if only one discount is present
    // multiple coupons scenario will be handled differently
    // if(estimate.discounts && estimate.discounts.length == 1) {
    //   var discount = estimate.discounts[0];
    //   if(["item_level_coupon", "document_level_coupon"].indexOf(discount.entity_type) > -1) {
    //     orderItem.coupon = discount.entity_id;
    //   }
    // }

    return orderItem;
  }

  static constructItem(type, line_item, estimate) {
    const orderItem = {
      type: type,
      id: line_item.entity_id,
      lineItem: line_item,
      quantity: line_item.quantity,
      initialQuantity: line_item.quantity,
      pricing_model: line_item.pricing_model,
      unit_amount: line_item.unit_amount,
      amount: line_item.amount,  
      tiers: ((estimate.line_item_tiers && estimate.line_item_tiers.filter(t => t.line_item_id == line_item.id)) || [])
    }

    if(MultiDecimalHelper.isMultiDecimal(line_item)) {
      orderItem.quantity_in_decimal = MultiDecimalHelper.getQuantity(line_item);
      orderItem.initialQuantity = MultiDecimalHelper.getInitialQuantity(line_item);
      orderItem.unit_amount_in_decimal = MultiDecimalHelper.getUnitAmount(line_item);
      orderItem.amount_in_decimal = MultiDecimalHelper.getAmount(line_item);
    }

    return orderItem;
  }

  static constructAddons(data) {
    var output = [];
    var tmpMap = {};
    this.getAddonFields(data).forEach((key) => {
      let tmp = key.match(/addons\[(.*)\]\[(.*)\]/) && key.match(/addons\[(.*)\]\[(.*)\]/).slice(1);
      if(!['id', 'quantity', 'quantity_in_decimal'].includes(tmp[0])){
        return;
      }
      if(tmp.length == 2){
        if(!tmpMap[tmp[1]]) {
          tmpMap[tmp[1]] = {};
          output.push(tmpMap[tmp[1]]);
        }
        tmpMap[tmp[1]][tmp[0]] = this._getField(data, key);
      }
    });
    return output;
  }

  static constructPlanSetup(planSetupLineItem) {
    if(!planSetupLineItem) return;
    return {
      type: "plan_setup",
      id: planSetupLineItem.entity_id,
      lineItem: planSetupLineItem,
      amount: planSetupLineItem && planSetupLineItem.amount
    }
  }

  static getAddonFields(hostedPage) {
    return Object.keys(hostedPage).filter(param => param.startsWith("addons"));
  }

  static _getField(data, field) {
    return data[field];
  }

  static getCurrentTimestamp(){
    return new Date().getTime();
  }

  static getMeteredPlanProps(subscription, subscription_items) {
    const items = subscription_items || subscription.subscription_items || []
    const meteredPlan = items.find(item => item.item_type === 'plan' && item.hasOwnProperty('metered_quantity'))
    let meteredPlanProps = {}
    if (meteredPlan) {
      meteredPlanProps = {
        metered: true,
        metered_quantity: meteredPlan.metered_quantity,
        last_calculated_at: meteredPlan.last_calculated_at,
        has_non_zero_metered_quantity: this.isNonZero(meteredPlan.metered_quantity),
        id: meteredPlan.item_price_id
      }
    }
    return meteredPlanProps
  }

  static getOrderItemTemplate(subscription, subscription_items, getters){
    var orderItem = {};
    if (getters && getters.is_checkout_one_time) {
      return orderItem;
    }
    orderItem = {
      plan: {
        id: subscription.plan_id,
        quantity: subscription.plan_quantity,
        quantity_in_decimal: subscription.plan_quantity_in_decimal,
        type: "plan",
        initialQuantity: MultiDecimalHelper.getInitialQuantity(subscription),
        ...this.getMeteredPlanProps(subscription, subscription_items)
      }
    }
    return orderItem;
  }
  /**
   *
   * @param {object} entity - can be either hosted page or subscription
   */
  static getOrderItemAddons(entity){
    return ((entity && entity.addons) || []).map(addon => {
      const items = entity.subscription_items || []
      const meteredAddon = items.find(item =>
        item.item_type === 'addon' && item.item_price_id === addon.id && item.hasOwnProperty('metered_quantity'))
      let meteredAddonProps = {}
      if (meteredAddon) {
        meteredAddonProps = {
          metered: true,
          metered_quantity: meteredAddon.metered_quantity,
          last_calculated_at: meteredAddon.last_calculated_at,
          has_non_zero_metered_quantity: this.isNonZero(meteredAddon.metered_quantity),
        }
      }
      return {
        ...addon,
        ...meteredAddonProps,
        initialQuantity : MultiDecimalHelper.getInitialQuantity(addon),
        type : "addon"
      }
    });
  }
  static isNonZero(value) {
    return ! /^[0]((\.0*)*)$/.test(value)
  }

  static getManualDiscounts(order) {
    let discountsList = [];
    if (order && order.manual_discounts) {
      discountsList = order.manual_discounts;
      discountsList.forEach(discount => {
        discount.type = discount.type || "manual_discount";
      });
    }

    return discountsList;
  }

  static segregateAndSort(dataList) {
    if (dataList) {
      /* keeping legacy behaviour intact */
      if(!this.isManualDiscountPresent(dataList)) return dataList;

      /* eventually, all coupons & manual discounts will have entity_type + discount_type, sort only then to optimise */
      for (let item of dataList) {
        if (!item.entity_type || !item.discount_type) {
          if (!item.details || !item.type) {
            return dataList;
          }
        }
      }

      dataList = cloneDeep(dataList);
      let coupon_flat_line_level = [];
      let discount_flat_line_level = [];
      let coupon_percentage_line_level = [];
      let discount_percentage_line_level = [];
      let coupon_flat_invoice_level = [];
      let discount_flat_invoice_level = [];
      let coupon_percentage_invoice_level = [];
      let discount_percentage_invoice_level = [];
      let no_category_data = [];
      let result = [];
  
      /* segregate into individual categories */
      dataList.forEach(item => {
        let item_discount_type, item_entity_type;

        /* discount type : FIXED AMOUNT vs PERCENTAGE */
        if (!!item.discount_type) item_discount_type = item.discount_type.toUpperCase();
        else if (!!item.details) item_discount_type = item.details.discount_type.toUpperCase();

        /* entity type : line_level vs invoice_level */
        if (!!item.entity_type) item_entity_type = item.entity_type.toUpperCase();
        else {
          if (!!item.type && !!item.details) {
            let item_apply_on = item.details.apply_on.toUpperCase();
            if (item.type === "coupon") {
              if(item_apply_on === this.ApplicableType.EACH_SPECIFIED_ITEM) item_entity_type = this.EntityType.ITEM_LEVEL_COUPON;
              else if (item_apply_on === this.ApplicableType.INVOICE_AMOUNT) item_entity_type = this.EntityType.DOCUMENT_LEVEL_COUPON;
            } else if (item.type === "manual_discount") {
              if (item_apply_on === this.ApplicableType.EACH_SPECIFIED_ITEM) item_entity_type = this.EntityType.ITEM_LEVEL_DISCOUNT;
              else if (item_apply_on === this.ApplicableType.INVOICE_AMOUNT) item_entity_type = this.EntityType.DOCUMENT_LEVEL_DISCOUNT;
            }
          }
        }

        switch(item_entity_type) {
          case this.EntityType.ITEM_LEVEL_COUPON:
            if (item_discount_type === this.DiscountType.FIXED_AMOUNT) coupon_flat_line_level.push(item);
            else if (item_discount_type === this.DiscountType.PERCENTAGE) coupon_percentage_line_level.push(item);
            break;
          case this.EntityType.ITEM_LEVEL_DISCOUNT:
            if (item_discount_type === this.DiscountType.FIXED_AMOUNT) discount_flat_line_level.push(item);
            else if (item_discount_type === this.DiscountType.PERCENTAGE) discount_percentage_line_level.push(item);
            break;
          case this.EntityType.DOCUMENT_LEVEL_COUPON:
            if (item_discount_type == this.DiscountType.FIXED_AMOUNT) coupon_flat_invoice_level.push(item);
            else if (item_discount_type === this.DiscountType.PERCENTAGE) coupon_percentage_invoice_level.push(item);
            break;
          case this.EntityType.DOCUMENT_LEVEL_DISCOUNT:
            if (item_discount_type === this.DiscountType.FIXED_AMOUNT) discount_flat_invoice_level.push(item);
            else if (item_discount_type === this.DiscountType.PERCENTAGE) discount_percentage_invoice_level.push(item);
            break;
          default:
            no_category_data.push(item);
            break;
        }
      });

      let coupon_flat_consolidated_list = [];
      let coupon_percentage_consolidated_list = [];
      let discount_flat_consolidated_list = [];
      let discount_percentage_consolidated_list = [];

      /* sort within a category */
      no_category_data = this.sortByNumericalValue(no_category_data);
      coupon_flat_line_level = this.sortByNumericalValue(coupon_flat_line_level);
      coupon_flat_invoice_level = this.sortByNumericalValue(coupon_flat_invoice_level);
      coupon_percentage_line_level = this.sortByNumericalValue(coupon_percentage_line_level);
      coupon_percentage_invoice_level = this.sortByNumericalValue(coupon_percentage_invoice_level);
      discount_flat_line_level = this.sortByNumericalValue(discount_flat_line_level);
      discount_flat_invoice_level = this.sortByNumericalValue(discount_flat_invoice_level);
      discount_percentage_line_level = this.sortByNumericalValue(discount_percentage_line_level);
      discount_percentage_invoice_level = this.sortByNumericalValue(discount_percentage_invoice_level);

      coupon_flat_consolidated_list = coupon_flat_consolidated_list.concat(coupon_flat_line_level, coupon_flat_invoice_level);
      coupon_percentage_consolidated_list = coupon_percentage_consolidated_list.concat(coupon_percentage_line_level, coupon_percentage_invoice_level);
      discount_flat_consolidated_list = discount_flat_consolidated_list.concat(discount_flat_line_level, discount_flat_invoice_level);
      discount_percentage_consolidated_list = discount_percentage_consolidated_list.concat(discount_percentage_line_level, discount_percentage_invoice_level);

      /* merge sorted sub-categories */
      result = result.concat(no_category_data,
        coupon_flat_consolidated_list, coupon_percentage_consolidated_list,
        discount_flat_consolidated_list, discount_percentage_consolidated_list);

      return result;
    }

    return dataList;
  }

  static sortByNumericalValue(dataList) {
    if (!!dataList) {
      dataList.sort((item1, item2) => {
        let item1Value, item2Value;
        let item1ComputedAmount = 0, item2ComputedAmount = 0;

        if (this.isManualDiscount(item1)) {
          /* manual discount */
          let item1Amount = item1.description || item1.details.invoice_name;
          let item2Amount = item2.description || item2.details.invoice_name;
          item1Value = parseInt(item1Amount.replace(/[^0-9]/g, '')) || 0;
          item2Value = parseInt(item2Amount.replace(/[^0-9]/g, '')) || 0;

          /* computed amount in case of percentage, for flat/fixed type computed amount = amount set */
          item1ComputedAmount = item1.amount || item1.details.discount_amount || 0;
          item2ComputedAmount = item2.amount || item2.details.discount_amount || 0;
        } else {
          /* coupon */
          /* no computed amount as BE API shares only computed amount even in case of percentage type */
          item1Value = item1.amount || 0;
          item2Value = item2.amount || 0;
        }
        
        /* sort in decreasing order -> higher amount / percentage first */
        if (item1Value == item2Value)
          return item2ComputedAmount - item1ComputedAmount;
        return item2Value - item1Value;
      });

      return dataList;
    }
    
    return dataList;
  }

  static isManualDiscount(data) {
    if (data) {
      /* estimate API */
      if (!!data.entity_type) return data.entity_type.toUpperCase() === this.EntityType.ITEM_LEVEL_DISCOUNT || data.entity_type.toUpperCase() === this.EntityType.DOCUMENT_LEVEL_DISCOUNT;
      /* order -> coupon_list */
      if (!!data.type) return data.type == "manual_discount";
    }
    
    return false;
  }

  /* util to find if atleast one manual discount present in a list of coupons / manual_discount */
  static isManualDiscountPresent(item_list) {
    if (item_list) {
      return item_list.find(item => this.isManualDiscount(item)) != undefined;
    }

    return false;
  }

  static get EntityType() {
    return {
      ITEM_LEVEL_COUPON: "ITEM_LEVEL_COUPON",
      ITEM_LEVEL_DISCOUNT: "ITEM_LEVEL_DISCOUNT",
      DOCUMENT_LEVEL_COUPON: "DOCUMENT_LEVEL_COUPON",
      DOCUMENT_LEVEL_DISCOUNT: "DOCUMENT_LEVEL_DISCOUNT",
      PROMOTIONAL_CREDITS: "PROMOTIONAL_CREDITS",
      PRORATED_CREDITS: "PRORATED_CREDITS",
    };
  };

  static get DiscountType() {
    return {
      FIXED_AMOUNT: "FIXED_AMOUNT",
      PERCENTAGE: "PERCENTAGE"
    };
  }

  static get ApplicableType() {
    return {
      INVOICE_AMOUNT: "INVOICE_AMOUNT",
      EACH_SPECIFIED_ITEM: "EACH_SPECIFIED_ITEM"
    };
  }
  static isPayNow(hosted_page_type) {
    return hosted_page_type == "paynow";
  }
}
