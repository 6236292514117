<template>
	<div class="cb-hp__wrapper">
		<div ref="parent" class="cb-header cb-transition__slide" id="cb-header">
	      <component v-cb-slide="{ isnew : true , ref: $refs.parent }" :is="'header-1'" :key="$route.path">
	      </component>
	    </div>
	    <div class="cb-body" id="cb-body" role="main">
	    	<div class="cb-body__content">
		    	<div v-cb-animate='true' class="cb-body__wrap">
		    		<div id="account-list" class="cb-section cb-bar account-container" @scroll="loadMore">
		    			<div class="cb-bar__wrap" v-cb-link = "'shadow'" v-for="(linked_handle, index) in linked_handles" role="navigation" data-cb-id="choose_account" v-bind:key='index' @click="load(linked_handle)">
							<div class="account-info-text" v-if="additionalSummaryInfo(linked_handle.handle)" v-cb-html="sanitizeTags(additionalSummaryInfo(linked_handle.handle),['br', 'b', 'a', 'u', 'i'])"></div>
					      	<div class="cb-bar__content">
								<span class="cb-bar__desc">{{st('account_txt')}} #{{ index + 1 }} • </span> 
								<span class="cb-bar__desc">{{st('account_signup')}}</span>
								<span class="cb-bar__desc">{{date(linked_handle.created_at)}}</span>
					      	</div>
					    </div>
		    		</div>
				</div>
			</div>
			<div v-if="loading" class="loader"></div>
		</div>
	</div>
</template>
<script type="text/javascript">
import CBNotification from "@/models/cb-notification";
import Auth from "@/store/common/auth.js";
import PortalHeader from "@/components/collections/headers/PortalHeader"
import MultiEntityMixin from "@/mixins/multi-entity-mixin";
import apiClient from "@/utils/cb-client"
import { replaceFromData } from "@/utils/helper-functions"

import {
  mapGetters
} from "vuex"
export default {
	components: {
		"header-1": PortalHeader
	},
	data(){
		return{
			customers: undefined,
			offset: undefined,
			loading: false,
			linkedHandlesList: []
		};
	},
	mixins: [MultiEntityMixin],
	computed: {
	    ...mapGetters([
			'cancan',
			'portal_loaded',
			'linked_handles',
			'authType',
	    ]),
	    accountChooserTxt(){
	    	return "Choose Account";
	    }
	},
	methods: {
		load : function(linked_handle){
			this.$store.commit('SET_CUSTOMER_HANDLE',linked_handle.handle);
			this.$store.dispatch('authenticate_user').then( () => {
				this.$router.replace({
                	name: "home"
            	});
			})
		},
		loadHandles(append) {
			this.loading = true;
			var source = this.$store.state.auth.hpToken ? "checkout" : "portal";
			apiClient.auth_service.list_users({},{source: source, offset: this.offset}).then((data) => {
				if(append && this.linked_handles) {
					this.$store.commit('APPEND_LINKED_HANDLES', data.linked_handles)
					this.$store.commit('APPEND_LINKED_CUSTOMERS', data.data)
				}
				else {
					this.$store.commit('SET_LINKED_HANDLES', data.linked_handles)
					this.$store.commit('SET_LINKED_CUSTOMERS', data.data)
				}
				this.offset = data.next_offset
				this.loading = false;
			})			
		},
		loadMore() {
			const scrollableDiv = document.getElementById('account-list');
			const distanceToBottom = scrollableDiv.scrollHeight - scrollableDiv.scrollTop - scrollableDiv.clientHeight;
			const threshold = 1;
			if (distanceToBottom <= threshold && this.offset) {
				this.loadHandles(true);
			}
		},
		additionalSummaryInfo(handle) {
			var customer = this.$store.getters.linkedCustomer(handle);
			var output = this.$store.getters.nl_translate('account.summary.additionalInfo');
			if(!output) return "";
			output = output.replace(/\sEOL\s/g, "<br>");
			return replaceFromData(output, {"customer" : customer});
		},
		getAccountName(linked_handle) {
			if(!linked_handle || !linked_handle.name)
				return ""
			return linked_handle.name
		},
		getAccountCreationDate(linked_handle) {
			if(!linked_handle || !linked_handle.created_at)
				return ""
			return this.date(linked_handle.created_at)
		},
		sanitizeTags(content, allowedTags) {
			return {
				text: content,
				tags: allowedTags,
          		attrs: {a: ["href"]}
			}
      	}
	},
	mounted(){
		var token = this.$store.state.auth.jwt;
		this.$store.commit('RESET_LIST');
		this.$store.commit('RESET_CUSTOMER_DATA');
		// Reset invoices on switching account
		this.$store.dispatch('reset_invoice_list');

		if(!token) {
			this.$store.dispatch('reset_auth');
			if(this.$store.state.auth.authType == "email") {
				this.$router.push({name: "portal_login"});
			}
		}
		this.$store.dispatch('set_page_type', 'portal');
		if(this.$store.state.auth.authType == "email")
			this.loadHandles(false);
	}

}


</script>

<style scoped>
.account-container {
	max-height: 25rem;
	overflow-x: scroll;
}
.loader {
  border: 2.5px solid #f3f3f3; /* Light grey */
  border-top: 2.5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin-left: 11.5rem;
  transition: border 0.3s ease;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.account-info-text {
  overflow-wrap: normal;
  max-width: 100%;
}

</style>
