<template>
  <transition mode="out-in" @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @after-leave="afterLeave" @leave="leave" @before-leave="beforeLeave" v-if="!isList">
    <slot></slot>
  </transition>
  <transition-group mode="out-in" @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @after-leave="afterLeave" @leave="leave" @before-leave="beforeLeave" v-else>
    <slot></slot>
  </transition-group>
</template>
<script type="text/javascript">
export default {
  props: ["disableBeforeLeave","timeOut", "isList", "reCalculateMargin"],
  data() {
    return {
      elHeight : 0,
      app: document.getElementById("app"),
    }
  },
  methods: {

    calculateAppMargin(){
      let isPreview = window && window.initInfo && window.initInfo.is_preview;
      let minMarginTop = (isPreview) ? 90 : 20;
      if ((window.innerHeight - this.app.clientHeight) > minMarginTop) {
        let diff = window.innerHeight - this.app.clientHeight;
        this.app.style.marginTop = (diff / 2.0) + "px";
      } else {
        this.app.style.marginTop = minMarginTop + "px";
      }
    },

    resetValues(el){
      el.style.height = 'auto';
      this.app.$prevHeight = this.app.clientHeight + "px";
    },

    beforeEnter(el) {
      el.style.opacity = 0;
    },

    //------------------------------------------------------------------------------
    //  Animate div from 0px to its height
    //------------------------------------------------------------------------------
    enter(el){
      this.elHeight = el.clientHeight;
      el.style['height'] = "0px";
      window.cbTimeout(() => {
        el.style['height'] = this.elHeight + "px";
      });
    },

    //------------------------------------------------------------------------------
    //  Reset values
    //------------------------------------------------------------------------------
    afterEnter(el) {
      window.setTimeout(() => {
        this.resetValues(el);
      },400);//animation duration
      el.style.opacity = 1;
      this.reCalculateMargin && !window.isMobile && this.calculateAppMargin();
    },

    beforeLeave(el) {
      el.style.opacity = 0;
      this.timeOut && (el.style.transition = "opacity 0.3s, height 0.3s 0.2s");
    },

    leave(el){
      if(this.disableBeforeLeave) {
        return;
      }
      el.style.height = el.clientHeight + "px"
      window.cbTimeout(() => {
        el.style.height = '0px';
      });
    },

    //------------------------------------------------------------------------------
    //  Reset values
    //------------------------------------------------------------------------------
    afterLeave(el) {
      window.setTimeout(() => {
        this.resetValues(el);
      },400);//animation duration
      el.style.opacity = 0;
      this.reCalculateMargin && this.calculateAppMargin();
    }
  },
}
</script>
