<template>
  <div v-if="showDetailedView" class="cb-field__extend">
    <template v-for="(arr,index) in config_in_twos">
      <div v-bind:key="index" v-if="arr.length == 2" class="cb-field__group" :class="[{'cb-field--top': index == 0},{'cb-field--bottom': (config_in_twos.length-1) == index}]">
        <component @informError="informError(index, 0)" :sibling-error="showSiblingError(index, 1)" @clearError="clearError(index, 0)" class="cb-field__left" :is="componentName(arr[0].type)" :name="arr[0].name" v-if="arr[0].type">
        </component>
        <component @informError="informError(index, 1)" :sibling-error="showSiblingError(index, 0)" @clearError="clearError(index, 1)" class="cb-field__right" :is="componentName(arr[1].type)" :name="arr[1].name" v-if="arr[1].type">
        </component>
      </div>
      <div v-bind:key="index" :class="[{'cb-field--top': index == 0},{'cb-field--bottom': (config_in_twos.length-1) == index}]" v-else-if="arr.length == 1 && arr[0].type!= 'hidden'" class="cb-field__group">
        <component :is="componentName(arr[0].type)" @place-changed="fetchResults" :name="arr[0].name" v-if="arr[0].type">
        </component>
      </div>
    </template>
  </div>
</template>

<script type="text/javascript">
import TextField from "@/components/collections/form/TextField"
import RegionField from "@/components/collections/form/RegionField"
import SelectField from "@/components/collections/form/SelectField"
import AddressExtractor from "@/utils/address-extractor"
import Vue from "vue"
export default {
  props: ['name', 'params', 'useAutocomplete'],
  data() {
    return {
      pName: undefined,
      showDetailedView: true,
      searchErrorMessage: "",
      searchText: "",
      searchLabel: "",
      siblingError: {}
    }
  },
  created() {
    this.pName = this.findParentForm();
  },
  computed: {
    config() {
      return this.$store.getters.vField_config(this.pName, this.name).meta.sub_fields
    },
    config_in_twos() {
      let fields = this.config.filter(i => (i.type != 'hidden') );
      return fields.reduce((o, field, index) => {
        let last = o[o.length - 1];
        if ((last && last.length == 2) || !last) {
          o.push([field]);
        } else if (last[0].short_field && last[0].label.length <= 18) {
          last.push(field);
        } else {
          o.push([field]);
        }
        return o;
      }, [])
    },
  },
  components: {
    'cb-text': TextField,
    'cb-region': RegionField,
    'cb-select': SelectField
  },
  methods: {
    componentName(type) {
      switch (type) {
        case "text":
          return "cb-text";
        case "region":
          return "cb-region";
        case "select":
          return "cb-select";
        case "hidden":
          return;
      }
      return "cb-text"
    },
    fetchResults(value) {
      let extractedValues = AddressExtractor.extractAddress(value);
      this.config.reduce((o,f) => {
        f.sub_fields ? o = o.concat(f.sub_fields) : o.push(f);
        return o;
      },[]).filter(f => !f.read_only).map(f => f.name).forEach(field => {
        this.$store.dispatch('update_form_input',
          {
            name: field,
            value: extractedValues[field] || ( AddressExtractor.ALIASES[field] && extractedValues[AddressExtractor.ALIASES[field]] ),
            formName: this.pName
          });
      });

      this.$store.dispatch('validate_form', this.pName);
      this.showDetailedView = true;
    },
    input(event) {
      this.searchText = event.target.value;
    },
    informError(index, siblingIndex) {
      if(!this.siblingError[index]) {
        Vue.set(this.siblingError, index + "", {});
      }
      Vue.set(this.siblingError[index + ""], siblingIndex + "",true);
    },
    clearError(index, siblingIndex) {
      if(!this.siblingError[index]) {
        Vue.set(this.siblingError, index + "", {});
      }
      Vue.set(this.siblingError[index + ""], siblingIndex + "", false);
    },
    showSiblingError(index, siblingIndex) {
      return this.siblingError[index + ""] && this.siblingError[index + ""][siblingIndex + ""];
    }
  },
  mounted() {
      // TODO check if autocomplete object is loaded
      // TODO its a bug change the condition logic
      if(this.$store.getters.fields(this.pName).filter(field => this.$store.getters.field_config(this.pName, field).value).length > 0) {
        this.showDetailedView = true;
      }
  }
}
</script>