import { getRouteKey, checkIfEntryEqualsExit } from "@/utils/checkout-router-helper"
function hasMeteredItems(estimate) {
  if (estimate) {
    return (
      estimate.line_items &&
      estimate.line_items.some(lineItem => lineItem.metered)
    );
  }
  return false;
}

function isLineItemMultiDecimal(lineItem) {
  if (lineItem) {
    const { unit_amount_in_decimal = "", quantity_in_decimal = "" } = lineItem;
    return [unit_amount_in_decimal, quantity_in_decimal].some(amount => {
      if (!isNaN(amount)) {
        const decimal = amount.split(".")[1];
        // To check if number of decimal digits is > 2
        return !isNaN(decimal) && parseInt(decimal) > 99;
      }
      return false;
    });
  }
  return false;
}

function hasMultiDecimal(estimate) {
  // TODO: handle multidecimal tier price
  if (estimate) {
    return estimate.line_items.some(lineItem =>
      isLineItemMultiDecimal(lineItem)
    );
  }
  return false;
}

function hasTaxes(estimate) {
  if (estimate) {
    return (
      (estimate.line_item_taxes || []).length > 0 ||
      (estimate.taxes || []).length > 0
    );
  }
  return false;
}

function getCurrencyCode(estimate) {
  if (estimate) {
    return estimate.currency_code;
  }
}

function hasHighValueItems(estimate) {
  if (estimate) {
    return (estimate.line_items || []).some(lineItem => {
      // Amount should be greater than 1,000,000,000
      return !isNaN(lineItem.amount) && lineItem.amount > 1000000000
    })
  }
  return false;
}

export default class LogTracker {
  constructor(getters, dispatch) {
    this.getters = getters;
    this.dispatch = dispatch;
  }

  setOrderEstimateHolder(orderEstimateHolder) {
    this.orderEstimateHolder = orderEstimateHolder;
    return this;
  }

  getEstimate() {
    return this.orderEstimateHolder && this.orderEstimateHolder.estimate;
  }

  getTotal() {
    const estimate = this.getEstimate();
    return estimate && estimate.total;
  }

  hasTaxes() {
    return this.orderEstimateHolder && (
      hasTaxes(this.orderEstimateHolder.immediateEstimate) ||
      this.orderEstimateHolder.nextEstimates.some(estimate =>
        hasTaxes(estimate)
      )
    );
  }

  hasMeteredItems() {
    return this.orderEstimateHolder && (
      hasMeteredItems(this.orderEstimateHolder.immediateEstimate) ||
      this.orderEstimateHolder.nextEstimates.some(estimate =>
        hasMeteredItems(estimate)
      )
    );
  }

  hasMultiDecimalItems() {
    return this.orderEstimateHolder && (
      hasMultiDecimal(this.orderEstimateHolder.immediateEstimate) ||
      this.orderEstimateHolder.nextEstimates.some(estimate =>
        hasMultiDecimal(estimate)
      )
    );
  }

  getCurrencyCode() {
    return this.orderEstimateHolder && (
      getCurrencyCode(this.orderEstimateHolder.immediateEstimate) ||
      getCurrencyCode(
        this.orderEstimateHolder.nextEstimates &&
        this.orderEstimateHolder.nextEstimates[0]
      )
    );
  }

  getCouponsCount() {
    const counter = {};
    this.getters.coupon_list.forEach(({ type: couponType }) => {
      if (couponType) {
        if (counter[couponType]) counter[couponType] += 1;
        else counter[couponType] = 1;
      }
    });
    return counter;
  }

  hasHighValueItems() {
    return this.orderEstimateHolder && (
      hasHighValueItems(this.orderEstimateHolder.immediateEstimate) ||
      this.orderEstimateHolder.nextEstimates.some(estimate =>
        hasHighValueItems(estimate)
      )
    );
  }

  hasEinvoicing() {
    return !!(this.getters.einvoice_schemes || []).filter(scheme => scheme && !!scheme.value).length
  }

  init() {
    try {
      Promise.all([
        this.dispatch("log_has_taxes", this.hasTaxes()),
        this.dispatch("log_has_metered_items", this.hasMeteredItems()),
        this.dispatch("log_total_order_value", this.getTotal()),
        this.dispatch("log_currency_code", this.getCurrencyCode()),
        this.dispatch("log_has_multidecimal", this.hasMultiDecimalItems()),
        this.dispatch("log_coupons_count", this.getCouponsCount()),
        this.dispatch("log_has_hvc", this.hasHighValueItems()),
        this.dispatch("log_has_einvoicing", this.hasEinvoicing()),
      ]).catch(error => {
        logger.e(error);
      })
    } catch (error) {
      logger.e(error);
    }
  }

  logUserNavigation(from, to) {
    try {
      if (to == null) return;
      let toKey = getRouteKey(to.name);
      if (!toKey) return;

      let entryPayload = { key: toKey, type: "entry", time: new Date().getTime() };
      this.dispatch("log_user_navigation_stat", entryPayload)

      if (from == null) return;
      let fromKey = getRouteKey(from.name);
      if (fromKey && !checkIfEntryEqualsExit(fromKey, toKey)) {
        let exitPayload = { key: fromKey, type: "exit", time: new Date().getTime() };
        this.dispatch("log_user_navigation_stat", exitPayload)
      }
    } catch (error) {
      logger.e(error);
    }
  }
  logUserExit() {
    try {
      let lastEnteredRoute = this.getters.get_last_entry_route;
      if (lastEnteredRoute) {
        let exitPayload = { key: lastEnteredRoute.hp_exit_page, type: "exit", time: new Date().getTime() };
        this.dispatch("log_user_navigation_stat", exitPayload)
      }
    } catch (error) {
      logger.e(error);
    }
  }

}