<template>
  <div class="cb-field" :class="[{'cb-field--active': (internalValue || focussed || isDate)}, customClass,{'cb-field--error':(!!errorMessage && !focussed)},{'cb-field--sibling':siblingError},{'cb-field--focus': focussed && !readonly},{'cb-field--select-alt': this.config.meta.help_text}]">
    <input ref="input" type="date" @click="focus($event)" :value="displayValue" class="cb-field__control" :readonly="readonly" :placeholder="isDate && _timestampPlaceholder" @focus="focus($event)" @blur="blur($event)" @keyup="keyup($event)" :name='name' :aria-disabled="readonly" :disabled="readonly" :class="{'cb-field__readonly':readonly}" :aria-required='required' :id="name" />
    <label class="cb-field__label" :class="{'cb-field__label--s': isShort}" :for='name'> {{displayLabel}}</label>
    <slot></slot>
    <div class="cb-field__help" v-if="this.config.meta.help_text">
      <template>
        <div class="cb-field__trigger" :class="{'active-opacity': helpTextShown}" @click="showHelpText($event)" ref="helpText">
        </div>
        <transition name="help-text">
          <div class="cb-field__menu" v-show="helpTextShown">
            {{this.config.meta.help_text}}
          </div>
        </transition>
      </template>
    </div>
    <div class="cb-field__error" v-if="errorMessageWrapper && !focussed">
      {{errorMessageWrapper}}
    </div>
  </div>
</template>
<script>
import FieldMixin from "@/mixins/field-mixin";
import Transformer from "@/utils/transformer";

export default {
  name: "timestamp",
  props: ["name", "customClass", "siblingError", "type"],
  created() {
    this.pName = this.findParentForm();
  },
  mixins: [FieldMixin],
  data() {
    return {
      placeholder: ""
    };
  },
  computed: {
    displayValue() {
      return Transformer.getDateFromTimestamp(this.internalValue);
    },
    _timestampPlaceholder() {
      return (
        this.config.meta.validations.timestampFormat &&
        this.config.meta.validations.timestampFormat.format
      );
    },
    isDate() {
      return this.config.meta.type == "date" ||
        this.config.meta.type == "timestamp"
        ? true
        : false;
    }
  },
  mounted() {
    if (this.internalValue) {
      this.$store.dispatch("update_form_input", {
        name: this.name,
        value: Number(this.internalValue),
        formName: this.pName
      });
    }
  },
  methods: {
    blur(event) {
      this.focussed = false;
      this.helpTextShown = false;
      var val = Transformer.getTimestampFromDate(event.target.value);
      this.$store.dispatch("update_form_input", {
        name: this.name,
        value: val,
        formName: this.pName
      });
      this.validate(true);
    },
    keyup(event) {
      if (event.keyCode === 13) {
        this.blur(event);
      }
    }
  }
};
</script>
