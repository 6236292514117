import intentHolder, {INTENT_FOR_EXISTING_CUSTOMER, INTENT_FOR_NEW_CUSTOMER} from "@/models/intent-holder";
import { deepGet } from "@/utils/helper-functions"
import PaymentHelper from "@/utils/payment-helper";
import { Gateway, UpperCaseGateway } from '@/models/gateway-type';

let btClientInstance = undefined;
export function updateBTClientInstance(instance) {
    btClientInstance = instance;
}

export const DEFAULT_PI_AMOUNT = 100

export function getCardBillingAddress(pm) {
  return {
    firstName: pm.first_name,
    lastName: pm.last_name,
    addressLine1: pm.billing_addr1,
    addressLine2: pm.billing_addr2,
    city: pm.billing_city,
    state: pm.billing_state,
    stateCode: pm.billing_state_code,
    countryCode: pm.billing_country,
    zip: pm.billing_zip,
  }
}

function getCustomerInfo(store) {
  const customer = store.state.customer.data || {};
  return {
    firstName: customer.first_name,
    lastName: customer.last_name,
    phone: customer.phone,
    email: customer.email,
  }
}

export function getPaymentIntentAmount(pmType, getters) {
  let amount = getters.hosted_page_type === 'paynow' ? getters.amountToBePaid : getters.current_amount_due;
  let pm = getters.pm_by_type(pmType);
  // if zero then set it from token amount
  if(!amount && (pm && pm.token_amount) != undefined) {
    amount = pm.token_amount;
  }
  if(amount == undefined) {
    amount = DEFAULT_PI_AMOUNT;
  }
  return amount;
}

export function createPaymentIntent(store, payment_method = {}, options = {}) {
  const getters = store.getters;
  const state = store.state;
  const params = {
    amount: undefined,
    currencyCode: getters.currency_code || store.state.customer.data.preferred_currency_code,
    intent: getters.payment_intent,
    paymentMethodType: payment_method.type
  }

  if(options.paymentMethodType) {
    params.paymentMethodType = options.paymentMethodType
  }
  if(options.postAction) {
    params.postActionJson = options.postAction
  }
  if(options.amount != undefined) {
    params.amount = options.amount
  } else {
    params.amount = getPaymentIntentAmount(params.paymentMethodType, getters)
  }

  if(state.customer?.temp_payment_intent) params.tempPaymentIntent = state.customer.temp_payment_intent;
  return Promise.resolve().then(() => {
    if(state.auth.authenticated) {
      params["customerId"] = getters.customer_handle
      params["paymentSourceId"] = payment_method.reference_id
      params["gatewayId"] = payment_method.gateway_account_id
      return intentHolder.set(INTENT_FOR_EXISTING_CUSTOMER, params);
      }
    else {
      params["gatewayId"] = payment_method.gateway_account_id
      return intentHolder.set(INTENT_FOR_NEW_CUSTOMER, params);
    }
  }).then(({payment_intent}) => {
    // For apple pay/google pay we are creating a new payment intent when button is loaded, so If user authorizes a payment intent then
    // we moved user to summary page but if user clicks in payment edit button we will load apple pay/google pay button again and will create a new payment
    // intent and if he will click back without authorizing new payment intent we will get error because new payment intent is not authorized
    // so we will use skipSetPaymentIntent to not set payment intent on button load.
    if(options.skipSetPaymentIntent) {
      // we will skip but store this in temp variable
      store.dispatch("set_temp_payment_intent", payment_intent);
    } else {
      store.dispatch("set_payment_intent", payment_intent);
      store.dispatch("set_temp_payment_intent", undefined);
    }

    if(window.cbStorage && window.cbStorage.localStorageEnabled) {
      window.cbStorage.setItem("cb_payment_intent_id", payment_intent.id)
    }

    return payment_intent;
  })
}

export function threeDSHandlerWithMandate(store, payment_method, options = {}, mandateDescription) {
  const pm = store.getters.pm_by_type(payment_method.type);
  if(PaymentHelper.isMandateEnabledPaymentMethod(pm)) {
    return threeDSHandler(store, payment_method, options, { requireMandate: true, description: mandateDescription })
  }
  return threeDSHandler(store, payment_method, options)
}

export function threeDSHandler(store, payment_method, options = {}, mandate) {
  PaymentHelper.setConfig(store.state, store.getters);
  const getters = store.getters;
  let threeDShandler;
  return window.cbjsLoader.then(() => {
    threeDShandler = Chargebee.getInstance().create3DSHandler();
    const pm = store.getters.pm_by_type(payment_method.type);
    const gateway = payment_method?.gateway || pm?.gateway_name;
    // https://mychargebee.atlassian.net/browse/CHKOUTENGG-28750
    // To fix popup blocker
    if(gateway.toUpperCase() == UpperCaseGateway.MOLLIE) {
      threeDShandler.openNewWindow();
    }
  })
  .then(() => createPaymentIntent(store, payment_method, options))
  .then(payment_intent => {
      let options = {}, additionalInfo ={};
      if(payment_intent.gateway == Gateway.BRAINTREE && btClientInstance) {
          options[Gateway.BRAINTREE] = btClientInstance;
      }
      threeDShandler.setPaymentIntent(payment_intent, options);
      let params3ds = {}
      if(payment_method.tmp_token) {
          params3ds['tokenizer'] = () => Promise.resolve(payment_method.tmp_token);
      }
      else if(payment_method.number) {
        params3ds['card'] = {
          firstName:  payment_method.first_name,
          lastName: payment_method.last_name,
          number: payment_method.number.replace(/\s+/g, ''),
          cvv: payment_method.cvv,
          expiryMonth: payment_method.expiry_month,
          expiryYear: payment_method.expiry_year > 100 ? payment_method.expiry_year : (2000 + parseInt(payment_method.expiry_year))
        }
        additionalInfo['plan'] = deepGet(getters.cart_plan, 'details.invoice_name') || deepGet(getters.cart_plan, 'details.name') || deepGet(getters.cart_plan, 'id');
        additionalInfo['shippingAddress'] = PaymentHelper.getShippingAddress(store);
        additionalInfo['email'] = getters.customer_data.email;
        additionalInfo['billingAddress'] = getCardBillingAddress(payment_method);
        additionalInfo['cardBillingAddress'] = getCardBillingAddress(payment_method);
        additionalInfo['customerBillingAddress'] = PaymentHelper.getCustomerBillingAddress(store);
        additionalInfo['customer'] = getCustomerInfo(store);
        additionalInfo['locale'] = getters.init_info_locale && getters.init_info_locale.slice(0,2);
      }
      additionalInfo['email'] = getters.customer_data.email || payment_method.email;
      additionalInfo['phone'] = getters.customer_data.phone || payment_method.phone;
      if([Gateway.EBANX, Gateway.DLOCAL].includes(payment_intent.gateway)) {
        additionalInfo['document'] = {
          number: payment_method.document_number,
          type : payment_method.document_type
        }
        additionalInfo['is3dsRequired'] = getters.threeDSNeeded;
        let paymentData = (payment_method.reference_id) ? payment_method.card : payment_method;
        additionalInfo['billingAddress'] = additionalInfo['billingAddress'] || getCardBillingAddress(paymentData);
        additionalInfo['cardBillingAddress'] = additionalInfo['cardBillingAddress'] || getCardBillingAddress(paymentData);
      }
      if(mandate && mandate.requireMandate) {
        additionalInfo['mandate'] = {
          requireMandate: mandate.requireMandate,
          description: mandate.description || deepGet(getters.cart_plan, 'details.invoice_name') || deepGet(getters.cart_plan, 'details.name') || deepGet(getters.cart_plan, 'id'),
        };
      }
      if(payment_intent.gateway == Gateway.RAZORPAY) {
        additionalInfo['customer'].firstName = payment_method.first_name;
        additionalInfo['customer'].lastName = payment_method.last_name;
        additionalInfo['customer'].phone = payment_method.phone;
        additionalInfo['customer'].email = payment_method.email;
        // For paynow additionalInfo['mandate'] will be true if customer check the save payment
        Object.assign(additionalInfo, PaymentHelper.getRazorpayAdditionalData(payment_method.type, {shippingAddress: additionalInfo.shippingAddress, retain_card: !!additionalInfo['mandate']}));
      }
      if(Object.keys(additionalInfo).length > 0) {
        params3ds['additionalData'] = additionalInfo;
      }
      return threeDShandler.handleCardPayment(params3ds).then((intent) => {
        store.dispatch("set_payment_intent", intent);
        return intent;
      });
  })
}
