<template>
  <div>
    <div class="cb-section cb-bar cb-section-sub-list">
      <cb-slide-n-fade :isList="true" :is-list="true" :timeOut="true" :reCalculateMargin="true">
        <div
          class="cb-bar__wrap"
          :class="{'cb-bar__wrap--noborder': index == limit - 1  && childSubLimit && childSubDataDisplayList.length >= childSubLimit}"
          v-cb-link="'shadow'"
          v-for="(item, index) in childSubDataDisplayList"
          @click="navigate_to('child_sub_details', {params: {subscriptionId: item.id}})"
          v-if="(!childSubLimit || index < childSubLimit)"
          role="navigation"
          data-cb-id="child_subscription_details"
          v-bind:key="index"
        >
          <div class="cb-mar--b-xs">
            <i class="icon-parent-child" :style="themeColor"></i>
            <span class="cb-bar__label cb-bar__middle">{{getChild(item.subscription.customer_id)}}</span>
          </div>
          <!-- TODO - Review DRY too many if checks will spoil readability and produce more bugs -->
          <div class="cb-bar__title" v-if="!item.hide_plan">
            <span v-if="item.gift_id">
              <i class="icon-gift" :style="themeColor"></i>
            </span>
            <span
              class="cb-bar__flag"
              v-if="item.state == 'cancelled'"
            >{{st('subscription_status.canceled')}}</span>
            <span
              class="cb-bar__flag"
              v-if="item.state == 'paused'"
            >{{st('subscription_status.paused')}}</span>
            <span class="cb-bar__middle">{{item.plan_name}}</span>
            <span
              class="cb-bar__middle"
              v-if="item.plan_quantity_in_decimal"
            >&times; {{item.plan_quantity_in_decimal}}</span>
            <span
              class="cb-bar__middle"
              v-else-if="item.plan_quantity > 1"
            >&times; {{item.plan_quantity}}</span>
          </div>
          <template v-if="item.addons && item.addons.length > 0">
            <div
              class="cb-bar__title"
              v-for="(addon, index) in item.addons"
              v-if="index < 1"
              v-bind:key="index"
            >
              <template v-if="item.hide_plan">
                <span
                  class="cb-bar__flag"
                  v-if="item.hide_plan && item.state == 'paused'"
                >{{st('subscription_status.paused')}}</span>
                <span
                  class="cb-bar__flag"
                  v-if="item.hide_plan && item.state == 'cancelled'"
                >{{st('subscription_status.canceled')}}</span>
              </template>
              {{addon.details.invoice_name || addon.details.name}}
              <span v-if="addon.quantity_in_decimal">&times; {{addon.quantity_in_decimal}}</span>
              <span v-else-if="addon.quantity > 1">&times; {{addon.quantity}}</span>
            </div>
          </template>
          <div
            v-if="item.addons && item.addons.length > 1"
          >+{{item.addons.length - 1}} {{getMoreItemTxt()}}</div>
          <div class="cb-bar__content">
            <span class="cb-bar__price">{{ item.recurring_price | cbCurrency(item.currency_code) }}</span>
            <span
              class="cb-bar__desc"
              v-if="!item.gift_id && billingText(item)"
            >{{ billingText(item) }}</span>
            <div class="cb-truncate" v-if="additionalSummaryInfo(item)" v-cb-html="additionalSummaryInfo(item)"></div>
          </div>
        </div>
      </cb-slide-n-fade>
    </div>
  </div>
</template>

<script type="text/javascript">
// TODO - Review DRY. Why should we duplicate everything?? Think of a smarter approach on how to solve without duplicating.
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["childSubDataDisplayList", "childSubDataLoaded"]),
    limit() {
      return this.childSubDataDisplayList.length;
    }
  },
  props: ["childSubLimit"],
  methods: {
    navigate_to(section, options) {
      options = options || {};
      options.name = section;
      this.$router.customPush(options);
    },
    getMoreItemTxt() {
      return this.t("more_items_txt.custom_label") || this.st("more_items_txt");
    },
    additionalSummaryInfo(item) {
      return this.dataTranslate('child_subscription.summary.additionalInfo', {subscription: item.subscription});
    },
    getChild(handle) {
      return this.$store.getters.getCustomerDisplayName(handle);
    },
    billingText(item) {
      return this.$store.getters.billingText(item);
    }
  }
};
</script>

<style scoped>
  .cb-section-sub-list {
    max-height: 75vh;
    overflow-y: auto;
  }
</style>
