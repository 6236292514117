import cbClient from "@/utils/cb-client"
export default class LogDumper {
  constructor(logData, timerDuration) {
    this.logData = logData;
    this.inTransit = false;
    this.timerDuration = timerDuration;
    this.isSubmitted = false;
    this.init();
  }

  init() {
    window.cbTimeout(() => this.send(), this.timerDuration);
  }

  send() {
    if(this.canSend() && this.logData.version != this.logData.server_version){
      this.inTransit = true;
      this._send().then(() => {
        this.inTransit = false;
      }).catch((error) => {
        console.log(error);
        this.inTransit = false;
      });
    }
    window.cbTimeout(() => this.send(), this.timerDuration);
  }

  canSend() {
    return !this.inTransit && !this.isSubmitted;
  }

  _send() {
    this.logData.server_version = this.logData.version;
    return this.sendPayLoad(this.logData.payload());
  }

  sendPayLoad(payload) {
    return cbClient.loggers.info_error({}, payload);
  }
  
}