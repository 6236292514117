import Vue from "vue"
import apiClient from "@/utils/cb-client"


const ADD_TO_LIST = "ADD_TO_LIST";
const SET_OFFSET = "SET_OFFSET";
const SET_CHILD_OFFSET = "SET_CHILD_OFFSET";
const SET_PARENT_OFFSET = "SET_PARENT_OFFSET";
const SET_EOL = "SET_EOL";
const SET_CHILD_EOL = "SET_CHILD_EOL";
const SET_PARENT_EOL = "SET_PARENT_EOL";
const SET_IN_TRANSIT = "SET_IN_TRANSIT";
const SET_FETCHED = "SET_FETCHED";
const SET_RESET_INVOICE = "SET_RESET_INVOICE";
const ADD_INVOICES_OF_PARENT = "ADD_INVOICES_OF_PARENT";
const SET_INVOICES_OF_CHILD = "SET_INVOICES_OF_CHILD";
const ADD_INVOICES_OF_CHILD = "ADD_INVOICES_OF_CHILD";
const SET_INVOICE_CUSTOMER_RELATION_TYPE = "SET_INVOICE_CUSTOMER_RELATION_TYPE"

const SELF_CUSTOMER = "customer_id[is]"
const PAYMENT_OWNER = "payment_owner[is]"


const state = {
  list: [],
  invoices_of_parent: [],
  invoices_of_child: [],
  parent_next_offset: undefined,
  next_offset: undefined,
  child_next_offset: {}, // one to many
  in_transit: false,
  eol: false,
  child_eol: {}, // one to many
  parent_eol: false,
  fetched: false,
  invoice_customer_relation_type: PAYMENT_OWNER
}

const actions = {
  reset_invoice_list({commit, state}) {
    commit(SET_RESET_INVOICE);
  },

  load_invoices({commit, state, rootState, rootGetters, dispatch}, options) {
    if(!state.fetched) {
      commit(SET_FETCHED, true);
    }
    if(state.in_transit) {
      resolve(false);
      return;
    }
    commit(SET_IN_TRANSIT, true);
    var promises = [];
    promises.push(dispatch('load_my_invoices', options));
    if(rootGetters.account_hierarchy_enabled) {
      promises.push(dispatch('load_invoices_of_child'))
      promises.push(dispatch('load_invoices_of_parent'));
    }
    return Promise.all(promises).then(() => {
      commit(SET_IN_TRANSIT, false);
    })
    .catch(error =>{
      commit(SET_IN_TRANSIT, false);
      reject(error);
    });
  },

  load_more_invoices({commit, state, getters, rootGetters, dispatch}, options){
    commit(SET_IN_TRANSIT, true);
    var promises = [];
    if (!state.eol) {
      promises.push(dispatch('load_my_invoices', options));
    }
    if(rootGetters.account_hierarchy_enabled && !getters.invoices_child_eol) {
      promises.push(dispatch('load_invoices_of_child'));
    }
    if(rootGetters.account_hierarchy_enabled && !getters.invoices_parent_eol) {
      promises.push(dispatch('load_invoices_of_parent'));
    }
    return Promise.all(promises).then(()=> {
      commit(SET_IN_TRANSIT, false);
    }).catch(error =>{
      commit(SET_IN_TRANSIT, false);
      reject(error);
    })

  },

  load_my_invoices({commit, state, rootState}, options) {
    apiClient.customers.invoices({},{
      "customer_id[is]": rootState.customer.data.id,
      "limit": "5",
      "offset": state.next_offset,
      "sort_by[desc]": "date",
      ...options
    }).then(data => {
      if(data.list.length == 0 || !data.next_offset) {
        commit(SET_EOL, true);
      }
      commit(ADD_TO_LIST, data.list.map(({invoice}) => invoice));
      commit(SET_OFFSET, data.next_offset);
    });
  },

  load_invoices_of_child({commit, state, rootGetters}) {
    rootGetters.getChildCustomers.filter(o => o && o.access && o.access.can_view_invoice)
    .forEach(({customer_id}) => {
      if(!state.child_eol[customer_id]) {
        apiClient.customers.invoices({}, {
          "customer_id[is]" : customer_id,
          "limit": "5",
          "offset": state.child_next_offset[customer_id],
          "sort_by[desc]": "date",
        }).then(data => {
          if (data.list.length == 0 || !data.next_offset) {
            commit(SET_CHILD_EOL, {customer_id: customer_id, eol: true});
          } else {
            commit(SET_CHILD_EOL, {customer_id: customer_id, eol: false});
          }
          commit(ADD_INVOICES_OF_CHILD, data.list.map(({invoice}) => invoice));
          commit(SET_CHILD_OFFSET, {customer_id: customer_id, next_offset: data.next_offset});
        });
      }
    })
  },

  load_invoices_of_parent({commit, state, rootState, rootGetters}) {
    if(rootGetters.customerHasParent(rootState.customer.data.id)) {
      apiClient.customers.invoice_by_parent({},{
        "customer_id" : rootState.customer.data.id,
        "limit": "5",
        "offset": state.parent_next_offset,
        "sort_by[desc]": "date",
      }).then(data => {
        if(data.list.length == 0 || !data.next_offset) {
          commit(SET_PARENT_EOL, true);
        }
        commit(ADD_INVOICES_OF_PARENT, data.list.map(({invoice}) => invoice));
        commit(SET_PARENT_OFFSET, data.next_offset);
      });
    } else {
      commit(SET_PARENT_EOL, true);
    }
  },

  load_paynow_invoices({commit, state, rootState, dispatch}, options) {

    if(!options) {
      options = {}
    }

    let isAccountHierarchy = rootState.customer.data.relationship
    let isParentAccount = isAccountHierarchy && !rootState.customer.data.relationship.parent_id
    // initialize with Payment Owner
    let key = PAYMENT_OWNER;
    // if non-account hierarchy customer then query with customer_id
    if(!isAccountHierarchy) {
      key = SELF_CUSTOMER
    }
    // if parent account such that all payment_owner mapped invoices are fetched then use customer_id
    else if(isParentAccount && state.invoice_customer_relation_type == SELF_CUSTOMER) {
      key = SELF_CUSTOMER
    }
    options[key] = rootState.customer.data.id;
    return apiClient.customers.invoices({},{
      "limit": "5",
      "offset": state.next_offset,
      "channel[is]": "web",
      "status[in]": "[payment_due,posted,not_paid]",
      "sort_by[desc]": "date",
      ...options
    }).then(data => {
      commit(ADD_TO_LIST, data.list.map(({invoice}) => invoice));
      commit(SET_OFFSET, data.next_offset)
      commit(SET_IN_TRANSIT, false);
      if(data.list.length == 0 || !data.next_offset) {
        switch(key) {
          case PAYMENT_OWNER:
            if(isParentAccount) {
              // update the param type for a parent when all payment_owner invoices are fetched
              commit(SET_INVOICE_CUSTOMER_RELATION_TYPE, SELF_CUSTOMER)
              // make n+1 API call to fetch invoices and take care of any empty list cases
              dispatch('load_paynow_invoices')
            }
            else {
              commit(SET_EOL, true)
            }
            break
          case SELF_CUSTOMER: {
            commit(SET_EOL, true);
            break
          }
        }
      }
    });
  },

  get_unpaid_invoices({ rootState }, key, options = {}) {
    options[key] = rootState.customer.data.id;
    return apiClient.customers.invoices({}, {
      "limit": 50,
      "channel[is]": "web",
      "status[in]": "[payment_due,posted,not_paid]",
      ...options
    }).then(data => data.list.filter(i => i.invoice.amount_to_collect > 0).map(i => i.invoice.id));
  },

  get_unpaid_invoice_count({ state, rootState, dispatch }, options = {}) {
    const customerId = rootState.customer.data.id;
    const isAccountHierarchy = rootState.customer.data.relationship;
    const isParentAccount = isAccountHierarchy && !rootState.customer.data.relationship.parent_id;

    let selfOptions = { ...options };
    let parentOptions = { ...options };

    let fetchSelfInvoicesPromise = dispatch('get_unpaid_invoices', SELF_CUSTOMER, selfOptions);
    let fetchParentInvoicesPromise;

    if (isParentAccount && rootState.customer.hierarchy_accounts) {
      const hasDifferentInvoiceOwner = rootState.customer.hierarchy_accounts.some(i => {
        return i.invoice_owner_id !== undefined && i.invoice_owner_id !== customerId;
      });

      if (hasDifferentInvoiceOwner) {
        fetchParentInvoicesPromise = dispatch('get_unpaid_invoices', PAYMENT_OWNER, parentOptions);
      } else {
        fetchParentInvoicesPromise = Promise.resolve([]);
      }
    } else if (!isAccountHierarchy || (isParentAccount && state.invoice_customer_relation_type !== SELF_CUSTOMER)) {
      fetchParentInvoicesPromise = Promise.resolve([]);
    } else {
      const hasDifferentInvoiceOwner = rootState.customer.hierarchy_accounts && rootState.customer.hierarchy_accounts.some(i => {
        return i.payment_owner_id !== undefined && i.payment_owner_id !== customerId;
      });

      if (hasDifferentInvoiceOwner) {
        return Promise.resolve([]);
      } else {
        fetchParentInvoicesPromise = Promise.resolve([]);
      }
    }

    return Promise.all([fetchSelfInvoicesPromise, fetchParentInvoicesPromise])
    .then(([selfInvoices, parentInvoices]) => {
      let allInvoices = [...selfInvoices];
      if (parentInvoices != undefined) {
        allInvoices = [...allInvoices, ...parentInvoices];
      }
      const uniqueInvoices = new Set(allInvoices);
      return uniqueInvoices.size;
    })
  }
}

const mutations = {
  [ADD_TO_LIST] (state, list) {
    list.forEach(invoice => {
      invoice.selected_for_pm = true;
      const duplicateInvoice = state.list.find(inv => inv.id === invoice.id);
      if (!duplicateInvoice) {
        state.list.push(invoice);
      }
    });
  },

  [ADD_INVOICES_OF_PARENT] (state, list) {
    Vue.set(state, "invoices_of_parent", state.invoices_of_parent.concat(list));
  },

  [ADD_INVOICES_OF_CHILD] (state, list) {
    Vue.set(state,"invoices_of_child", state.invoices_of_child.concat(list));
  },

  [SET_OFFSET] (state, next_offset) {
    Vue.set(state, "next_offset", next_offset);
  },

  [SET_CHILD_OFFSET] (state, { customer_id, next_offset }) {
    Vue.set(state.child_next_offset, customer_id, next_offset);
  },

  [SET_PARENT_OFFSET] (state, parent_next_offset) {
    Vue.set(state, "parent_next_offset", parent_next_offset);
  },

  [SET_INVOICE_CUSTOMER_RELATION_TYPE] (state, invoice_customer_relation_type) {
    Vue.set(state, "invoice_customer_relation_type", invoice_customer_relation_type);
  },

  [SET_EOL] (state, eol) {
    Vue.set(state, "eol", eol);
  },

  [SET_CHILD_EOL] (state, { customer_id, eol}) {
    Vue.set(state.child_eol, customer_id, eol);
  },

  [SET_PARENT_EOL] (state, eol) {
    Vue.set(state, "parent_eol", eol);
  },

  [SET_IN_TRANSIT] (state, in_transit) {
    Vue.set(state, "in_transit", in_transit);
  },

  [SET_FETCHED] (state, fetched) {
    Vue.set(state, "fetched", fetched);
  },

  [SET_RESET_INVOICE] (state) {
    Vue.set(state, "list", []);
    Vue.set(state, "next_offset", undefined);
    Vue.set(state, "eol", undefined);
    Vue.set(state, "in_transit", false);
    Vue.set(state, "in_transit", false);
    Vue.set(state, "fetched", false);
    Vue.set(state, "invoices_of_parent", []);
    Vue.set(state, "invoices_of_child", []);
    // reset to original state
    Vue.set(state, "invoice_customer_relation_type", PAYMENT_OWNER);
  }
}

const getters = {
  invoice_loading_in_progress: state => state.in_transit,
  invoices: state => state.list,
  child_invoices: state => state.invoices_of_child,
  parent_invoices: state => state.invoices_of_parent,
  invoices_eol: state => state.eol,
  invoices_child_eol: state => Object.values(state.child_eol).every((val) => val),
  invoices_parent_eol:  state => state.parent_eol,
  invoices_fetched: state => state.fetched
}

export default {
  state,
  actions,
  mutations,
  getters
}
