import CartHelper from '@/utils/cart-helper'
import OrderEstimateHolder from "@/models/order-estimate-holder"

export default class EstimateData {
  constructor(source, cartItems, getters, isNewCart) {
    this.couponList = (getters && getters.coupon_list) || [];
    if(isNewCart) {
      this.initForNewCart(source, cartItems, getters);
    } else {
      this.initForOldCart(source, cartItems);
    }
  }

  initForOldCart(source, cartItems) {
    this._source = source.invoice_estimate || source.next_invoice_estimate || source;
    this.cartItems = cartItems;
    this.setType(source);
    this.subscriptionEstimate = source.subscription_estimate;
    this.creditNoteEstimates = source.credit_note_estimates || []; 
    this.init();
  }

  setType(source) {
    if(source.invoice_estimate) {
      this.type = "invoice_now";
    }
    else if(source.next_invoice_estimate) {
      this.type = "next_invoice";
    }
    else {
      this.type = "not_handled";
    }
  }

  initForNewCart(data, cartItems, getters) {
    this.orderEstimateHolder =  new OrderEstimateHolder(data, getters);
    this._source = this.orderEstimateHolder.estimate;
    this.cartItems = cartItems;
    this.setTypeForNewCart(data);
    // this.subscriptionEstimate = source.subscription_estimate; //not sent currently
    this.creditNoteEstimates = data.credit_note_estimates || [];
    this.init();
  }

  setTypeForNewCart(source) {
    if(source.immediate_invoice_estimate) {
      this.type = "invoice_now";
    } else if(source.next_invoice_estimates && source.next_invoice_estimates[0]) {
      this.type = "next_invoice";
    } else {
      this.type = "not_handled";
    }
  }

  
  init() {
    this.lineItems = this._source.line_items;
    this.discounts = this._source.discounts || [];
    this.taxes = this._source.taxes;
    this.currencyCode = this._source.currency_code;
    this.total = this._source.total || 0;
    this.amountDue = this._source.amount_due || 0;
    this.totalDiscount = this.discounts.reduce((a, i) => {a += i.amount ; return a}, 0);
    this.priceType = this._source.price_type;
    this.consolidated_items = CartHelper.consolidateEstimate(this.cartItems, this.taxes || [], this.discounts || [], this.couponList);
    this.creditsApplied = (this._source.credits_applied || 0) + (this._source.amount_paid || 0);
    this.credits = this.creditNoteEstimates.filter(es => es.type == "refundable").reduce((sum, es) => {
      if(this.type == "next_invoice") {
        sum += es.total;
      } else {
        sum += es.amount_available;
      }
      return sum;
    }, 0);
    this.creditsCurrency = (this.creditNoteEstimates && this.creditNoteEstimates[0] && this.creditNoteEstimates[0].currency_code);
    this.date = this._source.date;
  }

  getPlanLineItems() {
    return [...this.getLineItems("plan"), ...this.getLineItems("plan_item_price")];
  }

  getAddonLineItems() {
    return [...this.getLineItems("addon"), ...this.getLineItems("addon_item_price")];
  }

  getLineItems(entityType) {
    return this.lineItems && this.lineItems.filter(item => item.entity_type == entityType);
  }
}