import escapeStringRegex from 'escape-string-regexp';
import "@/utils/vendor-polyfills.js"
import { DateTime } from 'luxon';
import 'url-polyfill'

export const resolveRouteName = function(gaName) {
  switch(gaName) {
    case "account_details":
      return "portal_account";
    default:
      return ["sub_details", "portal_billing_history", "portal_payment_methods", "portal_address", "extend_subscription"].indexOf(gaName) > -1 ? gaName : undefined;
  }
}

export const checkForSafeFwd = function(fwd) {
  return ["portal_change_password", "portal_change_password_verify", "portal_setup_password", "portal_verify_otp"].indexOf(fwd) > -1 ? "" : fwd;
}

export const fetchForwardParams = function(params) {
  if(!params) {
    return {};
  }
  return Object.keys(params).reduce((prev, next) => {
    if(next.startsWith("fw")) {
      let p = next.match(/fw\[(.*)\]/);
      if(p) {
        prev[p[1]] = params[next];
      }
    }
    return prev;
  }, {})
}

export const getTimestampFromLuxon = function(date, time) {
  try {
    let a = DateTime;
    if(window.ifNativeDateSupported) {
      return (DateTime.fromFormat(`${date} ${time}`, "yyyy-MM-dd HH:mm").toMillis()) / 1000
    }
    else {
      return (DateTime.fromFormat(`${date} ${time}`, "MM-dd-yyyy HH:mm").toMillis()) / 1000
    }
  }
  catch(error) {
    logger.e(error);
  }
}

export const reorderFields = function(fields) {
  let lt1Fields = fields.filter(f1 => f1.order == -1);
  let gt1Fields = fields.filter(f1 => f1.order > -1);
  fields = gt1Fields.sort((f1, f2) => f1.order - f2.order).concat(lt1Fields);
  fields.forEach((sf) => {
    sf.sub_fields && (sf['sub_fields'] = reorderFields(sf.sub_fields));
  });
  return fields;
}

export const termTranslate = function(term, multiple, translateFunction) {

  switch(term){
    case "day": return (multiple) ? translateFunction('term.days') : translateFunction('term.day');
    case "week": return (multiple) ? translateFunction('term.weeks') : translateFunction('term.week');
    case "month": return (multiple) ? translateFunction('term.months') : translateFunction('term.month');
    case "year": return (multiple) ? translateFunction('term.years') : translateFunction('term.year');
    default: return term;
  }
}

export const getRefererDomain = function(allowedDomains) {
  let referrerDomain = null;
  if(allowedDomains.length > 0) {
    allowedDomains.forEach((domain) => {
      if(referrerDomain){
        return;
      }

      let documentReferrer = window.cbReferrer || document.referrer;

      if (domain.indexOf("//*.") > -1 || domain.indexOf("www.*.") > -1){
        let prefix = escapeStringRegex(domain.split('*')[0]);
        let postfix = escapeStringRegex(domain.split('*')[1]);
        let pattern = new RegExp("(" + prefix + ".*" + postfix + ").*")

        if(pattern.test(documentReferrer)){
          referrerDomain = documentReferrer.match(pattern)[1];
        }

      } else if (documentReferrer.indexOf(domain) > -1) {
        referrerDomain =  domain;
      }
    });
  }
  return referrerDomain;
}

export const replaceWithPlaceholders = function(output, placeholders) {
  if(output && placeholders) {
    Object.keys(placeholders).forEach((key) => {
      output = output.replace(`%\{${key}\}`, placeholders[key]);
    });
  }
  if (output && typeof output === 'string') {
    output = output.trim()
  }
  return output;
}

export const replaceFromData = function(text, data) {
  if (text && data) {
    while(text != text.split('%{').pop().split('}')[0]) {
      var dataClone = data;
      var key = text.split('%{').pop().split('}')[0];
      key.split('.').forEach(a => {
        if(dataClone) {
          dataClone = dataClone[a];
        }
      });
      if (dataClone) {
        text = text.replace(`%\{${key}\}`, dataClone);
      }
      else {
        if(text.search(`%\{${key}\}`) == -1) {
          break;
        }
        text = text.replace(`%\{${key}\}`, '');
      }
    }
  }
  return text;
}

export const copyTextToClipboard = function(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
  } catch (err) {
  }

  document.body.removeChild(textArea);
}

export const trackConversionReferralCandy = function(data) {
  try {
    let campaign_details = data.campaign_details;
    let conversion_info = data.conversion_info;
    if(!(conversion_info && conversion_info.referral_system == "referral_candy")) {
      return;
    }
    let appId = campaign_details.app_id;
    var rc = document.location.protocol + `//go.referralcandy.com/purchase/${appId}.js`;
    var js = document.createElement('script');
    js.src = rc;
    var fjs = document.getElementsByTagName('script')[0];
    fjs.parentNode.insertBefore(js,fjs);
  }
  catch(error) {
  }
}

export const isOpenedInsideAnIframe = function () {
  // Browsers can block access to window.top due to same origin policy.
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

//add conditions for password here
export const passwordAnalyser = function (value) {
  value = value || '';
  value = value.trim();
  let minChar = (value.length >= 8);
  let number = /^(?=.*[0-9])/.test(value);
  let special = /^(?=.*[@$!%*#?&)(])/.test(value);
  return {
    'min_char' : minChar,
    'number' : number,
    'special': special,
    'valid' : minChar && special && number
  };
}

/**
 * Safely get a deep property in a object
 * @param {any} object to query
 * @param {string} key - path of parameter
 * Ex. let obj = {a: {b: c: {d: 1}}}
 * deepGet(obj, 'a.b.c.d')
 */
export const deepGet = ( object={}, key='' ) => {
  if ( !(typeof object === "object" && object.constructor === Object) || typeof key !== "string" )
    return undefined;

  if(!key)
    return object;

  return key.split('.').reduce( ( a, b ) => ( a || { } )[ b ], object );
}

export const ToastNotificationTimeout = 3000

export const getHostFromUrl = (url='') => {
  if(!url) return ''

  try {
    return new URL(url).host
  } catch(e) {
    window.logger.e(e)
    // skip on invalid URL
  }

  return ''
}
export const pluralize = function (keyword, value, locale) {
  if ((locale == "en") || (value <= 1)) return keyword;
  return keyword + 's';
}

export const addRequiredFields = function(fields = []) {
  return [...fields, {
    "label": "Email",
    "name": "email",
    "type": "email",
    "validations": {
      "presence": {
        "message": "cannot be blank"
      },
      "email": {
        "message": " should be a valid email"
      }
    },
    "short_field": false,
    "extra": "",
    "required": true,
    "read_only": false,
    "order": 2
  },
  {
    "label": "Phone",
    "name": "phone",
    "type": "tel",
    "validations": {
      "presence": {
        "message": "cannot be blank"
      },
      "length": {
        "message": " should not have more than 50 characters",
        "maximum": 50
      }
    },
    "short_field": false,
    "extra": "",
    "required": true,
    "read_only": false,
    "order": 4
  }];
}



export const isAnyValuePresent = function (obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      return true; 
    }
  }
  return false; 
}