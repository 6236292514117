<template>
	<!-- <div class="cb-field" :class="{'cb-field--sibling':siblingError}"> -->
  <!-- Selecting based on index is too dangerous. TODO change it while refactoring -->
    <select-field :siblingError="siblingError" @informError="$emit('informError')" :name="subFields[1].name" @clearError="$emit('clearError')" v-if="showSelect" :useparent='true' :options="stateList"> 
    </select-field>
    <text-field  :siblingError="siblingError" @informError="$emit('informError')" @clearError="$emit('clearError')" :name="subFields[0].name" :useparent='true' v-else>
    </text-field>
	<!-- </div> -->
</template>
<script type="text/javascript">
import TextField from "./TextField"
import SelectField from "./SelectField"
import StateCodeList from "@/utils/state-code-list"
import StateListHelper from "@/utils/state-list-helper"
export default {
  props: ['name', 'siblingError'],
  components: {
    'text-field': TextField,
    'select-field': SelectField
  },
  watch: {
    showSelect(newValue, oldValue) {
      if(newValue) {
        this.clearState();
      }
      else{
        this.clearStateCode();
      }
    },
    countryValue(newValue, oldValue) {
      if(newValue && oldValue) {
        if([newValue, oldValue].every(v => ['US', 'CA', 'IN'].indexOf(v) > -1)) {
          this.clearStateCode();
        }
      }
    }
  },
  data() {
    return {
      // showSelect: false;
      pName: "",
      customClass: ""
    }
  },
  created() {
    this.pName = this.findParentForm();
  },
  mounted() {
    this.customClass = this.$vnode.data.staticClass;
  },
  computed: {
    config() {
      return this.$store.getters.vField_config(this.pName, this.name)
    },
    countryValue() {
      return this.cName && this.$store.getters.field_value(this.pName, this.cName);
    },
    showSelect() {
      return StateListHelper.canShowStateDropdown(this.$store,this.countryValue);
    },
    cName() {
      return this.config && JSON.parse(this.config.meta.extra).country_name;
    },
    subFields() {
      return this.config.meta.sub_fields;
    },
    stateList() {
      return this.showSelect && Object.keys(StateCodeList[this.countryValue]).map(f => {
          return {"display_name": f, "value" : StateCodeList[this.countryValue][f]};
        });
    }
  },
  methods: {
    clearState() {
      this.$store.dispatch('update_form_input', {
        name: this.subFields[0].name,
        value: "",
        formName: this.pName
      });
    },
    clearStateCode() {
      if (! this.doesStateCodeBelongToCountry()) {
        this.$store.dispatch('update_form_input', {
          name: this.subFields[1].name,
          value: "",
          formName: this.pName
        });
      }
    },
    doesStateCodeBelongToCountry() {
      const stateCode = this.$store.getters.field_value(this.pName, 'state_code')
      return !!stateCode && !!this.stateList.find(state => state.value === stateCode)
    }
  }
}
</script>
