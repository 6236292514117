<template>
  <div class="cb-hp__wrapper">
    <div ref="parent" class="cb-header cb-transition__slide" id="cb-header">
      <component v-cb-slide="{ isnew : true , ref: $refs.parent }" :is="'header-1'" :key="$route.path" v-if="allowed">
      </component>
    </div>
    <div class="cb-body" id="cb-body" role="main">
      <div v-cb-animate='true' class="cb-body__wrap" v-if="inited && allowed">
        <div class="cb-auth" >
          <div class="cb-auth__group--spacer cb-auth__slide" ref="authParent">
            <cb-toggle :parent="$refs.authParent" :recalculateMargin="true">

              <!-- Email -->
              <div class="cb-text" v-if="step == 'step1'" key="email" >
                <div class="cb-auth__label" :style="themeColor">{{ headerTxt }}</div>
                <input ref="x2" class="cb-text__control" :class="{'cb-text__control--error': errorMessage && errorMessage.length > 0}" type="text" aria-label="email" v-model="email" :placeholder=placeholder @keyup.enter="next()" autofocus :aria-label="headerTxt" spellcheck="false">
                <div class="cb-text__error cb-text__error--center">
                  {{ errorMessage }}
                </div>
              </div>

              <!--OTP or Password -->
              <div v-if="step == 'step2'" key="password">
                <div class="cb-auth__email-desc" v-if="(step == 'step2') && email" >
                    <div class="icon icon-account-circle" :style="themeColor"></div>
                    <p>{{email}}</p>
                </div>
                <div class="cb-auth__label" :style="themeColor">{{ headerTxt }}</div>
                <div v-if="!showPassword" :key="showPassword" class="cb-transition__fade--short">
                  <input class="cb-text__control cb-text__control--lg cb-text__control--center" :class="{'cb-field__control--error': errorMessage && errorMessage.length > 0}" @keydown="keydown($event)" pattern="[0-9]{6}" maxlength="6" ref="x1" autocomplete="off" :style="themeColor" v-model="otp" @keyup.enter="next()" name="otp" id="otp" @keyup="errorMessage = ''" :aria-label="headerTxt" @paste="onOtpInput">
                </div>
                <div v-else :key="showPassword" class="cb-transition__fade--short">
                  <cb-password @keyupEnter="next()" :error="errorMessage" :value="password"  @keyupCustom="password=$event" ref="pwd" />
                </div>
                <div class="cb-text__error cb-text__error--center">
                  {{ errorMessage }}
                </div>
                <div class="cb-auth__link" v-if="is_otp_pwd && showPassword && has_password">
                  <span @click="forgotPwd()" v-cb-link="'color'" role="link">{{nlt('forgot_password.link.txt')}}</span>
                </div>
                <div class="cb-auth__link">
                  <span @click="resendOtp()" v-cb-link="'color'" role="link">{{ otpText }} <template v-if="showPassword">&rarr;</template></span>
                </div>
                <div class="cb-auth__link" v-if="!showPassword && has_password">
                  <span @click="enterPassword($event)" v-cb-link="'color'" role="link" :key="showPassword">{{nlt('password.use')}}&rarr;</span>
                </div>
              </div>
            </cb-toggle>
          </div>
        </div>
        <div class="cb-body__footer">
          <button-field :custom-class="'primary'" :showLoader="showLoader" @next="next()" :attr="'portal_auth_action'">
            {{btnTxt}} <i class="icon-long-arrow-right"></i>
          </button-field>
        </div>
      </div>
      <div v-if="!allowed" class="cb-body__wrap">
        {{st('page_not_found')}}
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import {
  mapGetters
} from 'vuex';
import PortalHeader from "@/components/collections/headers/PortalHeader"
import Validators from "@/utils/validators.js";
import ButtonField from "@/components/collections/form/ButtonComponent"
import Password from "@/components/collections/form/PasswordField"
import { fetchForwardParams, resolveRouteName } from "@/utils/helper-functions"
import AuthMixin from "@/mixins/otp-password-mixin"
import PreviewPortalMixin from "@/mixins/preview-portal-mixin"
import OtpHelper from "@/utils/otp-helper"
import MultiEntityMixin from "@/mixins/multi-entity-mixin";

export default {
  data() {
      return {
        step: "step1",
        email: "",
        showLoader: false,
        errorMessage: "",
        otp: "",
        forward: "",
        allowed: false,
        showPassword:false,
        password:"",
        forgotPassword: false
      }
    },
    computed: {
      ...mapGetters(
        [
          'authenticated',
          'inited',
          'authType',
          'set_loader',
          'linked_handles',
          'customer_handle',
          "is_preview",
          'has_password',
          'is_otp_pwd'
        ]
      ),
      headerTxt() {
        if (this.step == "step1") {
          return this.nlt("login.txt");
        }
        if (this.step == "step2") {
          if(this.showPassword){
            return this.nlt("password.enter");
          }
          return this.nlt("otp.enter");
        }
      },
      placeholder(){
        return this.st('email_address');
      },
      otpText (){
        if(this.showPassword){
          return this.nlt('login.via_otp');
        }
        return this.nlt('otp.resend');
      },

      btnTxt() {
        if (this.step == "step1") {
          return this.nlt("login.next");
        }
        if (this.step == "step2") {
          return this.nlt("otp.next");
        }
      }
    },
    watch: {
      inited: function(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.doBasicFiltering();
        }
      },
      previewEmail: function(newValue, oldValue) {
        this.email = newValue;
      },
    },
    components: {
      'button-field': ButtonField,
      "header-1": PortalHeader,
      "cb-password": Password
    },
    mixins: [AuthMixin, PreviewPortalMixin, MultiEntityMixin],
    mounted() {
      window.cbPostMessage({key: "cb.page_visited", value: "login", type: 'portal'});
      this.$nextTick(function() {
        this.$refs.x2 && this.$refs.x2.focus();
      });
      this.forward = this.$route.query.forward || "home";
      if (this.inited) {
        this.doBasicFiltering();
      }
      if (this.authenticated) {
        this.$router.replace({
          name: this.forward,
          params: fetchForwardParams(this.$route.query)
        });
      }
    },
    methods: {
      doBasicFiltering() {
        if (this.authType != "sso") {
          this.allowed = true;
        }
        else {
          // redirect to error page
        }
        window.cbPostMessage("cb.loaded");
        window.cb_loaded = true;
        window.cbTimeout(() => document.getElementById("app").focus(), 1000);
      },
      next() {
        switch (this.step) {
          case "step1":
            return this.step1Next();
          case "step2":
            if(this.showPassword){
              return this.step2Next('verify_password',this.password);
            }
            return this.step2Next('verify_otp',this.otp);
        }
      },

      step1Next() {
        this.showLoader = true;
        this.errorMessage = Validators['validationWrapper']('email', {
          email: {
            message: this.st('invalid_email')
          }
        }, this.email, {});
        if (this.errorMessage) {
          this.showLoader = false;
          return;
        }
        this.checkIfUserHasPassword();
      },

      step2Next(authService,value) {
        if(!value) {
          this.errorMessage = this.st('enter_account_details');
          return;
        }
        this.showLoader = true;
        this.$store.dispatch(authService, value).then(() => {
          if (this.linked_handles) {
            this.$router.replace({
              name: "choose_account"
            });
          }
          else if(this.customer_handle){
            let fwd = ((!this.showPassword && this.forgotPassword) ? "portal_change_password" : this.forward);
            this.$router.replace({
              name: fwd,
              params: fetchForwardParams(this.$route.query)
            });
          }
        }).catch(error => {
          this.showLoader = false;
          this.errorMessage = error.message;
          logger.e(error);
        });
      },
      checkIfUserHasPassword() {
        this.$store.dispatch('set_loader', true);
        this.$store.dispatch("check_user", {email : this.email, send_otp : false})
        .then(data => {
          this.$store.dispatch('set_loader', false);
          if (data.user_identified) {
            this.step = "step2";
            this.showLoader = false;
            if(this.has_password) {
              this.enterPassword();

            } else {
              this.showPassword = false;
              window.cbTimeout(() => {
                this.$refs.x1.focus();
              }, 400);
            }
          } else {
            this.errorMessage = this.st('no_account');
            this.showLoader = false;
          }
        })
        .catch(error => {
          this.showLoader = false;
          this.$store.dispatch('set_loader', false);
          this.errorMessage = error.message || (error.exceptionType == "cb_param" && error.paramName == "email" && error.paramMessage);
          logger.e(error);
        });
      },
      resendOtp() {
        this.showPassword = false;
        this.dispatchOtp();
        this.errorMessage = undefined;
      },
      onOtpInput(event){
        event.preventDefault();
        if(event.clipboardData || window.clipboardData){
          this.otp = OtpHelper.getOtp((event.clipboardData || window.clipboardData).getData('text'))
        }
      },
      enterPassword(event) {
        this.showPassword = true;
        this.forgotPassword = false;
        this.errorMessage = undefined;
        window.cbTimeout(() => {
          this.$refs.pwd && this.$refs.pwd.$refs.password.focus();
        }, 400);
      },
      forgotPwd() {
        this.resendOtp();
        this.forgotPassword = true;
        this.errorMessage = undefined;
      }
    },
}
</script>

